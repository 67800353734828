import React from 'react';
import {
  Row,
  Col,
  Card,
  Badge,
  Collapse,
  Button,
  Spinner,
} from 'react-bootstrap';

import EDISendModal from './EDISendModal';
import EDITransactionCard from './EDITransactionCard';

import { EDIOrdersProps } from './EDIOrders';

export interface EDIOrderProps extends EDIOrdersProps {
  orderId: number;
}

export interface EDIOrderState {
  isOpen: boolean;
  showModal: boolean;
  isSending: boolean;
}

export default class EDIOrder extends React.Component<
  EDIOrderProps,
  EDIOrderState
> {
  state = {
    isOpen: false,
    showModal: false,
    isSending: false,
  };

  setOrderState = stateObject => {
    this.setState(stateObject);
  };

  render() {
    const { orderId, transactions } = this.props;
    const { isOpen, isSending } = this.state;
    const orderTransactions = transactions
      .filter(transaction => transaction.orderId === orderId)
      .sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return -1;
        } else if (a.createdAt < b.createdAt) {
          return 1;
        } else {
          return 0;
        }
      });
    const status =
      orderTransactions !== undefined && orderTransactions.length > 0
        ? orderTransactions[0].status
        : 'Created';
    return (
      <React.Fragment>
        <Row className='mt-2'>
          <Col>
            <Card>
              <Card.Header onClick={() => this.setState({ isOpen: !isOpen })}>
                <Row>
                  <Col>
                    <h5>{orderId}</h5>
                  </Col>
                  <Col xs='auto'>
                    <Badge
                      pill
                      variant={
                        status === 'Created'
                          ? 'info'
                          : status === 'Sent'
                          ? 'warning'
                          : status === 'MDN Received'
                          ? 'success'
                          : 'secondary'
                      }
                    >
                      {status}
                    </Badge>
                  </Col>
                </Row>
              </Card.Header>
              <Collapse in={isOpen}>
                <Card.Body>
                  <Row className='text-right mb-2'>
                    <Col className='align-self-top text-right'>
                      <Button
                        disabled={isSending}
                        variant='success'
                        onClick={() => this.setState({ showModal: true })}
                      >
                        {isSending === true ? (
                          <React.Fragment>
                            <Spinner size='sm' animation='border' />
                            Sending...
                          </React.Fragment>
                        ) : (
                          'Grab Data and Send'
                        )}
                      </Button>
                    </Col>
                  </Row>
                  <Row className='mt-1'>
                    <Col>
                      {orderTransactions.map((transaction, index) => (
                        <EDITransactionCard
                          key={index}
                          transaction={transaction}
                          {...this.props}
                          {...this.state}
                        />
                      ))}
                    </Col>
                  </Row>
                </Card.Body>
              </Collapse>
            </Card>
          </Col>
        </Row>
        <EDISendModal
          {...this.props}
          {...this.state}
          setOrderState={this.setOrderState}
          status={status}
        />
      </React.Fragment>
    );
  }
}
