import * as React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { TextField } from "../../../Shared/EFBootstrap";
import { RouteComponentProps } from "react-router";

import * as Settings from "../../../../Settings";
import BaseProps from "../../../../interfaces/BaseProps";

import PurchaseOrderCompany from "../../../../classes/Logistics/PurchaseOrderCompany";

const eformedApiBaseUri = Settings.eformedApiBaseUri;

interface MatchParams {
  id: string;
}

interface EditPurchaseOrderCompanyProps
  extends BaseProps,
    RouteComponentProps<MatchParams> {}

interface EditPurchaseOrderCompanyState {
  company: PurchaseOrderCompany;
  isLoading: boolean;
}

export default class EditPurchaseOrderCompany extends React.Component<
  EditPurchaseOrderCompanyProps,
  EditPurchaseOrderCompanyState
> {
  constructor(props) {
    super(props);
    this.state = {
      company: new PurchaseOrderCompany(),
      isLoading: false
    };
  }

  componentDidMount() {
    this.getCompany();
  }

  getCompany = () => {
    const companyId = this.props.match.params.id;

    this.setState({ isLoading: true });
    fetch(
      `${eformedApiBaseUri}po/companies/${companyId}?key=${
        this.props.apiKey
      }&shop=babywise-life.myshopify.com`
    )
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({
          company: data.company,
          isLoading: false
        });
      });
  };

  handleSubmit = () => {
    const history = this.props.history;
    this.setState({ isLoading: true });

    var body = {
      company: this.state.company
    };
    fetch(
      eformedApiBaseUri +
        "po/companies.json?key=" +
        this.props.apiKey +
        "&shop=babywise-life.myshopify.com",
      {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    )
      .then(response => {
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          return response.json();
        }
      })
      .then(data => {
        this.props.addAlert("success", "Company saved.");
        history.push("/logistics/po/companies");
      })
      .catch(error => {
        this.setState({ isLoading: false });
        console.error(error);
        try {
          error.text().then(errorText => console.error(errorText));
        } catch {}
        this.props.addAlert(
          "danger",
          "There was an error while saving the company."
        );
      });
  };

  handleChange = fieldName => newValue => {
    let tempCompany = this.state.company;
    tempCompany[fieldName] = newValue;
    this.setState({ company: tempCompany });
  };

  render() {
    return (
      <React.Fragment>
        <h1>Edit Purchase Order Company</h1>

        <h6>Enter our company information</h6>
        <br />
        <Form>
          <Row>
            <Col>
              <TextField
                label="Company Name"
                value={this.state.company.name}
                onChange={this.handleChange("name")}
              />
            </Col>
            <Col>
              <TextField
                label="Email Address"
                value={this.state.company.emailAddress}
                onChange={this.handleChange("emailAddress")}
                type="email"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label="Address"
                value={this.state.company.address}
                onChange={this.handleChange("address")}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label="City"
                value={this.state.company.city}
                onChange={this.handleChange("city")}
              />
            </Col>
            <Col>
              <TextField
                label="State/Province Code"
                value={this.state.company.state}
                onChange={this.handleChange("state")}
                maxLength={2}
                placeholder="OR"
              />
            </Col>
            <Col>
              <TextField
                label="Zip/Postal Code"
                value={this.state.company.zip}
                onChange={this.handleChange("zip")}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label="Country"
                value={this.state.company.country}
                onChange={this.handleChange("country")}
              />
            </Col>
            <Col>
              <TextField
                label="Phone Number"
                value={this.state.company.phone}
                onChange={this.handleChange("phone")}
                placeholder="(800) 555-1212"
              />
            </Col>
          </Row>
        </Form>

        <Row>
          <Button
            block
            variant="success"
            onClick={this.handleSubmit}
            disabled={this.state.isLoading}
          >
            {this.state.isLoading ? "Saving..." : "Edit Company"}
          </Button>
        </Row>
      </React.Fragment>
    );
  }
}
