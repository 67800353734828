import * as React from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';

import * as Settings from '../../../../Settings';
import BaseProps from '../../../../interfaces/BaseProps';
import Vendor from '../../../../classes/Logistics/PurchaseOrderVendor';
import LoadingPage from '../../../Shared/LoadingPage';

const eformedApiBaseUri = Settings.eformedApiBaseUri;

interface ManagePurchaseOrderVendorsProps
  extends BaseProps,
    RouteComponentProps {}

interface ManagePurchaseOrderVendorsState {
  isLoading: boolean;
  vendors: Array<Vendor>;
}

export default class ManagePurchaseOrderVendors extends React.Component<
  ManagePurchaseOrderVendorsProps,
  ManagePurchaseOrderVendorsState
> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      vendors: [],
    };
  }

  componentDidMount() {
    this.getVendors();
  }

  getVendors = () => {
    this.setState({ isLoading: true });
    fetch(
      eformedApiBaseUri +
        'po/vendors.json?key=' +
        this.props.apiKey +
        '&shop=babywise-life.myshopify.com',
    )
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({ vendors: data.vendors, isLoading: false });
      });
  };

  render() {
    const { vendors } = this.state;
    const history = this.props.history;
    if (this.state.isLoading) {
      return (
        <React.Fragment>
          <h1>Manage Purchase Order Vendors</h1>
          <LoadingPage />
        </React.Fragment>
      );
    } else if (
      vendors === undefined ||
      vendors === null ||
      vendors.length === 0
    ) {
      return (
        <React.Fragment>
          <h1>Manage Purchase Order Vendors</h1>
          <Row className='text-right'>
            <Col>
              <LinkContainer to='/logistics/po/vendors/add'>
                <Button variant='success'>Add A Vendor</Button>
              </LinkContainer>
            </Col>
          </Row>
          <Row>
            <Card>No vendors found...</Card>
          </Row>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h1>Manage Purchase Order Vendors</h1>
          <Row className='text-right'>
            <Col>
              <LinkContainer to='/logistics/po/vendors/add'>
                <Button variant='success'>Add A Vendor</Button>
              </LinkContainer>
            </Col>
          </Row>
          {vendors.map((vendor, index) => (
            <VendorListItem
              key={index}
              id={vendor.id}
              vendor={vendor}
              history={history}
            />
          ))}
        </React.Fragment>
      );
    }
  }
}

const VendorListItem = props => {
  return (
    <Row className='mt-2'>
      <Col>
        <Card>
          <Card.Header>{props.vendor.name}</Card.Header>
          <Card.Body>
            <Card.Text>{props.vendor.emailAddress}</Card.Text>
            <Card.Text>
              {props.vendor.address} {props.vendor.city}, {props.vendor.state}
            </Card.Text>
            <Card.Text>{props.vendor.phone}</Card.Text>
            <LinkContainer to={'/logistics/po/vendors/' + props.vendor.id}>
              <Card.Link>Edit</Card.Link>
            </LinkContainer>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
