import React from 'react';
import { useDrag, useDrop, ConnectableElement } from 'react-dnd';

import { ImagesDraggableProps } from './ImagesDraggable';
import MarketplaceProductImage from '../../../../../classes/Marketplace/Image';

import PanelImage from './Image';

export interface ImageDraggableProps extends ImagesDraggableProps {
  index: number;
  id: string;
  image: MarketplaceProductImage;
  moveCard: (id: string, to: number) => void;
  findCard: (id: string) => { index: number };
}

interface Item {
  type: string;
  id: string;
  originalIndex: number;
}

const ImageDraggable = (props: ImageDraggableProps) => {
  const {id, moveCard, findCard, isEditing} = props;

  const originalIndex = findCard(id).index;

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: 'card', id, originalIndex },
    canDrag: isEditing,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem()
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        moveCard(droppedId, originalIndex)
      }
    },
  });

  const [, drop] = useDrop({
    accept: 'card',
    canDrop: () => false,
    hover({ id: draggedId }: Item) {
      if (draggedId !== id) {
        const { index: overIndex } = findCard(id)
        moveCard(draggedId, overIndex)
      }
    },
  });

  const opacity = isDragging ? 0 : 1;


  return (
    <div ref={preview} style={{ opacity }} className="col-md-6 col-sm-12">
      <PanelImage
        ref={(node: ConnectableElement) => drag(drop(node))}
        {...props}
      />
    </div>
  );
}

export default ImageDraggable;