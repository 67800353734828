export default class MarketplaceChannelMarketplaceProduct {
  marketplaceChannelId: string;
  marketplaceProductId: string;
  externalId: string;
  categoryId: number;
  parentCategoryId: number;
  active: boolean;
  taxCode: string;
  publishStatus: string;
  updatedAt: string;
  syncedAt: string;
  notFoundCount: number;
  error: boolean;
  errorMessages: string[];
}
