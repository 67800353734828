export default class MarketplaceChannelMarketplaceVariant {
  marketplaceChannelId: string;
  marketplaceVariantId: string;
  externalId: string;
  sourceProductName: string;
  active: boolean;
  isPublishable: boolean;
  discountName: string;
  discountStartDate: string;
  discountStartDateTz: string;
  discountEndDate: string;
  discountEndDateTz: string;
  currentDateTz: string;
  discountStatus: number;
  discountManualPrice: number | string;
  price: number | string;
  compareAtPrice: number | string;
  map: number | string;
  manualPrice: number | string;
  cost: number | string;
  handlingCharge: number | string;
  outValue: number | string;
  variantHandlingCharge: number | string;
  publishStatus: string;
  syncedAt: string;
  updatedAt: string;
  inventoryUpdatedAt: string;
  inventorySyncedAt: string;
  priceUpdatedAt: string;
  priceSyncedAt: string;
  itemStatusCheckedAt: string;
  notFoundCount: number;
  error: boolean;
  errorMessages: string[];
}