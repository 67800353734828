import React, { useState } from 'react';
import { Row, Col, Collapse, Card } from 'react-bootstrap';

import Product from '../../../../classes/Marketplace/Product';
import MetafieldModel from '../../../../classes/Marketplace/Metafield';
import MetafieldTextfield from './Metafield';

export interface ProductMetafieldsProps {
  product: Product;
  isEditing: boolean;
  handleMetafieldChange: (mfield: MetafieldModel, newValue: string) => void;
}

const ProductMetafields = (props: ProductMetafieldsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isEditing, product, handleMetafieldChange } = props;

  const metafields = product.metafields !== undefined && product.metafields !== null &&
    product.metafields.length > 0 && product.metafields.filter((metafield) => metafield.ownerId === product.id.toString());

  return (
    <Card className='mt-3'>
      <Card.Header onClick={() => setIsOpen(!isOpen)}>
        <Row>
          <Col>
            <h4>Metafields ({metafields ? metafields.length : 0})</h4>
          </Col>
        </Row>
      </Card.Header>
      <Collapse in={isOpen}>
        <Card.Body>
          {metafields !== undefined && metafields !== null && metafields.length > 0 &&
          metafields.map((metafield, index) => (
            <MetafieldTextfield 
              isEditing={isEditing}
              key={index}
              metafield={metafield}
              handleMetafieldChange={handleMetafieldChange}
            />
          ))}
        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default ProductMetafields;
