import React, { CSSProperties } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

interface TextFieldProps {
  onChange?: any;
  onEnterKeyPress?: any;
  onChangeCheckBox?: any;
  label: string;
  value: any;
  checkBoxValue?: any;
  prefix?: string;
  readOnly?: boolean;
  type?: string;
  step?: number;
  placeholder?: string;
  helpText?: string;
  isValid?: boolean;
  isInvalid?: boolean;
  maxLength?: number;
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  helperTextStyle?: CSSProperties;
  prefixStyle?: CSSProperties;
  labelCheckBox?: boolean;
}

const TextField = (props: TextFieldProps) => {
  const {
    onChange,
    onEnterKeyPress,
    onChangeCheckBox,
    label,
    value,
    checkBoxValue,
    prefix,
    readOnly,
    type,
    step,
    placeholder,
    helpText,
    isValid,
    isInvalid,
    maxLength,
    style,
    labelStyle,
    helperTextStyle,
    prefixStyle,
    labelCheckBox,
  } = props;

  const handleChange = event => {
    if (onChange === null || onChange === undefined) {
      return;
    }
    onChange(event.target.value);
  };

  const handleKeyPress = event => {
    if (
      event.key === 'Enter' &&
      onEnterKeyPress !== undefined &&
      onEnterKeyPress !== null
    ) {
      onEnterKeyPress();
    }
  };

  const handleCheckBoxChange = event => {
    if (onChangeCheckBox === null || onChangeCheckBox === undefined) {
      return;
    }
    onChangeCheckBox(event.target.checked);
  }

  return (
    <Form.Group controlId={label}>
      <Form.Label style={{color: 'black'}}>{label}</Form.Label>
      {labelCheckBox !== null && labelCheckBox !== undefined &&
        <label className="switch text-right textfield" style={{ float: "right" }}>
          <input 
            type="checkbox"
            checked={checkBoxValue}
            onChange={handleCheckBoxChange}
          />
          <span className="textfield-slider" style={checkBoxValue === true ? {textAlign: "left"} : {textAlign: "right"}}>
            {checkBoxValue === true ? "AZ*" : "*AZ*"}
          </span>
        </label>
      }
      <InputGroup>
        {prefix !== null && prefix !== undefined && (
          <InputGroup.Text id='price-prepend' style={prefixStyle}>{prefix}</InputGroup.Text>
        )}
        <Form.Control
          type={type}
          step={step}
          readOnly={
            readOnly === undefined || readOnly === null ? false : readOnly
          }
          value={value || ''}
          placeholder={placeholder}
          onChange={handleChange}
          maxLength={maxLength}
          onKeyPress={handleKeyPress}
          style={style}
        />
      </InputGroup>
      <HelpText helpText={helpText} isInvalid={isInvalid} isValid={isValid} helperTextStyle={helperTextStyle}/>
    </Form.Group>
  );
};

const HelpText = props => {
  const { helpText, isInvalid, isValid, helperTextStyle } = props;
  if (helpText === undefined || helpText === null) {
    return null;
  } else if (
    isInvalid !== undefined &&
    isInvalid !== null &&
    isInvalid === true
  ) {
    return (
      <Form.Control.Feedback type='invalid'>{helpText}</Form.Control.Feedback>
    );
  } else if (isValid !== undefined && isValid !== null && isValid === true) {
    return (
      <Form.Control.Feedback type='valid'>{helpText}</Form.Control.Feedback>
    );
  } else {
    return <p style={helperTextStyle}>{helpText}</p>;
  }
};

export default TextField;
