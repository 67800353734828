import React from "react";
import AdminMenu from "./AdminMenu";
import FamilywiseMenu from "./FamilywiseMenu";
import LogisticsMenu from "./LogisticsMenu";
import MarketplaceMenu from "./MarketplaceMenu";
import SellerMenu from "./SellerMenu";

const InnerNavMenu = props => {
  if (props.app === "Admin") {
    return <AdminMenu {...props} />;
  } else if (props.app === "Logistics") {
    return <LogisticsMenu {...props} />;
  } else if (props.app === "Marketplace") {
    return <MarketplaceMenu {...props} />;
  } else if (props.app === "Familywise") {
    return <FamilywiseMenu {...props} />;
  } else if (props.app === "Seller") {
    return <SellerMenu {...props} />;
  } else {
    return null;
  }
};

export default InnerNavMenu;
