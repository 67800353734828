import React, { useState } from "react";
import { Row, Col, Button, Spinner, Modal, Alert, Badge } from "react-bootstrap";

import { TextField, Select } from "../../../Shared/EFBootstrap";
import * as Settings from "../../../../Settings";
import rest from "../../../../utils/Rest";
import errorHandler from "../../../../utils/ErrorHandler";

import PurchaseOrderItem from "../../../../classes/Logistics/PurchaseOrderItem";

import searchPoItem, { SearchKeys } from "../Items/SearchPoItem";

interface PoLineItemSearchAndAssignModalProps {
  apiKey: string;
  show: boolean;
  handleAddItem: (poItem: PurchaseOrderItem) => void;
  addAlert: (variant: any, text: string, title?: string) => void;
  onHide: () => void;
}

const PoLineItemSearchAndAssignModal = (props: PoLineItemSearchAndAssignModalProps) => {
  const [sellerVariantId, setSellerVariantId] = useState("");
  const [searchFilter, setSearchFilter] = useState("UPC");
  const [searchKey, setSearchKey] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [purchaseOrderItems, setPurchaseOrderItems] = useState(new Array<PurchaseOrderItem>());
  const [hasLoaded, setHasLoaded] = useState(0);
  const [modalAlert, setModalAlert] = useState("");

  const getPurchaseOrderItems = async (fromBegin: boolean = true) => {
    setIsProcessing(true);
    if (fromBegin) {     
      setHasLoaded(0);
      setPurchaseOrderItems(new Array<PurchaseOrderItem>()); 
    }
    try {
      let results = await rest.get(`${Settings.eformedApiBaseUri}po/items.json/search?key=${
        props.apiKey}&shop=babywise-life.myshopify.com&skip=${hasLoaded}&searchkey=${encodeURIComponent(searchKey)}&searchfilter=${encodeURIComponent(searchFilter)}`
      );
      let items: PurchaseOrderItem[] = results.items;
      if (items.length > 0) {
        let currentLoaded = hasLoaded + items.length;
        setHasLoaded(currentLoaded);
        let sellervariantsTemp = purchaseOrderItems;
        sellervariantsTemp.push(...items);
        setPurchaseOrderItems(sellervariantsTemp);
      }
    } catch (error) {
      props.onHide();
      errorHandler(
        error,
        'There was an error while getting PO items. Please try again, if this is not the first time you have seen this error, please report the issue.',
        props.addAlert,
      );
    }
    setIsProcessing(false);
  }

  const getPoItemFromSellerVariantId = async () => {
    let result = await searchPoItem(sellerVariantId, SearchKeys.SELLER_VARIANT_KEY, props.apiKey);
    let poItem = new PurchaseOrderItem();
    poItem = { ...poItem, ...result };
    if (poItem.description !== "No Item Found") {
      props.handleAddItem(poItem);
      setAlert("PO item added.");
    } else {
      setAlert("No PO item available for entered Seller Variant ID.");
    }
  }

  const setAlert = (alart: string) => {
    setModalAlert(alart);
    setTimeout(() => { 
      setModalAlert("");
    }, 4000);
  }

  const modalCloseFunc = () => {
    setSellerVariantId("");
    setSearchFilter("UPC");
    setSearchKey("");
    setIsProcessing(false);
    setPurchaseOrderItems(new Array<PurchaseOrderItem>());
    setHasLoaded(0);
    setModalAlert("");

    props.onHide();
  }

  const modalProps = {
    show: props.show,
    onHide: modalCloseFunc
  }
  
  return (
    <Modal
      {...modalProps}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md="8">
            <TextField
              label='Assign PO Item'
              placeholder="Seller Variant ID"
              value={sellerVariantId}
              onChange={newValue => {
                setSellerVariantId(newValue);
              }}
            />
          </Col>
          <Col md="4">
            <Button
              style={{marginTop: "28px", height: "45px"}}
              className='btn-block'
              size='sm'
              variant='outline-info'
              onClick={() => {
                setSellerVariantId("");
                getPoItemFromSellerVariantId();
              }}
            >
              Add
            </Button>
          </Col>
        </Row>
        <Row>
          {modalAlert !== "" && 
          <Col>
            <Alert variant={modalAlert === "PO item added." ? "success" : "warning"}>
              {modalAlert}
            </Alert>
          </Col>
          }
        </Row>
        <hr/>
        <Row>
          <Col md="5">
            <TextField
              label='Find PO Item'
              placeholder={searchFilter}
              value={searchKey}
              onChange={newValue => setSearchKey(newValue)}
            />
          </Col>
          <Col md="3">
            <Select
              label='Filter'
              options={[
                { label: 'UPC', value: 'UPC' },
                { label: 'MPN', value: 'MPN' },
                { label: 'SKU', value: 'SKU' }
              ]}
              value={searchFilter}
              onChange={newValue => setSearchFilter(newValue)}
            />
          </Col>
          <Col md="4">
            <Button
              style={{marginTop: "28px", height: "45px"}}
              className='btn-block'
              size='sm'
              variant='success'
              onClick={() => {
                getPurchaseOrderItems();
              }}
            >
              {isProcessing ?
              <React.Fragment>
                <Spinner size='sm' animation='border' />
                Searching...
              </React.Fragment> :
              "Search"
              }
            </Button>
          </Col>
        </Row>
        {purchaseOrderItems !== undefined && purchaseOrderItems.length > 0 &&
        <Row className="mt-3">
          <Col style={{color: "black"}}>
            Search Results
          </Col>
        </Row>
        }
        {purchaseOrderItems !== undefined && purchaseOrderItems.length > 0 && 
        purchaseOrderItems.map((purchaseOrderItem, index) => (
          <PoItemSearchResult 
            index={index}
            purchaseOrderItem={purchaseOrderItem}
            handleAddItem={props.handleAddItem}
          />
        ))
        }
        {purchaseOrderItems !== undefined && purchaseOrderItems.length > 0 && hasLoaded > 4 &&
          <Row className="mt-3 justify-content-center">
            <Button
              variant='info'
              onClick={() => {
                getPurchaseOrderItems(false);
              }}
            >
              Load More
            </Button>
          </Row>
        }
      </Modal.Body>
    </Modal>
  );
}

interface PoItemSearchResultProps {
  index: number;
  purchaseOrderItem: PurchaseOrderItem;
  handleAddItem: (poItem: PurchaseOrderItem) => void;
}
const PoItemSearchResult = (props: PoItemSearchResultProps) => {
  const [assigned, setAssigned] = useState(false);
  const { index, purchaseOrderItem, handleAddItem } = props;
  return(
    <Row key={index} className="mt-3">
      <Col md="9">
        <Row className="mt-0">
          <Col>
            {`${purchaseOrderItem.sellerVariantId} | 
            ${purchaseOrderItem.itemNumber === null ? "no OSC ID" : purchaseOrderItem.itemNumber}`}
          </Col>
        </Row>
        <Row className="mt-0">
          <Col>
            {`${purchaseOrderItem.vendor} | 
            ${purchaseOrderItem.description === null ? "no Description" : purchaseOrderItem.description}`}
          </Col>
        </Row>
        <Row className="mt-0">
          <Col>
            {`UPC: ${purchaseOrderItem.upc === null ? "no UPC" : purchaseOrderItem.upc} | 
            MPN: ${purchaseOrderItem.mpn === null ? "no MPN" : purchaseOrderItem.mpn} | 
            SKU: ${purchaseOrderItem.sku === null ? "no SKU" : purchaseOrderItem.sku}`}
          </Col>
        </Row>
      </Col>
      <Col md="3">
        <Button
          disabled={assigned}
          className='btn-block'
          size='sm'
          variant={assigned ? 'outline-primary' : 'outline-success'}
          onClick={() => {
            handleAddItem(purchaseOrderItem);
            setAssigned(true);
          }}
        >
          {assigned ? "Added" : "Add"}
        </Button>
      </Col>
    </Row>
  );
}

export default PoLineItemSearchAndAssignModal;