import React from 'react';

import LoadingPage from '../../Shared/LoadingPage';
import BaseProps from '../../../interfaces/BaseProps';
import rest from '../../../utils/Rest';
import errorHandler from '../../../utils/ErrorHandler';

import EDITransaction from '../../../classes/EDI/EDITransaction';

import EDIHeader from './EDIHeader';
import EDIOrders from './EDIOrders';
import { eformedApiBaseUri } from '../../../Settings';

export interface EDIState {
  isLoading: boolean;
  isLoadingMore: boolean;
  hasLoaded: number;
  transactions: EDITransaction[];
}

export interface EDIProps extends BaseProps, EDIState {}

// This template can likely be re-used as EDI moves from OSC-based to Marketplace-based
export default class EDI extends React.Component<BaseProps, EDIState> {
  state = {
    isLoading: false,
    isLoadingMore: false,
    hasLoaded: 0,
    transactions: new Array<EDITransaction>(),
  };

  componentDidMount() {
    this.getTransactions(0);
  }

  getTransactions = async (skip: number) => {
    const { apiKey } = this.props;
    const { transactions } = this.state;
    if (skip === 0) {
      await this.setState({ isLoading: true });
    } else {
      await this.setState({
        isLoadingMore: true,
      });
    }

    try {
      let data: EDITransaction[] = await rest.get(
        `${eformedApiBaseUri}edi/transactions?key=${apiKey}&skip=${skip}`,
      );
      let tempTransactions = transactions;
      if (skip === 0) {
        tempTransactions = new Array<EDITransaction>();
      }
      tempTransactions.push(...data);
      await this.setState({
        transactions: tempTransactions,
        isLoading: false,
        isLoadingMore: false,
        hasLoaded: tempTransactions.length,
      });
    } catch (error) {
      await errorHandler(
        error,
        "There was an error getting transactions. Please try again, if this isn't the first time you've received this error, please report.",
        this.props.addAlert,
      );
      await this.setState({
        isLoading: false,
        isLoadingMore: false,
      });
    }
  };

  getOscUpdate = async () => {
    await this.setState({ isLoading: true });
    try {
      let response = await fetch(`${eformedApiBaseUri}edi/osc`);
      await this.getTransactions(0);
      if (response.status >= 400) {
        throw response;
      }
    } catch (error) {
      await errorHandler(
        error,
        'There was an error while getting orders from OSC.',
        this.props.addAlert,
      );
    }
  };

  render() {
    const { isLoading } = this.state;
    if (isLoading) {
      return (
        <React.Fragment>
          <EDIHeader
            getTransactions={this.getTransactions}
            getOscUpdate={this.getOscUpdate}
          />
          <LoadingPage />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <EDIHeader
            getTransactions={this.getTransactions}
            getOscUpdate={this.getOscUpdate}
          />
          <EDIOrders
            {...this.props}
            {...this.state}
            getTransactions={this.getTransactions}
          />
        </React.Fragment>
      );
    }
  }
}
