import React, { useState } from "react";
import { Row, Col, Button, Spinner, Collapse } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { eformedApiBaseUri } from "../../../../Settings";
import rest from "../../../../utils/Rest";
import errorHandler from "../../../../utils/ErrorHandler";

import { DescriptionFormatterRouteProps } from "./DescriptionFormatter";
import { TextArea } from "../../../Shared/EFBootstrap";

const DescriptionEntry = (props: DescriptionFormatterRouteProps) => {
  const [htmlInput, setHtmlInput] = useState("");
  const [resultHtml, setResultHtml] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { marketplaceId } = props.match.params;
  const { apiKey } = props;

  const submitUpdateVariants = async () => {
    await setIsSubmitting(true);
    await setResultHtml("");
    let body = { tsvChanges: htmlInput };
    try {
      let response = await rest.post(
        `${eformedApiBaseUri}marketplace/${marketplaceId}/descriptioncleanertag/cleanhtml?key=${apiKey}`,
        body
      );
      await setResultHtml(response.tsvChanges);
      await setIsSubmitting(false);
      await setHtmlInput("");
    } catch (error) {
      await errorHandler(
        error,
        "There was an error while submitting the variant data.",
        props.addAlert
      );
      await setIsSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <Row className="mt-3">
        <Col>
          <Button size='sm' variant='info' onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? 'Collapse Editor <' : 'Expand Editor >'}
          </Button>
        </Col>
        <Collapse in={isOpen}>
          <Col className="text-right">
            <CopyToClipboard text={resultHtml}>
              <Button variant="outline-success">
                Copy HTML
              </Button>
            </CopyToClipboard>
          </Col>
        </Collapse>
        <Collapse in={isOpen}>
          <Col xs="auto" style={{ paddingRight: '15px' }} className="text-right">
            <Button
              variant="success"
              disabled={isSubmitting}
              onClick={() => submitUpdateVariants()}
            >
              {isSubmitting ? (
                <React.Fragment>
                  <Spinner size="sm" animation="border" />
                  Cleaning...
                </React.Fragment>
              ) : (
                "Clean"
              )}
            </Button>
          </Col>
        </Collapse>
      </Row>
      <Collapse in={isOpen}>
        <Row className="mt-2">
          <Col>
            <TextArea
              label="HTML Entry"
              rows={15}
              placeholder="//   ENTER HTML CODE HERE   //"
              style={{whiteSpace: "pre"}}
              value={htmlInput}
              onChange={newValue => setHtmlInput(newValue)}
            />
          </Col>
          <Col>
            <TextArea
              label="HTML Response"
              readOnly={true}
              rows={15}
              value={resultHtml}
              onChange={newValue => setResultHtml(newValue)}
            />
          </Col>
        </Row>
      </Collapse>
    </React.Fragment>
  );
};

export default DescriptionEntry;