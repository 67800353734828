import rest from "../../../../../utils/Rest";
import errorHandler from "../../../../../utils/ErrorHandler";
import * as Settings from "../../../../../Settings";

const DownloadProduct = async (props, setParentState) => {
  const { marketplaceId, productId } = props.match.params;
  const { apiKey } = props;

  await setParentState({ isDownloading: true });
  try {
    let uri = `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/products/downloadproductdetails/${productId}?key=${apiKey}`
    
    await fetch(uri).then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'product_list.csv';
        a.click();
      });
      // window.location.href = response.url;
    });
    
    await fetch(uri.replace("downloadproductdetails", "downloadproductvariants")).then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'variant_list.csv';
        a.click();
      });
      // window.location.href = response.url;
    });   
    
    await fetch(uri.replace("downloadproductdetails", "downloadproductpricing")).then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'price_list.csv';
        a.click();
      });
      // window.location.href = response.url;
    });

    await setParentState({ isDownloading: false });
  } catch (error) {
    await errorHandler(
      error,
      "There was an error while downloading product info. Please try again, if this is not the first time you have seen this error, please report the issue.",
      props.addAlert
    );
    await setParentState({ isDownloading: false });
  }
};

export default DownloadProduct;
