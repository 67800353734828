const formDateFormat = odateTime => {
  if (odateTime === undefined || odateTime === null) {
    return '';
  } else {
    if (!odateTime.includes('T')) {
      return odateTime;
    } else {
      let dateTime = new Date(odateTime);
      let year = dateTime.getFullYear();
      let month = dateTime.getMonth() + 1;
      let monthString;
      let dayString;
      if (month < 10) {
        monthString = '0' + month;
      } else {
        monthString = month.toString();
      }
      var day = dateTime.getDate();
      if (day < 10) {
        dayString = '0' + day;
      } else {
        dayString = day.toString();
      }
      return year + '-' + monthString + '-' + dayString;
    }
  }
};

export default formDateFormat;
