import React from 'react';
import { Row, Col } from 'react-bootstrap';

import Metafield from '../../../../classes/Marketplace/Metafield';
import { TextField } from '../../../Shared/EFBootstrap';

export interface MetafieldProps {
  isEditing: boolean;
  metafield: Metafield;
  handleMetafieldChange: (mfield: Metafield, newValue: string) => void;
}

const MetafieldTextfield = (props: MetafieldProps) => {
  const { isEditing, metafield, handleMetafieldChange } = props;
  return (
    <Row>
      <Col>
        <TextField
          label={`${metafield.namespace}: ${metafield.key} (${metafield.valueType})`}
          helpText={metafield.helperText !== undefined && metafield.helperText !== null && metafield.helperText !== "" ? `(${metafield.helperText})` : ""}
          readOnly={!isEditing}
          type={metafield.valueType === 'integer' ? 'number' : 'text'}
          value={metafield.value}
          onChange={newValue =>
            handleMetafieldChange(metafield, newValue)
          }
        />
      </Col>
    </Row>
  );
};

export default MetafieldTextfield;
