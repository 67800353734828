import React, { useState } from "react";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { TextArea } from "../../../Shared/EFBootstrap";

import { eformedApiBaseUri } from "../../../../Settings";

import { PriceSchedulerProps } from "./PriceScheduler";
import rest from "../../../../utils/Rest";
import errorHandler from "../../../../utils/ErrorHandler";

const PriceSchedulerEntry = (props: PriceSchedulerProps) => {
  const [tsvChanges, setTsvChanges] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { marketplaceId } = props.match.params;
  const { apiKey, setResults } = props;

  const submitPriceScheduler = async () => {
    await setIsSubmitting(true);
    await setResults(undefined);
    let body = { tsvChanges: tsvChanges };
    try {
      let response = await rest.put(
        `${eformedApiBaseUri}marketplace/${marketplaceId}/prices/pricescheduler?key=${apiKey}`,
        body
      );
      let data = await response.json();

      await setResults(data);
      await setIsSubmitting(false);
      setTsvChanges("");
    } catch (error) {
      await errorHandler(
        error,
        "There was an error while submitting the bulk changes.",
        props.addAlert
      );
      await setIsSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <Row className="mt-5">
        <Col>
          Enter in tab-delimited changes to be made (copied from a spreadsheet)
          in the order: Product Variant ID, Channel Name, Overide, Start Date, Start Time, End Date, End Time, Discount Name
        </Col>
        <Col lg="3" className="text-right">
          <Button
            variant="success"
            disabled={isSubmitting}
            onClick={() => submitPriceScheduler()}
          >
            {isSubmitting ? (
              <React.Fragment>
                <Spinner size="sm" animation="border" />
                Submitting...
              </React.Fragment>
            ) : (
              "Submit"
            )}
          </Button>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <TextArea
            label="Bulk Changes (TSV)"
            rows={15}
            placeholder="00000000-0000-0000-000000000000  channelname 12.54 01/31/2021  11:45pm 11/12/2021  11:45pm discountname"
            value={tsvChanges}
            onChange={newValue => setTsvChanges(newValue)}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PriceSchedulerEntry;
