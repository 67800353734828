import React from 'react';
import { Row, Col, Collapse, Card, Container, Button, Badge } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import { TextField, Select } from '../../../../Shared/EFBootstrap';
import MarketplaceChannel from '../../../../../classes/Marketplace/Channel';
import MarketplaceChannelMarketplaceProduct from '../../../../../classes/Marketplace/MarketplaceChannelMarketplaceProduct';
import { ChannelsAreaProps } from './ChannelsArea';

import ChannelErrorArea from './ChannelErrorArea';
import WalmartPublishButton from './WalmartPublishButton';
import ChannelVariantArea from './ChannelVariantArea';
import ChannelUpdateModal from './ChannelUpdateModal';

import productFunctions from '../Functions';
import TaxCode from '../../../../../classes/Marketplace/TaxCode';

export interface WalmartChannelAreaProps extends ChannelsAreaProps {
  getTaxCode: (gsId: string) => Promise<TaxCode>;
  channel: MarketplaceChannel;
}

export interface WalmartChannelAreaState {
  isOpen: boolean;
  isUpdating: boolean;
  showError: boolean;
  showModal: boolean;
  gsId: string;
  taxCode: TaxCode;
  updateMessages: string[];
}

export interface WalmartChannelAreaChildProps extends WalmartChannelAreaState {
  setChannelState: (any) => void;
}

interface SelectOption {
  label: string;
  value: string | number;
}

export default class WalmartChannelArea extends React.Component<
  WalmartChannelAreaProps,
  WalmartChannelAreaState
> {
  state = {
    isOpen: false,
    isUpdating: false,
    showError: false,
    showModal: false,
    gsId: '',
    taxCode: undefined,
    updateMessages: [],
  };

  componentDidMount() {
    const { taxCode } = this.state;
    const { product, channel } = this.props;

    const connections = product.marketplaceChannelMarketplaceProducts.filter(
      connection => connection.marketplaceChannelId === channel.id,
    );

    if (taxCode === undefined && connections.length > 0 && connections[0].taxCode !== undefined && connections[0].taxCode !== "") {
      this.getTaxCode(connections[0].taxCode);
    }
  }

  updateChannel = async (type: string, variantId: string = undefined) => {
    const { product, channel, marketplaceId, apiKey } = this.props;

    await productFunctions.updateChannel(
      product,
      channel,
      marketplaceId,
      apiKey,
      type,
      variantId,
      this.setChannelState
    );
  };

  handleChannelChange = (fieldName: string, newValue: string) => {
    let marketplaceChannelMarketplaceProducts = this.props.product.marketplaceChannelMarketplaceProducts;

    const isWalmartChannelAvailable = marketplaceChannelMarketplaceProducts
      .filter(mcmp => mcmp.marketplaceChannelId === this.props.channel.id).length > 0;
    
    let newChannels = [];
    if (isWalmartChannelAvailable) {
      newChannels = marketplaceChannelMarketplaceProducts.map(mcmp => {
        if (mcmp.marketplaceChannelId !== this.props.channel.id) {
          return mcmp;
        }
        return {
          ...mcmp,
          [fieldName]: newValue,
        };
      });
    } else {
      let walmartChannel = new MarketplaceChannelMarketplaceProduct();
      walmartChannel.marketplaceChannelId = this.props.channel.id;
      walmartChannel.marketplaceProductId = this.props.product.id;
      walmartChannel.active = false;
      
      walmartChannel[fieldName] = newValue;

      newChannels = marketplaceChannelMarketplaceProducts;
      newChannels.push(walmartChannel);
    }

    this.props.handleProductChange('marketplaceChannelMarketplaceProducts', newChannels);
  }

  setChannelState = async (stateObject) => {
    await this.setState(stateObject);
  };

  getChildCategoriesForParentId = (parentId: number | string) : SelectOption[] => {
    const { walmartCategories } = this.props;
    const parentCategories = walmartCategories.filter(category => category.categoryId.toString() === parentId.toString());
    return parentCategories !== undefined && parentCategories.length > 0 ?
      parentCategories[0].children.map(category => { return { label: category.name, value: category.categoryId } }) :
      [{label: "Select an option", value: undefined}];
  }

  getTaxCode = async (gsId: string) => {
    const preGsId = this.state.gsId;
    if (gsId !== undefined && gsId !== null && gsId !== "" && preGsId !== gsId) {
      let taxCode = await this.props.getTaxCode(gsId);
      if (taxCode !== undefined && taxCode !== null) {
        this.setState({ taxCode: taxCode });
      } else {
        this.setState({ taxCode: undefined });
      }
      this.setState({ gsId: gsId });
    }
  }

  render() {
    const { isEditing, productId, marketplaceId, product, channel, walmartCategories, apiKey, handleVariantChange, addAlert } = this.props;
    const { isOpen, taxCode } = this.state;

    const connections = product.marketplaceChannelMarketplaceProducts.filter(
      connection => connection.marketplaceChannelId === channel.id,
    );

    const published =
      connections !== undefined &&
      connections !== null &&
      connections.length === 1 &&
      connections[0].active === true;
    const syncError =
      connections !== undefined &&
      connections !== null &&
      connections.length === 1 &&
      connections[0].error === true;
    const connection =
      connections !== undefined &&
      connections !== null &&
      connections.length === 1 &&
      connections[0];

    let parentCategories = walmartCategories.map(category => {
      return { label: category.name, value: category.categoryId };
    });

    let subCategories : SelectOption[] = [];

    if (connections !== undefined && connections !== null && connections.length === 1) {
      if (connections[0].parentCategoryId !== undefined && connections[0].parentCategoryId !== null) {
        subCategories = this.getChildCategoriesForParentId(connections[0].parentCategoryId)
      }
    }

    parentCategories.unshift({label: "Select an option", value: undefined});
    if (subCategories.length === 0 || (subCategories.length > 0 && subCategories[0].value !== undefined)) {
      subCategories.unshift({label: "Select an option", value: undefined});
    }

    const isSubCategoriesAvailable = subCategories.length > 1;

    return (
      <Card className='mt-1'>
        <Card.Header onClick={() => this.setState({ isOpen: !isOpen })}>
          <Row>
            <Col>
              <h6>{channel.name}</h6>
            </Col>
            <Col lg='3' className='align-self-center text-right'>
              {published === false ? (
                <Badge pill variant='danger'>
                  Unpublished
                </Badge>
              ) : (
                <Badge pill variant='success'>
                  Published
                </Badge>
              )}
              {syncError === true && (
                <Badge pill variant='danger'>
                  Sync Error
                </Badge>
              )}
            </Col>
          </Row>
        </Card.Header>
        <Collapse in={isOpen}>
          <Card.Body>
            <Row>
              {published === true && 
              <React.Fragment>
              <Col>
                <Button
                  variant='outline-info'
                  onClick={() => this.updateChannel('product')}
                >
                  Push Product and Variant Info
                </Button>
              </Col>
              </React.Fragment>}
              {published === false && <Col></Col>}

              <Col lg='3' className='align-text-top align-self-top text-right'>
                <WalmartPublishButton {...this.props} />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Select
                  label='Parent Category'
                  readOnly={!isEditing}
                  options={parentCategories}
                  value={connection.parentCategoryId !== null ? connection.parentCategoryId : parentCategories[0].value}
                  onChange={newValue =>
                    this.handleChannelChange('parentCategoryId', newValue === 'Select an option' ? undefined : newValue)
                  }
                />
              </Col>
              <Col>
                <Select
                  label='Sub Category'
                  readOnly={!(isEditing && isSubCategoriesAvailable)}
                  options={subCategories}
                  value={connection.categoryId !== null ? connection.categoryId : subCategories[0].value}
                  onChange={newValue =>
                    this.handleChannelChange('categoryId', newValue === 'Select an option' ? undefined : newValue)
                  }
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md="3">
                <TextField
                  label='Walmart Tax Code'
                  readOnly={!isEditing}
                  value={connection.taxCode}
                  onChange={newValue => {
                    this.handleChannelChange('taxCode', newValue.trim() === '' ? undefined : newValue);
                    this.getTaxCode(newValue.trim());
                  }}
                />
              </Col>
              <Col className='align-self-center align-middle'>
                {taxCode !== undefined ? `${taxCode.gsId}: ${taxCode.gsDesc}` : ""}
              </Col>
              <LinkContainer exact to={`/marketplace/${marketplaceId}/tools/taxcodes`}>
                <Col className='align-self-center align-middle clickable-text' xs="auto">
                  See Codes
                </Col>
              </LinkContainer>
            </Row>
            <Container className="p-0 mt-4">
              {product.variants.map((variant, variantIndex) => (
                variant.marketplaceChannelMarketplaceVariants !== undefined &&
                variant.marketplaceChannelMarketplaceVariants.map((mcmv, indexx) => (
                  mcmv.marketplaceChannelId === channel.id && 
                    <ChannelVariantArea
                      apiKey={apiKey}
                      productId={productId}
                      marketplaceId={marketplaceId}
                      product={product}
                      variant={variant}
                      channel={channel}
                      connection={mcmv}
                      productConnection={connection}
                      key={indexx}
                      isEditing={isEditing}
                      thresholdInventory={channel.thresholdInventory}
                      index={indexx}
                      variantIndex={variantIndex}
                      handleVariantChange={handleVariantChange}
                      updateChannel={this.updateChannel}
                      addAlert={addAlert}
                    />
                ))
              ))}
            </Container>
            {syncError === true && (
              <ChannelErrorArea
                connections={connections}
                setChannelState={this.setChannelState}
                {...this.state}
              />
            )}
          </Card.Body>
        </Collapse>
        <ChannelUpdateModal
          setChannelState={this.setChannelState}
          {...this.state}
        />
      </Card>
    );
  }
}
