import React from "react";
import { Row, Col } from "react-bootstrap";
import LoadingPage from "../Shared/LoadingPage";

import { eformedApiBaseUri } from "../../Settings";
import rest from "../../utils/Rest";
import errorHandler from "../../utils/ErrorHandler";
import SearchTextBox from '../Shared/SearchTextBox';

import BaseProps from "../../interfaces/BaseProps";
import User from "../../classes/Core/User";
import Marketplace from "../../classes/Marketplace/Marketplace";
import LogisticsCompany from "../../classes/Logistics/LogisticsCompany";
import Seller from "../../classes/Seller/Seller";

import UserLine from "./Users/UserLine";

const initialLogisticsCompany = new LogisticsCompany(
  "0",
  "Select Logistics Company..."
);
const initialMarketplace = new Marketplace("0", "Select Marketplace...");
const initialSeller = new Seller("0", "Select Seller...");

export default class Users extends React.Component<BaseProps> {
  state = {
    isLoading: true,
    searchText: '',
    users: new Array<User>(),
    logisticsCompanies: [initialLogisticsCompany],
    marketplaces: [initialMarketplace],
    sellers: [initialSeller]
  };

  componentDidMount() {
    this.getUsers();
    this.getLogisticsCompanies();
    this.getMarketplaces();
    this.getSellers();
  }

  getUsers = async () => {
    if (this.props.user.admin === true) {
      this.setState({ isLoading: true });
      try {
        let data = await rest.get(
          `${eformedApiBaseUri}users?key=${this.props.apiKey}`
        );
        this.setState({ users: data, isLoading: false });
      } catch (error) {
        this.setState({ isLoading: false });
        errorHandler(
          error,
          "There was an error while getting users.",
          this.props.addAlert
        );
      }
    }
  };

  getLogisticsCompanies = async () => {
    if (this.props.user.admin === true) {
      try {
        let data = await rest.get(
          `${eformedApiBaseUri}logistics/logisticscompanies?key=${
            this.props.apiKey
          }`
        );
        data.unshift(initialLogisticsCompany);
        this.setState({ logisticsCompanies: data });
      } catch (error) {
        errorHandler(
          error,
          "There was an error while getting logistics companies.",
          this.props.addAlert
        );
      }
    }
  };

  getMarketplaces = async () => {
    if (this.props.user.admin === true) {
      try {
        let data = await rest.get(
          `${eformedApiBaseUri}marketplace/marketplaces?key=${
            this.props.apiKey
          }`
        );
        data.unshift(initialMarketplace);
        this.setState({ marketplaces: data });
      } catch (error) {
        errorHandler(
          error,
          "There was an error while getting marketplaces.",
          this.props.addAlert
        );
      }
    }
  };

  getSellers = async () => {
    if (this.props.user.admin === true) {
      try {
        let data = await rest.get(
          `${eformedApiBaseUri}seller/sellers?key=${this.props.apiKey}`
        );
        data.unshift(initialSeller);
        this.setState({ sellers: data });
      } catch (error) {
        errorHandler(
          error,
          "There was an error while getting sellers.",
          this.props.addAlert
        );
      }
    }
  };

  setParentState = stateObject => {
    this.setState(stateObject);
  };

  render() {
    if (this.props.user.admin !== true) {
      return (
        <React.Fragment>
          <h1>Users Admin</h1>
          <Row className="justify-content-center">
            <Col>You do not have access to this content.</Col>
          </Row>
        </React.Fragment>
      );
    } else if (this.state.isLoading) {
      return (
        <React.Fragment>
          <h1>Users Admin</h1>
          <LoadingPage />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h1>Users Admin</h1>
          <Row>
            <Col>
              All permissions besides admin are editable, use the edit button in
              the card header to edit, then save after all edits are complete.
              Logistics, Marketplaces, and Sellers are lists of the item the
              person has access to.
            </Col>
          </Row>
          <SearchTextBox 
            searchText={this.state.searchText}
            setSearchText={newValue => this.setState({ searchText: newValue })}
          />
          <Row>
            <Col>
              {this.state.users.filter(s => s.email.toLowerCase()
                  .startsWith(this.state.searchText.toLowerCase())
              ).map((user, index) => (
                <UserLine
                  key={index}
                  {...this.props}
                  {...this.state}
                  user={user}
                  setParentState={this.setParentState}
                />
              ))}
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
}
