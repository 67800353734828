const legacy = {
  name: 'Legacy',
  variant: 'danger',
  text: 'light',
  description:
    'This stack contains apps that should likely be rewritten at some point',
  apps: [
    {
      name: 'Stork',
      link: 'https://github.com/eformedpartners/stork',
      description:
        'Large app from ~1998 that powers part of the OSCommerce Drop Ship Seller platform. Sellers can login and edit product and inventory information. The EDI portion of this app is broken and considered deprecated. There are many other pieces that have been considered deprecated pre-our-acquistion: this app was actually double acquired (once back in ~2004).',
      services: [
        {
          name: 'Stork',
          type: 'Monolith',
          variant: 'danger',
          description: 'Monolith app, only one service.',
          location:
            'Bare metal server rack inside the warehouse, with various versions of windows server (2003-2012). Spans 4 servers that must all be running for functionality. The intranet server handles incoming traffic, the edi server handles EDI, the third server houses the app and database, and there is a domain server.',
          language: 'Visual Basic',
          framework: '.Net Framework / SQL Server',
        },
      ],
    },
    {
      name: 'OSCommerce',
      link: 'https://github.com/eformedpartners/oscommerce',
      description:
        'What replaced a lot of the stork functionality is housed in this app. OSCommerce is an open source warehouse management system, but this piece of software has been modified beyond recognition of what it was originally intended to do. It now not only runs warehouse functionality, but has many integrations, both in and out, to channels like Amazon and to Stork itself.',
      services: [
        {
          name: 'OSCommerce.Database',
          type: 'Storage',
          variant: 'info',
          description:
            'A MySQL Database holding lots of product related data. In addition to data, a significant portion of the application logic is housed in stored procedures (Not ideal). Until recently, this server contained the whole app (monolith).',
          location: 'Azure VM - OSCommerce',
          language: 'SQL',
          framework: 'MySQL',
        },
        {
          name: 'OSCommerce.DataProcessing',
          type: 'Back-end',
          variant: 'warning',
          description:
            'Contains the vast majority of the application logic to enable all functionality, and also is the UI.',
          location: 'Azure Web Apps for Containers - oscommerce',
          language: 'PHP',
          framework: 'OSCommerce',
        },
        {
          name: 'OSCommerce.API',
          type: 'Back-end',
          variant: 'warning',
          description:
            'Does the scheduling as well as providing an image API to DataProcessing.',
          location: 'Azure Web Apps for Containers - oscapi',
          language: 'C#',
          framework: 'Azure Functions',
        },
        {
          name: 'OSCommerce.Proxy',
          type: 'Front-end',
          variant: 'success',
          description: 'Provides some redirection for the other services.',
          location: 'Azure Web Apps for Containers - oscproxy',
          language: 'Nginx',
          framework: 'Nginx',
        },
      ],
    },
    {
      name: 'Shipping Portal',
      link: 'https://github.com/eformedpartners/BNOShippingPortal',
      description:
        'An app that allows partners to login and utilize our shipping account to leverage a discount.',
      services: [
        {
          name: 'ShippingPortal.Storage',
          type: 'Storage',
          variant: 'info',
          description: 'The database that houses the data for the app.',
          location: 'Google Cloud SQL - MySQL',
          language: 'SQL',
          framework: 'MySQL',
        },
        {
          name: 'ShippingPortal.WebApp',
          type: 'Back-end',
          variant: 'warning',
          description:
            'The application logic, and also the front end for the customers.',
          location: 'Azure Web Apps for Containers - shipportal',
          language: 'PHP',
          framework: 'None',
        },
      ],
    },
  ],
};

export default legacy;
