import React, { useState } from 'react';
import { Row, Col, Card, Button, Badge, Collapse } from 'react-bootstrap';

import SellerLog from '../../../classes/Seller/SellerLog';
import SellerLogStatusBadge from './SellerLogStatusBadge';

interface SellerLogCardProps {
  index: number;
  sellerLog: SellerLog;
}

const SellerLogCard = (props: SellerLogCardProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { sellerLog } = props;

  return (
    <Row className='mt-4'>
      <Col>
        <Card>
          <Card.Header
            style={{ cursor: 'pointer' }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <Row>
              <Col>
                <Row>
                  <Col className='align-self-center text-dark'>
                    {sellerLog.createdAt
                      .replace('T', ' ')
                      .substring(0, sellerLog.createdAt.lastIndexOf(':'))}
                  </Col>
                  <Col className='align-self-center'>
                    <Badge
                      pill
                      variant={dropShipTypeToBootstrapVariant(
                        sellerLog.dropShipType,
                      )}
                    >
                      {sellerLog.dropShipType}
                    </Badge>
                    <SellerLogStatusBadge status={sellerLog.status} />
                  </Col>
                  <Col>
                    <Button variant='info'>
                      Processed:{' '}
                      <Badge variant='light' pill>
                        {sellerLog.processed}
                      </Badge>
                    </Button>
                  </Col>
                  <Col>
                    <Button variant='success'>
                      Successes:{' '}
                      <Badge variant='light' pill>
                        {sellerLog.successes}
                      </Badge>
                    </Button>
                  </Col>
                  <Col>
                    <Button variant='danger'>
                      Failures:{' '}
                      <Badge variant='light' pill>
                        {sellerLog.failures}
                      </Badge>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Header>
          <Collapse in={isOpen}>
            <Card.Body>
              {sellerLog.messages.map((message, iindex) => (
                <Card.Text key={iindex}>{message}</Card.Text>
              ))}
            </Card.Body>
          </Collapse>
        </Card>
      </Col>
    </Row>
  );
};

const dropShipTypeToBootstrapVariant = (
  dropShipType: string,
):
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark' => {
  if (dropShipType === 'Inventory') {
    return 'primary';
  } else {
    return 'info';
  }
};

export default SellerLogCard;
