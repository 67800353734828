export default class PurchaseOrderVendor {
  constructor(shop?: string) {
    this.shop = shop;
  }

  id: number;
  name: string;
  shop?: string;
  emailAddress: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
  freightTerms: string;
  paymentTerms: string;
  creditLimit: string;
  salesContactName: string;
  salesContactEmail: string;
  salesContactPhone: string;
  apContactName: string;
  apContactEmail: string;
  apContactPhone: string;
  notes: string;
  distributorsId: number;
}
