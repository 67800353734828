const wmsPlan = {
  title: 'Warehouse Management System',
  bullets: [
    'Normally I would suggest decoupling application parts, but this one is so integral I would suggest building it as coupled to the Marketplace components. This would allow for instant syncing of inventory forward to the marketplace.',
    "The user permission scopes for this are already in place and mimic Marketplaces and Sellers and can be controlled on the Admin page. (See 'LogisticsCompanies' in DataModel and PermissionsCheck)",
    "The UI for this isn't built, but the experience to build it is (there are just no components to handle the actual data manipulation) (See EFEnterprise.WebApp/Portal)",
    "The data model for this isn't built, but in my opinion should be very, very simple, basically only an identifier and location(s), and all product data should be pushed forward and controlled in the marketplace components.",
    'I recommend writing this in ASP.Net Core (C#) as the data model would be simple to add to and similar components it will be working with are written this way. Another language would be highly duplicative code wise.',
    'The feature set can be determined by the business, but I would recommend modularizing any features outside the very specific scope of warehouse management. If something smells like Business Intelligence or Forecasting, split it off from this service and have it use the API built here.',
    'There is a PO System that is built that should be merged into this project.  The implementation will need to change, since it currently does things like updating OSC, but the work should be minimal.',
  ],
};

export default wmsPlan;
