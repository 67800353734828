import React from "react";
import { Row, Col, Card, Button, Collapse, Badge } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactHtmlParser from 'react-html-parser';
import { LinkContainer } from 'react-router-bootstrap';

import SellerVariant from "../../../classes/Seller/SellerVariant";

import SellerVariantAssociations from "./SellerVariantAssociations";

import { MarketplaceSellerVariantsProps } from "./SellerVariants";
import SellerVariantDropdown from './SellerVariantDropdown';

export interface SellerVariantCardProps extends MarketplaceSellerVariantsProps {
  sellerVariant: SellerVariant;
  marketplaceId: string;
  getSellerVariants: (skip: number) => void;
  setParentState: (stateObject: any) => void;
}

export default class SellerVariantCard extends React.Component<
  SellerVariantCardProps
> {
  state = {
    isOpen: false,
    ignored: this.props.sellerVariant.ignored === true ? true : false
  };

  setIgnore = (ignore: boolean) => {
    this.setState({ ignored: ignore });
  }

  formatPriceFields = (price: number) : number => {
    return price !== undefined && price !== null ? price : 0;
  }

  render() {
    const { sellerVariant, getSellerVariants, setParentState, changeApp } = this.props;
    const { isOpen, ignored } = this.state;
    const isSellerVariantAssociated = sellerVariant.sellerVariantMarketplaceVariants !== undefined && sellerVariant.sellerVariantMarketplaceVariants.length > 0;
    const isArchived = sellerVariant.archived === true;

    return (
      <Row className="mt-2">
        <Col>
          <Card>
            <Card.Header>
              <Row>
                <Col
                  className="align-self-center"
                  onClick={() =>
                    this.setState({
                      isOpen: !isOpen
                    })
                  }
                >
                  {sellerVariant.vendor}<br/>
                  {sellerVariant.productTitle}<br/>
                  {getSellerVariantOptionString(sellerVariant)}
                </Col>
                <Col className="align-self-center" xs="auto">
                  {ignored === true && (
                    <Badge pill variant="warning">
                      Ignored
                    </Badge>
                    )}
                    {isArchived === true && (
                        <Badge pill variant="danger">
                            Archived
                        </Badge>
                    )}
                    {isSellerVariantAssociated ? (
                    <Badge pill variant="success">
                        Associated
                    </Badge>
                    ) : (
                    <Badge pill variant="danger">
                      Unassociated
                    </Badge>
                  )}
                  {sellerVariant.inventoryPolicy === 'continue' ?
                    <Badge pill variant="info">
                      In Stock
                    </Badge> : (sellerVariant.quantityAboveThreshold > 0 ? (
                      <Badge pill variant="info">
                        Stock: {sellerVariant.quantityAboveThreshold}
                      </Badge>
                    ) : (
                      <Badge pill variant="danger">
                        Out of Stock
                      </Badge>
                    ))}
                  {sellerVariant.duplicateVariant &&
                    <Badge pill variant="info">
                      Duplicate
                    </Badge>
                  }
                  {sellerVariant.matchedVariant &&
                    <Badge pill variant="info">
                      Matched
                    </Badge>
                  }
                </Col>
                <Col className="align-self-center" xs="auto">
                  <CopyToClipboard text={sellerVariant.id}>
                    <Button size="sm" variant="outline-success">
                      Copy Id
                    </Button>
                  </CopyToClipboard>
                </Col>
                <Col className="align-self-center" xs="auto">
                  {!isSellerVariantAssociated &&
                    <SellerVariantDropdown 
                      {...this.props}
                      ignored={ignored}
                      setIgnore={this.setIgnore}
                    />
                  }
                </Col>
              </Row>
            </Card.Header>
            <Collapse in={isOpen}>
              <Card.Body>
                <Row>
                  <Col md="3">Created At:</Col>
                  <Col md="3">
                    {sellerVariant.createdAt
                      .replace('T', ' ')
                      .substring(0, sellerVariant.createdAt.lastIndexOf(':'))}
                  </Col>
                  <Col md="3">Updated At:</Col>
                  <Col md="3">
                    {sellerVariant.updatedAt
                      .replace('T', ' ')
                      .substring(0, sellerVariant.updatedAt.lastIndexOf(':'))}
                  </Col>
                </Row>
                <Row>
                  <Col md="3">Cost Changed At:</Col>
                  <Col md="3">
                    {sellerVariant.costChangedAt && sellerVariant.costChangedAt
                      .replace('T', ' ')
                      .substring(0, sellerVariant.costChangedAt.lastIndexOf(':'))}
                  </Col>
                  <Col md="3">Inventory Changed At:</Col>
                  <Col md="3">
                    {sellerVariant.inventoryChangedAt && sellerVariant.inventoryChangedAt
                      .replace('T', ' ')
                      .substring(0, sellerVariant.inventoryChangedAt.lastIndexOf(':'))}
                  </Col>
                </Row>
                <Row>
                  <Col md="3">Connections Updated At:</Col>
                  <Col md="3">
                    {sellerVariant.traitsUpdatedAt && sellerVariant.traitsUpdatedAt
                      .replace('T', ' ')
                      .substring(0, sellerVariant.traitsUpdatedAt.lastIndexOf(':'))}
                  </Col>
                  <Col md="3">PO Synced At:</Col>
                  <Col md="3">
                    {sellerVariant.pOSyncedAt && sellerVariant.pOSyncedAt
                      .replace('T', ' ')
                      .substring(0, sellerVariant.pOSyncedAt.lastIndexOf(':'))}
                  </Col>
                </Row>
                <hr/>
                <Row>
                  <Col md="2">Vendor:</Col>
                  <Col md="4">{sellerVariant.vendor}</Col>                 
                  <Col md="2">Variant ID:</Col>
                  <LinkContainer
                    to={`/seller/${sellerVariant.sellerId}/variants?variantId=${sellerVariant.id}`}
                  >
                    <Col md="4" className="clickable-text" onClick={() => changeApp('Seller')}>{sellerVariant.id}</Col>
                  </LinkContainer>
                </Row>
                <Row>
                  <Col md="2">Product Title:</Col>
                  <Col md="4" className="clickable-text" onClick={() => {
                    setParentState({
                      productTitleFilter: sellerVariant.productTitle,
                      skuFilter: '',
                      barcodeFilter: '',
                      isInitialLoading: false
                    });
                    getSellerVariants(0);
                  }}>{sellerVariant.productTitle}</Col>
                  <Col md="2">OSC ID:</Col>
                  <Col md="4">{sellerVariant.oscId}</Col>
                </Row>
                <Row>
                  <Col md="2">Quantity:</Col>
                  <Col md="10">{sellerVariant.quantityAboveThreshold}</Col>
                </Row>
                <hr/>
                <Row>
                  <Col md="2">Variant Title:</Col>
                  <Col md="10">{sellerVariant.associatedTitle}</Col>
                </Row>
                <Row>
                  <Col md="2" >SKU:</Col>
                  <Col md="4" className="clickable-text" onClick={() => {
                    setParentState({
                      skuFilter: sellerVariant.sku,
                      barcodeFilter: '',
                      productTitleFilter: '',
                      isInitialLoading: false
                    });
                    getSellerVariants(0);
                  }}>{sellerVariant.sku}</Col>
                  <Col md="2">Option 1:</Col>
                  <Col md="4">{sellerVariant.option1Name ? `${sellerVariant.option1Name}: ${sellerVariant.option1Value}` : ' '}</Col>
                </Row>
                <Row>
                  <Col md="2">Barcode:</Col>
                  <Col md="4" className="clickable-text" onClick={() => {
                    setParentState({
                      skuFilter: '',
                      barcodeFilter: sellerVariant.barcode,
                      productTitleFilter: '',
                      isInitialLoading: false
                    });
                    getSellerVariants(0);
                  }}>{sellerVariant.barcode}</Col>
                  <Col md="2">Option 2:</Col>
                  <Col md="4">{sellerVariant.option2Name ? `${sellerVariant.option2Name}: ${sellerVariant.option2Value}` : ' '}</Col>
                </Row>
                <Row>
                  <Col md="2">MPN:</Col>
                  <Col md="4">{sellerVariant.mpn}</Col>
                  <Col md="2">Option 3:</Col>
                  <Col md="4">{sellerVariant.option3Name ? `${sellerVariant.option3Name}: ${sellerVariant.option3Value}` : ' '}</Col>
                </Row>
                <hr/>
                <Row>
                  <Col md="2">Feed Price:</Col>
                  <Col md="4">${this.formatPriceFields(sellerVariant.price)}</Col>
                  <Col md="2">MSRP:</Col>
                  <Col md="4">${this.formatPriceFields(sellerVariant.compareAtPrice)}</Col>
                </Row>
                <Row>
                  <Col md="2">MAP:</Col>
                  <Col md="4">${this.formatPriceFields(sellerVariant.map)}</Col>
                  <Col md="2">Cost:</Col>
                  <Col md="4">${this.formatPriceFields(sellerVariant.cost)}</Col>
                </Row>
                <Row>
                  <Col md="2">Handling:</Col>
                  <Col md="4">${this.formatPriceFields(sellerVariant.handlingCharge)}</Col>
                  <Col md="2">Out:</Col>
                  <Col md="4">${this.formatPriceFields(sellerVariant.outValue)}</Col>
                </Row>
                <hr/>
                <Row>
                  <Col md="2" style={{ whiteSpace: "nowrap"}}>Inventory Policy:</Col>
                  <Col md="4">{sellerVariant.inventoryPolicy}</Col>
                  <Col md="2">Lead Time:</Col>
                  <Col md="4">{sellerVariant.leadTime}</Col>
                </Row>
                <hr/>
                <Row>
                  <Col md="2">Weight:</Col>
                  <Col md="4">
                    {sellerVariant.weight}
                    {sellerVariant.weightUnit}
                  </Col>
                  <Col md="2">Height:</Col>
                  <Col md="4">
                    {sellerVariant.dimH}
                    {sellerVariant.dimUnit}
                  </Col>
                </Row>
                <Row>
                  <Col md="2">Length:</Col>
                  <Col md="4">
                    {sellerVariant.dimL}
                    {sellerVariant.dimUnit}
                  </Col>
                  <Col md="2">Width:</Col>
                  <Col md="4">
                    {sellerVariant.dimW}
                    {sellerVariant.dimUnit}
                  </Col>
                </Row>
                <hr/>
                <Row>
                  <Col md="2">Description:</Col>
                  <Col md="10" style={{overflow: 'hidden'}}>{ ReactHtmlParser(sellerVariant.description) }</Col>
                </Row><br/>
                <Row>
                  <Col md="2">Feature 1:</Col>
                  <Col md="10" style={{overflow: 'hidden'}}>{sellerVariant.feature1}</Col>
                </Row><br/>
                <Row>
                  <Col md="2">Feature 2:</Col>
                  <Col md="10" style={{overflow: 'hidden'}}>{sellerVariant.feature2}</Col>
                </Row><br/>
                <Row>
                  <Col md="2">Feature 3:</Col>
                  <Col md="10" style={{overflow: 'hidden'}}>{sellerVariant.feature3}</Col>
                </Row><br/>
                <Row>
                  <Col md="2">Feature 4:</Col>
                  <Col md="10" style={{overflow: 'hidden'}}>{sellerVariant.feature4}</Col>
                </Row><br/>
                <Row>
                  <Col md="2">Feature 5:</Col>
                  <Col md="10" style={{overflow: 'hidden'}}>{sellerVariant.feature5}</Col>
                </Row><br/>
                <Row>
                  <Col md="2">Tags:</Col>
                  <Col md="10">{sellerVariant.tags}</Col>
                </Row>
                <hr/>
                <Row>
                  <Col md="2">Featured Image URL:</Col>
                  <Col md="10">
                    <a href={sellerVariant.featuredImageURL} target="_blank" rel="noopener noreferrer">
                      {sellerVariant.featuredImageURL}
                    </a>
                  </Col>
                </Row>
                <Row>
                  <Col md="2">Variant Image URL:</Col>
                  <Col md="10">
                    <a href={sellerVariant.variantImageURL} target="_blank" rel="noopener noreferrer">
                      {sellerVariant.variantImageURL}
                    </a>
                  </Col>
                </Row>
                <Row>
                  <Col md="2">Other Image URLs:</Col>
                  <Col md="10">
                    {sellerVariant.additionalImageURLs !== null && sellerVariant.additionalImageURLs.split(',').map((url, index) => (
                      <React.Fragment key={index}>
                        <a href={url} target="_blank" rel="noopener noreferrer" key={index}>
                          {url}
                        </a><br/>
                      </React.Fragment>
                    ))}
                  </Col>
                </Row>
                <hr/>
                <Row>
                  <Col md="2">Youtube Id:</Col>
                  <Col md="10">{sellerVariant.youtubeVideoId}</Col>
                </Row>
                {sellerVariant.sellerVariantMarketplaceVariants !== undefined &&
                  sellerVariant.sellerVariantMarketplaceVariants.length > 0 && (
                    <SellerVariantAssociations
                      {...this.props}
                      sellerVariant={sellerVariant}
                    />
                  )}
              </Card.Body>
            </Collapse>
          </Card>
        </Col>
      </Row>
    );
  }
}

export const getSellerVariantOptionString = (sellerVariant: SellerVariant) : string => {
  if (sellerVariant !== undefined && sellerVariant !== null) {
    return (sellerVariant.option1Name && sellerVariant.option1Name !== "" ? `${sellerVariant.option1Name}: ${sellerVariant.option1Value}` : ' ') +
    (sellerVariant.option2Name ? ` / ${sellerVariant.option2Name}: ${sellerVariant.option2Value}` : ' ') +
    (sellerVariant.option3Name ? ` / ${sellerVariant.option3Name}: ${sellerVariant.option3Value}` : ' ');
  } else {
    return "";
  }
}