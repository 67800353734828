import React from 'react';
import { Container } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import NavMenu from './NavMenu';
import AlertBox from './AlertBox';
import Footer from './Footer';

const Layout = props => {
  const Nav = withRouter(routerProps => (
    <NavMenu {...props} {...routerProps} />
  ));
  return (
    <React.Fragment>
      <Nav />
      <AlertBox {...props} />
      <Container className='mt-5' style={{ minHeight: '70vh' }}>
        {props.children}
      </Container>
      <Footer addAlert={props.addAlert} />
    </React.Fragment>
  );
};

export default Layout;
