import React from 'react';
import { Route, Switch } from 'react-router';

import Home from '../Shared/Home';
import Users from '../Admin/Users';
import Sellers from '../Admin/Sellers/Sellers';

import FamilywiseRoutes from './AppRoutes/FamilywiseRoutes';
import LogisticsRoutes from './AppRoutes/LogisticsRoutes';
import MarketplaceRoutes from './AppRoutes/MarketplaceRoutes';
import SellerRoutes from './AppRoutes/SellerRoutes';

import BaseProps from '../../interfaces/BaseProps';
import RoutingProps from '../../interfaces/RoutingProps';

interface RoutingCombinedProps extends BaseProps, RoutingProps {}

const Routing = (props: RoutingCombinedProps) => {
  return (
    <Switch>
      <Route
        exact
        path='/home'
        render={routerProps => <Home {...props} {...routerProps} />}
      />

      {/* Admin */}
      <Route
        path='/admin/users'
        render={routerProps => <Users {...props} {...routerProps} />}
      />
      <Route
        path='/admin/sellers'
        render={routerProps => <Sellers {...props} {...routerProps} />}
      />

      {FamilywiseRoutes.map((route, index) => (
        <Route
          key={index}
          exact={route.exact}
          path={route.path}
          render={routerProps => (
            <route.component {...props} {...routerProps} />
          )}
        />
      ))}

      {LogisticsRoutes.map((route, index) => (
        <Route
          key={index}
          exact={route.exact}
          path={route.path}
          render={routerProps => (
            <route.component {...props} {...routerProps} />
          )}
        />
      ))}

      {MarketplaceRoutes.map((route, index) => (
        <Route
          key={index}
          exact={route.exact}
          path={route.path}
          render={routerProps => (
            // @ts-ignore
            <route.component {...props} {...routerProps} />
          )}
        />
      ))}

      {SellerRoutes.map((route, index) => (
        <Route
          key={index}
          exact={route.exact}
          path={route.path}
          render={routerProps => (
            <route.component {...props} {...routerProps} />
          )}
        />
      ))}
    </Switch>
  );
};

export default Routing;
