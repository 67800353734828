import Marketplace from '../classes/Marketplace/MarketplacePermission';
import User from '../classes/Core/User';

const setMarketplaceFromId = async (
  marketplaceId: string,
  setMarketplace: (marketplace: Marketplace) => void,
  user: User,
  history: any,
) => {
  let tempMarketplace = user.marketplaces.filter(
    marketplace => marketplace.id === marketplaceId,
  );

  if (
    tempMarketplace !== undefined &&
    tempMarketplace !== null &&
    tempMarketplace.length > 0
  ) {
    await setMarketplace(tempMarketplace[0]);
  } else {
    history.push(`/marketplace/${user.marketplaces[0].id}/`);
  }
};

export default setMarketplaceFromId;
