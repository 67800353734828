import React from 'react';
import { Row, Col, Button, Spinner } from 'react-bootstrap';

import * as Settings from '../../../../Settings';
import RouteVariableProps from '../../../../interfaces/RouteVariableProps';
import rest from '../../../../utils/Rest';
import errorHandler from '../../../../utils/ErrorHandler';
import setMarketplaceFromId from '../../../../utils/SetMarketplaceFromId';
import { addUrlParam, removeAllUrlParams } from '../../../../utils/SetUrlParameters';

import WalmartFeedFilters from './WalmartFeedFilters';
import WalmartFeedResults from './WalmartFeedResults';

import WalmartFeed, { Feed_Type } from "../../../../classes/Marketplace/Walmart/WalmartFeed";
import MarketplaceChannel from "../../../../classes/Marketplace/Channel";

import productFunctions from "../../Products/Product/Functions";

interface MatchParams {
  marketplaceId: string;
}

interface WalmartFeedsProps extends RouteVariableProps<MatchParams> {} 

interface WalmartFeedsState {
  isInitialLoading: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  hasMore: boolean;
  hasLoaded: number;
  feedResults: WalmartFeed[];
  channels: MarketplaceChannel[];

  channelFilter: string;
  productIdFilter: string;
  feedIdFilter: string;
  skuFilter: string;
  startDateFilter: string;
  endDateFilter: string;
  orderByFilter: string;
}

export interface WalmartFeedsChildProps extends WalmartFeedsProps, WalmartFeedsState {
  getWalmartFeeds: (skip: number) => void;
  setParentState: (stateObject: any) => void;
}

export default class WalmartFeeds extends React.Component<WalmartFeedsProps, WalmartFeedsState> {
  constructor(props) {
    super(props);
    this.state = {
      isInitialLoading: true,
      isLoading: false,
      isLoadingMore: false,
      hasMore: false,
      hasLoaded: 0,
      feedResults: new Array<WalmartFeed>(),
      channels: new Array<MarketplaceChannel>(),

      channelFilter: undefined,
      productIdFilter: undefined,
      feedIdFilter: undefined,
      skuFilter: undefined,
      startDateFilter: undefined,
      endDateFilter: undefined,
      orderByFilter: 'createdatdesc'
    }
  }

  componentDidMount() {
    if (this.props.match.params.marketplaceId !== undefined) {
      this.initialSetMarketplace();
      this.getMarketplaceChannels();
      this.getWalmartFeeds(0);
    }
  }

  initialSetMarketplace = async () => {
    let marketplaceId = this.props.match.params.marketplaceId;
    const { selectedMarketplace, setMarketplace, user } = this.props;
    this.setState({ isInitialLoading: true });

    if (selectedMarketplace === undefined) {
      await setMarketplaceFromId(
        marketplaceId,
        setMarketplace,
        user,
        this.props.history,
      );
    }
  };

  getMarketplaceChannels = async () => {
    await productFunctions.getMarketplaceChannels(
      this.props,
      this.setParentState,
    );
  };

  getWalmartFeeds = async (skip: number) => {
    const { marketplaceId } = this.props.match.params;
    const { apiKey } = this.props;
    const {
      hasLoaded,
      feedResults,
      channelFilter,
      productIdFilter,
      feedIdFilter,
      skuFilter,
      startDateFilter,
      endDateFilter,
      orderByFilter
    } = this.state;
    if (skip === 0) {
      await this.setState({ isLoading: true });
    } else {
      await this.setState({ isLoadingMore: true });
    }

    try {
      if(!this.state.isInitialLoading) {
        removeAllUrlParams(this.props);

        if (channelFilter !== undefined) {
          addUrlParam(this.props, 'channel', channelFilter.toString());
        }
        if (productIdFilter !== undefined && productIdFilter !== '') {
          addUrlParam(this.props, 'productId', productIdFilter);
        }
        if (feedIdFilter !== undefined && feedIdFilter !== '') {
          addUrlParam(this.props, 'feedId', feedIdFilter);
        }
        if (skuFilter !== undefined && skuFilter !== '') {
          addUrlParam(this.props, 'sku', skuFilter);
        }
        if (startDateFilter !== undefined) {
          addUrlParam(this.props, 'startDate', startDateFilter);
        }
        if (endDateFilter !== undefined) {
          addUrlParam(this.props, 'endDate', endDateFilter);
        }
        if (orderByFilter !== undefined) {
          addUrlParam(this.props, 'orderBy', orderByFilter);
        }
      } else {
        let preSearchParams = new URLSearchParams(this.props.location.search);
        if (preSearchParams.get('orderBy') === null) {
          addUrlParam(this.props, 'orderBy', orderByFilter.toString());
        }
      }

      let uri = `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/walmartfeed/feed/${Feed_Type.INVENTORY}?key=${apiKey}&skip=${skip}`;
      let searchParams = new URLSearchParams(this.props.location.search);

      searchParams.forEach((value: string, key: string) => {        
        let tempState = {...this.state, [key+'Filter']:value };
        this.setState(tempState);

        uri = uri + `&${key}=${value}`;
      });

      let data: WalmartFeed[] = await rest.get(uri);

      let tempResults = feedResults;
      if (skip === 0) {
        tempResults = new Array<WalmartFeed>();
        await this.setState({ hasLoaded: 0 });
      }
      tempResults.push(...data);

      await this.setState({
        feedResults: tempResults,
        hasLoaded: hasLoaded + data.length,
        isLoading: false,
        isLoadingMore: false,
      });

      if (data.length >= 25) {
        await this.setState({ hasMore: true });
      } else {
        await this.setState({ hasMore: false });
      }
    } catch (error) {
      errorHandler(
        error,
        'There was an error retrieving walmart feeds. Please try again, if this is not the first time you have seen this error, please report it.',
        this.props.addAlert,
      );
      await this.setState({ isLoading: false, isLoadingMore: false });
    }
  }

  setParentState = stateObject => {
    this.setState(stateObject);
  };

  render() {
    const { hasMore, hasLoaded, isLoadingMore } = this.state;

    return (
      <React.Fragment>
        <Row>
          <Col>
            <h1>Walmart Inventory Feeds</h1>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg="auto">
            <WalmartFeedFilters
              {...this.state}
              {...this.props}
              getWalmartFeeds={this.getWalmartFeeds}
              setParentState={this.setParentState}
            />
          </Col>
          <Col>
            <WalmartFeedResults
              {...this.state}
              {...this.props}
              getWalmartFeeds={this.getWalmartFeeds}
              setParentState={this.setParentState}
            />
          </Col>
        </Row>
        <Row className="mt-3 text-center">
          <Col>
            {hasMore && (
              <Button
                variant="outline-info"
                onClick={() => {
                  this.setState({ isInitialLoading: false });
                  this.getWalmartFeeds(hasLoaded);
                }}
              >
                {isLoadingMore ? (
                  <React.Fragment>
                    <Spinner size="sm" animation="border" />
                    Loading...
                  </React.Fragment>
                ) : (
                  "Load More..."
                )}
              </Button>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}