import support from './Data/Stacks/Support';
import enterprise from './Data/Stacks/Enterprise';
import frontEnd from './Data/Stacks/FrontEnd';
import legacy from './Data/Stacks/Legacy';

import wmsPlan from './Data/Plans/WMS';
import additionalChannelsPlan from './Data/Plans/AdditionalChannels';

const data = {
  dateUpdated: '8/28/2019',
  stacks: [support, enterprise, frontEnd, legacy],
  plans: [wmsPlan, additionalChannelsPlan],
};

export default data;
