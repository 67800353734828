import React from 'react';
import { Row, Col, Button, Collapse } from 'react-bootstrap';

import MarketplaceChannelMarketplaceProduct from '../../../../../classes/Marketplace/MarketplaceChannelMarketplaceProduct';

import { ShopifyChannelAreaChildProps } from './ShopifyChannelArea';

interface ChannelErrorAreaProps extends ShopifyChannelAreaChildProps {
  connections: MarketplaceChannelMarketplaceProduct[];
}

const ChannelErrorArea = (props: ChannelErrorAreaProps) => {
  const { showError, connections, setChannelState } = props;
  return (
    <React.Fragment>
      <Row className='mt-3'>
        <Col className='align-self-center'>
          This product is having a Sync Error on this channel.
        </Col>
        <Col lg='3' className='align-self-center text-right'>
          <Button
            variant='outline-danger'
            onClick={() => setChannelState({ showError: !showError })}
          >
            See Error
          </Button>
        </Col>
      </Row>
      <Collapse in={showError}>
        <Row className='mt-3'>
          <Col>
            <ul>
              {connections[0].errorMessages !== undefined &&
              connections[0].errorMessages.length > 0 ? (
                connections[0].errorMessages.map((errorMessage, index) => (
                  <li key={index}>{errorMessage}</li>
                ))
              ) : (
                <li>No error messages present</li>
              )}
            </ul>
          </Col>
        </Row>
      </Collapse>
    </React.Fragment>
  );
};

export default ChannelErrorArea;
