import React, { useState } from "react";
import { Row, Col, ListGroup, Button } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';

import PriceScheduleResultModel from "../../../../classes/Marketplace/PriceScheduleResult";

import dateFormat from '../../../../utils/DateFormat';

import { PriceSchedulerViewChildProps } from "./PriceSchedulerView";

export interface PriceScheduleResultProps extends PriceSchedulerViewChildProps {
  priceSchedule: PriceScheduleResultModel;
}

const PriceScheduleResult = (props: PriceScheduleResultProps) => {
  const { priceSchedule, removePriceSchedule } = props;
  const [showModal, setShowModal] = useState(false);
  
  const startDate = new Date(priceSchedule.discountStartDateTz);
  const endDate = new Date(priceSchedule.discountEndDateTz);
  const now = new Date(priceSchedule.currentDateTz);

  let buttonState = false;
  if (startDate < now && endDate > now) {
    buttonState = true;
  }

  return (
    <LinkContainer to={`/marketplace/${props.match.params.marketplaceId}/products/${priceSchedule.productId}`}>
      <ListGroup.Item action>
        <React.Fragment>
          <Row>
            <Col xs="auto" className='font-weight-bold' style={{paddingRight: 0}}>
              {priceSchedule.vendor}
            </Col>
            <Col style={{paddingLeft: '5px'}}>
              {`${priceSchedule.productTitle} / ${priceSchedule.variantTitle}`}
            </Col>
            <Col xs="auto">
              <Button size="sm" variant={buttonState ? "outline-danger" : "outline-info"}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  removePriceSchedule(priceSchedule.variantId, priceSchedule.channelId);
                }}
              >
                {buttonState ? "Stop" : "Remove"}
              </Button>
              <PriceScheduleRemoveModel 
                showModal={showModal}
                setShowModal={setShowModal}
                priceSchedule={priceSchedule}
                removePriceSchedule={removePriceSchedule}
                buttonState={buttonState}
              />
            </Col>
          </Row>
          <Row>
            <Col className="self-align-center text-dark">
              {priceSchedule.discountName}
            </Col>
            <Col className="self-align-center align-middle">
              {dateFormat(priceSchedule.discountStartDateTz, true)}
            </Col>
            <Col className="self-align-center align-middle">
              {dateFormat(priceSchedule.discountEndDateTz, true)}
            </Col>
            <Col className="h-100 self-align-center align-middle">
              {priceSchedule.channelName}
            </Col>
            <Col className="h-100 self-align-center align-middle">
              ${priceSchedule.discountManualPrice}
            </Col>
            <Col md="1"></Col>
          </Row>
        </React.Fragment>
      </ListGroup.Item>
    </LinkContainer>
  );
};

const PriceScheduleRemoveModel = props => {
  const { showModal, setShowModal, priceSchedule, removePriceSchedule, buttonState } = props;

  const handleClose = () => setShowModal(false);

  return (
    <div role="dialog" aria-modal="true" className="fade modal show" style={{display: showModal ? "block" : "none", backgroundColor: "#00000094"}} onClick={() => setShowModal(false)}>
      <div role="document" className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title h4">Are you sure?</div>
            <button type="button" className="close" onClick={() => setShowModal(false)}>
              <span aria-hidden="true" >×</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
            <button type="button" className={buttonState ? "btn btn-danger" : "btn btn-info"} onClick={() => {
              removePriceSchedule(priceSchedule.variantId, priceSchedule.channelId);
              setShowModal(false);
            }}>{buttonState ? "Stop" : "Remove"}</button>
          </div>
        </div>
      </div>
    </div>

      // <Modal show={showModal} onHide={handleClose}>
      //   <Modal.Header closeButton>
      //     <Modal.Title>Are you sure?</Modal.Title>
      //   </Modal.Header>
      //   <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
      //   <Modal.Footer>
      //     <Button variant="secondary" onClick={handleClose}>
      //       Close
      //     </Button>
      //     <Button variant="primary" onClick={() => {
      //       removePriceSchedule(priceSchedule.variantId, priceSchedule.channelId);
      //       setShowModal(false);
      //     }}>
      //       Remove
      //     </Button>
      //   </Modal.Footer>
      // </Modal>
  );
};

export default PriceScheduleResult;
