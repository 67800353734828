import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import uuidv4 from 'uuid/v4';
import LoadingPage from '../../Shared/LoadingPage';

import { eformedApiBaseUri } from '../../../Settings';
import rest from '../../../utils/Rest';
import errorHandler from '../../../utils/ErrorHandler';
import SearchTextBox from '../../Shared/SearchTextBox';

import BaseProps from '../../../interfaces/BaseProps';
import Seller from '../../../classes/Seller/Seller';
import SellerCard from './SellerCard';
import Marketplace from '../../../classes/Marketplace/Marketplace';

export interface SellersState {
  isLoading: boolean;
  searchText: string;
  marketplaces: Marketplace[];
  sellers: Seller[];
  filteredSellers: Seller[];
}

const initialMarketplace = new Marketplace("0", "Select Marketplace...");

export default class Sellers extends React.Component<BaseProps, SellersState> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      searchText: '',
      marketplaces: [initialMarketplace],
      sellers: new Array<Seller>(),
      filteredSellers: new Array<Seller>(),
    };
    
    this.getMarketplaces();
  }

  componentDidMount() {
    this.getSellers();
  }

  getMarketplaces = async () => {
    try {
      let data = await rest.get(
        `${eformedApiBaseUri}marketplace/marketplaces?key=${
          this.props.apiKey
        }`
      );
      data.unshift(initialMarketplace);
      this.setState({ marketplaces: data });
    } catch (error) {
      errorHandler(
        error,
        "There was an error while getting marketplaces.",
        this.props.addAlert
      );
    }
  };

  getSellers = async () => {
    try {
      this.setState({ isLoading: true });
      let data = await rest.get(
        `${eformedApiBaseUri}seller/sellers?key=${this.props.apiKey}`,
      );

      this.setState({ isLoading: false, sellers: data });
    } catch (error) {
      errorHandler(
        error,
        'There was an error while retrieving sellers. Please try again, if this is not the first time you have seen this error, please report it.',
        this.props.addAlert,
      );
      this.setState({ isLoading: false });
    }
  };

  addNewSeller = () => {
    let sellers = this.state.sellers;
    sellers = sellers !== undefined && sellers !== null ?
      sellers : new Array<Seller>();
    let seller  = new Seller(uuidv4, "");
    
    sellers.unshift(seller);
    this.setState({ sellers: sellers, searchText: '' });
  }

  removeSeller = (seller: Seller) => {
    let sellers =  this.state.sellers.filter(s => s.id !== seller.id);

    this.setState({ sellers: sellers });
  }

  setParentState = stateObject => {
    this.setState(stateObject);
  };

  render() {
    const { isLoading, searchText, sellers } = this.state;
    if (this.props.user.sellerAdmin !== true) {
      return (
        <React.Fragment>
          <Row>
            <Col>
              <h1>Sellers Admin</h1>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col>You do not have access to this content.</Col>
          </Row>
        </React.Fragment>
      );
    } else if (isLoading === true) {
      return (
        <React.Fragment>
          <Row>
            <Col>
              <h1>Sellers Admin</h1>
            </Col>
          </Row>
          <LoadingPage />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Row>
            <Col>
              <h1>Sellers Admin</h1>
            </Col>
            <Col>
              <Button
                className="float-right"
                variant="outline-success"
                onClick={() => this.addNewSeller()}
              >
                Add new seller
              </Button>
            </Col>
          </Row>
          <SearchTextBox 
            searchText={this.state.searchText}
            setSearchText={newValue => this.setState({ searchText: newValue })}
          />
          {sellers !== undefined && sellers.length > 0 &&
              sellers.filter(s => s.name.toLowerCase()
                .startsWith(this.state.searchText.toLowerCase())
          ).map((seller, index) => (
              <SellerCard
                key={index}
                seller={seller}
                {...this.state}
                {...this.props}
                setParentState={this.setParentState}
                removeSeller={this.removeSeller}
              />
            ))}
        </React.Fragment>
      );
    }
  }
}
