import React from 'react';
import { Row, Col, Button, Card } from 'react-bootstrap';
import 'react-image-lightbox/style.css';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import * as Settings from '../../../../../Settings';

import { ImageDraggableProps } from './ImageDraggable';

interface ImageProps extends ImageDraggableProps {
}

const PanelImage = React.forwardRef((props: ImageProps, ref: any) => {
  const { index, marketplaceId, product, image, isEditing, isDeleting, setIsLightBoxOpen, setImagePrimary, deleteImageModal, setImagesParentState } = props;
  return (
    <React.Fragment key={index}>
      <Card border="light" className="product-card">
        <div ref={ref} className="image-div" onClick={() => {
          setIsLightBoxOpen(true);
          setImagesParentState({ lightBoxImgSrc: `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/products/${product.id}/images/${image.id}/image` });
          }}>
          <Card.Img
            className='d-block product-thumbnail'
            style={{}}
            src={`${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/products/${product.id}/images/${image.id}/image`}
            alt={image.handle}
          />
        </div>
        <Card.Body className="product-card-body">
          <Row>
            <Col>
              <CopyToClipboard text={image.id}>
                <Button size='sm' variant='info'>
                  Copy Id
              </Button>
              </CopyToClipboard>
            </Col>
            {image.position !== 1 &&
              <Col>
                <CopyToClipboard text={image.id}>
                  <Button
                    size='sm'
                    variant='primary'
                    disabled={!isEditing}
                    onClick={() => setImagePrimary(image.id)}
                  >
                    Set as Primary
                </Button>
                </CopyToClipboard>
              </Col>
            }
            <Col>
              <Button
                disabled={isDeleting}
                size='sm'
                variant='danger'
                onClick={() =>
                  deleteImageModal(image.id, true)
                }
              >
                Delete Image
            </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {index % 2 !== 0 ? (
        <div className="w-100"></div>
      ) : ('')}
    </React.Fragment>
  );
})

export default PanelImage;