import React, { useState } from 'react';
import { Row, Col, Card, Badge, Button, Form, Spinner } from 'react-bootstrap';

import dateFormat from '../../../../utils/DateFormat';
import syncStatusToVariant from '../../../../utils/SyncStatusToVariant';

import Order, { ORDER_SHIPPING_ACTION } from '../../../../classes/Marketplace/Order';

interface BasicInformationProps {
  order: Order;
  saveOrder: () => void;
  handleOrderChange: (fieldName: string, newValue: any) => void;
  approveRisk: () => void;
  addAlert: (    
    variant:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light',
    text: string,
    title?: string) => void;
}

const BasicInformation = (props: BasicInformationProps) => {
  const { order, approveRisk, handleOrderChange } = props;

  const saveOrder = async () => {
    const shippingRequired = order.orderShippingAction === ORDER_SHIPPING_ACTION.NO_SHIPPING_REQUIRED ?
      undefined : ORDER_SHIPPING_ACTION.NO_SHIPPING_REQUIRED;
    handleOrderChange("orderShippingAction", shippingRequired);

    await props.saveOrder();
  }

  const isShippingRequired = order.orderShippingAction === ORDER_SHIPPING_ACTION.NO_SHIPPING_REQUIRED;

  return (
    <Row>
      <Col>
        <Card border='primary' text='dark'>
          <Card.Header as='h3'>Basic Information</Card.Header>
          <Card.Body>
            <Row>
              <Col md="2">Ordered At:</Col>
              <Col md="4">{dateFormat(order.orderedAt, true)}</Col>
              <Col md="2" style={{ whiteSpace: "nowrap"}}>Marketplace Order ID:</Col>
              <Col md="4">{order.id}</Col>
            </Row>
            <Row className="mt-1">
              <Col md="2">Order Status:</Col>
              <Col md="4">
                <Badge pill variant='primary'>
                  {order.orderStatus}
                </Badge>
              </Col>
              <Col md="2">Channel Name:</Col>
              <Col md="4">{order.marketplaceChannel !== undefined && order.marketplaceChannel !== null ? order.marketplaceChannel.name : ""}</Col>
            </Row>
            <Row className="mt-1">
              <Col md="2">Order Payment Status:</Col>
              <Col md="4">
                <Badge pill variant='warning'>
                  {order.paymentStatus}
                </Badge>
              </Col>
              <Col md="2">Channel Order ID:</Col>
              <Col md="4">{order.externalId}</Col>
            </Row>
            <Row className="mt-1">
              <Col md="2">Order Fulfillment Status:</Col>
              <Col md="4">
                <Badge pill variant='danger'>
                  {order.fulfillmentStatus}
                </Badge>
              </Col>
              <Col md="2" style={{ whiteSpace: "nowrap"}}>Channel Order Number:</Col>
              <Col md="4">{order.externalOrderNumber}</Col>
            </Row>
            <Row className="mt-1">
              <Col md="2">Order Sync Status:</Col>
              <Col md="4">
                <Badge pill variant={syncStatusToVariant(order.syncStatus)}>
                  {order.syncStatus}
                </Badge>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col md="2">Order Synced At:</Col>
              {order.syncedAt !== null && order.syncedAt !== undefined ?
                <Col md="4">{dateFormat(order.syncedAt, true)} (UTC)</Col> : <Col md="4"></Col>
              }
            </Row>
            <Row className="mt-1">
              <Col md="2" className='align-self-center'>Risky Order:</Col>
              <Col md="4">
                <Row>
                  <Col className='align-self-center' xs='auto'>
                    {order.risky === undefined ||
                    order.risky === null ||
                    order.risky === false
                      ? 'Not Risky'
                      : order.riskApproved !== true
                      ? 'Risky, and Not Yet Approved'
                      : 'Risk-Approved'}
                  </Col>
                  {order.risky === true && order.riskApproved !== true ? (
                    <Col xs='auto'>
                      <Button
                        size='sm'
                        variant='outline-success'
                        onClick={() => approveRisk()}
                      >
                        Approve
                      </Button>
                    </Col>
                  ) : null}
                </Row>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col md="2">Order Notes:</Col>
              <Col md="4">{order.orderNotes}</Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Check 
                  type="checkbox"
                  label="No Shipping Required"
                  checked={isShippingRequired}
                  onChange={saveOrder}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default BasicInformation;
