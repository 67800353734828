import React from "react";
import { Row, Col, Card, Badge, Button } from "react-bootstrap";

const Statistics = props => {
  const { data } = props;
  const countAppsAndServices = () => {
    let appCount = 0;
    let serviceCount = 0;
    let types = [];
    let languages = [];
    let frameworks = [];
    data.stacks.forEach(stack => {
      appCount = appCount + stack.apps.length;
      stack.apps.forEach(app => {
        serviceCount = serviceCount + app.services.length;
        types.push(...app.services.map(service => service.type));
        languages.push(...app.services.map(service => service.language));
        frameworks.push(...app.services.map(service => service.framework));
      });
    });

    let typeCounts = [];
    let languageCounts = [];
    let frameworkCounts = [];
    let uniqueTypes = types.filter(onlyUnique);
    let uniqueLanguages = languages.filter(onlyUnique);
    let uniqueFrameworks = frameworks.filter(onlyUnique);

    uniqueTypes.forEach(uniqueType => {
      let typeCount = 0;
      for (var i = 0; i < types.length; i++) {
        if (types[i] === uniqueType) {
          typeCount++;
        }
      }
      typeCounts.push({
        type: uniqueType,
        count: typeCount
      });
    });

    uniqueLanguages.forEach(uniqueLanguage => {
      let languageCount = 0;
      for (var i = 0; i < languages.length; i++) {
        if (languages[i] === uniqueLanguage) {
          languageCount++;
        }
      }
      languageCounts.push({ language: uniqueLanguage, count: languageCount });
    });

    uniqueFrameworks.forEach(uniqueFramework => {
      let frameworkCount = 0;
      for (var i = 0; i < frameworks.length; i++) {
        if (frameworks[i] === uniqueFramework) {
          frameworkCount++;
        }
      }
      frameworkCounts.push({
        framework: uniqueFramework,
        count: frameworkCount
      });
    });

    return {
      appCount: appCount,
      serviceCount: serviceCount,
      typeCounts: typeCounts,
      languageCounts: languageCounts,
      frameworkCounts: frameworkCounts
    };
  };
  const counts = countAppsAndServices();

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  return (
    <Row className="mt-3">
      <Col>
        <Card border="info">
          <Card.Header>
            <h3 className="text-info">Statistics & Constants</h3>
          </Card.Header>
          <Card.Body className="text-dark">
            <Row className="mt-1">
              <Col md="3" className="text-info font-weight-bold">
                Last Update:
              </Col>
              <Col>{data.dateUpdated}</Col>
            </Row>
            <Row className="mt-1">
              <Col md="3" className="text-info font-weight-bold">
                Total Apps:
              </Col>
              <Col className="text-info font-weight-bold">
                {counts.appCount}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col md="3" className="text-info font-weight-bold">
                Total Services:
              </Col>
              <Col className="text-info font-weight-bold">
                {counts.serviceCount}
              </Col>
            </Row>
            <hr />
            <Row className="mt-1">
              <Col
                md="3"
                className="text-info font-weight-bold align-self-center"
              >
                Type Stats:
              </Col>
              <Col className="text-info font-weight-bold">
                {counts.typeCounts.map((typeCount, index) => (
                  <React.Fragment>
                    <Button key={index} size="sm" variant="primary">
                      {typeCount.type}{" "}
                      <Badge pill variant="light">
                        {typeCount.count}
                      </Badge>
                    </Button>{" "}
                  </React.Fragment>
                ))}
              </Col>
            </Row>
            <hr />
            <Row className="mt-1">
              <Col
                md="3"
                className="text-info font-weight-bold align-self-center"
              >
                Language Stats:
              </Col>
              <Col className="text-info font-weight-bold">
                {counts.languageCounts.map((languageCount, index) => (
                  <React.Fragment>
                    <Button key={index} size="sm" variant="info">
                      {languageCount.language}{" "}
                      <Badge pill variant="light">
                        {languageCount.count}
                      </Badge>
                    </Button>{" "}
                  </React.Fragment>
                ))}
              </Col>
            </Row>
            <hr />
            <Row className="mt-1">
              <Col
                md="3"
                className="text-info font-weight-bold align-self-center"
              >
                Framework Stats:
              </Col>
              <Col className="text-info font-weight-bold">
                {counts.frameworkCounts.map((frameworkCount, index) => (
                  <React.Fragment>
                    <Button key={index} size="sm" variant="outline-info">
                      {frameworkCount.framework}{" "}
                      <Badge pill variant="info">
                        {frameworkCount.count}
                      </Badge>
                    </Button>{" "}
                  </React.Fragment>
                ))}
              </Col>
            </Row>
            <hr />
            <Row className="mt-1">
              <Col md="3" className="text-info font-weight-bold">
                Philosophy:
              </Col>
              <Col>
                Microservices, but only when scale justifies it, otherwise
                prioritize development speed. Modularity is prized very highly
                and nearly everything is decoupled, static, and stateless due to
                heavy reliance on serverless technology.
              </Col>
            </Row>
            <Row className="mt-1">
              <Col md="3" className="text-info font-weight-bold">
                Deployment:
              </Col>
              <Col>
                100% Continuously integrated and deployed to production. In 95%
                of cases, this is done through Azure DevOps, but in some smaller
                services, it may be done directly (using SCM in Azure).
              </Col>
            </Row>
            <Row className="mt-1">
              <Col md="3" className="text-info font-weight-bold">
                Containers:
              </Col>
              <Col>
                All containers are built and housed in an Azure Container
                Registry named 'eformed'.
              </Col>
            </Row>
            <Row className="mt-1">
              <Col md="3" className="text-info font-weight-bold">
                Monitoring/Logging:
              </Col>
              <Col>
                Azure Monitor Log Analytics is used as the centralized logging
                platform for all apps, and each app individually has an
                Application Insights associated with it. Every app is also ping
                tested by AppSupport (Heartbeat), and a message is posted in
                Slack when down. See Support stack for more details.
              </Col>
            </Row>
            <Row className="mt-1">
              <Col md="3" className="text-info font-weight-bold">
                Code Cleanliness:
              </Col>
              <Col>
                <ul>
                  <li>
                    Overall: As this is a startup focused organization and ideas
                    come fast and furiously, the degree to with which code has
                    been maintained to a standard of large team maintainability
                    is low, as most ideas are considered prototypes and may be
                    discarded. Delivery timelines have been typically shortened
                    by 25-50%, and this has also greatly hampered cleanliness.
                    In cases where the concept is more proven, the cleanliness
                    has also bee improved. The newer an app is described as
                    being, the more clean the code. In addition, this has been
                    mitigated somewhat by application design for simplicity and
                    modularity, and more details are below.
                  </li>
                  <li>
                    Naming: In the past, there were deep struggles with outside
                    developers and single letter or abbreviated naming, but this
                    has been mostly mitigated, and things should be named in
                    ways that are easily understandable. Grade: B.
                  </li>
                  <li>
                    Simple functions/classes: Similar to naming, in the past
                    there were 1000+ line monsters, but this has been gradually
                    improved in most cases, but could still use some work on
                    ensuring single responsilbilty. Polymorphism has been
                    avoided for the most part, but due to simplistic application
                    design, also mostly unneeded. Grade: C.
                  </li>
                  <li>
                    Tests: This is the area that has been most hit by timeline
                    shortening, and can be vastly improved. The exception is
                    EFEnterprise, due to its complexity, there is a fair degree
                    of test coverage for future developer benefit, in both C#
                    (xUnit) and JS (Jest). Grade: F (EFEnterprise: C).
                  </li>
                </ul>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Statistics;
