import * as React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Row, Col, Button, Form } from "react-bootstrap";
import { TextArea } from "../../../Shared/EFBootstrap";

import * as Settings from "../../../../Settings";
import BaseProps from "../../../../interfaces/BaseProps";

const eformedApiBaseUri = Settings.eformedApiBaseUri;

export default class ManagePurchaseOrderItemsBulk extends React.Component<
  BaseProps
> {
  state = {
    bulkUpload: "",
    isLoading: false,
    isSuccess: false,
    response: { successes: 0, errorLog: [] }
  };

  handleSubmit = () => {
    this.setState({ isLoading: true });
    const { bulkUpload } = this.state;

    var body = {
      body: bulkUpload
    };

    fetch(
      eformedApiBaseUri +
        "po/items.json?key=" +
        this.props.apiKey +
        "&shop=babywise-life.myshopify.com&type=bulk",
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    )
      .then(response => {
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          return response.json();
        }
      })
      .then(data => {
        this.setState({ isLoading: false, isSuccess: true, response: data });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        console.error(error);
        try {
          error.text().then(errorText => console.error(errorText));
        } catch {}
        this.props.addAlert(
          "danger",
          "There was an error while adding, please try again. If this is not the first error, please report the issue."
        );
      });
  };

  render() {
    if (!this.state.isSuccess) {
      return (
        <React.Fragment>
          <h1>Add Purchase Order Items</h1>
          <Row className="text-right">
            <Col>
              <LinkContainer to="/logistics/po/items/search">
                <Button>Search Items</Button>
              </LinkContainer>
            </Col>
            <Col sm="auto">
              <LinkContainer to="/logistics/po/items">
                <Button>Single Item Add/Edit</Button>
              </LinkContainer>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6>Enter Bulk Upload Information</h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-secondary">
                Using a template similar to{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://docs.google.com/spreadsheets/d/1EWEQxmwpNRPeZSMmH0BL-Y4wGti47jH7ZTHQF-GHtLE/edit?usp=sharing"
                >
                  this
                </a>
                , create new items by filling out the fields in GSheets or
                Excel, then copying and pasting them into the text box. You can
                copy the headers, or not, whichever. The results will appear
                immediately on this page.
              </p>
            </Col>
          </Row>
          <Form>
            <Row>
              <Col>
                <TextArea
                  label="Bulk Upload"
                  value={this.state.bulkUpload}
                  onChange={newValue => this.setState({ bulkUpload: newValue })}
                  rows={10}
                />
              </Col>
            </Row>
          </Form>
          <Row>
            <Col>
              <Button
                block
                onClick={this.handleSubmit}
                disabled={this.state.isLoading}
              >
                {this.state.isLoading === true ? "Uploading..." : "Bulk Upload"}
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h1>Add Purchase Order Items</h1>
          <Row className="text-right">
            <Col>
              <LinkContainer to="/logistics/po/items/search">
                <Button>Search Items</Button>
              </LinkContainer>
            </Col>
            <Col sm="auto">
              <LinkContainer to="/logistics/po/items">
                <Button>Single Item Add/Edit</Button>
              </LinkContainer>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Report</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Successes: {this.state.response.successes}</h3>
            </Col>
          </Row>
          <h3>Error Log: </h3>
          {this.state.response.errorLog.map((errorLog, index) => (
            <p>
              Item: {errorLog.itemNumber} - Error: {errorLog.error}
            </p>
          ))}
        </React.Fragment>
      );
    }
  }
}
