const createVariantTitle = (
  option1: string,
  option2: string,
  option3: string,
) => {
  let defaultTitle = 'Default Title';
  if (option1 === undefined || option1 === null || option1 === '') {
    return defaultTitle;
  } else {
    return `${option1}${
      option2 !== undefined && option2 !== null && option2 !== ''
        ? ` / ${option2}`
        : ''
    }${
      option3 !== undefined && option3 !== null && option3 !== ''
        ? ` / ${option3}`
        : ''
    }`.trim();
  }
};

export default createVariantTitle;
