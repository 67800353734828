import React from 'react';
import { Row, Col, Jumbotron, Spinner, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Dropzone from 'react-dropzone';
import { TextArea } from '../../Shared/EFBootstrap';

import * as Settings from '../../../Settings';
import errorHandler from '../../../utils/ErrorHandler';

import RouteVariableProps from '../../../interfaces/RouteVariableProps';
import rest from '../../../utils/Rest';
import ImageUploadResults from './Product/AddImageResults'

interface MatchParams {
  marketplaceId: string;
  productId: string;
}

export default class AddImages extends React.Component<
  RouteVariableProps<MatchParams>
> {
  state = {
    isUploading: false,
    isSaved: false,
    imageUrls: '',
    results: undefined
  };

  uploadImage = async (
    marketplaceId: string,
    productId: string,
    files: any,
  ) => {
    await this.setState({ isUploading: true });
    await files.forEach(async file => {
      let formData = new FormData();
      formData.append(file.name, file);
      try {
        let response = await fetch(
          `${
            Settings.eformedApiBaseUri
          }marketplace/${marketplaceId}/products/${productId}/images?key=${
            this.props.apiKey
          }`,
          {
            method: 'POST',

            body: formData,
          },
        );
        if (response.status >= 400) {
          throw response;
        } else {
          await this.props.addAlert('success', 'Image successfully uploaded.');
        }
      } catch (error) {
        errorHandler(
          error,
          'There was an error while uploading the image.',
          this.props.addAlert,
        );
      }
    });

    await this.setState({ isUploading: false, isSaved: true });
  };

  uploadImagesFromUrl = async () => {
    await this.setState({ isUploading: true, results: undefined });
    const { marketplaceId, productId } = this.props.match.params;
    const { imageUrls } = this.state;

    try {
      let results = await rest.post(
        `${
          Settings.eformedApiBaseUri
        }marketplace/${marketplaceId}/products/${productId}/images/url?key=${
          this.props.apiKey
        }&urls=${encodeURIComponent(imageUrls)}`,
        null,
      );

      this.setState({ imageUrls:'', results: results });
    } catch (error) {
      errorHandler(
        error,
        'There was an error while uploading the image/s.',
        this.props.addAlert,
      );
    }

    await this.setState({ isUploading: false, isSaved: true });
  };

  render() {
    const { marketplaceId, productId } = this.props.match.params;
    const { isUploading, imageUrls, results } = this.state;
    return (
      <React.Fragment>
        <Row>
          <Col>
            <h1>Add Images</h1>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col>
            <LinkContainer
              exact
              to={`/marketplace/${marketplaceId}/products/${productId}`}
            >
              <Button variant='outline-info'>Back to Product</Button>
            </LinkContainer>
          </Col>
        </Row>
        <Row className='mt-5 text-dark'>
          <Col>Enter URLs of images or upload images from your device below.</Col>
        </Row>
        <Row className='mt-5'>
          <Col>
            <TextArea
              label='URLs of Images'
              value={imageUrls}
              placeholder='https://example.com/image_1.jpg&#13;&#10;https://example.com/image_2.jpg'
              onChange={newValue => this.setState({ imageUrls: newValue })}
            />
          </Col>
          <Col className='align-self-center text-right' lg='2'>
            <Button variant='success' onClick={() => this.uploadImagesFromUrl()}>
              {isUploading ? (
                <React.Fragment>
                  <Spinner size='sm' animation='border' />
                  Uploading...
                </React.Fragment>
              ) : (
                'Upload'
              )}
            </Button>
          </Col>
        </Row>
        {results !== undefined &&
          <ImageUploadResults results={results} />
        }
        <Row className='mt-5 text-center'>
          <Col>
            <Dropzone
              onDrop={acceptedFiles =>
                this.uploadImage(marketplaceId, productId, acceptedFiles)
              }
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <Row>
                  <Col {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Jumbotron
                      fluid
                      style={{ cursor: 'pointer' }}
                      className='bg-info'
                    >
                      <h4 className='text-white'>
                        {isUploading ? (
                          <React.Fragment>
                            <Spinner animation='border' /> Uploading...
                          </React.Fragment>
                        ) : isDragActive ? (
                          'Drop image here'
                        ) : (
                          "Drag 'n' drop some images here, or click to select images"
                        )}
                      </h4>
                    </Jumbotron>
                  </Col>
                </Row>
              )}
            </Dropzone>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
