import LogisticsCompanyPermission from "../Logistics/LogisticsCompanyPermission";
import MarketplacePermission from "../Marketplace/MarketplacePermission";
import SellerPermission from "../Seller/SellerPermission";

export default class User {
  id: string;
  apiKey: string;
  email: string;
  admin: boolean;
  sellerAdmin: boolean;
  babywise: boolean;
  kialoa: boolean;
  ccs: boolean;
  ccsAdmin: boolean;
  isAllowAllSellers: boolean;
  logisticsCompanies: LogisticsCompanyPermission[];
  marketplaces: MarketplacePermission[];
  sellers: SellerPermission[];
}
