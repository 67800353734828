import * as React from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { TextArea, TextField } from "../../Shared/EFBootstrap";

import * as Settings from "../../../Settings";
import BaseProps from "../../../interfaces/BaseProps";

const eformedApiBaseUri = Settings.eformedApiBaseUri;

export default class CCSBilling extends React.Component<BaseProps> {
  state = {
    isLoading: false,
    csvData: "",
    poNumber: ""
  };

  componentDidMount() {
    this.getCSV();
  }

  getCSV = () => {
    this.setState({ isLoading: true });
    fetch(eformedApiBaseUri + "ccs/orders.csv?key=" + this.props.apiKey)
      .then(response => {
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          return response.text();
        }
      })
      .then(data => {
        this.setState({ csvData: data, isLoading: false });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        console.error(error);
        try {
          error.text().then(errorText => console.error(errorText));
        } catch {}
        this.props.addAlert("danger", "There was an error while retrieving.");
      });
  };

  markBilled = poNumber => {
    this.setState({ isLoading: true });
    fetch(
      eformedApiBaseUri +
        "ccs/orders.json?key=" +
        this.props.apiKey +
        "&poNumber=" +
        poNumber +
        "&billed=true",
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    )
      .then(response => {
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          this.props.addAlert("success", "Marked completed.");
          this.setState({ isLoading: false });
          this.getCSV();
        }
      })
      .catch(error => {
        this.setState({ isLoading: false });
        console.error(error);
        try {
          error.text().then(errorText => console.error(errorText));
        } catch {}
        this.props.addAlert(
          "danger",
          "There was an error while marking billed."
        );
      });
  };

  render() {
    const { csvData, isLoading, poNumber } = this.state;
    return (
      <div>
        <h1>CCS Billing</h1>
        <Form>
          <Row>
            <Col>
              <TextField
                label="PoNumber"
                value={this.state.poNumber}
                onChange={newValue => this.setState({ poNumber: newValue })}
              />
            </Col>
            <Col className="align-self-center" md="auto">
              <Button
                variant="outline-success"
                onClick={() => this.markBilled(poNumber)}
              >
                Mark All Billed to This PO Number
              </Button>
            </Col>
          </Row>
          <Row>
            <p className="text-secondary">
              All unbilled PO's are below. Use the output in excel to create the
              bill. If it doesn't automatically format it, Use Data {'>'} Text to
              Columns {'>'} Delimiter: comma .
            </p>
          </Row>
          <TextArea
            label="Results"
            rows="10"
            readOnly
            value={isLoading ? "Loading..." : csvData}
          />
        </Form>
      </div>
    );
  }
}
