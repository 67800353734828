import React, { useState } from 'react';
import { Row, Col, Button, Spinner, Collapse, Form } from 'react-bootstrap';
import { IoMdSettings } from 'react-icons/io'

import { TextField } from './EFBootstrap';

interface SearchFilterHeaderProps {
  isOpen: boolean;
  setParentState: (stateObject: any) => void;
  getResults: (skip: number) => void;
  setIsOpen: (isOpen: boolean) => void;
}

export const SearchFilterHeader = (props: SearchFilterHeaderProps) => {
  const { isOpen, setParentState, getResults, setIsOpen } = props;

  return (
    <Row>
      <Collapse in={isOpen}>
        <Col className='align-self-center'>
          <h5>Filters</h5>
        </Col>
      </Collapse>

      <Col xs='auto' className='align-self-center text-right'>
        <Collapse in={isOpen}>
          <Button size='sm' variant='success' onClick={() => {
            setParentState({ isInitialLoading: false });
            getResults(0);
          }}>
            Apply
          </Button>
        </Collapse>
        <Button size='sm' variant='info' onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? '<' : '>'}
        </Button>
      </Col>
    </Row>
  );
}

interface SearchFilterFooterProps {
  defaultStatus: object;
  setParentState: (stateObject: any) => void;
  getResults: (skip: number) => void;
  enableDownload?: boolean;
  isDownloading?: boolean;
  exportCsv?: (limitCount: number, downloadAll: boolean) => void;
  advancedDownloadOptions?: boolean;
}
  
export const SearchFilterFooter = (props: SearchFilterFooterProps) => {
  const { isDownloading, defaultStatus, setParentState, exportCsv, getResults, enableDownload, advancedDownloadOptions } = props;
  const [ downloadCount, setDownloadCount ] = useState(50);
  const [ downloadSettingsOpen, setDownloadSettingsOpen ] = useState(false);
  const [ downloadAll, setDownloadAll ] = useState(false);

  const validateDownloadCountField = (newValue) => {
    newValue = newValue ? Math.round(Math.abs(newValue)) : null;
    newValue = newValue >= 0 ? (newValue > 200 ? 200 : newValue) : null;
    setDownloadCount(newValue);
  }

  return (
    <React.Fragment>
      <Row className='mt-3'>
        <Col>
          <Button className='btn-block' variant='success' onClick={() => {
            setParentState({ isInitialLoading: false });
            getResults(0);
          }}>
            Apply
          </Button>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <Button className='btn-block' variant='outline-secondary' onClick={() => {
            setParentState(defaultStatus);
          }}>
            Reset
          </Button>
        </Col>
      </Row>
      {enableDownload && 
        <React.Fragment>
        <Row className='mt-4'>
          <Col>
            <Button 
              className='btn-block' 
              style={{whiteSpace:"pre", paddingLeft:"unset", paddingRight:"unset", color:"red"}} 
              disabled={isDownloading} 
              variant='secondary' 
              onClick={() => {
                exportCsv(downloadCount, downloadAll);
                setParentState({ isDownloading: true });
            }}>
              {!isDownloading ? 
                (downloadAll ? "Download all results" : `Download ${downloadCount} results`) :
                <React.Fragment>
                  <Spinner size='sm' animation='border' />
                  <span>&nbsp;Downloading...</span>
                </React.Fragment>
              }
            </Button>
          </Col>
          {advancedDownloadOptions &&
          <Col md="3" style={{padding:"10px 0"}} >
            <span onClick={() => setDownloadSettingsOpen(!downloadSettingsOpen)}>
              <IoMdSettings style={{cursor:"pointer"}} size={28} />
            </span>
          </Col>
          }
        </Row>
        {advancedDownloadOptions &&
        <Collapse in={downloadSettingsOpen}>
          <Row><Col>          
            <Row className="mt-3">
              <Col>
                <Form>  
                  <Form.Check
                    label='Download Limit Off'
                    disabled={isDownloading}
                    checked={downloadAll || false}
                    onChange={() => setDownloadAll(!downloadAll)}
                  />
                </Form>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <TextField
                  label='Download Count'
                  type="number"
                  placeholder='50'
                  step={1}
                  readOnly={isDownloading || downloadAll}
                  value={downloadCount}
                  onChange={newValue => validateDownloadCountField(newValue)}
                  helpText='Default is 50, Max is 200'
                />
              </Col>
            </Row>
          </Col></Row>
        </Collapse>
        }
        </React.Fragment>
      }
    </React.Fragment>
  );
}

interface SearchFilterProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  defaultStatus: object;
  setParentState: (stateObject: any) => void;
  getResults: (skip: number) => void;
  enableDownload?: boolean;
  isDownloading?: boolean;
  exportCsv?: (limitCount: number, downloadAll: boolean) => void;
  advancedDownloadOptions?: boolean;
}

const SearchFilter: React.FC<SearchFilterProps> = ({ 
  children, isOpen, setIsOpen, isDownloading, defaultStatus, setParentState, exportCsv, getResults, enableDownload, advancedDownloadOptions 
}) => {

  return (
    <>
      <SearchFilterHeader 
        isOpen={isOpen}
        setParentState={setParentState}
        getResults={getResults}
        setIsOpen={setIsOpen}
      />
      <Collapse in={isOpen}>
        <Row>
          <Col>
            {children}
            <SearchFilterFooter 
              enableDownload={enableDownload}
              isDownloading={isDownloading}
              defaultStatus={defaultStatus}
              setParentState={setParentState}
              exportCsv={exportCsv}
              getResults={getResults}
              advancedDownloadOptions={advancedDownloadOptions}
            />
          </Col>
        </Row>
      </Collapse>
    </>
  );
}

export default SearchFilter;