import React from "react";
import { Row, Col, ListGroup } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import dateFormat from "../../../../utils/DateFormat";
import WalmartInventoryFeed from "../../../../classes/Marketplace/Walmart/WalmartFeed";


import { WalmartFeedsChildProps } from "./WalmartFeeds";

export interface WalmartFeedResultProps extends WalmartFeedsChildProps {
  feed: WalmartInventoryFeed;
}

const WalmartFeedResult = (props: WalmartFeedResultProps) => {
  const { feed, channels } = props;
  const { marketplaceId } = props.match.params;

  const channel = channels.filter(channel => channel.id === feed.marketplaceChannelId)[0];
  const channelName = channel !== undefined ? channel.name : '';

  return (
    <LinkContainer to={`/marketplace/${marketplaceId}/walmartpricefeeds/${feed.id}`}>
      <ListGroup.Item action>
        <Row>
          <Col md="2" className="self-align-center text-dark font-weight-bold">
            {channelName}
          </Col>
          <Col md="4" style={{wordBreak: 'break-word'}}>{feed.feedId}</Col>
          <Col md="3">{dateFormat(feed.createdAt, true)}</Col>
          <Col md="3" className="self-align-center align-middle">
            {dateFormat(feed.updatedAt, true)}
          </Col>
        </Row>
      </ListGroup.Item>
    </LinkContainer>
  );
};

export default WalmartFeedResult;
