import React, { useState } from 'react';
import { Row, Col, Card, Collapse, Form, Badge } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import { TextField } from '../../../Shared/EFBootstrap';

import MarketplaceProductProps from '../../../../interfaces/MarketplaceProductProps';

import MarketplaceProductTags from './Tags';

const BasicProductInfo = (props: MarketplaceProductProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [youtubeId, setYoutubeId] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const { isEditing, product, handleProductChange } = props;

  const removeYoutubeVideoId = (value: string) => {
    let videoIdArr = product.youtubeVideoIds !== undefined &&
      product.youtubeVideoIds !== null &&
      product.youtubeVideoIds !== "" ?
      product.youtubeVideoIds.split(",") : [];

    videoIdArr = videoIdArr.filter(id => id !== value);
    handleProductChange("youtubeVideoIds", videoIdArr.join(","));
  }

  return (
    <Card className='mt-3'>
      <Card.Header onClick={() => setIsOpen(!isOpen)}>
        <h4>Product Information</h4>
      </Card.Header>
      <Collapse in={isOpen}>
        <Card.Body>
          <Row>
            <Col>
              <TextField
                label='Product Vendor'
                readOnly={!isEditing}
                value={product.vendor}
                onChange={newValue => handleProductChange('vendor', newValue)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label='Product Title'
                readOnly={!isEditing}
                value={product.title}
                onChange={newValue => handleProductChange('title', newValue)}
              />
            </Col>
            <Col>
              <TextField
                label='Product Handle'
                readOnly={true}
                value={product.handle}
                onChange={newValue => handleProductChange('handle', newValue)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ProductDescriptionField 
                label='Product Description'
                rows='10'
                readOnly={!isEditing}
                value={product.description}
                onChange={newValue =>
                  handleProductChange('description', newValue)
                }
                showPreview={showPreview}
                setShowPreview={setShowPreview}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <TextField
                label='Feature 1'
                readOnly={!isEditing}
                value={product.feature1}
                onChange={newValue => handleProductChange('feature1', newValue)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label='Feature 2'
                readOnly={!isEditing}
                value={product.feature2}
                onChange={newValue => handleProductChange('feature2', newValue)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label='Feature 3'
                readOnly={!isEditing}
                value={product.feature3}
                onChange={newValue => handleProductChange('feature3', newValue)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label='Feature 4'
                readOnly={!isEditing}
                value={product.feature4}
                onChange={newValue => handleProductChange('feature4', newValue)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label='Feature 5'
                readOnly={!isEditing}
                value={product.feature5}
                onChange={newValue => handleProductChange('feature5', newValue)}
              />
            </Col>
          </Row>
          <Row>
            <Col style={{color: 'black'}}>
              Youtube Id
            </Col>
          </Row>
          {!isEditing &&
          <Row>
            <Col>
              {product.youtubeVideoIds !== undefined &&
              product.youtubeVideoIds !== null &&
              product.youtubeVideoIds !== "" ?
              product.youtubeVideoIds.split(",").map((id, index) => (
                <Badge pill variant='primary' key={index}>
                  {id}
                </Badge>
              )) :
              <Badge pill variant='primary'>
                none
              </Badge>
            }
            </Col>
          </Row>
          }
          {isEditing &&
          <React.Fragment>
            <Row>
              <Col>
                {product.youtubeVideoIds !== undefined &&
                product.youtubeVideoIds !== null &&
                product.youtubeVideoIds !== "" ?
                product.youtubeVideoIds.split(",").map((id, index) => (
                  <Badge pill variant='primary' key={index} onClick={() => removeYoutubeVideoId(id)}>
                    {id} X
                  </Badge>
               )) :
               <Badge pill variant='primary'>
                 none
               </Badge>
              }
              </Col>
            </Row>
            <Row>
              <Col>
                <TextField
                  label=''
                  placeholder="Enter youtube vide ID and press Enter"
                  readOnly={!isEditing}
                  value={youtubeId}
                  onChange={newValue => setYoutubeId(newValue)}
                  onEnterKeyPress={() => {
                    handleProductChange('youtubeVideoIds', SetYoutubeIdFielsUnique(product.youtubeVideoIds, youtubeId))
                    setYoutubeId("")
                  }}
                />
              </Col>
            </Row>
          </React.Fragment>
          }
          <MarketplaceProductTags {...props} />
        </Card.Body>
      </Collapse>
    </Card>
  );
};

const ProductDescriptionField = (props) => {
  const { label, rows, value, readOnly, onChange, showPreview, setShowPreview } = props;

  const handleChange = event => {
    if (onChange === null || onChange === undefined) {
      return;
    }
    onChange(event.target.value);
  };

  return (
    <Form.Group>
      <Form.Label>{label}&nbsp;&nbsp;
        <button style={{ boxShadow: '0px 0px 2px 0px', border: 'none' }} onClick={() => setShowPreview(!showPreview)}>{showPreview ? 'Edit HTML' : 'Preview'}</button>
      </Form.Label>
      {!props.showPreview ?
        <Form.Control
          as="textarea"
          rows={rows}
          readOnly={
            readOnly === undefined || readOnly === null ? false : readOnly
          }
          value={value}
          onChange={handleChange}
        /> :
        <div style={{ backgroundColor: '#eceeef', padding: '15px 30px' }}>
          {ReactHtmlParser(value)}
        </div>
      }
    </Form.Group>
  );
}

export const SetYoutubeIdFielsUnique = (videoIds: string, newVal: string): string => {
  if (videoIds === undefined || videoIds === null || videoIds === "") {
    return newVal;
  }

  if (newVal === undefined || newVal === null || newVal === "") {
    return videoIds;
  }

  let videoIdArr = videoIds.split(",");
  videoIdArr = videoIdArr.concat(newVal.split(","));

  videoIdArr = videoIdArr.filter((item, pos) => {
    return videoIdArr.indexOf(item) === pos;
  });

  return videoIdArr.join(",");
}

export default BasicProductInfo;
