import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { TextField, Select } from '../../../../Shared/EFBootstrap';

import * as Settings from '../../../../../Settings';
import { CHANNEL_TYPE } from '../../../../../utils/Constants';
import { ZeroPositiveInteger } from '../../../../../utils/NumberHelper';

import { MarketplaceVariantProps } from './Variant';
import { validateVariantFieldsForWalmartPublish } from '../Channels/WalmartPublishButton';
import { GetVariantImage } from "../VariantImageThumbnail";


export default class VariantData extends React.Component<
  MarketplaceVariantProps
  > {
  state = {
    manufacturerColor: undefined
  }

  componentDidMount() {
    const { index, variant, handleVariantChange } = this.props;

    let manufacturerColor = variant.manufacturerColor;
    if (manufacturerColor === undefined || manufacturerColor === null || manufacturerColor === "") {
      manufacturerColor = this.setManufacturerColor();
      handleVariantChange(index, 'manufacturerColor', manufacturerColor);
    }

    this.setState({ manufacturerColor: manufacturerColor } );
  }
  
  unarchiveVariant = () => {
    const { index, channels, product, variant, addAlert, handleVariantChange} = this.props;
    const walmartChannel = channels.filter(c => (c.channelType === CHANNEL_TYPE.WALMART || c.channelType === CHANNEL_TYPE.WALMART_MKP)).map(c => c.id);
    const mcmps = product.marketplaceChannelMarketplaceProducts.filter(c => c.active === true && walmartChannel.includes(c.marketplaceChannelId));

    let isFieldsValid = true;
    mcmps.forEach(mcmp => {
      if (variant.marketplaceChannelMarketplaceVariants.filter(
        m => m.marketplaceChannelId === mcmp.marketplaceChannelId && m.isPublishable === true
      ).length > 0) {
        const channel = channels.filter(c => c.id === mcmp.marketplaceChannelId)[0];
        isFieldsValid = validateVariantFieldsForWalmartPublish(variant, channel, addAlert) && isFieldsValid;
      }
    });

    if (isFieldsValid) {
      handleVariantChange(index, 'archived', false)
    }
  }

  setManufacturerColor = () => {
    const { product, variant } = this.props;
    let isColorOptionAvail = false;
    let colorOptionNumber = "0";
    let manufacturerColor = undefined;
    for(let x of ["1", "2", "3"]){
      if (
        product[`option${x}`] !== undefined &&
        product[`option${x}`] !== null &&
        product[`option${x}`] !== ""
      ) {
        let optionVal: string = product[`option${x}`];
        if (optionVal.toLowerCase() === "color") {
          isColorOptionAvail = true;
          colorOptionNumber = x;
          break;
        }
      }
    }

    if (isColorOptionAvail) {
      let systemColors = ["Beige", "Black", "Blue", "Bronze", "Brown", "Clear", 
        "Gold", "Gray", "Grey", "Green", "Multicolor", "OffWhite", "Orange", 
        "Pink", "Purple", "Red", "Silver", "White", "Yellow"];

      systemColors.forEach(color => {
        if (variant[`option${colorOptionNumber}`] !== undefined &&
          variant[`option${colorOptionNumber}`] !== null &&
          variant[`option${colorOptionNumber}`] !== "" &&
          variant[`option${colorOptionNumber}`].includes(color)) {
            manufacturerColor = (color === "Gray" || color === "Grey") ? "Gray" : color;
        }
      });

      manufacturerColor = manufacturerColor === undefined ? "Multicolor" : manufacturerColor;
    }

    return manufacturerColor;
  }

  handlePositiveIntegers = (index, fieldName, newValue) => {
    let formattedNumber = ZeroPositiveInteger(newValue);
    this.props.handleVariantChange(index, fieldName, formattedNumber);
  }

  render() {
    const {
      isEditing,
      variant,
      handleVariantChange,
      handleRemoveVariant,
      productId,
      marketplaceId,
      product,
      index,
    } = this.props;

    const manufacturerColor = variant.manufacturerColor !== undefined &&
      variant.manufacturerColor !== null &&
      variant.manufacturerColor !== "" ? variant.manufacturerColor : this.state.manufacturerColor;

    const image = GetVariantImage(product, variant);
    
    return (
      <React.Fragment>
      <Row className='mt-4'>
        <Col>
          <Row>
            <Col>
              <TextField
                label='Variant ID'
                readOnly={true}
                value={variant.id}
              />
            </Col>
          </Row>
        </Col>
        <Col xs='auto' className='self-align-top text-right'>
          {variant.archived === true ? (
            <Button
              variant='outline-success'
              disabled={!isEditing}
              onClick={() => handleVariantChange(index, 'archived', false)}
            >
              Unarchive
            </Button>
          ) : (
            <Button
              size='sm'
              variant='outline-danger'
              disabled={!isEditing}
              onClick={() => handleVariantChange(index, 'archived', true)}
            >
              Archive
            </Button>
          )}
          <Button
            size='sm'
            variant='danger'
            disabled={!isEditing}
            onClick={() => handleRemoveVariant(variant.id)}
          >
            Delete
          </Button>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col>
          <Row>
            <Col>
              <TextField
                label='Variant Title'
                readOnly={true}
                value={variant.title}
                onChange={newValue =>
                  handleVariantChange(index, 'title', newValue)
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <TextField
            label={product.option1 !== undefined && product.option1 !== null && product.option1 !== '' ? product.option1 : 'Option 1'}
            readOnly={!isEditing}
            value={variant.option1}
            onChange={newValue =>
              handleVariantChange(index, 'option1', newValue)
            }
          />
        </Col>
        <Col>
          <TextField
            label={product.option2 !== undefined && product.option2 !== null && product.option2 !== '' ? product.option2 : 'Option 2'}
            readOnly={
              isEditing === false ||
              product.option2 === undefined ||
              product.option2 === null ||
              product.option2 === ''
            }
            helpText={
              product.option2 === undefined ||
              product.option2 === null ||
              product.option2 === ''
                ? 'Must create an Option 2 Category Name before editing'
                : ''
            }
            value={variant.option2}
            onChange={newValue =>
              handleVariantChange(index, 'option2', newValue)
            }
          />
        </Col>
        <Col>
          <TextField
            label={product.option3 !== undefined && product.option3 !== null && product.option3 !== '' ? product.option3 : 'Option 3'}
            readOnly={
              isEditing === false ||
              product.option3 === undefined ||
              product.option3 === null ||
              product.option3 === ''
            }
            helpText={
              product.option3 === undefined ||
              product.option3 === null ||
              product.option3 === ''
                ? 'Must create an Option 3 Category Name before editing'
                : ''
            }
            value={variant.option3}
            onChange={newValue =>
              handleVariantChange(index, 'option3', newValue)
            }
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col md="4" className='align-self-center'>
          <Select
            label='Color Category'
            readOnly={!isEditing}
            options={[
              { label: "Select an option", value: undefined },
              { label: 'Beige', value: 'Beige' },
              { label: 'Black', value: 'Black' },
              { label: 'Blue', value: 'Blue' },
              { label: 'Bronze', value: 'Bronze' },
              { label: 'Brown', value: 'Brown' },
              { label: 'Clear', value: 'Clear' },
              { label: 'Gold', value: 'Gold' },
              { label: 'Gray', value: 'Gray' },
              { label: 'Green', value: 'Green' },
              { label: 'Multicolor', value: 'Multicolor' },
              { label: 'OffWhite', value: 'OffWhite' },
              { label: 'Orange', value: 'Orange' },
              { label: 'Pink', value: 'Pink' },
              { label: 'Red', value: 'Red' },
              { label: 'Silver', value: 'Silver' },
              { label: 'White', value: 'White' },
              { label: 'Yellow', value: 'Yellow' }
            ]}
            value={manufacturerColor}
            onChange={newValue =>
              handleVariantChange(index, 'manufacturerColor', newValue === 'Select an option' ? undefined : newValue)
            }
          />
        </Col>
        <Col className='align-self-center' style={{height: "90px"}}>
          <div className="mr-1 input-with-clear">
            <TextField
              label='Associated Image Id'
              readOnly={!isEditing}
              value={variant.marketplaceProductImageId}
              onChange={newValue =>
                handleVariantChange(index, 'marketplaceProductImageId', newValue)
              }
            />
            {isEditing &&
              <span
                className="input-close-icon"
                onClick={() => handleVariantChange(index, 'marketplaceProductImageId', undefined)}
                style={{float: "right", right: "5px", top: "-54px"}}
              >
                  &times;
              </span>
            }
          </div>
        </Col>
        <Col className='align-self-center' xs='auto'>
          {productId !== "new" && image !== undefined ? (
            <img
              src={`${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/products/${product.id}/images/${image.id}/image`}
              width='100px'
              height='100px'
              alt={product.title + ' - ' + variant.title}
            />
          ) : (
            <p className='text-danger'>No image found</p>
          )}
        </Col>
      </Row>

      <Row className='mt-2'>
        <Col>
          <TextField
            label='SKU'
            readOnly={!isEditing}
            value={variant.sku}
            onChange={newValue => handleVariantChange(index, 'sku', newValue)}
          />
        </Col>

        <Col>
          <TextField
            label='MPN'
            readOnly={!isEditing}
            value={variant.mpn}
            onChange={newValue => handleVariantChange(index, 'mpn', newValue)}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col md="6">
          <TextField
            label='Barcode'
            readOnly={!isEditing}
            value={variant.barcode}
            onChange={newValue =>
              handleVariantChange(index, 'barcode', newValue)
            }
          />
        </Col>
        <Col md="6">
          <TextField
            label='Lead Time'
            readOnly={!isEditing}
            type="number"
            step={1}
            value={variant.leadTime}
            onChange={newValue =>
              this.handlePositiveIntegers(index, 'leadTime', newValue)
            }
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <TextField
            label='Length'
            readOnly={!isEditing}
            type='number'
            step={1}
            value={variant.dimL}
            onChange={newValue =>
              handleVariantChange(index, 'dimL', newValue)
            }
          />
        </Col>
        <Col>
          <TextField
            label='Width'
            readOnly={!isEditing}
            type='number'
            step={1}
            value={variant.dimW}
            onChange={newValue => handleVariantChange(index, 'dimW', newValue)}
          />
        </Col>
        <Col>
          <TextField
            label='Height'
            readOnly={!isEditing}
            type='number'
            step={1}
            value={variant.dimH}
            onChange={newValue => handleVariantChange(index, 'dimH', newValue)}
          />
        </Col>
        <Col>
          <Select
            label='Dimension Unit'
            readOnly={true}
            options={[
              { label: 'in', value: 'in' },
              { label: 'cm', value: 'cm' }
            ]}
            value={variant.dimUnit}
            onChange={newValue =>
              handleVariantChange(index, 'dimUnit', newValue)
            }
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <TextField
            label='Weight'
            readOnly={!isEditing}
            type='number'
            step={0.01}
            value={variant.weight}
            onChange={newValue =>
              handleVariantChange(index, 'weight', newValue)
            }
          />
        </Col>
        <Col>
          <Select
            label='Weight Unit'
            readOnly={!isEditing}
            options={[
              { label: 'g', value: 'g' },
              { label: 'kg', value: 'kg' },
              { label: 'oz', value: 'oz' },
              { label: 'lb', value: 'lb' }
            ]}
            value={variant.weightUnit}
            onChange={newValue =>
              handleVariantChange(index, 'weightUnit', newValue)
            }
          />
        </Col>
      </Row>
    </React.Fragment>
    );
  }
}
