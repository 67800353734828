import * as Settings from "../../Settings";
import rest from "../Rest";

import SellerVariantMarketplaceVariant from "../../classes/Marketplace/SellerVariantMarketplaceVariant";

const addAssociation = async (
  apiKey: string,
  marketplaceId: string,
  sellerVariantMarketplaceVariant: SellerVariantMarketplaceVariant
) => {
  return await rest.post(
    `${
      Settings.eformedApiBaseUri
    }marketplace/${marketplaceId}/sellervariantmarketplacevariants?key=${apiKey}`,
    sellerVariantMarketplaceVariant
  );
};

export default addAssociation;
