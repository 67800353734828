import Sellers from "../../Seller/Sellers";
import SellerHelp from "../../Seller/SellerHelp";
import SellerLogs from "../../Seller/SellerLogs";
import SellerVariants from "../../Seller/SellerVariantsForSeller";
import BulkInventory from "../../Seller/Tools/BulkInventory/BulkInventory";
import ImportVariants from "../../Seller/Tools/ImportVariants/ImportVariants";
import UpdateVariants from "../../Seller/Tools/UpdateVariants/UpdateVariants";

const SellerRoutes = [
  {
    exact: true,
    path: "/seller",
    component: Sellers
  },
  {
    exact: true,
    path: "/seller/:sellerId",
    component: Sellers
  },
  {
    exact: true,
    path: "/seller/:sellerId/help",
    component: SellerHelp
  },
  {
    exact: true,
    path: "/seller/:sellerId/logs",
    component: SellerLogs
  },
  {
    exact: true,
    path: "/seller/:sellerId/variants",
    component: SellerVariants
  },
  {
    exact: true,
    path: "/seller/:sellerId/tools/bulkinventory",
    component: BulkInventory
  },
  {
    exact: true,
    path: "/seller/:sellerId/tools/importvariants",
    component: ImportVariants
  },
  {
    exact: true,
    path: "/seller/:sellerId/tools/updatevariants",
    component: UpdateVariants
  }
];

export default SellerRoutes;
