import React, { useState } from 'react';
import { useDrag, useDrop, ConnectableElement } from 'react-dnd';

import MarketplaceVariantClass from '../../../../../classes/Marketplace/Variant';
import MarketplaceProduct from '../../../../../classes/Marketplace/Product';
import MetafieldModel from '../../../../../classes/Marketplace/Metafield';
import MarketplaceChannel from '../../../../../classes/Marketplace/Channel';
import MarketplaceVariant from "./Variant";

export interface VariantDraggableProps {
  id: string;
  moveCard: (id: string, to: number) => void;
  findCard: (id: string) => { index: number };
  index: number;
  isEditing: boolean;
  isImporting: boolean;
  marketplaceId: string;
  channels: MarketplaceChannel[];
  product: MarketplaceProduct;
  variant: MarketplaceVariantClass;
  handleVariantChange: (
    index: number,
    fieldName: string,
    newValue: any,
  ) => void;
  handleRemoveVariant: (variantId: string) => void;
  productId: string,
  apiKey: string;
  addAlert: any;
  setParentState: any;
  handleMetafieldChange: (mfield: MetafieldModel, newValue: string) => void;
  handleRoutingClick: (link: string) => void;
}

interface Item {
  type: string;
  id: string;
  originalIndex: number;
}

const VariantDraggable = (props: VariantDraggableProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const {id, moveCard, findCard, product, isEditing} = props;

  const originalIndex = findCard(id).index;

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: 'card', id, originalIndex },
    canDrag: isEditing && product.isManuallyOrderedVariants && !isOpen,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem()
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        moveCard(droppedId, originalIndex)
      }
    },
  });

  const [, drop] = useDrop({
    accept: 'card',
    canDrop: () => false,
    hover({ id: draggedId }: Item) {
      if (draggedId !== id) {
        const { index: overIndex } = findCard(id)
        moveCard(draggedId, overIndex)
      }
    },
  });

  const opacity = isDragging ? 0 : 1;

  return (
    <div ref={preview} style={{ opacity }}>
      <MarketplaceVariant
        ref={(node: ConnectableElement) => drag(drop(node))}
        {...props}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  )
}

export default VariantDraggable