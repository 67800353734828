import rest from '../../../../../utils/Rest';
import { eformedApiBaseUri } from '../../../../../Settings';

import Product from '../../../../../classes/Marketplace/Product';
import Channel from '../../../../../classes/Marketplace/Channel';

const updateChannel = async (
  product: Product,
  channel: Channel,
  marketplaceId: string,
  apiKey: string,
  type: string,
  variantId: string = undefined,
  setParentState: (any) => void
  ) => {
  await setParentState({
    isUpdating: true,
    showModal: true,
  });
  try {
    let data = await rest.get(
      `${eformedApiBaseUri}marketplace/${marketplaceId}/products/${
        product.id
      }/channelupdate/${channel.id}?key=${apiKey}&type=${type}${
        variantId !== undefined ? `&variantId=${variantId}` : ''
      }`,
    );
    await setParentState({
      updateMessages: data,
      isUpdating: false,
    });
  } catch (error) {
    await setParentState({
      isUpdating: false,
      updateMessages: ['There was a fatal error when trying to update.'],
    });
  }
};

export default updateChannel;