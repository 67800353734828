import React from "react";
import { Form } from "react-bootstrap";

const TextArea = props => {
  const { label, rows, value, readOnly, onChange, placeholder, style } = props;

  const handleChange = event => {
    if (onChange === null || onChange === undefined) {
      return;
    }
    onChange(event.target.value);
  };

  return (
    <Form.Group>
      <Form.Label style={{color: 'black'}}>{label}</Form.Label>
      <Form.Control
        as="textarea"
        rows={rows}
        style={style}
        readOnly={
          readOnly === undefined || readOnly === null ? false : readOnly
        }
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
      />
    </Form.Group>
  );
};

export default TextArea;
