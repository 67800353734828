import React from 'react';
import { Row, Col, Card, Badge, Collapse } from 'react-bootstrap';

import { EDIOrderProps, EDIOrderState } from './EDIOrder';
import EDITransaction from '../../../classes/EDI/EDITransaction';

interface EDITransactionCardProps extends EDIOrderProps, EDIOrderState {
  transaction: EDITransaction;
}

export default class EDITransactionCard extends React.Component<
  EDITransactionCardProps
> {
  state = {
    isOpen: false,
  };

  render() {
    const { transaction } = this.props;
    const { isOpen } = this.state;
    return (
      <Row>
        <Col>
          <Card>
            <Card.Header onClick={() => this.setState({ isOpen: !isOpen })}>
              <Row>
                <Col>
                  {transaction.transactionNumber} - {transaction.poNumber}
                </Col>
                <Col xs='auto'>
                  <Badge
                    pill
                    variant={
                      transaction.status === 'Created'
                        ? 'info'
                        : transaction.status === 'Sent'
                        ? 'warning'
                        : transaction.status === 'MDN Received'
                        ? 'success'
                        : 'secondary'
                    }
                  >
                    {transaction.status}
                  </Badge>
                </Col>
              </Row>
            </Card.Header>
            <Collapse in={isOpen}>
              <Card.Body>
                <Row>
                  <Col md='2'>Created At: </Col>
                  <Col>{transaction.createdAt}</Col>
                </Row>
                <Row>
                  <Col md='2'>Updated At: </Col>
                  <Col>{transaction.updatedAt}</Col>
                </Row>
                <Row>
                  <Col md='2'>From: </Col>
                  <Col>{transaction.from}</Col>
                </Row>
                <Row>
                  <Col md='2'>To: </Col>
                  <Col>{transaction.to}</Col>
                </Row>
                <Row>
                  <Col md='2'>Message ID: </Col>
                  <Col>{transaction.messageId}</Col>
                </Row>
                <Row>
                  <Col md='2'>Message: </Col>
                  <Col>{transaction.message}</Col>
                </Row>
                <Row>
                  <Col md='2'>Init Resp Code: </Col>
                  <Col>{transaction.initialResponseCode}</Col>
                </Row>
                <Row>
                  <Col md='2'>Init Response: </Col>
                  <Col>{transaction.initialResponse}</Col>
                </Row>
                <Row>
                  <Col md='2'>MDN Status Code: </Col>
                  <Col>{transaction.mdnStatusCode}</Col>
                </Row>
                <Row>
                  <Col md='2'>MDN Response: </Col>
                  <Col>{transaction.mdnResponse}</Col>
                </Row>
              </Card.Body>
            </Collapse>
          </Card>
        </Col>
      </Row>
    );
  }
}
