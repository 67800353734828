import WalmartPartnerFeedDetails from './WalmartPartnerFeedDetails';

export default class WalmartPartnerFeed {
  id: string;
  marketplaceId: string;
  marketplaceChannelId: string;
  feedId: string;
  feedStatus: string;
  feedType: number;
  status: number;
  feedSubmissionDate: string;
  lastUpdatedDate: string;    
  itemsReceived: number;
  itemsSucceeded: number;
  itemsFailed: number;
  itemsProcessing: number;
  createdAt: string;
  lastErrorMessage: string;
  partnerFeedItemDetails: WalmartPartnerFeedDetails[];
}
