import createVariantTitle from '../../../../../utils/CreateVariantTitle';

const handleVariantChange = async (
  index,
  fieldName,
  newValue,
  state,
  setParentState,
) => {
  let tempProduct = state.product;
  const newVariants = tempProduct.variants.map((variant, iindex) => {
    if (index !== iindex) {
      return variant;
    } else if (
      fieldName === 'option1' ||
      fieldName === 'option2' ||
      fieldName === 'option3'
    ) {
      return {
        ...variant,
        [fieldName]: newValue,
        title: createVariantTitle(
          fieldName === 'option1' ? newValue : variant.option1,
          fieldName === 'option2' ? newValue : variant.option2,
          fieldName === 'option3' ? newValue : variant.option3,
        ),
      };
    }
    return {
      ...variant,
      [fieldName]: newValue,
    };
  });
  tempProduct.variants = newVariants;
  setParentState({ product: tempProduct });
};

export default handleVariantChange;
