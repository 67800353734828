const enterprise = {
  name: 'Enterprise',
  variant: 'info',
  text: 'light',
  description:
    'This stack contains line of business apps that perform critical operations-centric functionality.',
  apps: [
    {
      name: 'EFEnterprise',
      link: 'https://github.com/eformedpartners/EFEnterprise',
      description:
        "This app contains a large amount of services surrounding commonalities between eFormed businesses. It's public face is the eFormed Portal (where you are now).",
      services: [
        {
          name: 'EFEnterprise.Storage',
          type: 'Storage',
          variant: 'info',
          description: 'Database holding all app functionality.',
          location: 'Azure SQL - EFEnterprise',
          language: 'SQL',
          framework: 'Azure SQL',
        },
        {
          name: 'EFEnterprise.Logistics.Storage',
          type: 'Storage',
          variant: 'info',
          description:
            'Database holding the data retrieved from OSCommerce. This is considered temporary as a shim for OSC.',
          location: 'Azure SQL - LogisticsIntegration',
          language: 'SQL',
          framework: 'Azure SQL',
        },
        {
          name: 'EFEnterprise.API.Core',
          type: 'Back-end',
          variant: 'warning',
          description: 'Library that contains common API functionality.',
          location: 'Shared',
          language: 'C#',
          framework: 'Class Libraries',
        },
        {
          name: 'EFEnterprise.API.DataProcessing',
          type: 'Back-end',
          variant: 'warning',
          description:
            'Performs data gathering, processing, and manipulating for API centric data. This has become much more important with the addition of the "Fixer" functions, which perform background database tasks for the Marketplace',
          location: 'Azure Serverless - eformed',
          language: 'C#',
          framework: 'Azure Functions',
        },
        {
          name: 'EFEnterprise.API.EFormabot',
          type: 'AI',
          variant: 'primary',
          description:
            'Chatbot that interacts with the eFormed API to perform business functions (inside Slack). This uses some AI functionality like Language Recognization and Q & A.',
          location: 'Azure Web App for Containers - eformabotlinux',
          language: 'C#',
          framework: 'Azure Bot Framework',
        },
        {
          name: 'EFEnterprise.API.WebApp',
          type: 'Front-end',
          variant: 'success',
          description:
            'A service that houses both the API and Portal for eFormed. This is the largest and most varied of the APIs. The swagger for this API is available at https://api.eformedpartners.com/swagger',
          location: 'Azure Web App for Containers - eformedapi',
          language: 'C#',
          framework: 'ASP.Net Core',
        },
        {
          name: 'EFEnterprise.Channels.Shopify',
          type: 'Back-end',
          variant: 'warning',
          description: 'Integration from the Marketplaces to Shopify.',
          location: 'Azure Web App for Containers - syncdbshopify',
          language: 'C#',
          framework: 'Azure Functions',
        },
        {
          name: 'EFEnterprise.Core',
          type: 'Back-end',
          variant: 'warning',
          description: 'Holds all functions common to the whole app.',
          location: 'Shared',
          language: 'C#',
          framework: 'Class Libraries',
        },
        {
          name: 'EFEnterprise.DataModel',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This is the data model that connects the various services to the storage.  The actual database is fully designed through the migrations created through this project (in conjunction with .WebApp to push them live).',
          location: 'Shared',
          language: 'C#',
          framework: 'Entity Framework Core',
        },
        {
          name: 'EFEnterprise.Logistics.Billing.Core',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This holds Logistics Billing (Distribution) functionality other apps may need.',
          location: 'Shared',
          language: 'C#',
          framework: 'Class Libraries',
        },
        {
          name: 'EFEnterprise.Logistics.Billing.DataProcessing',
          type: 'Back-end',
          variant: 'warning',
          description:
            'Integration from OSCommerce (Legacy) that creates reporting to bill distribution partners.',
          location: 'Azure Serverless - logisticsportaldataprocessing',
          language: 'C#',
          framework: 'Azure Functions',
        },
        {
          name: 'EFEnterprise.Logistics.DataProcessing',
          type: 'Back-end',
          variant: 'warning',
          description:
            'Sometimes called LogisticsIntegration, this is the only connection point to OSCommerce for all of the rest of the apps (which use the API to gain access). Uses .Storage for storage of this data.',
          location: 'Azure Web Apps for Containers - logisticsintegration',
          language: 'C#',
          framework: 'Azure Functions',
        },
        {
          name: 'EFEnterprise.Sellers.DataProcessing',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This provides the primary data processing for the Seller side of the Marketplace app. This contains all of the "Drop Ship Integrations" like FTP and Shopify.',
          location: 'Azure Web Apps for Containers - efenterprisesellers',
          language: 'C#',
          framework: 'Azure Functions',
        },
        {
          name: 'EFEnterprise.Sellers.EDI',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This provides EDI send and receive functionality for the Sellers of the Marketplace app.',
          location: 'Azure Logic Apps - EDI Resource Group',
          language: 'C#',
          framework: 'Azure Logic Apps',
        },
        {
          name: 'EFEnterprise.Sellers.FamilywiseEmailFeed',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This provides the ability to send feeds through email, which in turn ends up in FTP.',
          location: 'Azure Logic Apps - FamilywiseEmailFeed Resource Group',
          language: 'C#',
          framework: 'Azure Logic Apps',
        },
        {
          name: 'EFEnterprise.Sellers.Logistics',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This specifically loads the warehouse products (piggybacking on Logistics.DataProcessing), and does so at a much higher rate than Sellers.DataProcessing.',
          location: 'Azure Serverless - syncdbwarehouse',
          language: 'C#',
          framework: 'Azure Functions',
        },
      ],
    },
  ],
};

export default enterprise;
