import rest from "../../../../../utils/Rest";
import errorHandler from "../../../../../utils/ErrorHandler";
import * as Settings from "../../../../../Settings";
import createMarketplaceProductFromSellerVariant, {
  mapSellerVariantToVariant, createProductImagesFromSellerVariantImages
} from "../../../../../utils/SellerVariantToMarketplaceProduct";

import SellerVariant from "../../../../../classes/Seller/SellerVariant";
import MarketplaceSeller from "../../../../../classes/Marketplace/MarketplaceSeller";
import MarketplaceProduct from "../../../../../classes/Marketplace/Product";
import MarketplaceVariant from "../../../../../classes/Marketplace/Variant";
import Metafield from "../../../../../classes/Marketplace/Metafield";
import { SetYoutubeIdFielsUnique } from "../BasicProductInfo";
import MarketplaceChannelMarketplaceVariant from "../../../../../classes/Marketplace/MarketplaceChannelMarketplaceVariant";
import MarketplaceChannel from "../../../../../classes/Marketplace/Channel";

export const importSellerVariantToProduct = async (
  sellerVariantId: string,
  props,
  setParentState
) => {
  const { apiKey, marketplaceId, addAlert } = props;
  await setParentState({ isImporting: true });
  try {
    let sellerVariant: SellerVariant = await getSellerVariant(
      apiKey,
      marketplaceId,
      sellerVariantId
    );
    if (sellerVariant !== undefined) {
      let tempProduct = await createMarketplaceProductFromSellerVariant(
        sellerVariant,
        marketplaceId
      );

      // add default metafields to product
      let metafields = await getDefaultMetafields(apiKey, marketplaceId);
      if (metafields !== undefined && metafields !== null && metafields.length > 0) {
        let productMetafields = metafields.filter(metafield => metafield.ownerResource === "product");
        productMetafields.forEach((metafield) => {
          metafield.ownerId = tempProduct.id
        });

        let variantMetafields = metafields.filter(metafield => metafield.ownerResource === "variant");
        variantMetafields.forEach((metafield) => {
          metafield.ownerId = tempProduct.variants[0].id
        });

        let formattedMetafields = productMetafields.concat(variantMetafields);
        tempProduct.metafields = formattedMetafields;
      }

      // add variant channel records to variant
      tempProduct.variants[0] = await createMarketplaceChannelMarketplaceVariant(tempProduct.variants[0], sellerVariant, props.channels);

      await setParentState({ product: tempProduct, isImporting: false, isImported: true });
      addAlert("success", `Seller Variant was successfully imported.`);
      addAlert(
        "info",
        "Please note that you must verify the information, save the changes, copy the Seller Variant Id to the variant, and then associate the variant in order to complete the process."
      );
    } else {
      throw sellerVariant;
    }
  } catch (error) {
    await errorHandler(
      error,
      "There was an error while importing the seller variant.",
      addAlert
    );
    await setParentState({ isImporting: false });
  }
};

export const importSellerVariantToVariant = async (
  index: number,
  sellerVariantId: string,
  props,
  setParentState
) => {
  const { apiKey, marketplaceId, addAlert, product } = props;
  await setParentState({ isImporting: true });
  try {
    let sellerVariant: SellerVariant = await getSellerVariant(
      apiKey,
      marketplaceId,
      sellerVariantId
    );
    if (sellerVariant !== undefined) {
      let tempProduct: MarketplaceProduct = product;

      // update product youtube video ids
      if (sellerVariant.youtubeVideoId !== undefined && sellerVariant.youtubeVideoId !== null && sellerVariant.youtubeVideoId.trim() !== '') {
        tempProduct.youtubeVideoIds = tempProduct.youtubeVideoIds !== undefined && tempProduct.youtubeVideoIds !== null && tempProduct.youtubeVideoIds.trim() !== '' ?
          SetYoutubeIdFielsUnique(tempProduct.youtubeVideoIds, sellerVariant.youtubeVideoId) : sellerVariant.youtubeVideoId;
      }

      let tempVariant = tempProduct.variants[index];
      await mapSellerVariantToVariant(tempVariant, sellerVariant);

      // add variant channel records to variant
      tempVariant = await createMarketplaceChannelMarketplaceVariant(tempVariant, sellerVariant, props.channels);
      
      const newVariants = tempProduct.variants.map((variant, iindex) => {
        if (index !== iindex) {
          return variant;
        } else {
          return tempVariant;
        }
      });
      tempProduct.variants = newVariants;
      
      tempProduct.images = tempProduct.images.concat(await createProductImagesFromSellerVariantImages(product.id, sellerVariant))

      // add variant default metafileds
      let metafields = await getDefaultMetafields(apiKey, marketplaceId);
      if (metafields !== undefined && metafields !== null && metafields.length > 0) {
        let variantMetafields = metafields.filter(metafield => metafield.ownerResource === "variant");
        variantMetafields.forEach((metafield) => {
          metafield.ownerId = tempVariant.id
        });

        let formattedMetafields = tempProduct.metafields.concat(variantMetafields);
        tempProduct.metafields = formattedMetafields;
      }

      await setParentState({ product: tempProduct, isImporting: false });
      addAlert(
        "success",
        `Seller Variant was successfully imported to variant ${tempVariant.id}.`
      );
      addAlert(
        "info",
        "Please note that you must verify the information, save the changes, and then associate the variant in order to complete the process."
      );
    } else {
      throw sellerVariant;
    }
  } catch (error) {
    await errorHandler(
      error,
      "There was an error while importing the seller variant.",
      addAlert
    );
    await setParentState({ isImporting: false });
  }
};

export const reUpdateProduct = async (
  sellerVariantId: string,
  props,
  setParentState
) => {
  const { apiKey, marketplaceId, addAlert } = props;
  await setParentState({ isImporting: true });
  try {
    let sellerVariant: SellerVariant = await getSellerVariant(
      apiKey,
      marketplaceId,
      sellerVariantId
    );
    if (sellerVariant !== undefined) {
      let tempProduct = props.product;
      tempProduct.title = sellerVariant.productTitle;
      tempProduct.vendor = sellerVariant.vendor;
      tempProduct.description = sellerVariant.description;

      [1, 2, 3, 4, 5].forEach(x => {
        let fieldName = `feature${x}`;
        tempProduct[fieldName] = sellerVariant[fieldName];
      });

      await setParentState({ product: tempProduct, isImporting: false, isImported: true });
      addAlert("success", `Seller Variant was successfully imported.`);
      addAlert(
        "info",
        "Please note that you must verify the information and save the changes."
      );
    } else {
      throw sellerVariant;
    }
  } catch (error) {
    await errorHandler(
      error,
      "There was an error while importing the seller variant.",
      addAlert
    );
    await setParentState({ isImporting: false });
  }
}

export const updateProductChannelPrices = async (
  variantId: string,
  sellerVariantId: string,
  props,
  setParentState
) => {
  const { apiKey, marketplaceId, addAlert } = props;
  await setParentState({ isImporting: true });
  try {
    let sellerVariant: SellerVariant = await getSellerVariant(
      apiKey,
      marketplaceId,
      sellerVariantId
    );
    if (sellerVariant !== undefined) {
      let tempProduct: MarketplaceProduct = props.product;
      let tempVariants = tempProduct.variants;
      tempVariants = tempVariants.map(variant => {
        if (variant.id !== variantId) {
          return variant;
        } else {
          return updateMarketplaceChannelMarketplaceVariant(variant, sellerVariant);
        }
      })

      tempProduct.variants = tempVariants;

      await setParentState({ product: tempProduct, isImporting: false, isImported: true });
      addAlert("success", `Variant channel prices successfully updated.`);
    } else {
      throw sellerVariant;
    }
  } catch (error) {
    await errorHandler(
      error,
      "There was an error while importing the seller variant.",
      addAlert
    );
    await setParentState({ isImporting: false });
  }
}

const getSellerVariant = async (
  apiKey: string,
  marketplaceId: string,
  sellerVariantId: string
): Promise<SellerVariant> => {
  let sellers: MarketplaceSeller[] = await rest.get(
    `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/sellers?key=${apiKey}&sellervariantId=${sellerVariantId}`
  );
  if (sellers !== null && sellers.length > 0) {
    let sellerId = sellers[0].sellerId;

    return await rest.get(
      `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/sellers/${sellerId}/sellervariants/${sellerVariantId}?key=${apiKey}`
    );
  } else {
    return undefined;
  }
};

const getDefaultMetafields = async (
  apiKey: string,
  marketplaceId: string): Promise<Metafield[]> => {
  
  let data: Metafield[] = await rest.get(
      `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/metafields/templates?key=${apiKey}`
  )

  return data;
}

const createMarketplaceChannelMarketplaceVariant = async (variant: MarketplaceVariant, sellerVariant: SellerVariant, channels: MarketplaceChannel[]) => {
  variant.marketplaceChannelMarketplaceVariants = variant.marketplaceChannelMarketplaceVariants !== undefined ?
    variant.marketplaceChannelMarketplaceVariants : new Array<MarketplaceChannelMarketplaceVariant>();

  channels !== undefined && channels.forEach((channel) => {
    if (variant.marketplaceChannelMarketplaceVariants.filter(v => v.marketplaceChannelId === channel.id).length === 0) {
      let marketplaceChannelMarketplaceVariant = new MarketplaceChannelMarketplaceVariant();
      marketplaceChannelMarketplaceVariant.marketplaceChannelId = channel.id;
      marketplaceChannelMarketplaceVariant.marketplaceVariantId = variant.id;
      marketplaceChannelMarketplaceVariant.active = false;
      marketplaceChannelMarketplaceVariant.isPublishable = false;
      marketplaceChannelMarketplaceVariant.error = false;

      marketplaceChannelMarketplaceVariant.price = sellerVariant.price;
      marketplaceChannelMarketplaceVariant.map = sellerVariant.map;
      marketplaceChannelMarketplaceVariant.handlingCharge = sellerVariant.handlingCharge;
      marketplaceChannelMarketplaceVariant.compareAtPrice = sellerVariant.compareAtPrice;
      marketplaceChannelMarketplaceVariant.cost = sellerVariant.cost;
      marketplaceChannelMarketplaceVariant.outValue = sellerVariant.outValue;

      variant.marketplaceChannelMarketplaceVariants.push(marketplaceChannelMarketplaceVariant);
    }
  });

  return variant;
}

const updateMarketplaceChannelMarketplaceVariant = (variant: MarketplaceVariant, sellerVariant: SellerVariant) => {
  let marketplaceChannelMarketplaceVariants = variant.marketplaceChannelMarketplaceVariants;
  let tempMarketplaceChannelMarketplaceVariants = new Array<MarketplaceChannelMarketplaceVariant>();

  if (marketplaceChannelMarketplaceVariants !== undefined && marketplaceChannelMarketplaceVariants !== null && marketplaceChannelMarketplaceVariants.length > 0) {
    marketplaceChannelMarketplaceVariants.forEach(mcmv => {
      mcmv.price = sellerVariant.price;
      mcmv.map = sellerVariant.map;
      mcmv.handlingCharge = sellerVariant.handlingCharge;
      mcmv.compareAtPrice = sellerVariant.compareAtPrice;
      mcmv.cost = sellerVariant.cost;
      mcmv.outValue = sellerVariant.outValue;

      tempMarketplaceChannelMarketplaceVariants.push(mcmv);
    });

    variant.marketplaceChannelMarketplaceVariants = tempMarketplaceChannelMarketplaceVariants;
  }

  return variant;
}
