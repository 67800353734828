import React from "react";
import { Row, Col, Card, Button, Spinner, Collapse } from "react-bootstrap";
import ReactHtmlParser from 'react-html-parser';

import { TextField, Select } from "../../Shared/EFBootstrap";
import SellerVariant from "../../../classes/Seller/SellerVariant";

interface SellerVariantCardForSellerProps {
  index: number;
  sellerVariant: SellerVariant;
  handleFieldChange: (index: number, fieldName: string, newValue: any) => void;
  saveSellerVariant: (sellerVariant: SellerVariant) => Promise<boolean>;
}

interface SellerVariantCardForSellerState {
  isEditing: boolean;
  isSaving: boolean;
  isOpenDetails: boolean;
}

export default class SellerVariantCardForSeller extends React.Component<
  SellerVariantCardForSellerProps,
  SellerVariantCardForSellerState
> {
  state = {
    isEditing: false,
    isSaving: false,
    isOpenDetails: false
  };

  saveChanges = async () => {
    await this.setState({ isSaving: true });
    if (await this.props.saveSellerVariant(this.props.sellerVariant)) {
      await this.setState({ isSaving: false, isEditing: false });
    } else {
      this.setState({ isSaving: false });
    }
  };

  openCloseVariantDetails = () => {
    this.setState({ isOpenDetails: !this.state.isOpenDetails });
  };
  
  formatPriceFields = (price: number) : number => {
    return price !== undefined && price !== null ? price : 0;
  }

  render() {
    const { index, sellerVariant, handleFieldChange } = this.props;
    const { isEditing, isSaving, isOpenDetails } = this.state;

    return (
      <Row className="mt-4">
        <Col>
          <Card>
            <Card.Header onClick={() => {this.openCloseVariantDetails()}}>
              <Row>
                <Col>
                  <Row>
                    <Col md="2">Internal Id:</Col>
                    <Col>{sellerVariant.id}</Col>
                  </Row>
                  <Row>
                    <Col md="2">SKU:</Col>
                    <Col>{sellerVariant.sku}</Col>
                  </Row>
                  <Row>
                    <Col md="2">Vendor:</Col>
                    <Col md="4">{sellerVariant.vendor}</Col>
                  </Row>
                  <Row>
                    <Col md="2">Title:</Col>
                    <Col>
                      {sellerVariant.productTitle}
                      {sellerVariant.title !== undefined &&
                        sellerVariant.title !== null &&
                        sellerVariant.title !== "" &&
                        " - "}
                      {sellerVariant.title}
                    </Col>
                  </Row>
                </Col>
                <Col lg="3">
                  <Button
                    variant={isEditing ? "success" : "outline-warning"}
                    disabled={isSaving}
                    onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          isEditing ? 
                            this.saveChanges() :
                            this.setState({ isEditing: true })
                        }
                    }
                  >
                    {isEditing ? (
                      isSaving ? (
                        <React.Fragment>
                          <Spinner size="sm" animation="border" />
                          Saving...
                        </React.Fragment>
                      ) : (
                        "Save"
                      )
                    ) : (
                      "Edit"
                    )}
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Collapse in={isOpenDetails}>
              <Card.Body>
                <Row>        
                  <Col md="4" className='align-self-top'>
                    <Select
                      label=''
                      readOnly={!isEditing}
                      options={[
                        { label: 'Active', value: 'false' },
                        { label: 'Archive', value: 'true' },
                      ]}
                      value={sellerVariant.archived === undefined || sellerVariant.archived === null ? 'false' : sellerVariant.archived}
                      onChange={newValue =>
                        handleFieldChange(index, "archived", newValue)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextField
                      label={`Inventory Quantity (Threshold: ${
                        sellerVariant.quantityAboveThreshold === null || sellerVariant.quantityAboveThreshold === undefined ? 0 : sellerVariant.quantityAboveThreshold
                      })`}
                      type="number"
                      readOnly={!isEditing}
                      step={1}
                      value={sellerVariant.quantity}
                      onChange={newValue =>
                        handleFieldChange(index, "quantity", newValue)
                      }
                    />
                  </Col>
                  <Col>
                    <TextField
                      label="Cost"
                      readOnly={!isEditing}
                      prefix="$"
                      type="number"
                      step={0.01}
                      value={sellerVariant.cost}
                      onChange={newValue =>
                        handleFieldChange(index, "cost", newValue)
                      }
                    />
                  </Col>
                  <Col>
                    <Select
                      label='Inventory Policy'
                      readOnly={!isEditing}
                      options={[
                        { label: 'Continue', value: 'continue' },
                        { label: 'Deny', value: 'deny' },
                        { label: 'Disable', value: 'disable' }
                      ]}
                      value={sellerVariant.inventoryPolicy}
                      onChange={newValue =>
                        handleFieldChange(index, 'inventoryPolicy', newValue)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextField
                      label="SKU"
                      readOnly={!isEditing}
                      value={sellerVariant.sku}
                      onChange={newValue =>
                        handleFieldChange(index, "sku", newValue)
                      }
                    />
                  </Col>
                  <Col>
                    <TextField
                      label="Barcode"
                      readOnly={!isEditing}
                      value={sellerVariant.barcode}
                      onChange={newValue =>
                        handleFieldChange(index, "barcode", newValue)
                      }
                    />
                  </Col>
                  <Col>
                    <TextField
                      label="MPN"
                      readOnly={!isEditing}
                      value={sellerVariant.mpn}
                      onChange={newValue =>
                        handleFieldChange(index, "mpn", newValue)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="2">SKU:</Col>
                  <Col md="4">{sellerVariant.sku}</Col>
                  <Col md="2">Option 1:</Col>
                  <Col md="4">{sellerVariant.option1Name ? `${sellerVariant.option1Name}: ${sellerVariant.option1Value}` : ' '}</Col>
                </Row>
                <Row>
                  <Col md="2">Barcode:</Col>
                  <Col md="4">{sellerVariant.barcode}</Col>
                  <Col md="2">Option 2:</Col>
                  <Col md="4">{sellerVariant.option2Name ? `${sellerVariant.option2Name}: ${sellerVariant.option2Value}` : ' '}</Col>
                </Row>
                <Row>
                  <Col md="2">MPN:</Col>
                  <Col md="4">{sellerVariant.mpn}</Col>
                  <Col md="2">Option 3:</Col>
                  <Col md="4">{sellerVariant.option3Name ? `${sellerVariant.option3Name}: ${sellerVariant.option3Value}` : ' '}</Col>
                </Row>
                <hr/>
                <Row>
                  <Col md="2">Feed Price:</Col>
                  <Col md="4">${this.formatPriceFields(sellerVariant.price)}</Col>
                  <Col md="2">MSRP:</Col>
                  <Col md="4">${this.formatPriceFields(sellerVariant.compareAtPrice)}</Col>
                </Row>
                <Row>
                  <Col md="2">MAP:</Col>
                  <Col md="4">${this.formatPriceFields(sellerVariant.map)}</Col>
                  <Col md="2">Cost:</Col>
                  <Col md="4">${this.formatPriceFields(sellerVariant.cost)}</Col>
                </Row>
                <Row>
                  <Col md="2">Handling:</Col>
                  <Col md="4">${this.formatPriceFields(sellerVariant.handlingCharge)}</Col>
                  <Col md="2">Out:</Col>
                  <Col md="4">${this.formatPriceFields(sellerVariant.outValue)}</Col>
                </Row>
                <hr/>
                <Row>
                  <Col md="2" style={{ whiteSpace: "nowrap"}}>Inventory Policy:</Col>
                  <Col md="4">{sellerVariant.inventoryPolicy}</Col>
                  <Col md="2">Lead Time:</Col>
                  <Col md="4">{sellerVariant.leadTime}</Col>
                </Row>
                <hr/>
                <Row>
                  <Col md="2">Weight:</Col>
                  <Col md="4">
                    {sellerVariant.weight}
                    {sellerVariant.weightUnit}
                  </Col>
                  <Col md="2">Height:</Col>
                  <Col md="4">
                    {sellerVariant.dimH}
                    {sellerVariant.dimUnit}
                  </Col>
                </Row>
                <Row>
                  <Col md="2">Length:</Col>
                  <Col md="4">
                    {sellerVariant.dimL}
                    {sellerVariant.dimUnit}
                  </Col>
                  <Col md="2">Width:</Col>
                  <Col md="4">
                    {sellerVariant.dimW}
                    {sellerVariant.dimUnit}
                  </Col>
                </Row>
                <hr/>
                <Row>
                  <Col md="2">Description:</Col>
                  <Col md="10" style={{overflow: 'hidden'}}>{ ReactHtmlParser(sellerVariant.description) }</Col>
                </Row><br/>
                <Row>
                  <Col md="2">Feature 1:</Col>
                  <Col md="10" style={{overflow: 'hidden'}}>{sellerVariant.feature1}</Col>
                </Row><br/>
                <Row>
                  <Col md="2">Feature 2:</Col>
                  <Col md="10" style={{overflow: 'hidden'}}>{sellerVariant.feature2}</Col>
                </Row><br/>
                <Row>
                  <Col md="2">Feature 3:</Col>
                  <Col md="10" style={{overflow: 'hidden'}}>{sellerVariant.feature3}</Col>
                </Row><br/>
                <Row>
                  <Col md="2">Feature 4:</Col>
                  <Col md="10" style={{overflow: 'hidden'}}>{sellerVariant.feature4}</Col>
                </Row><br/>
                <Row>
                  <Col md="2">Feature 5:</Col>
                  <Col md="10" style={{overflow: 'hidden'}}>{sellerVariant.feature5}</Col>
                </Row><br/>
                <Row>
                  <Col md="2">Tags:</Col>
                  <Col md="10">{sellerVariant.tags}</Col>
                </Row>
                <hr/>
                <Row>
                  <Col md="2">Featured Image URL:</Col>
                  <Col md="10">
                    <a href={sellerVariant.featuredImageURL} target="_blank" rel="noopener noreferrer">
                      {sellerVariant.featuredImageURL}
                    </a>
                  </Col>
                </Row>
                <Row>
                  <Col md="2">Variant Image URL:</Col>
                  <Col md="10">
                    <a href={sellerVariant.variantImageURL} target="_blank" rel="noopener noreferrer">
                      {sellerVariant.variantImageURL}
                    </a>
                  </Col>
                </Row>
                <Row>
                  <Col md="2">Other Image URLs:</Col>
                  <Col md="10">
                    {sellerVariant.additionalImageURLs !== null && sellerVariant.additionalImageURLs.split(',').map((url, index) => (
                      <React.Fragment key={index}>
                        <a href={url} target="_blank" rel="noopener noreferrer">
                          {url}
                        </a><br/>
                      </React.Fragment>
                    ))}
                  </Col>
                </Row>
              </Card.Body>
            </Collapse>
          </Card>
        </Col>
      </Row>
    );
  }
}
