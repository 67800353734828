import React from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import RoutingProps from '../../../interfaces/RoutingProps';

const SellerMenu = (props: RoutingProps) => {
  if (props.user.sellers === undefined || props.user.sellers.length === 0) {
    return null;
  }
  return (
    <React.Fragment>
      <LinkContainer
        exact
        to={`/seller`}
      >
        <Nav.Link>{
          props.selectedSeller !== undefined
            ? 'Seller: ' + props.selectedSeller.name
            : 'Please Select Seller'
        }</Nav.Link>
      </LinkContainer>
      <LinkContainer
        exact
        to={`/seller/${
          props.selectedSeller === undefined
            ? props.user.sellers[0].id
            : props.selectedSeller.id
        }/help`}
      >
        <Nav.Link>Help</Nav.Link>
      </LinkContainer>
      <LinkContainer
        to={`/seller/${
          props.selectedSeller === undefined
            ? props.user.sellers[0].id
            : props.selectedSeller.id
        }/logs`}
      >
        <Nav.Link>Logs</Nav.Link>
      </LinkContainer>
      <LinkContainer
        to={`/seller/${
          props.selectedSeller === undefined
            ? props.user.sellers[0].id
            : props.selectedSeller.id
        }/variants`}
      >
        <Nav.Link>Variants</Nav.Link>
      </LinkContainer>
      <NavDropdown title="Tools" id="tools-dropdown">
        <LinkContainer
          to={`/seller/${
            props.selectedSeller === undefined
            ? props.user.sellers[0].id
            : props.selectedSeller.id
          }/tools/bulkinventory`}
        >
          <NavDropdown.Item>Bulk Inventory</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer
          to={`/seller/${
            props.selectedSeller === undefined
            ? props.user.sellers[0].id
            : props.selectedSeller.id
          }/tools/importvariants`}
        >
          <NavDropdown.Item>Create Variants</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer
          to={`/seller/${
            props.selectedSeller === undefined
            ? props.user.sellers[0].id
            : props.selectedSeller.id
          }/tools/updatevariants`}
        >
          <NavDropdown.Item>Update Variants</NavDropdown.Item>
        </LinkContainer>
      </NavDropdown>
    </React.Fragment>
  );
};

export default SellerMenu;
