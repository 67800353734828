import uuidv4 from 'uuid/v4';

export default class Metafield {
  constructor() {
    this.id = uuidv4();
  }

  id: string;
  marketplaceId: string;
  metafieldName: string;
  ownerResource: string;
  ownerId: string;
  key: string;
  value: string;
  valueType: string;
  namespace: string;
  description: string;
  helperText: string;
  isNonEditable: boolean;
  isNewField: boolean;
}
