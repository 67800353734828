import React from 'react';

import { eformedApiBaseUri } from '../../../Settings';
import rest from '../../../utils/Rest';
import errorHandler from '../../../utils/ErrorHandler';

import { EDIOrderProps, EDIOrderState } from './EDIOrder';

interface EDISendModalProps extends EDIOrderProps, EDIOrderState {
  status: string;
  setOrderState: (stateObject: any) => void;
}

const EDISendModal = (props: EDISendModalProps) => {
  const {
    getTransactions,
    showModal,
    setOrderState,
    orderId,
    status,
    apiKey,
  } = props;

  const sendOrder = async (id: number) => {
    await setOrderState({ isSending: true, showModal: false });
    try {
      await rest.get(
        `${eformedApiBaseUri}edi/osc/
          ${id}?key=${apiKey}`,
      );

      await setOrderState({ isSending: false });
      await props.addAlert('success', `EDI Order ${id} successfully sent.`);
      await getTransactions(0);
    } catch (error) {
      errorHandler(
        error,
        `There was an error while sending EDI Order ${id}.`,
        props.addAlert,
      );
    }

    await setOrderState({ isSending: false });
  };

  return (
    <div role="dialog" aria-modal="true" className="fade modal show" style={{display: showModal ? "block" : "none", backgroundColor: "#00000094"}} onClick={() => setOrderState({ showModal: false })}>
      <div role="document" className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title h4">Are you sure?</div>
            <button type="button" className="close" onClick={() => setOrderState({ showModal: false })}>
              <span aria-hidden="true" >×</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body">
            <p className="text-dark">You are getting the data for order {orderId} and sending a PO. It currently has a transaction with status {status}.</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => setOrderState({ showModal: false })}>Close</button>
            <button type="button" className="btn btn-success" onClick={() => sendOrder(orderId)}>Send</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EDISendModal;
