import React, { useState } from 'react';
import { Row, Col, Button, ListGroup } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import * as Settings from '../../../Settings';
import rest from '../../../utils/Rest';

const SellerVariantAssociation = props => {
  const [isUnassociating, setIsUnassociating] = useState(false);
  const [isUnassociated, setIsUnassociated] = useState(false);
  const { marketplaceId, connection } = props;

  const unassociate = async (
    sellerVariantId: string,
    marketplaceVariantId: string,
  ) => {
    await setIsUnassociating(true);

    const { apiKey, marketplaceId } = props;
    await rest.delete(
      `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/sellervariantmarketplacevariants/${sellerVariantId}?key=${apiKey}&marketplaceVariantId=${marketplaceVariantId}`,
    );

    await setIsUnassociated(true);
  };

  if (isUnassociated) {
    return null;
  } else {
    return (
      <LinkContainer
        to={`/marketplace/${marketplaceId}/products/${connection.marketplaceProductId}`}
      >
        <ListGroup.Item action>
          <Row>
            <Col className='h-100 align-self-center small'>
              {connection.marketplaceProductVendor}
            </Col>
            <Col className='h-100 align-self-center small'>
              {connection.marketplaceProductTitle}
            </Col>
            <Col className='h-100 align-self-center small'>
              {connection.marketplaceVariantTitle}
            </Col>

            <Col xs='auto' className='align-self-center text-right'>
              <Button
                size='sm'
                variant='danger'
                onClick={event => {
                  event.stopPropagation();
                  event.preventDefault();
                  unassociate(
                    connection.sellerVariantId,
                    connection.marketplaceVariantId,
                  );
                }}
              >
                {isUnassociating ? 'Unassociating...' : 'Unassociate'}
              </Button>
            </Col>
          </Row>
        </ListGroup.Item>
      </LinkContainer>
    );
  }
};

export default SellerVariantAssociation;
