import uuidv4 from 'uuid/v4';

import MarketplaceProductImage from './Image';
import MarketplaceProductTag from './Tag';
import MarketplaceVariant from './Variant';
import MarketplaceChannelMarketplaceProduct from './MarketplaceChannelMarketplaceProduct';
import Metafield from './Metafield';

export default class MarketplaceProduct {
  constructor(marketplaceId: string) {
    this.id = uuidv4();
    this.marketplaceId = marketplaceId;
    this.option1 = 'Option1';
    this.images = new Array<MarketplaceProductImage>();
    this.tags = new Array<MarketplaceProductTag>();
    this.variants = new Array<MarketplaceVariant>();
    this.marketplaceChannelMarketplaceProducts = new Array<
      MarketplaceChannelMarketplaceProduct
    >();
    this.metafields = new Array<Metafield>();
  }

  id: string;
  marketplaceId: string;
  handle: string;
  title: string;
  productType: string;
  description: string;
  vendor: string;
  archived: boolean;
  option1: string;
  option2: string;
  option3: string;
  createdAt: string;
  updatedAt: string;
  ignored: boolean;
  isManuallyOrderedVariants: boolean;
  needsReview: boolean;
  feature1: string;
  feature2: string;
  feature3: string;
  feature4: string;
  feature5: string;
  youtubeVideoIds: string;
  images: MarketplaceProductImage[];
  tags: MarketplaceProductTag[];
  variants: MarketplaceVariant[];
  marketplaceChannelMarketplaceProducts: MarketplaceChannelMarketplaceProduct[];
  metafields: Metafield[];
}
