import rest from '../../../../../utils/Rest';
import errorHandler from '../../../../../utils/ErrorHandler';
import * as Settings from '../../../../../Settings';
import setMarketplaceFromId from '../../../../../utils/SetMarketplaceFromId';

const getProduct = async (props, setParentState) => {
  const { marketplaceId, productId } = props.match.params;
  const { apiKey, selectedMarketplace, setMarketplace, user } = props;

  if (selectedMarketplace === undefined) {
    await setMarketplaceFromId(
      marketplaceId,
      setMarketplace,
      user,
      props.history,
    );
  }

  await setParentState({ isLoading: true });
  try {
    let data = await rest.get(
      `${
        Settings.eformedApiBaseUri
      }marketplace/${marketplaceId}/products/${productId}?key=${apiKey}`,
    );
    await setParentState({ isLoading: false, product: data });
  } catch (error) {
    if (error.status === 404) {
      await setParentState({ isLoading: false, isNotFound: true });
    } else {
      await errorHandler(
        error,
        'There was an error while retrieving the product. Please try again, if this is not the first time you have seen this error, please report the issue.',
        props.addAlert,
      );
      await setParentState({ isLoading: false });
    }
  }
};

export default getProduct;
