import React from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';

import PurchaseOrderItem from "../../../../classes/Logistics/PurchaseOrderItem";

interface PoItemProps {
  poItem: PurchaseOrderItem;
}

const PoItem = (props: PoItemProps) => {
  const { poItem } = props;
  console.log(poItem);
  
  return (
    <ListGroup.Item action>
      <Row>
        <Col md="3" className="self-align-center text-dark font-weight-bold px-0">
          {poItem.vendor}
        </Col>
        <Col md="auto" className="self-align-center text-dark font-weight-bold px-0">{poItem.description}</Col>
      </Row>
      <Row>
        <Col className="px-0">{poItem.upc}</Col>
        <Col className="px-0">{poItem.sku}</Col>
        <Col className="px-0">{poItem.mpn}</Col>
        <Col className="px-0">{poItem.itemNumber}</Col>
        <Col md="4" className="px-0">{poItem.sellerVariantId}</Col>
        <Col md="1" className="px-0">{poItem.casePack}</Col>
        <Col md="1" className="px-0">{poItem.cost}</Col>
      </Row>
    </ListGroup.Item>
  );
}

export default PoItem;