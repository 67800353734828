import React from "react";
import { Row, Col, Button, Spinner } from "react-bootstrap";

import { MarketplaceProductsChildProps } from "../Products";

import SearchFilters from "./SearchFilters";
import SearchResults from "./SearchResults";

const SearchResultsArea = (props: MarketplaceProductsChildProps) => {
  const { isLoadingMore, hasMore, hasLoaded, getProducts, setParentState } = props;
  return (
    <React.Fragment>
      <Row className="mt-5">
        <Col lg="auto">
          <SearchFilters {...props} />
        </Col>
        <Col>
          <SearchResults {...props} />
        </Col>
      </Row>
      <Row className="mt-3 text-center">
        <Col>
          {hasMore && (
            <Button
              variant="outline-info"
              onClick={() => {
                setParentState({ isInitialLoading: false });
                getProducts(hasLoaded)
              }}
            >
              {isLoadingMore ? (
                <React.Fragment>
                  <Spinner size="sm" animation="border" />
                  Loading...
                </React.Fragment>
              ) : (
                "Load More..."
              )}
            </Button>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SearchResultsArea;
