import uuidv4 from 'uuid/v4';

import SellerVariant from '../Seller/SellerVariant';
import MarketplaceChannelMarketplaceVariant from './MarketplaceChannelMarketplaceVariant';

export default class MarketplaceVariant {
  constructor() {
    this.id = uuidv4();
  }

  id: string;
  marketplaceProductId: string;
  title: string;
  option1: string;
  option2: string;
  option3: string;
  price: number | string;
  compareAtPrice: number | string;
  map: number | string;
  manualPrice: number | string;
  cost: number | string;
  sku: string;
  barcode: string;
  barcodeFormatted: string;
  manufacturerColor: string;
  inventoryPolicy: string;
  etaDate: string;
  requiresShipping: boolean;
  marketplaceProductImageId: string;
  position: number;
  weight: number;
  weightUnit: string;
  dimW: number | string;
  dimH: number | string;
  dimL: number | string;
  dimUnit: string;
  leadTime: number;
  archived: boolean;
  mpn: string;
  amazonAsin: string;
  handlingCharge: number | string;
  createdAt: string;
  updatedAt: string;
  sellerVariants: SellerVariant[];
  marketplaceChannelMarketplaceVariants: MarketplaceChannelMarketplaceVariant[];
}
