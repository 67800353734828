import Seller from "../Seller/SellerPermission";
import SellerVariant from "../Seller/SellerVariant";

export default class OrderLineItem {
  id: string;
  marketplaceOrderId: string;
  sellerId: string;
  sellerOrderId: string;
  channelOrderId: string;
  channelOrderLineItemId: string;
  channelProductId: string;
  channelVariantId: string;
  marketplaceProductId: string;
  marketplaceVariantId: string;
  sellerVariantId: string;
  inventoryQuantityAtTimeOfOrder: number;
  quantity: number;
  unitPrice: number;
  subtotal: number;
  productTitle: string;
  variantTitle: string;
  vendor: string;
  sku: string;
  mpn: string;
  barcode: string;
  sourceProductId: string;
  sourceId: string;
  cost: number;
  weight: number;
  weightUnit: string;
  dimW: number;
  dimH: number;
  dimL: number;
  discount: number;
  taxes: number;
  shippingCost: number;
  syncStatus:
    | "Created"
    | "Succeeded"
    | "Failed"
    | "Processed"
    | "Partially Processed"
    | "Cancelled";
  status:
    | "Created"
    | "Acknowledged"
    | "Shipped"
    | "Cancelled"
    | "Refund";
  syncText: string;
  trackingCompany: string;
  trackingNumber: string;
  trackingUrl: string;
  fulfillmentStatus: "Created" | "Fulfilled";
  outOfStock: boolean;
  refundedQuantity: number;
  refundedAt: string;
  sellerAssignedAt: string;
  orderSubmittedAt: string;
  cancelationNotifiedAt: string;
  shippedAt: string;
  seller: Seller;
  sellerVariant: SellerVariant;
}

export const OrderLineItemStatus = {
  Created: 'Created',
  Acknowledged: 'Acknowledged',
  Shipped: 'Shipped',
  Cancelled: 'Cancelled',
  Refund: 'Refund'
}