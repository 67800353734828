import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import * as Settings from "../../../../Settings";
import dateFormat from "../../../../utils/DateFormat";
const eformedApiBaseUri = Settings.eformedApiBaseUri;

const PurchaseOrderMenuItem = props => {
  const closePO = id => {
    fetch(
      eformedApiBaseUri +
        "po/purchaseorders.json?key=" +
        props.apiKey +
        "&shop=babywise-life.myshopify.com&id=" +
        id +
        "&closed=true",
      { method: "PUT" }
    )
      .then(response => {
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          return response.json();
        }
      })
      .then(data => {
        props.getPurchaseOrders();
      })
      .catch(error => {
        try {
          error.text().then(errorText => console.error(errorText));
        } catch {}
        props.addAlert(
          "danger",
          "There was an error while getting options, please try again. If this is not the first error, please report the issue."
        );
      });
  };

  const invoiceNumber = "#" + props.id;
  const lineItemCount = props.lineItems.length;
  const createDate = dateFormat(props.createdAt);

  return (
    <Row className="mt-2">
      <Col>
        <Card>
          <Card.Header>{invoiceNumber}</Card.Header>
          <Card.Body>
            <Row>
              <Col lg="2">
                <p className="text-info">{createDate}</p>
              </Col>
              <Col>
                <p>
                  <span className="font-weight-bold">From:</span>{" "}
                  {props.company}
                </p>
              </Col>
              <Col>
                <p>
                  <span className="font-weight-bold">To:</span> {props.vendor}
                </p>
              </Col>
              <Col lg="2">
                <p className="text-info">
                  <span className="font-weight-bold">{lineItemCount}</span> line
                  items
                </p>
              </Col>
              <Col lg="1">
                {props.closed === true ? (
                  <p className="text-danger">CLOSED</p>
                ) : (
                  <p className="text-success">OPEN</p>
                )}
              </Col>
            </Row>
            <LinkContainer to={"/logistics/po/view/" + props.id}>
              <Card.Link>View</Card.Link>
            </LinkContainer>
            <LinkContainer to={"/logistics/po/reconcile/" + props.id}>
              <Card.Link>Reconcile</Card.Link>
            </LinkContainer>
            {props.closed !== true && (
              <React.Fragment>
                <LinkContainer to={"/logistics/po/additem/" + props.id}>
                  <Card.Link>Add Item</Card.Link>
                </LinkContainer>
                <Card.Link
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    closePO(props.id);
                  }}
                >
                  Close
                </Card.Link>
              </React.Fragment>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default PurchaseOrderMenuItem;
