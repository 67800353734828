import uuidv4 from 'uuid/v4';

export default class MarketplaceProductTag {
  constructor(tag: string, marketplaceProductId: string) {
    this.id = uuidv4();
    this.marketplaceProductId = marketplaceProductId;
    this.tag = tag;
  }
  id: string;
  marketplaceProductId: string;
  tag: string;
}
