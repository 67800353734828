import React, { useState } from "react";
import { Dropdown, Spinner, Button } from "react-bootstrap";
import { MdMoreHoriz as ThreeDots } from "react-icons/md";

import { eformedApiBaseUri } from "../../../Settings";
import rest from "../../../utils/Rest";
import errorHandler from "../../../utils/ErrorHandler";

import { SellerVariantCardProps } from "./SellerVariantCard";

interface SearchResultMenuProps extends SellerVariantCardProps {
  ignored: boolean;
  setIgnore: (ignore: boolean) => void;
}

const SellerVariantDropdown = (props: SearchResultMenuProps) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { apiKey, sellerVariant, ignored, setIgnore } = props;
  const { marketplaceId } = props.match.params;
  return (
    <Dropdown id={`${sellerVariant.id}-dropdown`} drop="left">
      <Dropdown.Toggle<any>
        as={CustomToggle}
        id={`${sellerVariant.id}-dropdown-toggle`}
        isProcessing={isProcessing}
      />
      <Dropdown.Menu>
        <Dropdown.Item
          as="span"
          onClick={async e => {
            e.preventDefault();
            e.stopPropagation();
            await setIsProcessing(true);
            await ignoreSellerVariant(
              apiKey,
              marketplaceId,
              sellerVariant.sellerId,
              sellerVariant.id,
              ignored,
              props.addAlert
            );
            await setIgnore(!ignored);
            await setIsProcessing(false);
          }}
        >
          {ignored === true ? "Uni" : "I"}gnore This Seller Variant
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

interface CustomToggleProps {
  isProcessing: boolean;
  onClick: (event: any) => void;
  children: React.ReactNode;
}
const CustomToggle = React.forwardRef((props: CustomToggleProps, ref: any) => {
  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    props.onClick(e);
  };
  
  if (props.isProcessing) {
    return (
      <Button ref={ref} size="sm" variant="secondary">
        <Spinner size="sm" animation="border" onClick={handleClick}>
          {props.children}
        </Spinner>
      </Button>
    );
  } else {
    return (
      <Button ref={ref} size="sm" variant="secondary">
        <ThreeDots size={20} onClick={handleClick}>
          {props.children}
        </ThreeDots>
      </Button>
    );
  }
})

const ignoreSellerVariant = async (
  apiKey: string,
  marketplaceId: string,
  sellerId: string,
  sellerVariantId: string,
  ignored: boolean,
  addAlert
) => {
  try {
    rest.put(
      `${eformedApiBaseUri}marketplace/${marketplaceId}/sellers/${sellerId}/sellervariants/${sellerVariantId}/ignore?key=${apiKey}&ignored=${
        ignored === true ? "false" : "true"
      }`,
      null
    );
  } catch (error) {
    errorHandler(error, "There was an error ignoring the seller variant.", addAlert);
  }
};

export default SellerVariantDropdown;
