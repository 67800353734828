import React, { useState } from 'react';
import { Row, Col, Button, Card, Spinner, Form } from 'react-bootstrap';
import { TextField, Select } from '../../Shared/EFBootstrap';

import { eformedApiBaseUri } from '../../../Settings';
import rest from '../../../utils/Rest';
import errorHandler from '../../../utils/ErrorHandler';
import BaseProps from '../../../interfaces/BaseProps';

import SellerModel from '../../../classes/Seller/Seller';
import { SellersState } from './Sellers';
import MarketplaceSelector from './MarketplaceSelector';

export interface SellerCardProps extends BaseProps, SellersState {
  seller: SellerModel;
  setParentState: (any) => void;
  removeSeller: (seller: SellerModel) => void;
}

const SellerCard = (props: SellerCardProps) => {
  const { sellers, seller, setParentState, removeSeller } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  
  const handleSellerChange = async (
    fieldName,
    newValue
  ) => {
    const thisSeller = props.seller;
    const newSellers = sellers.map((seller, iindex) => {
      if (thisSeller.id !== seller.id) {
        return seller;
      } return {
        ...seller,
        [fieldName]: newValue,
      };
    });
    setParentState({ sellers: newSellers });
  };
  
  const handleVendorsChange = indexv => newValue => {
    const thisSeller = props.seller;
    const newSellers = sellers.map((seller, iindex) => {
      if (thisSeller.id !== seller.id) return seller;
      
      const vendors = seller.vendors.map((vendor, indexvv) => {
        if (indexvv !== indexv) return vendor;
        return vendor = newValue;
      });

      return { ...seller, vendors: vendors };
    });

    setParentState({ sellers: newSellers });
  }

  const removeVendor = (indexv) => {
    const thisSeller = props.seller;
    const newSellers = sellers.map((seller, iindex) => {
      if (thisSeller.id !== seller.id) return seller;
      
      seller.vendors.splice(indexv, 1);
      return seller;
    });

    setParentState({ sellers: newSellers });
  }

  const addVendorToSeller = () => {
    const thisSeller = props.seller;
    const newSellers = sellers.map((seller, iindex) => {      
      if (thisSeller.id !== seller.id){
        return seller;
      }else{
        if(seller.vendors === undefined){
          seller.vendors = [];
        }
        let tempVendors = seller.vendors;
        tempVendors.push('');        
        return { ...seller, vendors: tempVendors };
      }            
    });
    
    setParentState({ sellers: newSellers });
  }

  const getSellerTag =  (): string => {
    const { seller } = props;
    let sellerTag = seller.name;
    sellerTag = sellerTag.replace(/ /g, "_").replace(/[\W]+/g,"").replace(/_/g, "-").toLowerCase();

    return "seller:" + sellerTag;
  }

  const changeSeller = async (seller: SellerModel) => {
    setIsSaving(true);
    const { apiKey } = props;

    if (seller.mainSyncType === "FlatFile") {
      if (seller.ftpProductFilename === undefined || seller.ftpProductFilename === null || seller.ftpProductFilename === "") {
        seller.ftpProductFilename = "products.csv";
      }
      if (seller.ftpInventoryFilename === undefined || seller.ftpInventoryFilename === null || seller.ftpInventoryFilename === "") {
        seller.ftpInventoryFilename = "inventory.csv";
      }
    }

    if (seller.tag === undefined || seller.tag === null || seller.tag === "") {
      seller.tag = getSellerTag();
    }

    try {
      if (seller.createdAt === undefined) {
        const newSeller = await rest.post(
          `${eformedApiBaseUri}seller/sellers?key=${apiKey}`,
          seller,
        );

        const newSellers = sellers.map((sellerr, iindex) => {
          if (newSeller.id !== sellerr.id) {
            return sellerr;
          }
          
          return newSeller;
        });
        setParentState({ sellers: newSellers });
      } else {
        await rest.put(
          `${eformedApiBaseUri}seller/sellers/${seller.id}?key=${apiKey}`,
          seller,
        );
      }

      setIsEditing(false);
      setIsSaving(false);
    } catch (error) {
      errorHandler(
        error,
        'There was an error while changing the seller, please try again. If this is not the first error, please report the issue.',
        props.addAlert,
      );
      setIsSaving(false);
    }
  };

  return (
    <Row className='mt-2'>
      <Col>
        <Card>
          <Card.Header>
            <Row>
              <Col>
              {seller.createdAt !== undefined ?
                <h5>{seller.name}</h5> :
                <input
                  type="text"
                  placeholder="Seller Name"
                  value={seller.name}
                  style={{ background: "white", border: "none", fontSize: "medium", paddingLeft: "10px" }}
                  onChange={event => handleSellerChange('name', event.target.value)}
                />
              }
              </Col>
              {seller.createdAt === undefined && !isSaving &&
              <Col md='2' className='text-right'>
                <Button
                  size='sm'
                  disabled={isSaving}
                  variant='outline-danger'
                  onClick={() => removeSeller(seller)}
                >
                  Close
                </Button>
              </Col>
              }
                <Col md='2' className='text-right'>
                  <Button
                    size='sm'
                    variant={isEditing ? 'outline-success' : 'outline-warning'}
                    onClick={() => isEditing ? changeSeller(seller) : setIsEditing(!isEditing)}
                  >
                    {isEditing ? (isSaving ? (
                      <React.Fragment>
                        <Spinner size='sm' animation='border' />
                        Saving...
                      </React.Fragment>
                    ) : (
                      'Save this Seller'
                    )) : 'Edit'}
                  </Button>
                </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <Select
                  label='Active'
                  readOnly={!isEditing}
                  options={[
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' },
                  ]}
                  value={seller.active || 'false'}
                  onChange={newValue => handleSellerChange('active', newValue)}
                />
              </Col>
              <Col>
                <Select
                  label='3rd Party Eligible'
                  readOnly={!isEditing}
                  options={[
                    { label: 'Allow', value: 'true' },
                    { label: 'Disallow', value: 'false' },
                  ]}
                  value={seller.thirdPartySeller || 'false'}
                  onChange={newValue => handleSellerChange('thirdPartySeller', newValue === 'true' ? true : false)}
                />
              </Col>
              <Col>
                <TextField
                  label='Inventory Threshold'
                  readOnly={!isEditing}
                  type='number'
                  maxLength={3}
                  value={seller.thresholdInventory || 0}
                  onChange={newValue => handleSellerChange('thresholdInventory', newValue)}
                />
              </Col>
              <Col>
                <TextField
                  label='Inbound Shipping'
                  readOnly={!isEditing}
                  type='number'
                  maxLength={3}
                  value={seller.inboundShipping || 5}
                  onChange={newValue => handleSellerChange('inboundShipping', newValue)}
                />
              </Col>
              <Col>
                <TextField
                  label='Lead time'
                  readOnly={!isEditing}
                  type='number'
                  maxLength={3}
                  value={seller.leadTime || 0}
                  onChange={newValue => handleSellerChange('leadTime', newValue)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
              <TextField
                label='Order Email'
                readOnly={!isEditing}
                type='text'
                value={seller.orderEmailAddress}
                onChange={newValue => handleSellerChange('orderEmailAddress', newValue)}
              />
              </Col>
              <Col>
                <Select
                  label='Order include customer email'
                  readOnly={!isEditing}
                  options={[
                    { label: 'No', value: 'false' },
                    { label: 'Yes', value: 'true' },
                  ]}
                  value={seller.orderIncludeCustomerEmail || 'false'}
                  onChange={newValue => handleSellerChange('orderIncludeCustomerEmail', newValue)}
                />
              </Col>
              <Col>
                <Select
                  label='Order include shipping phone'
                  readOnly={!isEditing}
                  options={[
                    { label: 'No', value: 'false' },
                    { label: 'Yes', value: 'true' },
                  ]}
                  value={seller.orderIncludeShippingPhone || 'false'}
                  onChange={newValue => handleSellerChange('orderIncludeShippingPhone', newValue)}
                />
              </Col>
              <Col>
                <TextField
                  label='Website'
                  readOnly={!isEditing}
                  type='text'
                  value={seller.website}
                  onChange={newValue => handleSellerChange('website', newValue)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <TextField
                  label='Seller tag'
                  readOnly={!isEditing}
                  type='text'
                  value={seller.tag !== undefined && seller.tag !== null && seller.tag !== "" ? seller.tag : getSellerTag()}
                  onChange={newValue => handleSellerChange('tag', newValue)}
                />
              </Col>
              <Col md={3}>
                <Select
                  label='Main sync type'
                  readOnly={!isEditing}
                  options={[
                    { label: '', value: 'undefined' },
                    { label: 'Shopify', value: 'Shopify' },
                    { label: 'FlatFile', value: 'FlatFile' },
                  ]}
                  value={seller.mainSyncType === null ? "undefined" : seller.mainSyncType}
                  onChange={newValue => handleSellerChange('mainSyncType', newValue === '' ? undefined : newValue)}
                />
              </Col>
              <Col md={3}>
                <Select
                  label='Order sync type'
                  readOnly={!isEditing}
                  options={[
                    { label: '', value: 'undefined' },
                    { label: 'Shopify', value: 'Shopify' },
                    { label: 'Email', value: 'Email' },
                    { label: 'Ingram', value: 'Ingram' },
                    { label: 'FlatFile', value: 'FlatFile' },
                    { label: 'EDI', value: 'EDI' },
                  ]}
                  value={seller.orderSyncType === null ? "undefined" : seller.orderSyncType}
                  onChange={newValue => handleSellerChange('orderSyncType', newValue === '' ? undefined : newValue)}
                />
              </Col>
              {seller.orderSyncType === "FlatFile" && 
                <Col md={3}>
                  <TextField
                    label='FTP Mapping Filename'
                    readOnly={!isEditing}
                    type='text'
                    value={seller.ftpMappingFilename}
                    onChange={newValue => handleSellerChange('ftpMappingFilename', newValue)}
                  />
                </Col>
              }
            </Row>
            {seller.mainSyncType === "Shopify" &&
            <Row>
              <Col md={3}>
                <TextField
                  label='Shopify Host'
                  readOnly={!isEditing}
                  type='text'
                  value={seller.shopifyHost}
                  onChange={newValue => handleSellerChange('shopifyHost', newValue)}
                />
              </Col>
              <Col md={6}>
                <TextField
                  label='Admin API access token'
                  readOnly={!isEditing}
                  type='text'
                  value={seller.shopifyToken}
                  onChange={newValue => handleSellerChange('shopifyToken', newValue)}
                />
              </Col>
            </Row>
            }
            {seller.mainSyncType === "FlatFile" &&
            <React.Fragment>
              <Row>
                <Col md={3}>
                  <TextField
                    label='FTP Address'
                    readOnly={!isEditing}
                    type='text'
                    value={seller.ftpAddress}
                    onChange={newValue => handleSellerChange('ftpAddress', newValue)}
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    label='FTP Port'
                    readOnly={!isEditing}
                    type='text'
                    value={seller.ftpPort}
                    onChange={newValue => handleSellerChange('ftpPort', newValue)}
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    label='FTP Username'
                    readOnly={!isEditing}
                    type='text'
                    value={seller.ftpUsername}
                    onChange={newValue => handleSellerChange('ftpUsername', newValue)}
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    label='FTP Password'
                    readOnly={!isEditing}
                    type='text'
                    value={seller.ftpPassword}
                    onChange={newValue => handleSellerChange('ftpPassword', newValue)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <TextField
                    label='FTP Product Filename'
                    readOnly={!isEditing}
                    type='text'
                    value={seller.ftpProductFilename !== undefined && seller.ftpProductFilename !== null && seller.ftpProductFilename !== '' ?
                      seller.ftpProductFilename : 'products.csv'
                    }
                    onChange={newValue => handleSellerChange('ftpProductFilename', newValue)}
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    label='FTP Inventory Filename'
                    readOnly={!isEditing}
                    type='text'
                    value={seller.ftpInventoryFilename !== undefined && seller.ftpInventoryFilename !== null && seller.ftpInventoryFilename !== '' ?
                      seller.ftpInventoryFilename : 'inventory.csv'
                    }
                    onChange={newValue => handleSellerChange('ftpInventoryFilename', newValue)}
                  />
                </Col>
              </Row>
            </React.Fragment>
            }
            {seller.thirdPartySeller &&
              <React.Fragment>
                <Row><Col>3rd Party Vendors</Col></Row>
                <Row className="ml-1">
                  {seller.vendors === undefined ? addVendorToSeller() : seller.vendors.map((vendor, indexv) => {
                    return (
                      <div className="mr-1 input-with-clear" key={indexv} style={{width: '209px'}}>
                        <TextField
                          label=''
                          readOnly={!isEditing}
                          type='text'
                          value={vendor}
                          onChange={handleVendorsChange(indexv)}
                        />
                        {isEditing &&
                          <span className="input-close-icon" onClick={() => removeVendor(indexv)} title="Remove vendor">&times;</span>
                        }
                      </div>
                    );
                  })}
                  {(seller.vendors === undefined || seller.vendors.length !== 5) &&
                  <Col className='mt-4'>
                    <Button
                      size='sm'
                      disabled={!isEditing}
                      variant='outline-success'
                      onClick={() => addVendorToSeller()}
                    >
                      +
                    </Button>
                  </Col>
                  }
                </Row>
              </React.Fragment>
            }
            <Row>
              <Col>
                <Form>
                  <Form.Check
                    label='Po Sync Enable'
                    disabled={!isEditing}
                    checked={seller.isPoSyncEnable || false}
                    onChange={() => handleSellerChange('isPoSyncEnable', !seller.isPoSyncEnable)}
                  />
                </Form>
              </Col>
            </Row>
            <MarketplaceSelector
              {...props}
              isEditing={isEditing}
              handleSellerChange={handleSellerChange}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default SellerCard;
