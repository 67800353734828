import React, { useState } from 'react';
import { Row, Col, Collapse, Card, Badge } from 'react-bootstrap';

import MarketplaceProductProps from '../../../../../interfaces/MarketplaceProductProps';
import { MarketplaceProductRouteProps } from '../../Product';
import MarketplaceChannel from '../../../../../classes/Marketplace/Channel';
import { CHANNEL_TYPE } from '../../../../../utils/Constants';

import * as Settings from "../../../../../Settings";
import TaxCode from "../../../../../classes/Marketplace/TaxCode";
import rest from "../../../../../utils/Rest";

import ShopifyChannelArea from './ShopifyChannelArea';
import WalmartChannelArea from './WalmartChannelArea';

export interface ChannelsAreaProps
  extends MarketplaceProductProps,
    MarketplaceProductRouteProps {
  productId: string;
  handleChannelChange: (channel: MarketplaceChannel, publish: boolean) => void;
}

const ChannelsArea = (props: ChannelsAreaProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { product, channels } = props;

  const getTaxCode = async (gsId: string) => {
    const marketplaceId = props.match.params.marketplaceId;
    const { apiKey  } = props;

    try {
      let data: TaxCode = await rest.get(
        `${
          Settings.eformedApiBaseUri
        }marketplace/${marketplaceId}/taxcodes/${gsId}?key=${apiKey}`
      );

      return data;
    } catch (error) {
      
    }
  }

  return (
    <Card className='mt-3'>
      <Card.Header onClick={() => setIsOpen(!isOpen)}>
        <Row>
          <Col>
            <h4>
              Channels (
              {product.marketplaceChannelMarketplaceProducts.filter(
                connection => connection.active === true,
              ).length || 0}{' '}
              of {channels.length} Published)
            </h4>
          </Col>
          <Col lg='2' className='align-self-center text-right'>
            {product !== undefined &&
              product.marketplaceChannelMarketplaceProducts.filter(
                connection => connection.error === true,
              ).length > 0 && (
                <Badge pill variant='danger'>
                  Sync Error
                </Badge>
              )}
          </Col>
        </Row>
      </Card.Header>
      <Collapse in={isOpen}>
        <Card.Body>
          {product !== undefined &&
            channels !== undefined &&
            channels.map((channel, index) => (
              (channel.channelType.includes(CHANNEL_TYPE.WALMART) || channel.channelType === CHANNEL_TYPE.WALMART_MKP) ?
                <WalmartChannelArea key={index} channel={channel} getTaxCode={getTaxCode} {...props} /> :
                <ShopifyChannelArea key={index} channel={channel} {...props} />
            ))}
        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default ChannelsArea;
