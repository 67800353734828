import React, { useState } from 'react';
import { Row, Col, Button, Spinner, Collapse, Container } from 'react-bootstrap';

import { TextArea, Select } from '../../../Shared/EFBootstrap';

interface PoItemSearchFiltersProps {
  isLoading: boolean;
  searchFilter: string;
  searchValues: string;
  setSearchFilter: (searchFilter: string) => void;
  setSearchValues: (searchValues: string) => void;
  getPurchaseOrderItems: () => void;
}

const PoItemSearchFilters = (props: PoItemSearchFiltersProps) => {
  const { isLoading, searchFilter, searchValues, setSearchFilter, setSearchValues, getPurchaseOrderItems } = props;
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Row className="mt-3">
      <Col md="1">
        <Button size='sm' variant='info' onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? '<' : '>'}
        </Button>
      </Col>
      <Collapse in={isOpen}>
        <Col><Row>
          <Col>
            <TextArea 
              label="Enter values"
              rows={15}
              placeholder=""
              style={{whiteSpace: "pre"}}
              value={searchValues}
              onChange={newValue => setSearchValues(newValue)}
            />
          </Col>
          <Col>
            <Select
              label='Filter'
              options={[
                { label: 'UPC', value: 'UPC' },
                { label: 'MPN', value: 'MPN' },
                { label: 'SKU', value: 'SKU' }
              ]}
              value={searchFilter}
              onChange={newValue => setSearchFilter(newValue)}
            />
          </Col>
          <Col>
            <Button
              style={{marginTop: "28px", height: "45px"}}
              className='btn-block'
              size='sm'
              variant='success'
              onClick={() => {
                getPurchaseOrderItems();
              }}
            >
              {isLoading ?
                <>
                  <Spinner size='sm' animation='border' />
                  Searching...
                </> 
                :
                "Search"
              }
            </Button>
          </Col>
        </Row></Col>
      </Collapse>
    </Row>
  );
}

export default PoItemSearchFilters;