import React from 'react';
import { Row, Col, Button, Collapse, Card, Badge, Container } from 'react-bootstrap';

import MarketplaceChannel from '../../../../../classes/Marketplace/Channel';
import { ChannelsAreaProps } from './ChannelsArea';

import ChannelPublishButton from './ChannelPublishButton';
import ChannelErrorArea from './ChannelErrorArea';
import ChannelUpdateModal from './ChannelUpdateModal';
import ChannelVariantArea from './ChannelVariantArea';

import productFunctions from '../Functions';

export interface ShopifyChannelAreaProps extends ChannelsAreaProps {
  channel: MarketplaceChannel;
}

export interface ShopifyChannelAreaState {
  isOpen: boolean;
  isUpdating: boolean;
  showError: boolean;
  showModal: boolean;
  updateMessages: string[];
}

export interface ShopifyChannelAreaChildProps extends ShopifyChannelAreaState {
  setChannelState: (any) => void;
}

export default class ShopifyChannelArea extends React.Component<
  ShopifyChannelAreaProps,
  ShopifyChannelAreaState
> {
  state = {
    isOpen: false,
    isUpdating: false,
    showError: false,
    showModal: false,
    updateMessages: [],
  };

  updateChannel = async (type: string, variantId: string = undefined) => {
    const { product, channel, marketplaceId, apiKey } = this.props;

    await productFunctions.updateChannel(
      product,
      channel,
      marketplaceId,
      apiKey,
      type,
      variantId,
      this.setChannelState
    );
  };

  setChannelState = async stateObject => {
    await this.setState(stateObject);
  };

  render() {
    const { productId, marketplaceId, product, channel, isEditing, apiKey, handleVariantChange, addAlert } = this.props;
    const { isOpen } = this.state;
    const connections = product.marketplaceChannelMarketplaceProducts.filter(
      connection => connection.marketplaceChannelId === channel.id,
    );
    const published =
      connections !== undefined &&
      connections !== null &&
      connections.length === 1 &&
      connections[0].active === true;
    const syncError =
      connections !== undefined &&
      connections !== null &&
      connections.length === 1 &&
      connections[0].error === true;
    const connection =
      connections !== undefined &&
      connections !== null &&
      connections.length === 1 &&
      connections[0];

    return (
      <Card className='mt-1'>
        <Card.Header onClick={() => this.setState({ isOpen: !isOpen })}>
          <Row>
            <Col>
              <h6>{channel.name}</h6>
            </Col>
            <Col lg='3' className='align-self-center text-right'>
              {published === false ? (
                <Badge pill variant='danger'>
                  Unpublished
                </Badge>
              ) : (
                <Badge pill variant='success'>
                  Published
                </Badge>
              )}
              {syncError === true && (
                <Badge pill variant='danger'>
                  Sync Error
                </Badge>
              )}
            </Col>
          </Row>
        </Card.Header>
        <Collapse in={isOpen}>
          <Card.Body>
            <Row>
              {published === true && 
              <React.Fragment>
              <Col>
                <Button
                  variant='outline-info'
                  onClick={() => this.updateChannel('product')}
                >
                  Push Product and Variant Info
                </Button>
                {connection !== undefined &&
                  connection !== null &&
                  connection.externalId !== undefined &&
                  connection.externalId !== null &&
                  connection.externalId !== '' &&
                  channel.channelType === 'Shopify' && (
                    // @ts-ignore
                    <Button
                      variant='outline-success'
                      onClick={()=> window.open(`https://${channel.host}/admin/products/${connection.externalId}`, "_blank")}
                    >
                      Shopify Admin
                    </Button>
                  )}
              </Col>
              </React.Fragment>}
              {published === false && <Col></Col>}

              <Col lg='3' className='align-text-top align-self-top text-right'>
                <ChannelPublishButton {...this.props} />
              </Col>
            </Row>
            <Container className="p-0 mt-4">
            {product.variants.map((variant, variantIndex) => (
                variant.marketplaceChannelMarketplaceVariants !== undefined &&
                variant.marketplaceChannelMarketplaceVariants.map((mcmv, indexx) => (
                  mcmv.marketplaceChannelId === channel.id && 
                    <ChannelVariantArea
                      apiKey={apiKey}
                      productId={productId}
                      marketplaceId={marketplaceId}
                      product={product}
                      variant={variant}
                      channel={channel}
                      connection={mcmv}
                      productConnection={connection}
                      key={indexx}
                      isEditing={isEditing}
                      thresholdInventory={channel.thresholdInventory}
                      index={indexx}
                      variantIndex={variantIndex}
                      handleVariantChange={handleVariantChange}
                      updateChannel={this.updateChannel}
                      addAlert={addAlert}
                    />
                ))
              ))}
            </Container>
            {syncError === true && (
              <ChannelErrorArea
                connections={connections}
                setChannelState={this.setChannelState}
                {...this.state}
              />
            )}
          </Card.Body>
        </Collapse>
        <ChannelUpdateModal
          setChannelState={this.setChannelState}
          {...this.state}
        />
      </Card>
    );
  }
}
