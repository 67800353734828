import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

interface LightboxExampleProps {
  isOpen: boolean;
  image: string;
  setIsLightBoxOpen: (bool: boolean) => void;
}

const LightboxExample = (props: LightboxExampleProps) => {
    const { isOpen, image, setIsLightBoxOpen } = props;
 
    return (
      <div> 
        {isOpen && (
          <Lightbox
          closeLabel={"hello"}
            mainSrc={image}
            onCloseRequest={() => setIsLightBoxOpen(false)}
          />
        )}
      </div>
    );
}

export default LightboxExample;
