import './bootstrap.css';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import App from './App';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';

import Splash from './Splash';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <Switch>
      <Route exact path='/' component={Splash} />
      <Route component={App} />
    </Switch>
  </BrowserRouter>,
  rootElement,
);

unregisterServiceWorker();
