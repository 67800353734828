import React from 'react';
import { Container, Row, Col, Button, Card, Collapse } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

import MarketplaceProductProps from '../../../../../interfaces/MarketplaceProductProps';
import ImageDeleteModal from './ImageDeleteModal';
import ImageLightBox from './ImageLightBox';
import ImagesDraggable from './ImagesDraggable';

export interface MarketplaceProductImagesProps extends MarketplaceProductProps {
  apiKey: string;
  productId: string;
  addAlert: (
    variant:
      | 'primary'
      | 'secondary'
      | 'success'
      | 'danger'
      | 'warning'
      | 'info'
      | 'dark'
      | 'light',
    text: string,
    title?: string,
  ) => void;
}

export default class MarketplaceProductImages extends React.Component<
  MarketplaceProductImagesProps
  > {
  state = {
    isOpen: false,
    isDeleting: false,
    deleteImageId: undefined,
    showDeleteModal: false,
    lightBoxImgSrc: '',
    isLightBoxOpen: false
  };

  deleteImageModal = async (imageId: string, show: boolean) => {
    await this.setState({ deleteImageId: imageId, showDeleteModal: show });
  };

  setIsDeleting = (bool: boolean) => {
    this.setState({ isDeleting: bool });
  };

  setIsLightBoxOpen = (bool: boolean) => {
    this.setState({ isLightBoxOpen: bool })
  }

  setImagePrimary = (imageId: string) => {
    const { product } = this.props;
    const tempProduct = product;
    const tempImages = tempProduct.images;

    const matchingImage = tempImages.find(image => image.id === imageId);
    if (matchingImage !== undefined) {
      const tempImages = tempProduct.images.map(image => {
        if (image.id === imageId) {
          image.position = 1;
          return image;
        } else if (image.position === 1) {
          image.position = matchingImage.position;
          return image;
        } else {
          return image;
        }
      });

      tempImages.sort((a, b) => {
        if (a.position > b.position) {
          return 1;
        } else if (a.position < b.position) {
          return -1;
        }
        return 0;
      });

      tempProduct.images = tempImages;
      this.props.setParentState({ product: tempProduct });
    }
  };

  setParentState = stateObject => {
    this.setState(stateObject);
  };

  render() {
    const { product, marketplaceId, isEditing, productId, setParentState } = this.props;
    const { isOpen, isDeleting } = this.state;
    return (
      <Card className='mt-3'>
        <Card.Header onClick={() => this.setState({ isOpen: !isOpen })}>
          <Row>
            <Col className='align-self-center'>
              <h4>Images ({productId !== "new" ? product.images.length : 0})</h4>
            </Col>
            <Col lg='3' className='text-right'>
              <LinkContainer
                to={`/marketplace/${marketplaceId}/products/${product.id}/addimages`}
              >
                <Button size='sm' variant='outline-info'>
                  Add New Image
                </Button>
              </LinkContainer>
            </Col>
          </Row>
        </Card.Header>
        <Collapse in={isOpen}>
          <Card.Body>
            <Row>
              <Col>
                {/* if productId = new, then product is not saved yet */}
                {productId !== "new" && product.images !== undefined && product.images.length > 0 ? (
                  <DndProvider backend={Backend}>
                    <Container>
                      <ImagesDraggable 
                        marketplaceId={marketplaceId}
                        product={product}
                        isEditing={isEditing}
                        isDeleting={isDeleting}
                        setIsLightBoxOpen={this.setIsLightBoxOpen}
                        setImagePrimary={this.setImagePrimary}
                        deleteImageModal={this.deleteImageModal}
                        setImagesParentState={this.setParentState}
                        setParentState={setParentState}
                      />
                    </Container>
                  </DndProvider>
                ) : (
                  'No images for this product'
                )}
              </Col>
            </Row>
          </Card.Body>
        </Collapse>
        <ImageDeleteModal
          {...this.props}
          imageId={this.state.deleteImageId}
          show={this.state.showDeleteModal}
          deleteImageModal={this.deleteImageModal}
          setIsDeleting={this.setIsDeleting}
        />
        <ImageLightBox
          isOpen={this.state.isLightBoxOpen}
          image={this.state.lightBoxImgSrc}
          setIsLightBoxOpen={this.setIsLightBoxOpen}
        />
      </Card>
    );
  }
}
