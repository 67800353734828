import React, { Component } from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router';
import LoadingPage from './components/Shared/LoadingPage';
import { animateScroll as scroll } from 'react-scroll';
import { Cookies } from 'react-cookie';

import Layout from './components/Shared/Layout';
import SignInScreen from './components/Auth/SignInScreen';

import ViewPurchaseOrder from './components/Logistics/PurchaseOrders/ViewPurchaseOrder';
import SchematicDisplay from './components/Schematic/Display';
import Routing from './components/Routing/Routing';

import AlertObject from './classes/Core/AlertObject';
import User from './classes/Core/User';
import BaseProps from './interfaces/BaseProps';
import LogisticsCompanyPermission from './classes/Logistics/LogisticsCompanyPermission';
import MarketplacePermission from './classes/Marketplace/MarketplacePermission';
import SellerPermission from './classes/Seller/Seller';

import { firebaseApp, unregisterAuthObserver } from './utils/Firebase';
import * as CONSTANTS from './utils/Constants';

interface AppProps extends BaseProps, RouteComponentProps {}

interface AppState {
  loggingIn: boolean;
  authenticated: boolean;
  apiKey: string;
  username: string;
  alerts: Array<AlertObject>;
  user: User;
  app: string;
  selectedLogisticsCompany: LogisticsCompanyPermission;
  selectedMarketplace: MarketplacePermission;
  selectedSeller: SellerPermission;
  showNotificationPanel: boolean;
  isScrollTop: boolean;
}

export default class App extends Component<AppProps, AppState> {
  state = {
    loggingIn: false,
    authenticated: false,
    apiKey: '',
    username: undefined,
    alerts: new Array<AlertObject>(),
    user: new User(),
    app: undefined,
    selectedLogisticsCompany: undefined,
    selectedMarketplace: undefined,
    selectedSeller: undefined,
    showNotificationPanel: false,
    isScrollTop: false
  };

  displayName = App.name;

  componentDidMount() {
    this.setState({ authenticated: true });
    this.checkUserSession();
    unregisterAuthObserver(this.state, this.setAppState, this.addAlert);
    this.setAppByPath();

    document.addEventListener('scroll', () => {
      const isTop = window.scrollY > 40;
      if (isTop !== this.state.isScrollTop) {
          this.setState({ isScrollTop: isTop });
      }
    });
  }

  componentWillUnmount() {
    unregisterAuthObserver(this.state, this.setAppState, this.addAlert);
  }

  setAppByPath = () => {
    const { pathname } = this.props.location;

    if (pathname.startsWith('/admin')) {
      this.changeApp('Admin');
    } else if (pathname.startsWith('/familywise')) {
      this.changeApp('Familywise');
    } else if (pathname.startsWith('/logistics')) {
      this.changeApp('Logistics');
    } else if (pathname.startsWith('/marketplace')) {
      this.changeApp('Marketplace');
    } else if (pathname.startsWith('/seller')) {
      this.changeApp('Seller');
    } else {
    }
  };

  authenticate = (authenticated, apiKey) => {
    this.setState({
      authenticated: authenticated,
      apiKey: apiKey,
    });
  };

  checkUserSession = () => {
    const cookies = new Cookies();
    if (cookies.get('user-session') === undefined) {
      firebaseApp.auth().signOut();
    }
  };

  setLogisticsCompany = (logisticsCompany: LogisticsCompanyPermission) => {
    this.setState({
      selectedLogisticsCompany: logisticsCompany,
    });
  };

  setMarketplace = (marketplace: MarketplacePermission) => {
    this.setState({
      selectedMarketplace: marketplace,
    });
  };

  setSeller = (seller: SellerPermission) => {
    this.setState({
      selectedSeller: seller,
    });
  };

  addAlert = (
    variant:
      | 'primary'
      | 'secondary'
      | 'success'
      | 'danger'
      | 'warning'
      | 'info'
      | 'dark'
      | 'light',
    text: string,
    title: string = undefined,
  ) => {
    let alert = new AlertObject(variant, text, title);
    let tempAlerts = this.state.alerts;
    tempAlerts.push(alert);
    this.setState({ alerts: tempAlerts });

    // Hide the alert after seconds
    const _this = this;
    setTimeout(() => {
      let tempAlertsAfterTimeout = _this.state.alerts;
      tempAlertsAfterTimeout
        .filter(alertT => (new Date().getTime() - alertT.timestamp) > CONSTANTS.ALERT_HIDE_TIME)
        .map((alertT,i) => {
          return alertT.show = false;
        });
      _this.setState({ alerts: tempAlertsAfterTimeout });
    }, CONSTANTS.ALERT_HIDE_TIME);
  };

  changeApp = (app: string) => {
    this.setState({ app: app });
  };

  removeAlert = (index: number) => {
    this.setState({
      alerts: this.state.alerts.filter((i, iindex) => index !== iindex),
    });
  };

  removeAllAlerts = () => {
    this.setState({ alerts: new Array<AlertObject>() });
  }

  setAppState = stateObject => {
    this.setState(stateObject);
  };

  showHideNotificationPanel = (value: boolean) => {
    this.setState({ showNotificationPanel: value });
  }

  render() {
    if (this.state.authenticated !== true) {
      return (
        <Layout
          user={this.state.user}
          username={this.state.username}
          alerts={this.state.alerts}
          app={this.state.app}
          showNotificationPanel={this.state.showNotificationPanel}
          isScrollTop={this.state.isScrollTop}
          showHideNotificationPanel={this.showHideNotificationPanel}
          removeAllAlerts={this.removeAllAlerts}
          changeApp={this.changeApp}
          selectedLogisticsCompany={this.state.selectedLogisticsCompany}
          setLogisticsCompany={this.setLogisticsCompany}
          selectedMarketplace={this.state.selectedMarketplace}
          setMarketplace={this.setMarketplace}
          removeAlert={this.removeAlert}
          addAlert={this.addAlert}
        >
          <SignInScreen />
        </Layout>
      );
    } else if (this.state.loggingIn === true || this.state.apiKey.length < 1) {
      return (
        <Layout
          user={this.state.user}
          username={this.state.username}
          alerts={this.state.alerts}
          app={this.state.app}
          showNotificationPanel={this.state.showNotificationPanel}
          isScrollTop={this.state.isScrollTop}
          showHideNotificationPanel={this.showHideNotificationPanel}
          removeAllAlerts={this.removeAllAlerts}
          changeApp={this.changeApp}
          selectedLogisticsCompany={this.state.selectedLogisticsCompany}
          setLogisticsCompany={this.setLogisticsCompany}
          selectedMarketplace={this.state.selectedMarketplace}
          setMarketplace={this.setMarketplace}
          selectedSeller={this.state.selectedSeller}
          setSeller={this.setSeller}
          removeAlert={this.removeAlert}
          addAlert={this.addAlert}
        >
          <LoadingPage type='Grid' message='Logging you in...' />
        </Layout>
      );
    } else {
      return (
        <React.Fragment>
          <Switch>
            <Route
              path='/logistics/po/view/:poNumber'
              render={routerProps => (
                <ViewPurchaseOrder
                  {...this.props}
                  {...this.state}
                  {...routerProps}
                  addAlert={this.addAlert}
                />
              )}
            />
            <Route
              exact
              path='/schematic'
              render={routerProps => <SchematicDisplay />}
            />
            <Layout
              user={this.state.user}
              username={this.state.username}
              alerts={this.state.alerts}
              app={this.state.app}
              showNotificationPanel={this.state.showNotificationPanel}
              isScrollTop={this.state.isScrollTop}
              showHideNotificationPanel={this.showHideNotificationPanel}
              removeAllAlerts={this.removeAllAlerts}
              changeApp={this.changeApp}
              selectedLogisticsCompany={this.state.selectedLogisticsCompany}
              setLogisticsCompany={this.setLogisticsCompany}
              selectedMarketplace={this.state.selectedMarketplace}
              setMarketplace={this.setMarketplace}
              selectedSeller={this.state.selectedSeller}
              setSeller={this.setSeller}
              removeAlert={this.removeAlert}
              addAlert={this.addAlert}
            >
              <Routing
                {...this.props}
                {...this.state}
                addAlert={this.addAlert}
                removeAlert={this.removeAlert}
                changeApp={this.changeApp}
                setLogisticsCompany={this.setLogisticsCompany}
                setMarketplace={this.setMarketplace}
                setSeller={this.setSeller}
              />
            </Layout>
          </Switch>
        </React.Fragment>
      );
    }
  }
}
