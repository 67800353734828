const errorHandler = (
  error: any,
  alert: string,
  addAlert: (
    variant:
      | 'primary'
      | 'secondary'
      | 'success'
      | 'danger'
      | 'warning'
      | 'info'
      | 'dark'
      | 'light',
    text: string,
    title?: string,
  ) => void,
) => {
  console.error(error);
  try {
    error.text().then(errorText => console.error(errorText));
  } catch {}
  addAlert('danger', alert);
};

export default errorHandler;
