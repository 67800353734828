import React from 'react';
import { Button, Spinner, Row, Col } from 'react-bootstrap';

import LoadingPage from '../../Shared/LoadingPage';
import * as Settings from '../../../Settings';
import RouteVariableProps from '../../../interfaces/RouteVariableProps';
import rest from '../../../utils/Rest';
import errorHandler from '../../../utils/ErrorHandler';
import setMarketplaceFromId from '../../../utils/SetMarketplaceFromId';
import { addUrlParam, removeAllUrlParams } from '../../../utils/SetUrlParameters';

import Order from '../../../classes/Marketplace/Order';
import MarketplaceChannel from '../../../classes/Marketplace/Channel';

import MarketplaceOrdersHeader from './Orders/OrdersHeader';
import OrdersFilters from './Orders/OrdersFilters';
import MarketplaceOrderCard from './Orders/OrderCard';
import productFunctions from "../Products/Product/Functions";

interface MatchParams {
  marketplaceId: string;
}

export interface MarketplaceOrdersState {
  isDownloading: boolean;
  isInitialLoading: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  hasLoaded: number;
  hasMore: boolean;
  orders: Order[];
  channels: MarketplaceChannel[];
  channelFilter: string;
  channelOrderIdFilter: string;
  channelOrderNumberFilter: string;
  customerNameFileter: string;
  customerEmailFilter: string;
  startDateFilter: string;
  endDateFilter: string;
  riskyFilter: boolean;
  orderStatusFilter: string;
  paymentStatusFilter: string;
  fulfillmentStatusFilter: string;
  syncStatusFilter: string;
  outOfStockFilter: boolean;
}

export default class MarketplaceOrders extends React.Component<
  RouteVariableProps<MatchParams>,
  MarketplaceOrdersState
> {
  state = {
    isDownloading: false,
    isInitialLoading: true,
    isLoading: false,
    isLoadingMore: false,
    hasLoaded: 0,
    hasMore: false,
    orders: [],
    channels: new Array<MarketplaceChannel>(),
    channelFilter: undefined,
    channelOrderIdFilter: undefined,
    channelOrderNumberFilter: undefined,
    customerNameFileter: undefined,
    customerEmailFilter: undefined,
    startDateFilter: undefined,
    endDateFilter: undefined,
    riskyFilter: undefined,
    orderStatusFilter: undefined,
    paymentStatusFilter: undefined,
    fulfillmentStatusFilter: undefined,
    syncStatusFilter: '',
    outOfStockFilter: undefined,
  };

  componentDidMount() {
    if (this.props.match.params.marketplaceId !== undefined) {
      this.setMarketplaceIfUndefined();
      this.getMarketplaceChannels();
      this.getOrders(0);
    }
  }

  setMarketplaceIfUndefined = async () => {
    let marketplaceId = this.props.match.params.marketplaceId;
    const { selectedMarketplace, setMarketplace, user } = this.props;
    this.setState({ isInitialLoading: true });

    if (selectedMarketplace === undefined) {
      await setMarketplaceFromId(
        marketplaceId,
        setMarketplace,
        user,
        this.props.history,
      );
    }
  };

  getMarketplaceChannels = async () => {
    await productFunctions.getMarketplaceChannels(
      this.props,
      this.setParentState,
    );
  };

  getOrders = async (skip: number) => {
    let marketplaceId = this.props.match.params.marketplaceId;
    const { apiKey } = this.props;
    const {
      orders,
      channelFilter,
      channelOrderIdFilter,
      channelOrderNumberFilter,
      customerNameFileter,
      customerEmailFilter,
      startDateFilter,
      endDateFilter,
      riskyFilter,
      orderStatusFilter,
      paymentStatusFilter,
      fulfillmentStatusFilter,
      syncStatusFilter,
      outOfStockFilter,
    } = this.state;
    const ordersPerLoad = 50;

    if (skip === 0) {
      await this.setState({ isLoading: true });
    } else {
      await this.setState({ isLoadingMore: true });
    }

    try {
      let uri = `${
        Settings.eformedApiBaseUri
      }marketplace/${marketplaceId}/orders?key=${apiKey}&skip=${skip}`;


      if(!this.state.isInitialLoading) {
        removeAllUrlParams(this.props);

        if (channelFilter !== undefined && channelFilter !== '' && channelFilter !== 'Any') {
          addUrlParam(this.props, 'channel', channelFilter);
        }
        if (channelOrderIdFilter !== undefined && channelOrderIdFilter !== '') {
          addUrlParam(this.props, 'channelOrderId', channelOrderIdFilter);
        }
        if (channelOrderNumberFilter !== undefined && channelOrderNumberFilter !== '') {
          addUrlParam(this.props, 'channelOrderNumber', channelOrderNumberFilter);
        }
        if (customerNameFileter !== undefined && customerNameFileter !== '') {
          addUrlParam(this.props, 'customerName', customerNameFileter);
        }
        if (customerEmailFilter !== undefined && customerEmailFilter !== '') {
          addUrlParam(this.props, 'customerEmail', customerEmailFilter);
        }
        if (startDateFilter !== undefined && startDateFilter !== '') {
          addUrlParam(this.props, 'startDate', startDateFilter);
        }
        if (endDateFilter !== undefined && endDateFilter !== '') {
          addUrlParam(this.props, 'endDate', endDateFilter);
        }
        if (riskyFilter !== undefined && riskyFilter !== '' && riskyFilter !== 'Any') {
          addUrlParam(this.props, 'risky', riskyFilter);
        }
        if (orderStatusFilter !== undefined && orderStatusFilter !== '' && orderStatusFilter !== 'Any') {
          addUrlParam(this.props, 'orderStatus', orderStatusFilter);
        }
        if (paymentStatusFilter !== undefined && paymentStatusFilter !== '' && paymentStatusFilter !== 'Any') {
          addUrlParam(this.props, 'paymentStatus', paymentStatusFilter);
        }
        if (fulfillmentStatusFilter !== undefined && fulfillmentStatusFilter !== '' && fulfillmentStatusFilter !== 'Any') {
          addUrlParam(this.props, 'fulfillmentStatus', fulfillmentStatusFilter);
        }
        if (syncStatusFilter !== '' && syncStatusFilter !== '' && syncStatusFilter !== 'Any') {
          addUrlParam(this.props, 'syncStatus', syncStatusFilter);
        }
        if (outOfStockFilter !== undefined && outOfStockFilter !== '' && outOfStockFilter !== 'Any') {
          addUrlParam(this.props, 'outOfStock', outOfStockFilter);
        }
      } else {
        // let preSearchParams = new URLSearchParams(this.props.location.search);
        // if (preSearchParams.get('orderBy') === null) {
        //   addUrlParam(this.props, 'orderBy', orderByFilter.toString());
        // }
      }

      let searchParams = new URLSearchParams(this.props.location.search);

      searchParams.forEach((value: string, key: string) => {        
        let tempState = {...this.state, [key+'Filter']:value };
        this.setState(tempState);

        uri = uri + `&${key}=${value}`;
      });

      let tempOrders = orders;
      if (skip === 0) {
        tempOrders = new Array<Order>();
      }

      let data: Order[] = await rest.get(uri);
      tempOrders.push(...data);

      await this.setState({
        isLoading: false,
        isLoadingMore: false,
        hasLoaded: tempOrders.length,
        hasMore: data.length >= ordersPerLoad,
        orders: tempOrders,
      });
    } catch (error) {
      errorHandler(
        error,
        'There was an error while retrieving orders. Please try again, if this is not the first time you have seen this error, please report the issue.',
        this.props.addAlert,
      );
      this.setState({ isLoading: false, isLoadingMore: false });
    }
  };

  exportCsv = async (limitCount: number, downloadAll: boolean = false) => {
    const { marketplaceId } = this.props.match.params;
    const { apiKey } = this.props;
    const {
      channelFilter,
      channelOrderIdFilter,
      channelOrderNumberFilter,
      customerNameFileter,
      customerEmailFilter,
      startDateFilter,
      endDateFilter,
      riskyFilter,
      orderStatusFilter,
      paymentStatusFilter,
      fulfillmentStatusFilter,
      syncStatusFilter,
      outOfStockFilter,
    } = this.state;

    try {
      let uri = `${
        Settings.eformedApiBaseUri
      }marketplace/${marketplaceId}/orders/download?key=${apiKey}&limitCount=${limitCount}&isallrecords=${downloadAll}`;

      if (channelFilter !== undefined && channelFilter !== '' && channelFilter !== 'Any') {
        uri = uri + `&channel=${encodeURIComponent(channelFilter)}`;
      }
      if (channelOrderIdFilter !== undefined && channelOrderIdFilter !== '') {
        uri = uri + `&channelOrderId=${encodeURIComponent(channelOrderIdFilter)}`;
      }
      if (channelOrderNumberFilter!== undefined && channelOrderNumberFilter !== '') {
        uri = uri + `&channelOrderNumber=${encodeURIComponent(channelOrderNumberFilter)}`;
      }
      if (customerNameFileter !== undefined && customerNameFileter !== '') {
        uri = uri + `&customerName=${encodeURIComponent(customerNameFileter)}`;
      }
      if (customerEmailFilter !== undefined && customerEmailFilter !== '') {
        uri = uri + `&customerEmail=${encodeURIComponent(customerEmailFilter)}`;
      }
      if (startDateFilter !== undefined && startDateFilter !== '') {
        uri = uri + `&startDate=${encodeURIComponent(startDateFilter)}`;
      }
      if (endDateFilter !== undefined && endDateFilter !== '') {
        uri = uri + `&endDate=${encodeURIComponent(endDateFilter)}`;
      }
      if (riskyFilter !== undefined && riskyFilter !== '' && riskyFilter !== 'Any') {
        uri = uri + `&risky=${encodeURIComponent(riskyFilter)}`;
      }
      if (orderStatusFilter !== undefined && orderStatusFilter !== '' && orderStatusFilter !== 'Any') {
        uri = uri + `&orderStatus=${encodeURIComponent(orderStatusFilter)}`;
      }
      if (paymentStatusFilter !== undefined && paymentStatusFilter !== '' && paymentStatusFilter !== 'Any') {
        uri = uri + `&paymentStatus=${encodeURIComponent(paymentStatusFilter)}`;
      }
      if (fulfillmentStatusFilter !== undefined && fulfillmentStatusFilter !== '' && fulfillmentStatusFilter !== 'Any') {
        uri = uri + `&fulfillmentStatus=${encodeURIComponent(fulfillmentStatusFilter)}`;
      }
      if (syncStatusFilter !== undefined && syncStatusFilter !== '' && syncStatusFilter !== 'Any') {
        uri = uri + `&syncStatus=${encodeURIComponent(syncStatusFilter)}`;
      }
      if (outOfStockFilter !== undefined && outOfStockFilter !== '' && outOfStockFilter !== 'Any') {
        uri = uri + `&outOfStock=${encodeURIComponent(outOfStockFilter)}`;
      }
      
      if (downloadAll == true) {
        let response = await fetch(uri);
        if (response.status >= 400) {
          throw response;
        }

        this.props.addAlert("success", "When download file is ready, you will be informed through teams, Downloads Channel");
      }
      else {
        await fetch(uri).then(response => {
          response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'order_list.csv';
            a.click();
          });
          // window.location.href = response.url;
        });
      }
      
      this.setState({ isDownloading: false });
    } catch (error) {
      errorHandler(
        error,
        'There was an error while downloading. Please try again, if this is not the first time you have seen this error, please report it.',
        this.props.addAlert,
      );
      await this.setState({ isDownloading: false });
    }
  }

  setParentState = stateObject => {
    this.setState(stateObject);
  };

  render() {
    const marketplaceId = this.props.match.params.marketplaceId;
    const { isLoading, isLoadingMore, hasLoaded, orders } = this.state;
    if (isLoading) {
      return (
        <React.Fragment>
          <MarketplaceOrdersHeader />
          <Row>
            <Col lg='auto'>
              <OrdersFilters
                {...this.state}
                getOrders={this.getOrders}
                setParentState={this.setParentState}
                exportCsv={this.exportCsv}
              />
            </Col>
            <Col>
              <LoadingPage />
            </Col>
          </Row>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <MarketplaceOrdersHeader />
          <Row>
            <Col lg='auto'>
              <OrdersFilters
                {...this.state}
                getOrders={this.getOrders}
                setParentState={this.setParentState}
                exportCsv={this.exportCsv}
              />
            </Col>
            <Col>
              {orders.map((order: Order, index) => (
                <MarketplaceOrderCard
                  key={index}
                  marketplaceId={marketplaceId}
                  order={order}
                />
              ))}
            </Col>
          </Row>
          <Row className='mt-3 text-center'>
            <Col>
              <Button
                variant='outline-info'
                onClick={() => this.getOrders(hasLoaded)}
              >
                {isLoadingMore ? (
                  <React.Fragment>
                    <Spinner size='sm' animation='border' />
                    Loading...
                  </React.Fragment>
                ) : (
                  'Load More...'
                )}
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
}
