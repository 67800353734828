import React from 'react';
import { Form } from 'react-bootstrap';

interface SelectOption {
  label: string;
  value: any;
}

interface SelectProps {
  label: string;
  options: SelectOption[];
  value: any;
  readOnly?: boolean;
  onChange?: (any) => void;
}

const Select = (props: SelectProps) => {
  const { label, value, onChange, options, readOnly } = props;

  const handleChange = event => {
    if (onChange === null || onChange === undefined) {
      return;
    }
    onChange(event.target.value);
  };

  return (
    <Form.Group>
      <Form.Label style={{color: 'black'}}>{label}</Form.Label>
      <Form.Control
        as='select'
        value={value}
        onChange={handleChange}
        disabled={
          readOnly === undefined || readOnly === null ? false : readOnly
        }
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};

export default Select;
