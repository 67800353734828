import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Row, Col, Form, Button } from "react-bootstrap";
import { TextField } from "../../../Shared/EFBootstrap";

import * as Settings from "../../../../Settings";
import BaseProps from "../../../../interfaces/BaseProps";
import PurchaseOrderVendor from "../../../../classes/Logistics/PurchaseOrderVendor";

const eformedApiBaseUri = Settings.eformedApiBaseUri;

interface AddPurchaseOrderVendorProps extends BaseProps, RouteComponentProps {}

export default class AddPurchaseOrderVendor extends React.Component<
  AddPurchaseOrderVendorProps
> {
  state = {
    vendor: new PurchaseOrderVendor("babywise-life.myshopify.com"),
    isLoading: false
  };

  handleSubmit = () => {
    this.setState({ isLoading: true });

    var body = {
      vendor: this.state.vendor
    };

    fetch(
      eformedApiBaseUri +
        "po/vendors.json?key=" +
        this.props.apiKey +
        "&shop=babywise-life.myshopify.com",
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    )
      .then(response => {
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          return response.json();
        }
      })
      .then(data => {
        this.props.addAlert("success", "Vendor added.");
        this.props.history.push("/logistics/po/vendors");
      })
      .catch(error => {
        this.setState({ isLoading: false });
        console.error(error);
        try {
          error.text().then(errorText => console.error(errorText));
        } catch {}
        this.props.addAlert(
          "danger",
          "There was an error while creating the vendor, please try again. If this is not the first error, please report the issue."
        );
      });
  };

  handleChange = fieldName => newValue => {
    let tempVendor = this.state.vendor;
    tempVendor[fieldName] = newValue;
    this.setState({ vendor: tempVendor });
  };

  render() {
    return (
      <React.Fragment>
        <h1>Add Purchase Order Vendor</h1>
        <Row>
          <Col>
            <h6>Enter vendor information</h6>
          </Col>
        </Row>
        <Form>
          <Row>
            <Col>
              <TextField
                label="Vendor Name"
                value={this.state.vendor.name}
                onChange={this.handleChange("name")}
              />
            </Col>
            <Col>
              <TextField
                label="Distributors ID"
                value={this.state.vendor.distributorsId}
                onChange={this.handleChange("distributorsId")}
              />
            </Col>
            <Col>
              <TextField
                label="Email Address"
                value={this.state.vendor.emailAddress}
                onChange={this.handleChange("emailAddress")}
                type="email"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label="Address"
                value={this.state.vendor.address}
                onChange={this.handleChange("address")}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label="City"
                value={this.state.vendor.city}
                onChange={this.handleChange("city")}
              />
            </Col>
            <Col>
              <TextField
                label="State/Province Code"
                value={this.state.vendor.state}
                onChange={this.handleChange("state")}
                maxLength={2}
                placeholder="OR"
              />
            </Col>
            <Col>
              <TextField
                label="Zip/Postal Code"
                value={this.state.vendor.zip}
                onChange={this.handleChange("zip")}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label="Country"
                value={this.state.vendor.country}
                onChange={this.handleChange("country")}
              />
            </Col>
            <Col>
              <TextField
                label="Phone Number"
                value={this.state.vendor.phone}
                onChange={this.handleChange("phone")}
                placeholder="(800) 555-1212"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label="Freight Terms"
                value={this.state.vendor.freightTerms}
                onChange={this.handleChange("freightTerms")}
              />
            </Col>
            <Col>
              <TextField
                label="Payment Terms"
                value={this.state.vendor.paymentTerms}
                onChange={this.handleChange("paymentTerms")}
              />
            </Col>
            <Col>
              <TextField
                label="Credit Limit"
                value={this.state.vendor.creditLimit}
                onChange={this.handleChange("creditLimit")}
                prefix="$"
                type="currency"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label="Sales Contact Name"
                value={this.state.vendor.salesContactName}
                onChange={this.handleChange("salesContactName")}
              />
            </Col>
            <Col>
              <TextField
                label="Sales Contact Email"
                value={this.state.vendor.salesContactEmail}
                onChange={this.handleChange("salesContactEmail")}
              />
            </Col>
            <Col>
              <TextField
                label="Sales Contact Phone"
                value={this.state.vendor.salesContactPhone}
                onChange={this.handleChange("salesContactPhone")}
                placeholder="(800) 555-1212"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label="A/P Contact Name"
                value={this.state.vendor.apContactName}
                onChange={this.handleChange("apContactName")}
              />
            </Col>
            <Col>
              <TextField
                label="A/P Contact Email"
                value={this.state.vendor.apContactEmail}
                onChange={this.handleChange("apContactEmail")}
              />
            </Col>
            <Col>
              <TextField
                label="A/P Contact Phone"
                value={this.state.vendor.apContactPhone}
                onChange={this.handleChange("apContactPhone")}
                placeholder="(800) 555-1212"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label="Notes"
                value={this.state.vendor.notes}
                onChange={this.handleChange("notes")}
              />
            </Col>
          </Row>
        </Form>

        <Row>
          <Col>
            <Button
              block
              variant="success"
              onClick={this.handleSubmit}
              disabled={this.state.isLoading}
            >
              {this.state.isLoading === true ? "Creating..." : "Create Vendor"}
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
