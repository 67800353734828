import React from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const AdminMenu = props => {
  return (
    <React.Fragment>
      <LinkContainer to='/admin/users'>
        <Nav.Link>Users</Nav.Link>
      </LinkContainer>
      <LinkContainer to='/admin/sellers'>
        <Nav.Link>Sellers</Nav.Link>
      </LinkContainer>
    </React.Fragment>
  );
};

export default AdminMenu;
