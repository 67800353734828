import React, { useState } from 'react';
import { Row, Col, Card, Collapse, ButtonGroup, Button } from 'react-bootstrap';

const Plans = props => {
  const [isOpen, setIsOpen] = useState(false);
  const { data } = props;
  return (
    <Row className='mt-3'>
      <Col>
        <Card border='success'>
          <Card.Header onClick={() => setIsOpen(!isOpen)}>
            <h3 className='text-success'>
              Future Designs / Plans / Recommendations
            </h3>
          </Card.Header>
          <Collapse in={isOpen}>
            <Card.Body>
              <PlanDisplay data={data} />
            </Card.Body>
          </Collapse>
        </Card>
      </Col>
    </Row>
  );
};

const PlanDisplay = props => {
  const { data } = props;
  const plans = data.plans;
  const [selected, setSelected] = useState(undefined);
  return (
    <React.Fragment>
      <Row className='text-center'>
        <Col>
          <ButtonGroup>
            {plans.map((plan, index) => (
              <Button
                key={index}
                variant={selected === index ? 'success' : 'outline-success'}
                onClick={() => setSelected(index)}
              >
                {plan.title}
              </Button>
            ))}
          </ButtonGroup>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col className='text-dark'>
          <ul>
            {selected !== undefined &&
              plans[selected].bullets.map((bullet, index) => (
                <li key={index}>{bullet}</li>
              ))}
          </ul>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Plans;
