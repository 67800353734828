import React from "react";
import { Alert } from "react-bootstrap";

import AlertObject from "../../classes/Core/AlertObject";

interface AlertBoxProps {
  alerts: Array<AlertObject>;
  isScrollTop: boolean;
  removeAlert: (index: number) => void;
}

const AlertBox = (props: AlertBoxProps) => {
  if (props.alerts.length > 0) {
    return (
      <React.Fragment>
        <div className={props.isScrollTop ? "alert-box-wrapper" : ""}>
        {props.alerts.map((alert, index) => (
            <Alert
              key={index}
              variant={alert.variant}
              dismissible
              show={alert.show}
              onClose={() => props.removeAlert(index)}
            >
              {alert.title !== undefined &&
                alert.title !== null &&
                alert.title !== "" && (
                  <Alert.Heading>{alert.title}</Alert.Heading>
                )}
              {alert.text}
            </Alert>
        ))}
        </div>
      </React.Fragment>
    );
  } else {
    return null;
  }
};

export default AlertBox;
