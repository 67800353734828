import React from "react";
import { Row, Col, ListGroup, Button } from "react-bootstrap";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import TaxCodeModel from "../../../../classes/Marketplace/TaxCode";

interface TaxCodeProps {
  taxCode: TaxCodeModel
}

const TaxCode = (props: TaxCodeProps) => {
  const { taxCode } = props;

  return (
    <React.Fragment>
      <ListGroup.Item action style={{ cursor: "unset" }}>
        <Row>
          <Col>{taxCode.genreCategory}</Col>
          <Col>{taxCode.gsId}</Col>
          <Col>{taxCode.gsDesc}</Col>
          <Col>{taxCode.subCategoryDesc}</Col>
          <Col>{taxCode.categoryDesc}</Col>
          <Col xs="auto">
            <CopyToClipboard text={taxCode.gsId}>
              <Button size="sm" variant="success">Copy GS ID</Button>
            </CopyToClipboard>
          </Col>
        </Row>
      </ListGroup.Item>
    </React.Fragment>
  );
};

export default TaxCode;
