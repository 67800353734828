import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Row, Col, Button, Form } from "react-bootstrap";
import { TextField } from "../../../Shared/EFBootstrap";

import * as Settings from "../../../../Settings";
import BaseProps from "../../../../interfaces/BaseProps";
import searchPoItem, { SearchKeys } from "./SearchPoItem";

const eformedApiBaseUri = Settings.eformedApiBaseUri;

interface MatchParams {
  poNumber: string;
}

interface AddPurchaseOrderLineItemProps
  extends BaseProps,
    RouteComponentProps<MatchParams> {}

export default class AddPurchaseOrderLineItem extends React.Component<
  AddPurchaseOrderLineItemProps
> {
  state = {
    poId: 0,
    sellerVariantId: "",
    itemNumber: "",
    vendor: "",
    description: "",
    mpn: "",
    upc: "",
    sku: "",
    casePack: "",
    quantity: "",
    cost: "",
    isLoading: false
  };

  componentDidMount() {
    this.getPoNumber();
  }

  getPoNumber = () => {
    this.setState({ poId: this.props.match.params.poNumber });
  };
  
  handleItemNumberChange = async (newValue, searchKey) => {
    let result = await searchPoItem(newValue, searchKey, this.props.apiKey);

    let tempState = { ...this.state, ...result };
    this.setState(tempState);
  }

  handleSubmit = () => {
    const history = this.props.history;
    this.setState({ isLoading: true });
    const {
      poId,
      sellerVariantId,
      vendor,
      description,
      itemNumber,
      mpn,
      upc,
      sku,
      casePack,
      quantity,
      cost
    } = this.state;

    var body = {
      lineItem: {
        sellerVariantId: sellerVariantId,
        itemNumber: itemNumber,
        vendor: vendor,
        description: description,
        mpn: mpn,
        upc: upc,
        sku: sku,
        casePack: casePack,
        quantity: quantity,
        cost: cost
      }
    };

    fetch(
      `${eformedApiBaseUri}po/lineitems.json?key=${
        this.props.apiKey
      }&shop=babywise-life.myshopify.com&poId=${poId}`,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    )
      .then(response => {
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          history.push("/logistics/po");
        }
      })
      .catch(error => {
        this.setState({ isLoading: false });
        console.error(error);
        try {
          error.text().then(errorText => console.error(errorText));
        } catch {}
        this.props.addAlert(
          "danger",
          "There was an error while adding the line item, please try again. If this is not the first error, please report the issue."
        );
      });
  };

  render() {
    const {
      sellerVariantId,
      itemNumber,
      vendor,
      description,
      mpn,
      upc,
      sku,
      casePack,
      quantity,
      cost
    } = this.state;
    return (
      <React.Fragment>
        <h1>Add Line Item to PO</h1>
        <Row>
          <Col>
            <h6>Enter Line Item information</h6>
          </Col>
        </Row>
        <Form>
          <Row>
            <Col>
              <TextField
                label="Seller Variant ID"
                placeholder="Seller Variant ID - Enter to Auto Fill Other Fields"
                value={sellerVariantId}
                onChange={newValue => {
                  this.handleItemNumberChange(newValue, SearchKeys.SELLER_VARIANT_KEY);
                }}
              />
            </Col>
            <Col>
              <TextField
                label="OSC ID"
                placeholder="OSC ID - Enter to Auto Fill Other Fields"
                value={itemNumber}
                onChange={newValue => {
                  this.handleItemNumberChange(newValue, SearchKeys.OSC_ID_KEY);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label="Vendor"
                placeholder="Vendor"
                value={vendor}
                readOnly
              />
            </Col>
            <Col>
              <TextField
                label="Description (Title)"
                placeholder="Description (Title)"
                value={description}
                readOnly
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField label="MPN" placeholder="MPN" value={mpn} readOnly />
            </Col>
            <Col>
              <TextField label="UPC" placeholder="UPC" value={upc} readOnly />
            </Col>
            <Col>
              <TextField label="SKU" placeholder="SKU" value={sku} readOnly />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label="Case Pack"
                value={casePack}
                onChange={newValue => this.setState({ casePack: newValue })}
                type="number"
                helpText="AKA Minimum Order Quantity"
              />
            </Col>
            <Col>
              <TextField
                label="Quantity"
                placeholder="Quantity"
                value={quantity}
                onChange={newValue => this.setState({ quantity: newValue })}
                type="number"
                helpText="To Order"
              />
            </Col>
            <Col>
              <TextField
                label="Cost"
                placeholder="Cost"
                value={cost}
                onChange={newValue => this.setState({ cost: newValue })}
                type="currency"
                prefix="$"
                helpText="Changing Cost=Item Discount"
              />
            </Col>
          </Row>
        </Form>

        <Row>
          <Col>
            <Button
              block
              onClick={this.handleSubmit}
              disabled={this.state.isLoading}
            >
              {this.state.isLoading === true ? "Adding..." : "Add Line Item"}
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
