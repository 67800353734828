import * as React from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import * as Settings from '../../../Settings';
import BaseProps from '../../../interfaces/BaseProps';
import Draft from '../../../classes/Logistics/PurchaseOrderDraft';
import { RouteComponentProps } from 'react-router';
import dateFormat from '../../../utils/DateFormat';
import LoadingPage from '../../Shared/LoadingPage';

const eformedApiBaseUri = Settings.eformedApiBaseUri;

interface ManagePurchaseOrderDraftsProps
  extends BaseProps,
    RouteComponentProps {}

interface ManagePurchaseOrderDraftsState {
  isLoading: boolean;
  drafts: Array<Draft>;
}

export default class ManagePurchaseOrderDrafts extends React.Component<
  ManagePurchaseOrderDraftsProps,
  ManagePurchaseOrderDraftsState
> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      drafts: [],
    };
  }

  componentDidMount() {
    this.getDrafts();
  }

  getDrafts = () => {
    this.setState({ isLoading: true });
    fetch(
      eformedApiBaseUri +
        'po/drafts.json?key=' +
        this.props.apiKey +
        '&shop=babywise-life.myshopify.com',
    )
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({ drafts: data.drafts, isLoading: false });
      });
  };

  render() {
    const { drafts } = this.state;
    const history = this.props.history;

    if (this.state.isLoading) {
      return (
        <React.Fragment>
          <h1>Purchase Order Drafts</h1>
          <Row className='text-right'>
            <Col>
              <LinkContainer to='/logistics/po/create'>
                <Button variant='success'>Add A New PO</Button>
              </LinkContainer>
            </Col>
          </Row>
          <LoadingPage />
        </React.Fragment>
      );
    } else if (drafts === undefined || drafts === null || drafts.length === 0) {
      return (
        <React.Fragment>
          <h1>Purchase Order Drafts</h1>
          <Row className='text-right'>
            <Col>
              <LinkContainer to='/logistics/po/create'>
                <Button variant='success'>Add A New PO</Button>
              </LinkContainer>
            </Col>
          </Row>
          <Row>
            <Card>No drafts found...</Card>
          </Row>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h1>Purchase Order Drafts</h1>
          <Row className='text-right'>
            <Col>
              <LinkContainer to='/logistics/po/create'>
                <Button variant='success'>Add A New PO</Button>
              </LinkContainer>
            </Col>
          </Row>
          {drafts.map((draft, index) => (
            <DraftListItem
              key={index}
              vendorId={draft.purchaseOrder.vendorId}
              vendorName={draft.purchaseOrder.vendorCompany}
              modifiedDate={draft.modifiedAt}
              draft={draft}
              history={history}
              getDrafts={this.getDrafts}
              apiKey={this.props.apiKey}
            />
          ))}
        </React.Fragment>
      );
    }
  }
}

const DraftListItem = props => {
  const deleteDraft = vendorId => {
    fetch(
      `${eformedApiBaseUri}po/drafts.json?key=${
        props.apiKey
      }&shop=babywise-life.myshopify.com&vendor_id=${vendorId}`,
      { method: 'DELETE' },
    )
      .then(response => {
        props.getDrafts();
      })
      .catch(error => {
        console.error(error);
        try {
          error.text().then(errorText => console.error(errorText));
        } catch {}
        props.addAlert(
          'danger',
          'There was an error while deleting the draft, please try again. If this is not the first error, please report the issue.',
        );
      });
  };

  return (
    <Row className='mt-2'>
      <Col>
        <Card>
          <Card.Header>{props.vendorName || 'No Vendor Selected'}</Card.Header>
          <Card.Body>
            <p>{dateFormat(props.modifiedDate)}</p>

            <LinkContainer to={`/logistics/po/create/${props.vendorId}`}>
              <Card.Link href=''>Edit</Card.Link>
            </LinkContainer>
            <Card.Link
              href='#'
              onClick={e => {
                e.preventDefault();
                deleteDraft(props.vendorId);
              }}
            >
              Delete
            </Card.Link>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
