import rest from '../../../../../utils/Rest';
import errorHandler from '../../../../../utils/ErrorHandler';
import * as Settings from '../../../../../Settings';

import WalmartCategory from '../../../../../classes/Marketplace/Walmart/WalmartCategory';

const getWalmartCategories = async (props, setParentState) => {
  const { marketplaceId } = props.match.params;
  const { apiKey } = props;
  try {
    let data: WalmartCategory[] = await rest.get(
      `${
        Settings.eformedApiBaseUri
      }marketplace/${marketplaceId}/walmartcategory?key=${apiKey}`,
    );

    setParentState({ walmartCategories: data });
  } catch (error) {
    errorHandler(
      error,
      'There was an error while retrieving the walmart categories. Please try again, if this is not the first time you have seen this error, please report the issue.',
      props.addAlert,
    );
  }
};

export default getWalmartCategories;
