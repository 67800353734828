import React, { useState } from 'react';
import { Row, Col, Collapse } from 'react-bootstrap';

import { TextField, Select } from '../../../Shared/EFBootstrap';
import { SearchFilterHeader, SearchFilterFooter } from '../../../Shared/SearchFilterComponents';
import { CHANNEL_TYPE } from '../../../../utils/Constants';

import { WalmartFeedsChildProps } from './WalmartFeeds';

interface SelectOption {
  label: string;
  value: string | number;
}

const WalmartFeedFilters = (props: WalmartFeedsChildProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const {
    getWalmartFeeds,
    setParentState,
    channels,
    channelFilter,
    productIdFilter,
    feedIdFilter,
    startDateFilter,
    endDateFilter,
    orderByFilter
  } = props;

  let channelOptions: SelectOption[] = [{label: "Select an option", value: undefined}];

  channelOptions = channels !== undefined && channels !== null && channels.length > 0 ?
    channels.filter(channel => channel.active === true && (channel.channelType === CHANNEL_TYPE.WALMART || channel.channelType === CHANNEL_TYPE.WALMART_MKP)).map(channel => {
      return { label: channel.name, value: channel.id };
    }) : channelOptions;

  const defaultStatusValues = {
    isInitialLoading: false,
    channelFilter: undefined,
    productIdFilter: undefined,
    feedIdFilter: undefined,
    startDateFilter: undefined,
    endDateFilter: undefined,
    orderByFilter: 'createdatdesc'
  }

  return (
    <React.Fragment>
      <SearchFilterHeader 
        isOpen={isOpen}
        setParentState={setParentState}
        getResults={getWalmartFeeds}
        setIsOpen={setIsOpen}
      />
      <Collapse in={isOpen}>
        <Row>
          <Col>
            <Row className='mt-2'>
              <Col>
                <Select
                  label='Channel'
                  options={channelOptions}
                  value={channelFilter}
                  onChange={newValue =>
                    setParentState({
                      channelFilter: newValue,
                    })
                  }
                />
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col style={{height: "90px"}}>
                <div className="mr-1 input-with-clear">
                  <TextField
                    label='Product ID'
                    placeholder='Product ID'
                    value={productIdFilter}
                    onChange={newValue =>
                      setParentState({ productIdFilter: newValue })
                    }
                    onEnterKeyPress={() => {
                      setParentState({ isInitialLoading: false });
                      getWalmartFeeds(0);
                    }}
                  />
                  <span
                    className="input-close-icon"
                    onClick={() => setParentState({ productIdFilter: '' })}
                    style={{float: "right", right: "5px", top: "-54px"}}
                  >
                    &times;
                  </span>
                </div>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col style={{height: "90px"}}>
                <div className="mr-1 input-with-clear">
                  <TextField
                    label='Feed ID'
                    placeholder='Feed ID'
                    value={feedIdFilter}
                    onChange={newValue =>
                      setParentState({ feedIdFilter: newValue })
                    }
                    onEnterKeyPress={() => {
                      setParentState({ isInitialLoading: false });
                      getWalmartFeeds(0);
                    }}
                  />
                  <span
                    className="input-close-icon"
                    onClick={() => setParentState({ feedIdFilter: '' })}
                    style={{float: "right", right: "5px", top: "-54px"}}
                  >
                    &times;
                  </span>
                </div>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <Select
                  label='Order By'
                  options={[
                        { label: 'Any', value: undefined },
                        { label: 'Created At - Desc', value: 'createdatdesc' },
                        { label: 'Updated At - Desc', value: 'updatedatdesc' }
                  ]}
                  value={orderByFilter}
                  onChange={newValue =>
                    setParentState({
                      orderByFilter: newValue !== 'Any' ? newValue : undefined,
                    })
                  }
                />
              </Col>
            </Row>
            <SearchFilterFooter 
              defaultStatus={defaultStatusValues}
              setParentState={setParentState}
              getResults={getWalmartFeeds}
            />
          </Col>
        </Row>
      </Collapse>
    </React.Fragment>
  );
}

export default WalmartFeedFilters;