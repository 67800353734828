import React from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const FamilywiseMenu = props => {
  return (
    <React.Fragment>
      <LinkContainer to='/familywise/accounting/checklist'>
        <Nav.Link>Accounting</Nav.Link>
      </LinkContainer>
      <LinkContainer to='/familywise/ccs'>
        <NavDropdown title='CCS' id='ccs-dropdown'>
          <LinkContainer to='/familywise/ccs/billing'>
            <NavDropdown.Item>CCS Billing</NavDropdown.Item>
          </LinkContainer>
          <LinkContainer to='/familywise/ccs/pricing'>
            <NavDropdown.Item>CCS Pricing</NavDropdown.Item>
          </LinkContainer>
        </NavDropdown>
      </LinkContainer>
      <NavDropdown title='Business Intelligence' id='bi-dropdown'>
        <NavDropdown.Item href='https://app.powerbi.com/groups/me/apps/15093b1d-3b66-4c1f-a7b4-3d0df0a4304e/reports/1b98ca46-ab01-486a-834f-59f144850053/ReportSection'>
          E-Commerce Dashboard
        </NavDropdown.Item>
        <NavDropdown.Item href='https://app.powerbi.com/groups/me/apps/1ff1b0db-4198-4c97-a541-4e9f987c036d/reports/cdc36d51-82f9-422d-a80f-415946bb29b4/'>
          Logistics Dashboard
        </NavDropdown.Item>
        <NavDropdown.Item href='https://app.powerbi.com/groups/me/apps/d6d8126a-97e7-4429-bbe4-db39d10845dc/reports/a303f021-6f42-43b6-b02f-2b4737bd6137/ReportSection'>
          Media Dashboard
        </NavDropdown.Item>
        <NavDropdown.Item href='https://app.powerbi.com/groups/me/apps/bb090690-94e4-4069-9616-3ec0e150532e/reports/0fd6df56-e6cb-4b89-bc0e-a52cacdcfb94/ReportSection'>
          Merchandising Dashboard
        </NavDropdown.Item>
      </NavDropdown>
      <LinkContainer to='/familywise/edi'>
        <Nav.Link>EDI</Nav.Link>
      </LinkContainer>
    </React.Fragment>
  );
};

export default FamilywiseMenu;
