import React, { useState } from "react";
import { Row, Col, Button, Spinner } from "react-bootstrap";

import { eformedApiBaseUri } from "../../../../Settings";
import rest from "../../../../utils/Rest";
import errorHandler from "../../../../utils/ErrorHandler";

import { ImportVariantsProps } from "./ImportVariants";
import { TextArea } from "../../../Shared/EFBootstrap";


const ImportVariantsEntry = (props: ImportVariantsProps) => {
  const [tsvChanges, setTsvChanges] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { sellerId } = props.match.params;
  const { apiKey, setResults } = props;

  const submitImportVariants = async () => {
    await setIsSubmitting(true);
    let body = { tsvChanges: tsvChanges };
    try {
      let response = await rest.post(
        `${eformedApiBaseUri}seller/${sellerId}/variants/importvariants?key=${apiKey}`,
        body
      );
      await setResults(response);
      await setIsSubmitting(false);
      await setTsvChanges("");
    } catch (error) {
      await errorHandler(
        error,
        "There was an error while submitting the variant data.",
        props.addAlert
      );
      await setIsSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <Row className="mt-5">
        <Col>
          Enter in tab-delimited changes to be made (copied from a spreadsheet)
        in the order: <br/>Vendor, Product Title, SKU, MPN, Barcode, Option1 Name, Option1 Value, Option2 Name, Option2 Value, Option3 Name, Option3 Value, QTY, Image Src, Youtube ID, Weight, Weight Unit, Shipping Length, Shipping Width, Shipping Height, Dimension Unit, Lead Time, Description, Feature 1, Feature 2, Feature 3, Feature 4, Feature 5, Inventory Policy, Cost, Out, MSRP, MAP, Price, Handling
        </Col>
        <Col lg="3" className="text-right">
          <Button
            variant="success"
            disabled={isSubmitting}
            onClick={() => submitImportVariants()}
          >
            {isSubmitting ? (
              <React.Fragment>
                <Spinner size="sm" animation="border" />
                Submitting...
              </React.Fragment>
            ) : (
              "Submit"
            )}
          </Button>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <TextArea
            label="Bulk Changes (TSV)"
            rows={15}
            placeholder="Vendor	ProductTitle	SKU	MPN	Barcode	Option1Name	Option1Value	Option2Name	Option2Value	Option3Name	Option3Value	QTY	ImageSrc	YoutubeID	Weight	WeightUnit	ShippingLength	ShippingWidth	ShippingHeight	DimensionUnit LeadTime	Description	Feature1	Feature2	Feature3	Feature4	Feature5	InventoryPolicy	Cost  Out	MSRP	MAP	Price	Handling"
            style={{whiteSpace: "pre"}}
            value={tsvChanges}
            onChange={newValue => setTsvChanges(newValue)}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ImportVariantsEntry;