import React from 'react';
import { Row, Col, Button, Spinner } from 'react-bootstrap';

import * as Settings from '../../../../Settings';
import RouteVariableProps from '../../../../interfaces/RouteVariableProps';
import rest from '../../../../utils/Rest';
import errorHandler from '../../../../utils/ErrorHandler';
import setMarketplaceFromId from '../../../../utils/SetMarketplaceFromId';
import { addUrlParam, removeAllUrlParams } from '../../../../utils/SetUrlParameters';

import PriceScheduleResult from "../../../../classes/Marketplace/PriceScheduleResult";
import MarketplaceChannel from "../../../../classes/Marketplace/Channel";

import productFunctions from "../../Products/Product/Functions";

import PriceScheduleResults from "./PriceScheduleResults";
import PriceScheduleFilters from "./PriceScheduleFilters"

interface MatchParams {
  marketplaceId: string;
}

interface PriceSchedulerViewProps extends RouteVariableProps<MatchParams> {} 

interface PriceSchedulerViewState {
  isInitialLoading: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  hasMore: boolean;
  hasLoaded: number;
  isDownloading: boolean;
  priceSchedules: PriceScheduleResult[];
  channels: MarketplaceChannel[];

  channelFilter: string;
  vendorFilter: string;
  productTitleFilter: string;
  variantIdFilter: string;
  startDateFilter: string;
  discountNameFilter: string;
  endDateFilter: string;
  orderByFilter: string;
}

export interface PriceSchedulerViewChildProps extends PriceSchedulerViewState, PriceSchedulerViewProps {
  exportCsv: (skip: number, downloadAll: boolean) => void;
  getPriceSchedules: (skip: number) => void;
  removePriceSchedule: (variantId: string, channelId: string) => void;
  setParentState: (stateObject: any) => void;
}

export default class PriceSchedulerView extends React.Component<PriceSchedulerViewProps, PriceSchedulerViewState> {
  constructor(props) {
    super(props);
    this.state = {
      isInitialLoading: true,
      isLoading: false,
      isLoadingMore: false,
      hasMore: false,
      hasLoaded: 0,
      isDownloading: false,
      priceSchedules: new Array<PriceScheduleResult>(),
      channels: new Array<MarketplaceChannel>(),

      channelFilter: undefined,    
      vendorFilter: undefined,
      productTitleFilter: undefined,
      variantIdFilter: undefined,
      startDateFilter: undefined,
      endDateFilter: undefined,
      discountNameFilter: undefined,
      orderByFilter: 'createdatdesc'
    }
  }

  componentDidMount() {
    if (this.props.match.params.marketplaceId !== undefined) {
      this.initialSetMarketplace();
      this.getMarketplaceChannels();
      this.getPriceSchedules(0);
    }
  }

  initialSetMarketplace = async () => {
    let marketplaceId = this.props.match.params.marketplaceId;
    const { selectedMarketplace, setMarketplace, user } = this.props;
    this.setState({ isInitialLoading: true });

    if (selectedMarketplace === undefined) {
      await setMarketplaceFromId(
        marketplaceId,
        setMarketplace,
        user,
        this.props.history,
      );
    }
  };

  getMarketplaceChannels = async () => {
    await productFunctions.getMarketplaceChannels(
      this.props,
      this.setParentState,
    );
  };

  getPriceSchedules = async (skip: number) => {
    const { marketplaceId } = this.props.match.params;
    const { apiKey } = this.props;
    const {
      hasLoaded,
      priceSchedules,
      channelFilter,
      vendorFilter,
      productTitleFilter,
      variantIdFilter,
      startDateFilter,
      endDateFilter,
      discountNameFilter,
      orderByFilter
    } = this.state;
    if (skip === 0) {
      await this.setState({ isLoading: true });
    } else {
      await this.setState({ isLoadingMore: true });
    }

    try {
      if(!this.state.isInitialLoading) {
        removeAllUrlParams(this.props);

        if (channelFilter !== undefined && channelFilter !== 'Any') {
          addUrlParam(this.props, 'channel', channelFilter);
        }
        if (vendorFilter !== undefined) {
          addUrlParam(this.props, 'vendor', vendorFilter);
        }
        if (productTitleFilter !== undefined) {
          addUrlParam(this.props, 'productTitle', productTitleFilter);
        }
        if (variantIdFilter !== undefined) {
          addUrlParam(this.props, 'variantId', variantIdFilter);
        }
        if (startDateFilter !== undefined) {
          addUrlParam(this.props, 'startDate', startDateFilter);
        }
        if (endDateFilter !== undefined) {
          addUrlParam(this.props, 'endDate', endDateFilter);
        }
        if (discountNameFilter !== undefined) {
          addUrlParam(this.props, 'discountName', discountNameFilter);
        }
        if (orderByFilter !== undefined) {
          addUrlParam(this.props, 'orderBy', orderByFilter);
        }
      } else {
        let preSearchParams = new URLSearchParams(this.props.location.search);
        if (preSearchParams.get('orderBy') === null) {
          addUrlParam(this.props, 'orderBy', orderByFilter.toString());
        }
      }

      let uri = `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/prices/priceschedules?key=${apiKey}&skip=${skip}`;
      let searchParams = new URLSearchParams(this.props.location.search);

      searchParams.forEach((value: string, key: string) => {        
        let tempState = {...this.state, [key+'Filter']:value };
        this.setState(tempState);

        uri = uri + `&${key}=${value}`;
      });

      let data: PriceScheduleResult[] = await rest.get(uri);

      let tempResults = priceSchedules;
      if (skip === 0) {
        tempResults = new Array<PriceScheduleResult>();
        await this.setState({ hasLoaded: 0 });
      }
      tempResults.push(...data);

      await this.setState({
        priceSchedules: tempResults,
        hasLoaded: hasLoaded + data.length,
        isLoading: false,
        isLoadingMore: false,
      });

      if (data.length >= 25) {
        await this.setState({ hasMore: true });
      } else {
        await this.setState({ hasMore: false });
      }
    } catch (error) {
      errorHandler(
        error,
        'There was an error retrieving walmart feeds. Please try again, if this is not the first time you have seen this error, please report it.',
        this.props.addAlert,
      );
      await this.setState({ isLoading: false, isLoadingMore: false });
    }
  }

  exportCsv = async (skip: number, downloadAll: boolean) => {
    const { marketplaceId } = this.props.match.params;
    const { apiKey } = this.props;
    const {
      channelFilter,
      vendorFilter,
      productTitleFilter,
      variantIdFilter,
      startDateFilter,
      endDateFilter,
      discountNameFilter,
      orderByFilter
    } = this.state;

    try {
      let uri = `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/prices/download?key=${apiKey}`;

      if (channelFilter !== undefined && channelFilter !== 'Any') {
        uri = uri + `&channel=${encodeURIComponent(channelFilter)}`;
      }
      if (vendorFilter !== undefined) {
        uri = uri + `&vendor=${encodeURIComponent(vendorFilter)}`;
      }
      if (productTitleFilter !== undefined) {
        uri = uri + `&productTitle=${encodeURIComponent(productTitleFilter)}`;
      }
      if (variantIdFilter !== undefined) {
        uri = uri + `&variantId=${encodeURIComponent(variantIdFilter)}`;
      }
      if (startDateFilter !== undefined) {
        uri = uri + `&startDate=${encodeURIComponent(startDateFilter)}`;
      }
      if (endDateFilter !== undefined) {
        uri = uri + `&endDate=${encodeURIComponent(endDateFilter)}`;
      }
      if (discountNameFilter !== undefined) {
        uri = uri + `&discountName=${encodeURIComponent(discountNameFilter)}`;
      }
      if (orderByFilter !== undefined) {
        uri = uri + `&orderBy=${encodeURIComponent(orderByFilter)}`;
      }
      
      await fetch(uri).then(response => {
				response.blob().then(blob => {
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.href = url;
					a.download = 'discount_list.csv';
					a.click();
				});
				// window.location.href = response.url;
      });

      this.setState({ isDownloading: false });
    } catch (error) {
      errorHandler(
        error,
        'There was an error while downloading. Please try again, if this is not the first time you have seen this error, please report it.',
        this.props.addAlert,
      );
      await this.setState({ isDownloading: false });
    }
  }

  removePriceSchedule = async (variantId: string, channelId: string) => {
    const { apiKey, addAlert } = this.props;
    const { priceSchedules } = this.state;
    const { marketplaceId } = this.props.match.params;

    try {
      await rest.delete(
        `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/prices/${variantId}/removeschedule/${channelId}?key=${apiKey}`,
      );

      let priceSchedulesTemp = priceSchedules.filter(p => p.channelId !== channelId || p.variantId !== variantId);
      this.setState({ priceSchedules: priceSchedulesTemp });
      addAlert("success", `Price schedule removed successfully.`);
    } catch (error) {
      errorHandler(
        error,
        'There was an error while removing price schedule.',
        this.props.addAlert,
      );
    }
  }

  setParentState = stateObject => {
    this.setState(stateObject);
  };

  render() {
    const { hasMore, hasLoaded, isLoadingMore } = this.state;

    return (
      <React.Fragment>
        <Row>
          <Col>
            <h1>Price Schedule Page</h1>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg="auto">
            <PriceScheduleFilters
              {...this.state}
              {...this.props}
              exportCsv={this.exportCsv}
              getPriceSchedules={this.getPriceSchedules}
              removePriceSchedule={this.removePriceSchedule}
              setParentState={this.setParentState}
            />
          </Col>
          <Col>
            <PriceScheduleResults
              {...this.state}
              {...this.props}
              exportCsv={this.exportCsv}
              getPriceSchedules={this.getPriceSchedules}
              removePriceSchedule={this.removePriceSchedule}
              setParentState={this.setParentState}
            />
          </Col>
        </Row>
        <Row className="mt-3 text-center">
          <Col>
            {hasMore && (
              <Button
                variant="outline-info"
                onClick={() => {
                  this.setState({ isInitialLoading: false });
                  this.getPriceSchedules(hasLoaded);
                }}
              >
                {isLoadingMore ? (
                  <React.Fragment>
                    <Spinner size="sm" animation="border" />
                    Loading...
                  </React.Fragment>
                ) : (
                  "Load More..."
                )}
              </Button>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}