const additionalChannelsPlan = {
  title: 'Additional Channels (AMZ / WMT)',
  bullets: [
    'The current channel (Shopify) was started long ago and is tightly coupled to the data model, but this likely should not be the case in future channels (or Shopify, it would be fairly easy to correct it). The Products - GET API should provide all information needed for channels. (See /swagger)',
    'Integrations should likely be built in Azure Functions (C#), but could also be built in Node should there be a reason to do so. Node is recommended as there are many SDKs for Javascript and Javascript is already used in React services.',
    'In the case of Walmart and Amazon the channels do exist, and the data shapes sent to the channels could be matched, but I do not recommend in any way modeling the way data is accessed after the existing integrations. The SQL based data mashup is extremely brittle and constantly fails.',
  ],
};

export default additionalChannelsPlan;
