import React from 'react';
import { Row, Col, Badge } from 'react-bootstrap';

import MarketplaceProductProps from '../../../../interfaces/MarketplaceProductProps';

const MarketplaceProductTags = (props: MarketplaceProductProps) => {
  const { product } = props;
  return (
    <React.Fragment>
      <Row className='mt-2'>
        <Col>
          <h5>Tags</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          {product.tags !== undefined &&
            product.tags.map((tag, index) => (
              <Badge key={index} variant='info' pill>
                {tag.tag}
              </Badge>
            ))}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default MarketplaceProductTags;
