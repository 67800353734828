import getMarketplaceChannels from "./GetMarketplaceChannels";
import getProduct from "./GetProduct";
import downloadProduct from "./DownloadProduct";
import getWalmartCategories from "./GetWalmartCategories";
import handleChannelChange from "./HandleChannelChange";
import handleVariantChange from "./HandleVariantChange";
import getVariantChannelFinalPrice, { getVariantFloorValue, getVariantInValue } from "./GetVariantPriceCalculations";
import {
  importSellerVariantToProduct,
  importSellerVariantToVariant
} from "./ImportSellerVariant";
import saveProduct from "./SaveProduct";
import updateChannel from "./UpdateChannel";
import removeProductAssociations from "./RemoveProductAssociations";

const productFunctions = {
  getMarketplaceChannels: getMarketplaceChannels,
  getVariantChannelFinalPrice: getVariantChannelFinalPrice,
  getVariantFloorValue: getVariantFloorValue,
  getVariantInValue: getVariantInValue,
  getProduct: getProduct,
  downloadProduct: downloadProduct,
  getWalmartCategories: getWalmartCategories,
  handleChannelChange: handleChannelChange,
  handleVariantChange: handleVariantChange,
  importSellerVariantToProduct: importSellerVariantToProduct,
  importSellerVariantToVariant: importSellerVariantToVariant,
  saveProduct: saveProduct,
  updateChannel: updateChannel,
  removeProductAssociations: removeProductAssociations
};

export default productFunctions;
