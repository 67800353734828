import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Product from '../../../../classes/Marketplace/Product';

const MarketplaceProductHeader = props => {
  const { marketplaceId, productId, title, vendor, isEditing, setParentState } = props;

  return (
    <React.Fragment>
      <Row>
        <Col className='align-self-center'>
          <h1>Marketplace Product</h1>
        </Col>
        {!isEditing && productId !== 'new' &&
          <Col md="3" className="text-right">
            <LinkContainer 
              to={`/marketplace/${marketplaceId}/products/new`}
              onClick={() => setParentState({ product: new Product(marketplaceId), isEditing: true, isImported: false })}
            >
              <Button variant="outline-success">Add New Product</Button>
            </LinkContainer>
          </Col>
        }
        <Col lg='3' className='text-right'>
          <LinkContainer exact to={`/marketplace/${marketplaceId}/products`}>
            <Button variant='outline-info'>Back To Products</Button>
          </LinkContainer>
        </Col>
      </Row>
      {title !== undefined && vendor !== undefined &&
        <Row className="mt-5">
          <Col className='align-self-center'>
              <h3>{vendor}  |  {title}</h3>
            </Col>
        </Row>
      }
    </React.Fragment>
  );
};

export default MarketplaceProductHeader;
