import React from 'react';
import { Row, Col, Button, Badge } from 'react-bootstrap';
import { Select } from '../../Shared/EFBootstrap';

import { UserLineProps } from './UserLine';
import MarketplacePermission from '../../../classes/Marketplace/MarketplacePermission';

interface MarketplaceSelectorProps extends UserLineProps {
  edit: boolean;
}

const formatOptions = marketplaces => {
  return marketplaces.map((marketplace, index) => {
    return { label: marketplace.name, value: marketplace.id };
  });
};

export default class MarketplaceSelector extends React.Component<
  MarketplaceSelectorProps
> {
  state = {
    addMarketplace:
      this.props.marketplaces.length > 0
        ? formatOptions(this.props.marketplaces)[0].value
        : '',
  };

  handleAdd = (marketplaceId: string) => {
    const thisUser = this.props.user;
    const newUsers = this.props.users.map((user, iindex) => {
      if (thisUser.id !== user.id) {
        return user;
      } else {
        let tempMarketplaces = user.marketplaces;
        let tempMarketplace = this.props.marketplaces.filter(
          marketplace => marketplace.id === marketplaceId,
        )[0];
        let tempPermission = new MarketplacePermission(
          tempMarketplace.id,
          tempMarketplace.name,
        );
        tempMarketplaces.push(tempPermission);
        user.marketplaces = tempMarketplaces;
        return user;
      }
    });

    this.props.setParentState({ users: newUsers });
  };

  handleRemove = (indexParam: number) => {
    const thisUser = this.props.user;
    const newUsers = this.props.users.map((user, iindex) => {
      if (thisUser.id !== user.id) {
        return user;
      } else {
        return {
          ...user,
          marketplaces: user.marketplaces.filter(
            (marketplace, iiindex) => indexParam !== iiindex,
          ),
        };
      }
    });

    this.props.setParentState({ users: newUsers });
  };

  render() {
    const options = formatOptions(this.props.marketplaces);
    if (this.props.edit === true) {
      return (
        <React.Fragment>
          <Row>
            <Col xs='auto'>Marketplaces</Col>
            <Col>
              {this.props.user.marketplaces.length > 0 ? (
                this.props.user.marketplaces.map((marketplace, index) => (
                  <Badge
                    key={index}
                    pill
                    variant='danger'
                    onClick={() => {
                      this.handleRemove(index);
                    }}
                  >
                    {marketplace.name} x
                  </Badge>
                ))
              ) : (
                <Badge pill variant='primary'>
                  None
                </Badge>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Select
                label='Marketplace'
                value={this.state.addMarketplace}
                options={options}
                onChange={newValue => {
                  this.setState({ addMarketplace: newValue });
                }}
              />
            </Col>
            <Col className='align-self-center' xs='auto'>
              <Button
                size='sm'
                disabled={this.state.addMarketplace === '0'}
                variant='outline-success'
                onClick={() => this.handleAdd(this.state.addMarketplace)}
              >
                Add
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      );
    } else {
      return (
        <Row>
          <Col xs='auto'>Marketplaces</Col>
          <Col>
            {this.props.user.marketplaces.length > 0 ? (
              this.props.user.marketplaces.map((marketplace, index) => (
                <Badge key={index} pill variant='primary'>
                  {marketplace.name}
                </Badge>
              ))
            ) : (
              <Badge pill variant='primary'>
                None
              </Badge>
            )}
          </Col>
        </Row>
      );
    }
  }
}
