import * as React from "react";
import { Container, Row, Col, Spinner, Button, Form } from "react-bootstrap";
import { TextField } from "../../Shared/EFBootstrap";
import LoadingPage from "../../Shared/LoadingPage";

import * as Settings from "../../../Settings";
import BaseProps from "../../../interfaces/BaseProps";

const eformedApiBaseUri = Settings.eformedApiBaseUri;

interface CCSPricingState {
  prices: Array<Price>;
  upcAdd: string;
  priceAdd: number;
  isLoading: boolean;
  isSubmitting: boolean;
}

class Price {
  Upc: string;
  NewPrice: number;
}

export default class CCSPricing extends React.Component<
  BaseProps,
  CCSPricingState
> {
  constructor(props) {
    super(props);
    this.state = {
      prices: [],
      upcAdd: "",
      priceAdd: 0,
      isLoading: false,
      isSubmitting: false
    };
  }

  componentDidMount() {
    this.getPrices();
  }

  getPrices = () => {
    fetch(eformedApiBaseUri + "ccs/prices.json?key=" + this.props.apiKey)
      .then(response => {
        if (
          (response.status >= 400 && response.status <= 500) ||
          response.status === 401
        ) {
          throw response;
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data !== undefined && data !== null) {
          this.setState({ prices: data.prices });
        }
      })
      .catch(error => {
        this.setState({ isLoading: false });
        console.error(error);
        try {
          error.text().then(errorText => console.error(errorText));
        } catch {}
        this.props.addAlert(
          "danger",
          "There was an error while retrieving prices."
        );
      });
  };

  handleSubmit = () => {
    this.setState({ isSubmitting: true });
    const { upcAdd, priceAdd } = this.state;

    var body = {
      upc: upcAdd,
      new_price: priceAdd
    };

    console.log(body);

    fetch(eformedApiBaseUri + "ccs/prices.json?key=" + this.props.apiKey, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        console.log(response);
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          return response.json();
        }
      })
      .then(data => {
        this.props.addAlert("success", "Price has been added.");
        this.setState({ isSubmitting: false });
        this.getPrices();
      })
      .catch(error => {
        this.setState({ isSubmitting: false });
        console.error(error);
        try {
          error.text().then(errorText => console.error(errorText));
        } catch {}
        this.props.addAlert(
          "danger",
          "There was an error while creating this price."
        );
      });
  };

  render() {
    if (this.state.isLoading) {
      return (
        <div>
          <h1>CCS Pricing</h1>
          <Container className="mt-10">
            <LoadingPage />
          </Container>
        </div>
      );
    } else {
      return (
        <div>
          <h1>CCS Pricing</h1>

          <h5>
            Add or Change a price (Use 0 to go back to the normal store price)
          </h5>
          <br />
          <Form>
            <Row>
              <Col>
                <TextField
                  label="UPC"
                  value={this.state.upcAdd}
                  onChange={newValue => this.setState({ upcAdd: newValue })}
                />
              </Col>
              <Col>
                <TextField
                  label="Price"
                  prefix="$"
                  value={this.state.priceAdd}
                  onChange={newValue => this.setState({ priceAdd: newValue })}
                  type="number"
                  step={0.01}
                />
              </Col>
              <Col md="3" className="align-self-center">
                <Button
                  variant="success"
                  onClick={this.handleSubmit}
                  disabled={this.state.isSubmitting}
                >
                  {this.state.isSubmitting === true ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    "Add/Edit a Price"
                  )}
                </Button>
              </Col>
            </Row>
          </Form>

          <Row className="mt-3">
            <h3>Current Pricing</h3>
          </Row>
          {this.state.prices.map((price, index) => (
            <p key={index}>
              UPC: {price.Upc} Price: ${price.NewPrice}
            </p>
          ))}
        </div>
      );
    }
  }
}
