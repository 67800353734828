import React, { useState } from 'react';
import { Row, Col, FormLabel } from 'react-bootstrap';
import DatePicker, { Day } from 'react-modern-calendar-datepicker';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';

import { Select, TextField } from '../../../Shared/EFBootstrap';
import SearchFilter from '../../../Shared/SearchFilterComponents';

import { MarketplaceOrdersState } from '../Orders';

interface SelectOption {
  label: string;
  value: string | number;
}

interface OrdersFiltersProps extends MarketplaceOrdersState {
  getOrders: (skip: number) => void;
  setParentState: (any) => void;
  exportCsv: (limitCount: number, downloadAll: boolean) => void;
}

const OrdersFilters = (props: OrdersFiltersProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [startDate, setStartDate] = useState<Day>(null);
  const [endDate, setEndDate] = useState<Day>(null);
  const {
    getOrders,
    setParentState,
    exportCsv,
    isDownloading,
    channels,
    channelFilter,
    channelOrderIdFilter,
    channelOrderNumberFilter,
    customerNameFileter,
    customerEmailFilter,
    riskyFilter,
    orderStatusFilter,
    paymentStatusFilter,
    fulfillmentStatusFilter,
    syncStatusFilter,
    outOfStockFilter,
  } = props;

  const renderStartDateInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      placeholder="Start Date"
      value={startDate !== undefined && startDate !== null ? 
        `${startDate.month.toString()}-${startDate.day.toString()}-${startDate.year.toString()}` : ''}
      style={{
        padding: '0.75rem 2rem',
        border: 'none',
        background: 'rgb(247 247 249)',
        outline: 'none',
        width: '100%'
      }}
    />
  )

  const renderEndDateInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      placeholder="End Date"
      value={endDate !== undefined && endDate !== null ?
      `${endDate.month.toString()}-${endDate.day.toString()}-${endDate.year.toString()}` : ''}
      style={{
        padding: '0.75rem 2rem',
        border: 'none',
        background: 'rgb(247 247 249)',
        outline: 'none',
        width: '100%'
      }}
    />
  )

  const changeStartDate = (newDate) => {
    setStartDate(newDate);
    if (newDate !== undefined && newDate !== null && newDate !== "") {
      setParentState({ startDateFilter: `${newDate.month.toString()}/${newDate.day.toString()}/${newDate.year.toString()}`});
    } else {
      setParentState({ startDateFilter: undefined});
    }
  }

  const changeEndDate = (newDate) => {
    setEndDate(newDate);
    if (newDate !== undefined && newDate !== null && newDate !== "") {
      setParentState({ endDateFilter: `${newDate.month.toString()}/${newDate.day.toString()}/${newDate.year.toString()}`});
    } else {
      setParentState({ endDateFilter: undefined});
    }
  }

  let channelOptions: SelectOption[] = [{label: "Any", value: undefined}];

  channelOptions = channelOptions.concat(
    channels !== undefined && channels !== null && channels.length > 0 ?
    channels.map(channel => {
      return { label: channel.name, value: channel.id };
    }) : []
  );

  const defaultStatusValues = {
    isInitialLoading: false,
    channelFilter: 'Any',
    channelOrderIdFilter: undefined,
    channelOrderNumberFilter: undefined,
    customerNameFileter: undefined,
    customerEmailFilter: undefined,
    startDateFilter: undefined,
    endDateFilter: undefined,
    riskyFilter: 'Any',
    orderStatusFilter: 'Any',
    paymentStatusFilter: 'Any',
    fulfillmentStatusFilter: 'Any',
    syncStatusFilter: 'Any',
    outOfStockFilter: 'Any'
  }

  return (
    <SearchFilter 
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      enableDownload={true}
      isDownloading={isDownloading}
      defaultStatus={defaultStatusValues}
      setParentState={setParentState}
      exportCsv={exportCsv}
      getResults={getOrders}
      advancedDownloadOptions={true}
    >
      <Row className='mt-2'>
        <Col>
          <Select
            label='Channel'
            options={channelOptions}
            value={channelFilter}
            onChange={newValue =>
              setParentState({
                channelFilter: newValue === 'Any' ? undefined : newValue,
              })
            }
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col style={{height: "111px"}}>
          <div className="mr-1 input-with-clear">
            <TextField
              label='Channel Order Id'
              placeholder='Channel Order Id'
              value={channelOrderIdFilter}
              onChange={newValue =>
                setParentState({ channelOrderIdFilter: newValue })
              }
              helpText='Not Case Sensitive, Starts With'
              onEnterKeyPress={() => {
                setParentState({ isInitialLoading: false });
                getOrders(0);
              }}
            />
            <span
              className="input-close-icon"
              onClick={() => setParentState({ channelOrderIdFilter: '' })}
              style={{float: "right", right: "5px", top: "-75px"}}
            >
              &times;
            </span>
          </div>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col style={{height: "111px"}}>
          <div className="mr-1 input-with-clear">
            <TextField
              label='Channel Order Number'
              placeholder='Channel Order Number'
              value={channelOrderNumberFilter}
              onChange={newValue =>
                setParentState({ channelOrderNumberFilter: newValue })
              }
              helpText='Not Case Sensitive, Starts With'
              onEnterKeyPress={() => {
                setParentState({ isInitialLoading: false });
                getOrders(0);
              }}
            />
            <span
              className="input-close-icon"
              onClick={() => setParentState({ channelOrderNumberFilter: '' })}
              style={{float: "right", right: "5px", top: "-75px"}}
            >
              &times;
            </span>
          </div>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col style={{height: "111px"}}>
          <div className="mr-1 input-with-clear">
            <TextField
              label='Customer Name'
              placeholder='Customer Name'
              value={customerNameFileter}
              onChange={newValue =>
                setParentState({ customerNameFileter: newValue })
              }
              helpText='Not Case Sensitive, Contains'
              onEnterKeyPress={() => {
                setParentState({ isInitialLoading: false });
                getOrders(0);
              }}
            />
            <span
              className="input-close-icon"
              onClick={() => setParentState({ customerNameFileter: '' })}
              style={{float: "right", right: "5px", top: "-75px"}}
            >
              &times;
            </span>
          </div>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col style={{height: "111px"}}>
          <div className="mr-1 input-with-clear">
            <TextField
              label='Customer email'
              placeholder='Customer email'
              value={customerEmailFilter}
              onChange={newValue =>
                setParentState({ customerEmailFilter: newValue })
              }
              helpText='Not Case Sensitive, Contains'
              onEnterKeyPress={() => {
                setParentState({ isInitialLoading: false });
                getOrders(0);
              }}
            />
            <span
              className="input-close-icon"
              onClick={() => setParentState({ customerEmailFilter: '' })}
              style={{float: "right", right: "5px", top: "-75px"}}
            >
              &times;
            </span>
          </div>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <FormLabel style={{color: 'black'}}>Start Date</FormLabel>
        </Col>
      </Row>
      <Row>
        <Col style={{height: "61px"}}>
          <div className="input-with-clear">
            <DatePicker
              value={startDate}
              onChange={changeStartDate}
              inputPlaceholder="Start Date"
              renderInput={renderStartDateInput}
              maximumDate={endDate}
              calendarPopperPosition="top"
              wrapperClassName="datepicker-wrapper"
              shouldHighlightWeekends
            />
            <span className="input-close-icon date-input" onClick={() => changeStartDate(undefined)} title="Remove vendor">&times;</span>
          </div>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <FormLabel style={{color: 'black'}}>End Date</FormLabel>
        </Col>
      </Row>
      <Row>
        <Col style={{height: "61px"}}>
          <div className="input-with-clear">
            <DatePicker
              value={endDate}
              onChange={changeEndDate}
              inputPlaceholder="End Date"
              renderInput={renderEndDateInput}
              minimumDate={startDate}
              calendarPopperPosition="top"
              wrapperClassName="datepicker-wrapper"
              shouldHighlightWeekends
            />
          <span className="input-close-icon date-input" onClick={() => changeEndDate(undefined)} title="Remove vendor">&times;</span>
          </div>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <Select
            label='Order By'
            options={[{ label: 'Ordered At - Desc', value: '' }]}
            value={''}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <Select
            label='Risky'
            options={[
              { label: 'Any', value: undefined },
              { label: 'Risky', value: true },
              { label: 'Not Risky', value: false },
            ]}
            value={riskyFilter}
            onChange={newValue =>
              setParentState({
                riskyFilter: newValue === 'Any' ? undefined : newValue,
              })
            }
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <Select
            label='Order Status'
            options={[
              { label: 'Any', value: undefined },
              { label: 'Created', value: 'Created' },
              { label: 'Cancelled', value: 'Cancelled' },
              { label: 'Not Cancelled', value: 'Not Cancelled' },
              { label: 'Shipped', value: 'Shipped' },
              { label: 'Deleted', value: 'Deleted' },
            ]}
            value={orderStatusFilter}
            onChange={newValue =>
              setParentState({
                orderStatusFilter:
                  newValue === 'Any' ? undefined : newValue,
              })
            }
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <Select
            label='Payment Status'
            options={[
              { label: 'Any', value: undefined },
              { label: 'Pending', value: 'Pending' },
              { label: 'Paid', value: 'Paid' },
              { label: 'Partially Paid', value: 'Partially Paid' },
              { label: 'Refunded', value: 'Refunded' },
              { label: 'Partially Refunded', value: 'Partially Refunded' },
              { label: 'Voided', value: 'Voided' },
            ]}
            value={paymentStatusFilter}
            onChange={newValue =>
              setParentState({
                paymentStatusFilter:
                  newValue === 'Any' ? undefined : newValue,
              })
            }
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <Select
            label='Fulfillment Status'
            options={[
              { label: 'Any', value: undefined },
              { label: 'Fulfilled', value: 'Fulfilled' },
              { label: 'Partially Fulfilled', value: 'Partially Fulfilled' }
            ]}
            value={fulfillmentStatusFilter}
            onChange={newValue =>
              setParentState({
                fulfillmentStatusFilter:
                  newValue === 'Any' ? undefined : newValue,
              })
            }
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <Select
            label='Sync Status'
            options={[
              { label: 'Any', value: undefined },
              { label: 'Created', value: 'Created' },
              { label: 'Succeeded', value: 'Succeeded' },
              { label: 'Failed', value: 'Failed' },
              { label: 'Processed', value: 'Processed' },
              {
                label: 'Partially Processed',
                value: 'Partially Processed',
              },
              { label: 'Cancelled', value: 'Cancelled' },
            ]}
            value={syncStatusFilter}
            onChange={newValue =>
              setParentState({
                syncStatusFilter:
                  newValue === 'Any' ? undefined : newValue,
              })
            }
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <Select
            label='Out of Stock'
            options={[
              { label: 'Any', value: undefined },
              { label: 'Has an Out of Stock Item', value: true },
              { label: 'Has No Out of Stock Items', value: false },
            ]}
            value={outOfStockFilter}
            onChange={newValue =>
              setParentState({
                outOfStockFilter:
                  newValue === 'Any' ? undefined : newValue,
              })
            }
          />
        </Col>
      </Row>
    </SearchFilter>
  );
};

export default OrdersFilters;
