import React, { useState } from "react";
import { Row, Col, ListGroup, Badge } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import MarketplaceProductSearchResult from "../../../../classes/Marketplace/ProductSearchResult";

import SearchResultMenu from "./SearchResultMenu";

import { MarketplaceProductsChildProps } from "../Products";

export interface SearchResultProps extends MarketplaceProductsChildProps {
  product: MarketplaceProductSearchResult;
}

const SearchResult = (props: SearchResultProps) => {
  const { product } = props;
  const [ignored, setIgnore] = useState(
    product.ignored === true ? true : false
  );
  const { marketplaceId } = props.match.params;
  return (
    <LinkContainer to={`/marketplace/${marketplaceId}/products/${product.id}`}>
      <ListGroup.Item action>
        <Row>
          <Col md="3" className="self-align-center text-dark font-weight-bold">
            {product.vendor}
          </Col>
          <Col>{product.title}</Col>
          <Col xs="auto" className="self-align-center align-middle">
            {ignored === true && (
              <Badge pill variant="warning">
                Ignored
              </Badge>
            )}
            {product.archived && (
              <Badge pill variant="danger">
                Archived
              </Badge>
            )}
            {product.error && (
              <Badge pill variant="danger">
                Error
              </Badge>
            )}
            {product.needsReview ? 
              (
                <Badge pill variant="warning">
                  Needs Review
                </Badge>
              ) : (product.published ? 
              (
                <Badge pill variant="success">
                  Published
                </Badge>
              ) : (
                <Badge pill variant="danger">
                  Unpublished
                </Badge>
              ))
            }
          </Col>
          <Col xs="auto" className="h-100 self-align-center align-middle">
            {product.archived &&
              <SearchResultMenu
                {...props}
                ignored={ignored}
                setIgnore={setIgnore}
              />
            }
          </Col>
        </Row>
      </ListGroup.Item>
    </LinkContainer>
  );
};

export default SearchResult;
