import React from 'react';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import LoadingPage from '../../Shared/LoadingPage';
import * as Settings from '../../../Settings';
import RouteVariableProps from '../../../interfaces/RouteVariableProps';
import rest from '../../../utils/Rest';
import errorHandler from '../../../utils/ErrorHandler';
import setMarketplaceFromId from '../../../utils/SetMarketplaceFromId';

import Order from '../../../classes/Marketplace/Order';

import BasicInformation from './Order/BasicInformation';
import CustomerInformation from './Order/CustomerInformation';
import OrderLineItems from './Order/OrderLineItems';

interface MatchParams {
  marketplaceId: string;
  orderId: string;
}

export default class MarketplaceOrder extends React.Component<
  RouteVariableProps<MatchParams>
> {
  state = {
    isLoading: true,
    isSaving: false,
    isEditing: false,
    isSubmitting: false,
    shouldSubmit: false,
    showModal: false,
    updateMessages: [],
    order: new Order(),
  };

  componentDidMount() {
    if (this.props.match.params.marketplaceId !== undefined) {
      this.getOrder();
    }
  }

  getOrder = async () => {
    const { marketplaceId, orderId } = this.props.match.params;
    const { apiKey, selectedMarketplace, setMarketplace, user } = this.props;

    if (selectedMarketplace === undefined) {
      await setMarketplaceFromId(
        marketplaceId,
        setMarketplace,
        user,
        this.props.history,
      );
    }

    this.setState({ isLoading: true });
    try {
      let data = await rest.get(
        `${
          Settings.eformedApiBaseUri
        }marketplace/${marketplaceId}/orders/${orderId}?key=${apiKey}`,
      );
      this.setState(
        { isLoading: false, order: data },
        () => this.isOrderShouldSubmit()
      );
    } catch (error) {
      errorHandler(
        error,
        'There was an error while retrieving the order. Please try again, if this is not the first time you have seen this error, please report the issue.',
        this.props.addAlert,
      );
      this.setState({ isLoading: false });
    }
  };

  isOrderShouldSubmit = () => {
    const { order } = this.state;
    const lineItems = order.lineItems;

    lineItems.forEach(lineItem => {
      const assignedDate = new Date(lineItem.sellerAssignedAt);
      const submittedDate = new Date(lineItem.orderSubmittedAt);

      if (assignedDate >= submittedDate) {
        this.setState({ shouldSubmit: true });
      }
    });
  }

  saveOrder = async () => {
    const { order } = this.state;

    try {
      this.setState({ isSaving: true });
      await rest.put(
        `${
          Settings.eformedApiBaseUri
        }marketplace/${this.props.match.params.marketplaceId}/orders/updateOrder/${order.id}?key=${this.props.apiKey}`,
        order
      );
      this.setState({ isSaving: false, isEditing: false });
      this.props.addAlert("success", `Marketplace order was successfully updated.`);
    } catch (error) {
      errorHandler(
        error,
        'There was an error while saving the order. Please try again, if this is not the first time you have seen this error, please report the issue.',
        this.props.addAlert,
      );
      this.setState({ isSaving: false, isEditing: false });
    }
  }

  approveRisk = async () => {
    const { marketplaceId, orderId } = this.props.match.params;
    const { apiKey } = this.props;
    try {
      await rest.put(
        `${
          Settings.eformedApiBaseUri
        }marketplace/${marketplaceId}/orders/${orderId}?key=${apiKey}&riskApproved=true`,
        null,
      );
      await this.getOrder();
    } catch (error) {
      await errorHandler(
        error,
        'There was an error while approving the order. Please try again, if this is not the first time you have seen this error, please report the issue.',
        this.props.addAlert,
      );
    }
  };

  reSyncLineItem = async (lineItemId: string) => {
    const { marketplaceId, orderId } = this.props.match.params;
    const { apiKey } = this.props;
    try {
      await rest.put(
        `${
          Settings.eformedApiBaseUri
        }marketplace/${marketplaceId}/orders/${orderId}/lineItems/${lineItemId}?key=${apiKey}&syncStatus=Created`,
        null,
      );
      await this.getOrder();
    } catch (error) {
      await errorHandler(
        error,
        'There was an error while approving the order. Please try again, if this is not the first time you have seen this error, please report the issue.',
        this.props.addAlert,
      );
    }
  };

  fulfillLineItem = async (lineItemId: string) => {
    const { marketplaceId, orderId } = this.props.match.params;
    const { apiKey } = this.props;
    try {
      await rest.put(
        `${
          Settings.eformedApiBaseUri
        }marketplace/${marketplaceId}/orders/${orderId}/lineItems/orderfulfill${lineItemId}?key=${apiKey}`,
        null,
      );
      await this.getOrder();
    } catch (error) {
      await errorHandler(
        error,
        'There was an error while fulfilling the order. Please try again, if this is not the first time you have seen this error, please report the issue.',
        this.props.addAlert,
      );
    }
  }

  reSubmitOrder = async () => {
    const { marketplaceId, orderId } = this.props.match.params;
    const { apiKey } = this.props;   
    this.setState({
      isSubmitting: true,
      showModal: true,
      updateMessages: []
    });
    try {
      let data = await rest.put(
        `${
          Settings.eformedApiBaseUri
        }marketplace/${marketplaceId}/orders/submitOrder/${orderId}?key=${apiKey}`,
        null,
      );
      this.setState({
        isSubmitting: false,
        updateMessages: data,
      });
      await this.getOrder();
    } catch (error) {
      await errorHandler(
        error,
        'There was an error while submitting the order. Please try again, if this is not the first time you have seen this error, please report the issue.',
        this.props.addAlert,
      );
      this.setState({
        isSubmitting: false
      });
    }
  }

  handleOrderChange = (fieldName: string, newValue: any) => {
    let tempOrder = this.state.order;
    tempOrder[fieldName] = newValue;
    this.setState({ order: tempOrder });
  };

  setParentState = stateObject => {
    this.setState(stateObject);
  };

  render() {
    const { marketplaceId } = this.props.match.params;
    const { isLoading, isSaving, isEditing, order, showModal, isSubmitting, updateMessages, shouldSubmit } = this.state;
    if (isLoading) {
      return (
        <React.Fragment>
          <MarketplaceOrderHeader order={order} />
          <LoadingPage />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <MarketplaceOrderHeader order={order} marketplaceId={marketplaceId} />
          <BasicInformation
            order={order}
            saveOrder={this.saveOrder}
            approveRisk={this.approveRisk}
            handleOrderChange={this.handleOrderChange}
            addAlert={this.props.addAlert}
          />
          <CustomerInformation
            order={order}
            isSaving={isSaving}
            isEditing={isEditing}
            handleOrderChange={this.handleOrderChange}
            saveOrder={this.saveOrder}
            setParentState={this.setParentState}
          />
          <OrderLineItems
            apiKey={this.props.apiKey}
            marketplaceId={marketplaceId}
            order={order}
            shouldSubmit={shouldSubmit}
            setParentState={this.setParentState}
            resyncLineItem={this.reSyncLineItem}
            fulfillLineItem={this.fulfillLineItem}
            reSubmitOrder={this.reSubmitOrder}
            addAlert={this.props.addAlert}
          />
          <OrderSubmitModal 
            showModal={showModal}
            isSubmitting={isSubmitting}
            updateMessages={updateMessages}
            setParentState={this.setParentState}
          />
        </React.Fragment>
      );
    }
  }
}

const MarketplaceOrderHeader = props => {
  const { marketplaceId } = props;
  return (
    <Row className='mb-4'>
      <Col>
        <h1>Marketplace Order</h1>
      </Col>
      <Col lg='3' className='text-right'>
        <LinkContainer exact to={`/marketplace/${marketplaceId}/orders`}>
          <Button variant='outline-info'>Back To Orders</Button>
        </LinkContainer>
      </Col>
    </Row>
  );
};

const OrderSubmitModal = (props) => {
  const { setParentState, showModal, isSubmitting, updateMessages } = props;

  return (
    <div role="dialog" aria-modal="true" className="fade modal show" 
      style={{display: showModal ? "block" : "none", backgroundColor: "#00000094"}}
      onClick={() => setParentState({ showModal: false })}
    >
      <div role="document" className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title h4">{isSubmitting === true ? 'Submitting...' : 'Submitted'}</div>
            <button type="button" className="close" onClick={() => setParentState({ showModal: false })}>
              <span aria-hidden="true" >×</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body">
          {isSubmitting === true ? (
            <Row className='text-center'>
              <Col>
                <Spinner animation='border' />
              </Col>
            </Row>
          ) : (
            <React.Fragment>
              <Row>
                <Col>
                  <h6>Log Messages:</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  {updateMessages !== undefined &&
                  updateMessages !== null &&
                  updateMessages.length > 0 ?
                  updateMessages.map((message, index) => (
                    <p key={index} className='text-dark'>
                      {message}
                    </p>
                  )) :
                  <p className='text-dark'>
                    No Log messages to show.
                  </p>
                  }
                </Col>
              </Row>
            </React.Fragment>
          )}
          </div>
        </div>
      </div>
    </div>
  );
};
