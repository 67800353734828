import React, {useState} from 'react';
import { Container, Row, Col, Button, Collapse, Badge, Spinner } from 'react-bootstrap';

import * as Settings from '../../../../../Settings';
import errorHandler from '../../../../../utils/ErrorHandler';
import MarketplaceChannelMarketplaceVariant from '../../../../../classes/Marketplace/MarketplaceChannelMarketplaceVariant';
import MarketplaceChannelMarketplaceProduct from '../../../../../classes/Marketplace/MarketplaceChannelMarketplaceProduct';
import MarketplaceVariant from '../../../../../classes/Marketplace/Variant';
import MarketplaceProduct from '../../../../../classes/Marketplace/Product';

import { CHANNEL_TYPE, CHANNEL_PUBLISH_STATUS } from '../../../../../utils/Constants';
import dateFormat from "../../../../../utils/DateFormat";

import { validateVariantFieldsForWalmartPublish } from './WalmartPublishButton';
import { validateVariantFieldsForShopifyPublish } from './ChannelPublishButton';
import VariantImageThumbnail from '../VariantImageThumbnail';
import { StockLabelBadge } from '../VariantPanel/Variant';
import MarketplaceChannel from '../../../../../classes/Marketplace/Channel';

interface ChannelVariantAreaProps {
  apiKey: string;
  productId: string;
  marketplaceId: string;
  channel: MarketplaceChannel;
  index: number;
  variantIndex: number;
  product: MarketplaceProduct;
  isEditing: boolean;
  thresholdInventory: number;
  variant: MarketplaceVariant;
  productConnection: MarketplaceChannelMarketplaceProduct;
  connection: MarketplaceChannelMarketplaceVariant;
  handleVariantChange: (
    index: number,
    fieldName: string,
    newValue: any,
  ) => void;
  updateChannel: (type: string, variantId: string) => void | null;
  addAlert: any;
}

const ChannelVariantArea = (props: ChannelVariantAreaProps) => {
  const [ showDetails, setShowDetails ] = useState(false);
  const [ showError, setShowError ] = useState(false);
  const [ isDownloading, setIsDownloading ] = useState(false);
  const { 
    apiKey,
    productId,
    marketplaceId,
    product,
    index,
    variantIndex,
    isEditing,
    thresholdInventory,
    channel,
    variant,
    productConnection,
    connection,
    handleVariantChange,
    updateChannel,
    addAlert
  } = props;

  const changePublishableState = async (
    newValue
  ) => {
    if ((channel.channelType === CHANNEL_TYPE.WALMART || channel.channelType === CHANNEL_TYPE.WALMART_MKP) && newValue === true) {
      if (validateVariantFieldsForWalmartPublish(variant, channel, addAlert)) {
        changePublishableStateOnVariantConnection(variant, connection, true, variantIndex, handleVariantChange);
      }
    } else if (channel.channelType === CHANNEL_TYPE.SHOPIFY && newValue === true) {
      if (validateVariantFieldsForShopifyPublish(variant, channel, addAlert)) {
        changePublishableStateOnVariantConnection(variant, connection, true, variantIndex, handleVariantChange);
      }
    } else {
      changePublishableStateOnVariantConnection(variant, connection, newValue, variantIndex, handleVariantChange);
    }
  };

  const downloadXML = async () => {
    setIsDownloading(true);
    let uri = `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/WalmartFeed/${channel.id}/download/${variant.id}?key=${apiKey}`;

    try {
      await fetch(uri).then(response => {
        if (!response.ok) {
          throw new Error("File not found.")
        }
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = 'variant_feed.xml';
          a.click();
        })
        .catch((error) => {
          console.log("error: ", error.message);
        });
        // window.location.href = response.url;
      });
    } catch (error) {
      errorHandler(
        error,
        'For the selected variant, a data file does not available.',
        addAlert,
      );
    }
    setIsDownloading(false);
  }

  const classNameForAssociation = variant.sellerVariants !== undefined && variant.sellerVariants !== null &&
    variant.sellerVariants.length > 0 ? "" : "-unassociation";

  return (
    <Container className="mt-2 product-channel-variant-row">
      <Row className="p-1" style={{background: "#eceeef"}} onClick={() => setShowDetails(!showDetails)}>
        <Col md="1">
          <label className="switch">
            <input 
              type="checkbox"
              checked={connection.isPublishable}
              onChange={e => changePublishableState(e.target.checked)}
              disabled={!isEditing}
            />
            <span className={"slider slider-variant" + classNameForAssociation}></span>
          </label>
        </Col>
        <Col>
          <VariantImageThumbnail 
            marketplaceId={marketplaceId}
            variant={variant}
            product={product}
            productId={productId}
          />
          {variant.title}
        </Col>
        {productConnection.active === true && connection.isPublishable === true && <React.Fragment>

        {(channel.channelType === CHANNEL_TYPE.SHOPIFY || channel.channelType === CHANNEL_TYPE.WALMART_MKP) &&
          <Col xs="auto" className="p-0">
            <Button
              className='btn-block'
              size='sm'
              variant='outline-info'
              key={index}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                updateChannel('price', variant.id)
              }}
            >
              Push Price
            </Button>
          </Col>
        }
        <Col xs="auto" className="p-0">
          <Button
            className='btn-block'
            size='sm'
            variant='outline-info'
            key={index}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              updateChannel('inventory', variant.id)
            }}
          >
            Push Inventory
          </Button>
        </Col>
        {(channel.channelType === CHANNEL_TYPE.WALMART || channel.channelType === CHANNEL_TYPE.WALMART_MKP) && connection.externalId !== undefined &&
        connection.externalId !== null && connection.externalId !== '' &&
          <React.Fragment>
            <Col xs="auto" className="p-0">
              {channel.channelType === CHANNEL_TYPE.WALMART ?
              <Button
                className='btn-block'
                size='sm'
                variant='outline-primary'
                onClick={()=> window.open(`https://${channel.host}/editItem/${variant.barcodeFormatted}?idType=GTIN&readonly=false&isSetup=false&product_id=${connection.externalId}`, "_blank")}
              >
                Admin
              </Button> :
              <Button
                className='btn-block'
                size='sm'
                variant='outline-primary'
                onClick={()=> window.open(`https://${channel.host}/items-and-inventory/manage-items?searchQuery=${variant.sku}`, "_blank")}
              >
                Admin
              </Button>
              }
            </Col>
            <Col xs="auto" className="p-0">
              <Button
                className='btn-block'
                size='sm'
                variant='outline-success'
                onClick={()=> window.open(`${channel.storefrontUrl}/ip/${product.title.replace(/\s/g, "-").toLowerCase()}/${connection.externalId}`, "_blank")}
              >
                View On Site
              </Button>
            </Col>
          </React.Fragment>
        }
        </React.Fragment>}
        <Col style={{textAlign: "right"}} md="4">
          {connection.publishStatus !== undefined && connection.publishStatus !== null && connection.publishStatus !== "" &&
            <Badge pill variant={connection.publishStatus === CHANNEL_PUBLISH_STATUS.PUBLISHED ? 'success' : 'warning'}>
              {connection.publishStatus}
            </Badge>
          }
          <StockLabelBadge variant={variant} threshold={thresholdInventory}/>
          {connection.error === true &&
            <Badge pill variant='danger'>
              Sync Error
            </Badge>
          }
        </Col>
      </Row>
      <Collapse in={showDetails}>
        <Container className='my-2'>
          <Row>
            <Col>External ID:</Col>
            <Col>{connection.externalId}</Col>
            <Col>Updated At:</Col>
            <Col>{dateFormat(connection.updatedAt, true)}</Col>
          </Row>
          <Row>
            <Col>Item Status Checked At:</Col>
            <Col>{dateFormat(connection.itemStatusCheckedAt, true)}</Col>
            <Col md="3">Synced At:</Col>
            <Col md="3">{dateFormat(connection.syncedAt, true)}</Col>
          </Row>
          <hr />
          <Row>
            <Col>Inventory Updated At:</Col>
            <Col>{dateFormat(connection.inventoryUpdatedAt, true)}</Col>
            <Col>Price Updated At:</Col>
            <Col>{dateFormat(connection.priceUpdatedAt, true)}</Col>
          </Row>
          <Row>
            <Col>Inventory Synced At:</Col>
            <Col>{dateFormat(connection.inventorySyncedAt, true)}</Col>
            <Col>Price Synced At:</Col>
            <Col>{dateFormat(connection.priceSyncedAt, true)}</Col>
          </Row>
          {connection.externalId !== undefined && connection.externalId !== null && connection.externalId.trim() !== "" &&
            <Row className='mt-2'>
              <Col className="align-text-top align-self-top text-right">
                <Button
                  size="sm"
                  disabled={isDownloading}
                  onClick={downloadXML}
                >
                  {!isDownloading ? 
                    "XML Download" :
                    <span>
                      <Spinner size='sm' animation='border' />
                      Downloading...
                    </span>
                  }
                </Button>
              </Col>
            </Row>
          }
          {connection.error === true &&
            <Row className='mt-2'>
              <Col style={{textAlign: "right"}}>
                <Button size='sm' variant='outline-danger' onClick={() => setShowError(!showError)}>
                  See Error
                </Button>
              </Col>
            </Row>
          }
          <Collapse in={showError}>
            <Row className='mt-2'>       
              <Col>
                <ul>
                  {connection.errorMessages !== undefined &&
                  connection.errorMessages.length > 0 ? (
                    connection.errorMessages.map((errorMessage, index) => (
                      <li key={index}>{errorMessage}</li>
                    ))
                  ) : (
                    <li>No error messages present</li>
                  )}
                </ul>
              </Col>
            </Row>
          </Collapse>
        </Container>
      </Collapse>
    </Container>
  );
};

export const changePublishableStateOnVariantConnection = async (
  variant: MarketplaceVariant,
  connection: MarketplaceChannelMarketplaceVariant,
  publishState: boolean,
  variantIndex: number,
  handleVariantChange: (
    index: number,
    fieldName: string,
    newValue: any,
  ) => void
) => {
  const marketplaceChannelMarketplaceVariants = variant.marketplaceChannelMarketplaceVariants.map((mcmv) => {
    if (mcmv.marketplaceChannelId !== connection.marketplaceChannelId) {
      return mcmv;
    }
    return {
      ...mcmv,
      'isPublishable': publishState,
    };
  });
  await handleVariantChange(variantIndex, 'marketplaceChannelMarketplaceVariants', marketplaceChannelMarketplaceVariants)
};

export default ChannelVariantArea;
