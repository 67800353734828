import React, { useRef, useEffect } from "react";
import { Popover, Alert, Badge } from "react-bootstrap";
import { Overlay } from 'react-overlays';
import AlertObject from "../../classes/Core/AlertObject";
import { BsFillBellFill } from "react-icons/bs";

interface NotificationPopoverProps {
  show: boolean;
  alerts: Array<AlertObject>;
  showHideNotificationPanel: (value: boolean) => void;
  removeAlert: (index: number) => void;
  removeAllAlerts: () => void;
}

const arrowProps = {
  ref: null,
  style: {
    display: 'none'
  }
}

const NotificationPopover = (props: NotificationPopoverProps) => {
  const triggerRef = useRef(null);
  const containerRef = useRef(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef && containerRef !== null) {
      const cur = containerRef.current;
      if (cur && !cur.contains(event.target as Node)) {
        if (props.show) props.showHideNotificationPanel(false);
      }
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div
      className="flex flex-col items-center"
      ref={containerRef}
    >
      <div ref={triggerRef} onClick={() => props.showHideNotificationPanel(!props.show)}>
        <BsFillBellFill size={24}/>
        <Badge pill variant='warning'>{props.alerts.length}</Badge>
      </div>
      <Overlay
        show={props.show}
        rootClose={true}
        key="bottom"
        onHide={() => props.showHideNotificationPanel(false)}
        placement="bottom"
        container={containerRef}
        target={triggerRef}
        >
          {() => (
          <Popover placement="bottom" id={`popover-positioned-bottom`} style={{maxWidth: '400px', right: '0', top: 'unset', left: 'unset'}} arrowProps={arrowProps}>
            <Popover.Title as="p">
              <span onClick={() => props.removeAllAlerts()}>
                <strong className="clickable-text">Clear all alerts</strong>
              </span>
            </Popover.Title>
            <Popover.Content>
              {props.alerts.length > 0 ? props.alerts.reverse().map((alert, index) => (
                <Alert
                  key={index}
                  variant={alert.variant}
                  dismissible
                  onClose={() => props.removeAlert(index)}
                >
                  {alert.title !== undefined &&
                    alert.title !== null &&
                    alert.title !== "" && (
                      <Alert.Heading>{alert.title}</Alert.Heading>
                    )}
                  {alert.text}
                </Alert>
              )) : 
                <div style={{textAlign: 'center', width: '400px'}}>
                  No alerts to show!
                </div>
              }
            </Popover.Content>
          </Popover>
          )}
      </Overlay>
    </div>
  );
}

export default NotificationPopover;