import React from 'react';

import * as Settings from '../../../../Settings';
import rest from '../../../../utils/Rest';
import errorHandler from '../../../../utils/ErrorHandler';

import DescriptionCleanerTag from '../../../../classes/Marketplace/DescriptionCleanerTag';

import { DescriptionFormatterRouteProps } from './DescriptionFormatter';

interface FormattingTagDeleteModalProps extends DescriptionFormatterRouteProps {
  formatterTag: DescriptionCleanerTag;
  show: boolean;
  deleteFormattingTagModal: (formatterTag: DescriptionCleanerTag, show: boolean) => void;
  setIsDeleting: (bool: boolean) => void;
  handleRemoveFormatterTag: (formatterTagId: string) => void;
}

const FormattingTagDeleteModal = (props: FormattingTagDeleteModalProps) => {
  let marketplaceId = props.match.params.marketplaceId;

  const deleteFormatterTag = async (id: string) => {
    const { apiKey, formatterTag, setIsDeleting, handleRemoveFormatterTag } = props;
    await setIsDeleting(true);
    try {
      if (!formatterTag.isNewField) {
        await rest.delete(
            `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/descriptioncleanertag/${formatterTag.id}?key=${apiKey}`,
        );
      }

      handleRemoveFormatterTag(formatterTag.id);
      await props.addAlert('success', 'Tag successfully deleted.');
    } catch (error) {
      errorHandler(
        error,
        'There was an error while deleting this tag.',
        props.addAlert,
      );
    }
    await setIsDeleting(false);
  };

  const { show, formatterTag, deleteFormattingTagModal } = props;
  return (
    <div role="dialog" aria-modal="true" className="fade modal show" style={{display: show ? "block" : "none", backgroundColor: "#00000094"}} onClick={() => deleteFormattingTagModal(undefined, false)}>
      <div role="document" className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title h4">Are you sure?</div>
            <button type="button" className="close" onClick={() => deleteFormattingTagModal(undefined, false)}>
              <span aria-hidden="true" >×</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body">
            <p className="text-dark">You are deleting formatting tag, {formatterTag !== undefined ? formatterTag.tag : ''}.</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => deleteFormattingTagModal(undefined, false)}>Close</button>
            <button type="button" className="btn btn-danger" onClick={() => {
              deleteFormatterTag(formatterTag.id);
              deleteFormattingTagModal(undefined, false);
            }}>Delete</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormattingTagDeleteModal;
