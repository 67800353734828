export default class PurchaseOrderItem {
  itemNumber: string;
  sellerVariantId: string;
  vendor: string;
  sku: string;
  mpn: string;
  upc: string;
  description: string;
  quantity: number;
  cost: number;
  casePack: number;
}
