import React from 'react';

import * as Settings from '../../../../../Settings';
import rest from '../../../../../utils/Rest';
import errorHandler from '../../../../../utils/ErrorHandler';

import { MarketplaceProductImagesProps } from './Images';

interface ImageDeleteModalProps extends MarketplaceProductImagesProps {
  imageId: string;
  show: boolean;
  deleteImageModal: (imageId: string, show: boolean) => void;
  setIsDeleting: (bool: boolean) => void;
}

const ImageDeleteModal = (props: ImageDeleteModalProps) => {
  const deleteImage = async (id: string) => {
    const { apiKey, product, marketplaceId, setIsDeleting } = props;
    await setIsDeleting(true);
    try {
      await rest.delete(
        `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/products/${
          product.id
        }/images/${id}?key=${apiKey}`,
      );
      let tempProduct = product;
      let tempImages = tempProduct.images.filter(image => image.id !== id);
      tempProduct.images = tempImages;
      props.setParentState({ product: tempProduct });
      await props.addAlert('success', 'Image successfully deleted.');
    } catch (error) {
      errorHandler(
        error,
        'There was an error while deleting this image.',
        props.addAlert,
      );
    }
    await setIsDeleting(false);
  };

  const { show, imageId, deleteImageModal } = props;
  return (
    <div role="dialog" aria-modal="true" className="fade modal show" style={{display: show ? "block" : "none", backgroundColor: "#00000094"}} onClick={() => deleteImageModal(undefined, false)}>
      <div role="document" className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title h4">Are you sure?</div>
            <button type="button" className="close" onClick={() => deleteImageModal(undefined, false)}>
              <span aria-hidden="true" >×</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body">
            <p className="text-dark">You are deleting image {imageId}.</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => deleteImageModal(undefined, false)}>Close</button>
            <button type="button" className="btn btn-danger" onClick={() => {
              deleteImage(imageId);
              deleteImageModal(undefined, false);
            }}>Delete</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageDeleteModal;
