import FamilywiseHome from '../../Familywise/Home';
import AccountingChecklist from '../../Familywise/Accounting/AccountingChecklist';

import CCSBilling from '../../Familywise/CCS/CCSBilling';
import CCSPricing from '../../Familywise/CCS/CCSPricing';

import EDI from '../../Familywise/EDI/EDI';

const FamilywiseRoutes = [
  { exact: true, path: '/familywise', component: FamilywiseHome },
  {
    exact: false,
    path: '/familywise/accounting/checklist',
    component: AccountingChecklist,
  },
  {
    exact: false,
    path: '/familywise/ccs/billing',
    component: CCSBilling,
  },
  {
    exact: false,
    path: '/familywise/ccs/pricing',
    component: CCSPricing,
  },
  {
    exact: false,
    path: '/familywise/edi',
    component: EDI,
  },
];

export default FamilywiseRoutes;
