import React from 'react';
import { Row, Col, Card, Form, Button, Spinner } from 'react-bootstrap';

import { eformedApiBaseUri } from '../../../Settings';
import rest from '../../../utils/Rest';

import BaseProps from '../../../interfaces/BaseProps';
import User from '../../../classes/Core/User';
import Marketplace from '../../../classes/Marketplace/Marketplace';
import Seller from '../../../classes/Seller/Seller';
import MarketplaceSelector from './MarketplaceSelector';
import LogisticsSelector from './LogisticsSelector';
import SellerSelector from './SellerSelector';
import errorHandler from '../../../utils/ErrorHandler';

export interface UserLineProps extends BaseProps {
  users: User[];
  logisticsCompanies: any;
  marketplaces: Marketplace[];
  sellers: Seller[];
  user: User;
  setParentState: (any) => void;
}

export default class UserLine extends React.Component<UserLineProps> {
  state = {
    edit: false,
    saving: false,
  };

  saveUser = async () => {
    this.setState({ saving: true });
    try {
      await rest.put(
        `${eformedApiBaseUri}users/${this.props.user.id}?key=${
          this.props.apiKey
        }`,
        this.props.user,
      );
    } catch (error) {
      errorHandler(
        error,
        'There was an error while saving the user. Please try again, if this is not the first time you have seen this error, please report it.',
        this.props.addAlert,
      );
    }
    this.setState({ saving: false });
  };

  handleChange = (settingName: string, newValue: any) => {
    const user = this.props.user;
    const newUsers = this.props.users.map((userr, iindex) => {
      if (userr.id !== user.id) return userr;
      return { ...userr, [settingName]: newValue };
    });

    this.props.setParentState({ users: newUsers });
  };

  render() {
    return (
      <Row className='mt-1'>
        <Col>
          <Card>
            <Card.Header>
              <Row>
                <Col className='align-self-center'>{this.props.user.email}</Col>
                <Col xs='auto'>
                  <Button
                    size='sm'
                    disabled={this.state.saving}
                    variant={this.state.edit ? 'success' : 'outline-info'}
                    onClick={async () => {
                      if (this.state.edit) {
                        await this.saveUser();
                      }
                      this.setState({ edit: !this.state.edit });
                    }}
                  >
                    {this.state.saving ? (
                      <React.Fragment>
                        <Spinner animation='border' size='sm' />
                        <span>Saving...</span>
                      </React.Fragment>
                    ) : this.state.edit ? (
                      'Save'
                    ) : (
                      'Edit'
                    )}
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col className='text-center'>
                  <Form>
                    <Form.Check
                      label='Admin'
                      disabled
                      checked={this.props.user.admin || false}
                    />
                  </Form>
                </Col>
                <Col className='text-center'>
                  <Form>
                    <Form.Check
                      label='Familywise'
                      disabled={!this.state.edit}
                      checked={this.props.user.babywise || false}
                      onChange={() =>
                        this.handleChange('babywise', !this.props.user.babywise)
                      }
                    />
                  </Form>
                </Col>
                <Col className='text-center'>
                  <Form>
                    <Form.Check
                      label='CCS'
                      disabled={!this.state.edit}
                      checked={this.props.user.ccs || false}
                      onChange={() =>
                        this.handleChange('ccs', !this.props.user.ccs)
                      }
                    />
                  </Form>
                </Col>
                <Col className='text-center'>
                  <Form>
                    <Form.Check
                      label='CCS Admin'
                      disabled={!this.state.edit}
                      checked={this.props.user.ccsAdmin || false}
                      onChange={() =>
                        this.handleChange('ccsAdmin', !this.props.user.ccsAdmin)
                      }
                    />
                  </Form>
                </Col>
                <Col className='text-center'>
                  <Form>
                    <Form.Check
                      label='Seller Admin'
                      disabled={!this.state.edit}
                      checked={this.props.user.sellerAdmin || false}
                      onChange={() =>
                        this.handleChange(
                          'sellerAdmin',
                          !this.props.user.sellerAdmin,
                        )
                      }
                    />
                  </Form>
                </Col>
              </Row>
              <hr />
              <LogisticsSelector {...this.state} {...this.props} />
              <hr />
              <MarketplaceSelector {...this.state} {...this.props} />
              <hr />
              <SellerSelector {...this.state} {...this.props} handleChange={this.handleChange} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}
