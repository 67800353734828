import React from 'react';
import { NavDropdown, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const LogisticsMenu = props => {
  if (
    props.user.logisticsCompanies === undefined ||
    props.user.logisticsCompanies.length === 0
  ) {
    return null;
  }
  return (
    <React.Fragment>
      <NavDropdown
        className='active'
        title={
          props.selectedLogisticsCompany !== undefined
            ? 'Company: ' + props.selectedLogisticsCompany.name
            : 'Please Select Company'
        }
        id='logisticsCompany-dropdown'
      >
        {props.user.logisticsCompanies.map((logisticsCompany, index) => (
          <NavDropdown.Item
            key={index}
            onClick={() => props.setLogisticsCompany(logisticsCompany)}
          >
            {logisticsCompany.name}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
      <LinkContainer to='/logistics/po'>
        <NavDropdown title='Purchase Orders' id='po-dropdown'>
          <LinkContainer exact to='/logistics/po'>
            <NavDropdown.Item>View Purchase Orders</NavDropdown.Item>
          </LinkContainer>
          <LinkContainer to='/logistics/po/drafts'>
            <NavDropdown.Item>Create Purchase Order</NavDropdown.Item>
          </LinkContainer>
          <LinkContainer to='/logistics/po/companies'>
            <NavDropdown.Item>Manage Companies</NavDropdown.Item>
          </LinkContainer>
          <LinkContainer to='/logistics/po/vendors'>
            <NavDropdown.Item>Manage Vendors</NavDropdown.Item>
          </LinkContainer>
          {/* <LinkContainer to='/logistics/po/items'>
            <NavDropdown.Item>Manage Items</NavDropdown.Item>
          </LinkContainer> */}
        </NavDropdown>
      </LinkContainer>
      <LinkContainer to='/logistics/po/productsearch'>
        <Nav.Link>Product Search</Nav.Link>
      </LinkContainer>
    </React.Fragment>
  );
};

export default LogisticsMenu;
