import React, { useState } from "react";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { TextArea } from "../../../Shared/EFBootstrap";

import { eformedApiBaseUri } from "../../../../Settings";

import { ProductBuilderProps } from "./ProductBuilder";
import rest from "../../../../utils/Rest";
import errorHandler from "../../../../utils/ErrorHandler";

const ProductBuilderEntry = (props: ProductBuilderProps) => {
  const [tsvChanges, setTsvChanges] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { marketplaceId } = props.match.params;
  const { apiKey, setResults } = props;

  const submitProductBuilder = async () => {
    await setIsSubmitting(true);
    await setResults(undefined);
    let body = { tsvChanges: tsvChanges };
    try {
      let response = await rest.put(
        `${eformedApiBaseUri}marketplace/${marketplaceId}/productbuild?key=${apiKey}`,
        body
      );
      let data: string[] = await response.json();
      if (data.length === 1 && data[0] === "Some records available with either empty Product title or Seller variant ID. Please correct the data and re-submit.") {
        props.addAlert("danger", "Some records available with either empty Product title or Seller variant ID. Please correct the data and re-submit.");
      } else {
        await setResults(data);
      }
      await setIsSubmitting(false);
      setTsvChanges("");
    } catch (error) {
      await errorHandler(
        error,
        "There was an error while submitting the bulk changes.",
        props.addAlert
      );
      await setIsSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <Row className="mt-5">
        <Col>
          Enter in tab-delimited changes to be made (copied from a spreadsheet)
          in the order: Product Title, Seller Variant ID, Option 1 Value, Option 2 Value, Option 3 
        </Col>
        <Col lg="3" className="text-right">
          <Button
            variant="success"
            disabled={isSubmitting}
            onClick={() => submitProductBuilder()}
          >
            {isSubmitting ? (
              <React.Fragment>
                <Spinner size="sm" animation="border" />
                Submitting...
              </React.Fragment>
            ) : (
              "Submit"
            )}
          </Button>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <TextArea
            label="Bulk Changes (TSV)"
            rows={15}
            placeholder="producttitle  00000000-0000-0000-000000000000 option1value    option2value   option3value"
            value={tsvChanges}
            onChange={newValue => setTsvChanges(newValue)}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ProductBuilderEntry;
