import React from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';

import LoadingPage from '../../../Shared/LoadingPage';

import { PriceSchedulerViewChildProps } from './PriceSchedulerView';

import PriceScheduleResult from './PriceScheduleResult';

const PriceScheduleResults = (props: PriceSchedulerViewChildProps) => {
  const { isLoading, priceSchedules } = props;

  if (isLoading) {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <LoadingPage />
          </Col>
        </Row>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <ListGroup>
              <ListGroup.Item active>
                <Row className='font-weight-bold'>
                  <Col>
                    Discount
                  </Col>
                  <Col>
                    Start
                  </Col>
                  <Col>
                    Stop
                  </Col>
                  <Col>
                    Channel
                  </Col>
                  <Col>
                    Overide
                  </Col>
                  <Col md="1">
                    Action
                  </Col>
                </Row>
              </ListGroup.Item>
              {priceSchedules !== undefined && priceSchedules.length > 0 ? (
                priceSchedules.map((priceSchedule, index) => (
                  <PriceScheduleResult key={index} {...props} priceSchedule={priceSchedule} />
                ))
              ) : (
                <ListGroup.Item>No Price Schedule Found</ListGroup.Item>
              )}
            </ListGroup>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
};

export default PriceScheduleResults;
