import React from 'react';

import * as Settings from '../../../Settings';
import RouteVariableProps from '../../../interfaces/RouteVariableProps';
import errorHandler from '../../../utils/ErrorHandler';
import setMarketplaceFromId from '../../../utils/SetMarketplaceFromId';
import { addUrlParam, removeAllUrlParams } from '../../../utils/SetUrlParameters';

import MarketplaceProductSearchResult from '../../../classes/Marketplace/ProductSearchResult';
import MarketplaceChannel from '../../../classes/Marketplace/Channel';

import ProductsHeader from './Products/ProductsHeader';
import SearchResultsArea from './Products/SearchResultsArea';
import productFunctions from "../Products/Product/Functions";

interface MatchParams {
  marketplaceId: string;
}

interface MarketplaceProductsState {
  isInitialLoading: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  isDownloading: boolean;
  hasMore: boolean;
  hasLoaded: number;
  productId: string;
  productResults: MarketplaceProductSearchResult[];
  channels: MarketplaceChannel[];
  orderByFilter: string;
  ignoredFilter: boolean | string;
  archivedFilter: boolean | string;
  inStockFilter: boolean | string | undefined;
  publishedFilter: string;
  channelFilter: string;
  skuFilter: string;
  barcodeFilter: string;
  mpnFilter: string;
  errorFilter: boolean | string;
  vendorFilter: string;
  titleFilter: string;
  isTitleContainsFilter: boolean | string;
  qualityControlFilter: string;
}

export interface MarketplaceProductsChildProps
  extends RouteVariableProps<MatchParams>,
    MarketplaceProductsState {
  getProducts: (skip: number) => void;
  setParentState: (stateObject: any) => void;
  exportCsv: (skip: number, downloadAll: boolean) => void;
}

export default class MarketplaceProducts extends React.Component<
  RouteVariableProps<MatchParams>,
  MarketplaceProductsState
> {
  state = {
    isInitialLoading: true,
    isLoading: false,
    isLoadingMore: false,
    isDownloading: false,
    hasMore: false,
    hasLoaded: 0,
    productId: '',
    productResults: new Array<MarketplaceProductSearchResult>(),
    channels: new Array<MarketplaceChannel>(),
    orderByFilter: 'updatedatdesc',
    ignoredFilter: false,
    archivedFilter: undefined,
    inStockFilter: undefined,
    publishedFilter: undefined,
    channelFilter: undefined,
    skuFilter: undefined,
    barcodeFilter: undefined,
    mpnFilter: undefined,
    errorFilter: undefined,
    vendorFilter: '',
    titleFilter: '',
    isTitleContainsFilter: false,
    qualityControlFilter: undefined,
  };

  componentDidMount() {
    if (localStorage.getItem("isTitleContainsFilter") !== null) {
      this.setState({ isTitleContainsFilter: localStorage.getItem("isTitleContainsFilter") });
    }
    
    if (this.props.match.params.marketplaceId !== undefined) {
      this.initialSetMarketplace();
      this.getMarketplaceChannels();
      this.getProducts(0);
    }
  }

  initialSetMarketplace = async () => {
    let marketplaceId = this.props.match.params.marketplaceId;
    const { selectedMarketplace, setMarketplace, user } = this.props;
    this.setState({ isInitialLoading: true });

    if (selectedMarketplace === undefined) {
      await setMarketplaceFromId(
        marketplaceId,
        setMarketplace,
        user,
        this.props.history,
      );
    }
  };

  getMarketplaceChannels = async () => {
    await productFunctions.getMarketplaceChannels(
      this.props,
      this.setParentState,
    );
  };

  getProducts = async (skip: number) => {
    const { marketplaceId } = this.props.match.params;
    const { apiKey } = this.props;
    const {
      hasLoaded,
      productResults,
      orderByFilter,
      ignoredFilter,
      archivedFilter,
      inStockFilter,
      publishedFilter,
      channelFilter,
      skuFilter,
      barcodeFilter,
      mpnFilter,
      errorFilter,
      vendorFilter,
      titleFilter,
      isTitleContainsFilter,
      qualityControlFilter,
    } = this.state;
    if (skip === 0) {
      await this.setState({ isLoading: true });
    } else {
      await this.setState({ isLoadingMore: true });
    }

    try {
      if(!this.state.isInitialLoading) {
        removeAllUrlParams(this.props);

        if (orderByFilter !== undefined && orderByFilter !== '' && orderByFilter !== 'Any') {
          addUrlParam(this.props, 'orderBy', orderByFilter.toString());
        }
        if (ignoredFilter !== undefined) {
          addUrlParam(this.props, 'ignored', ignoredFilter.toString());
        }
        if (archivedFilter !== undefined && archivedFilter !== '' && archivedFilter !== 'Any') {
          addUrlParam(this.props, 'archived', archivedFilter);
        }
        if (inStockFilter !== undefined && inStockFilter !== '' && inStockFilter !== 'Any') {
          addUrlParam(this.props, 'inStock', inStockFilter);
        }
        if (publishedFilter !== undefined && publishedFilter !== '' && publishedFilter !== 'Any') {
          addUrlParam(this.props, 'published', publishedFilter);
        }
        if (channelFilter !== undefined && channelFilter !== '' && channelFilter !== 'Any') {
          addUrlParam(this.props, 'channel', channelFilter);
        }
        if (skuFilter !== undefined && skuFilter !== '') {
          addUrlParam(this.props, 'sku', skuFilter);
        }
        if (barcodeFilter !== undefined && barcodeFilter !== '') {
          addUrlParam(this.props, 'barcode', barcodeFilter);
        }
        if (mpnFilter !== undefined && mpnFilter !== '') {
          addUrlParam(this.props, 'mpn', mpnFilter);
        }
        if (errorFilter !== undefined && errorFilter !== '' && errorFilter !== 'Any') {
          addUrlParam(this.props, 'error', errorFilter);
        }
        if (vendorFilter !== undefined && vendorFilter !== '') {
          addUrlParam(this.props, 'vendor', vendorFilter.toLowerCase());
        }
        if (titleFilter !== undefined && titleFilter !== '') {
          addUrlParam(this.props, 'title', titleFilter.toLowerCase());
        }
        if (isTitleContainsFilter !== undefined && isTitleContainsFilter !== null) {
          addUrlParam(this.props, 'isTitleContains', isTitleContainsFilter.toString());
        }
        if (qualityControlFilter !== undefined && qualityControlFilter !== '' && qualityControlFilter !== 'Any') {
          addUrlParam(this.props, 'qualityControl', qualityControlFilter);
        }
      } else {
        let preSearchParams = new URLSearchParams(this.props.location.search);
        if (preSearchParams.get('orderBy') === null) {
          addUrlParam(this.props, 'orderBy', orderByFilter.toString());
        }
        if (preSearchParams.get('ignored') === null) {
          addUrlParam(this.props, 'ignored', ignoredFilter.toString());
        }
      }

      let uri = `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/products/search?key=${apiKey}&skip=${skip}`;
      let searchParams = new URLSearchParams(this.props.location.search);

      searchParams.forEach((value: string, key: string) => {        
        let tempState = {...this.state, [key+'Filter']:value };
        this.setState(tempState);

        uri = uri + `&${key}=${value}`;
      });

      let response = await fetch(uri);
      if (response.status >= 400) {
        throw response;
      }
      let data: MarketplaceProductSearchResult[] = await response.json();

      let tempResults = productResults;
      if (skip === 0) {
        tempResults = new Array<MarketplaceProductSearchResult>();
        await this.setState({ hasLoaded: 0 });
      }
      tempResults.push(...data);

      await this.setState({
        productResults: tempResults,
        hasLoaded: hasLoaded + data.length,
        isLoading: false,
        isLoadingMore: false,
      });

      if (data.length >= 25) {
        await this.setState({ hasMore: true });
      } else {
        await this.setState({ hasMore: false });
      }
    } catch (error) {
      errorHandler(
        error,
        'There was an error retrieving products. Please try again, if this is not the first time you have seen this error, please report it.',
        this.props.addAlert,
      );
      await this.setState({ isLoading: false, isLoadingMore: false });
    }
  };

  exportCsv = async (limitCount: number, downloadAll: boolean = false) => {
    const { marketplaceId } = this.props.match.params;
    const { apiKey } = this.props;
    const {
      orderByFilter,
      ignoredFilter,
      archivedFilter,
      inStockFilter,
      publishedFilter,
      channelFilter,
      skuFilter,
      barcodeFilter,
      mpnFilter,
      errorFilter,
      vendorFilter,
      titleFilter,
      isTitleContainsFilter,
      qualityControlFilter,
    } = this.state;

    try {
      let uri = `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/products/search/download?key=${apiKey}&limitCount=${limitCount}&isallrecords=${downloadAll}`;

      if (orderByFilter !== undefined && orderByFilter !== '' && orderByFilter !== 'Any') {
        uri = uri + `&orderBy=${encodeURIComponent(orderByFilter.toString())}`;
      }
      if (ignoredFilter !== undefined) {
        uri = uri + `&ignored=${encodeURIComponent(ignoredFilter.toString())}`;
      }
      if (archivedFilter !== undefined && archivedFilter !== '' && archivedFilter !== 'Any') {
        uri = uri + `&archived=${encodeURIComponent(archivedFilter)}`;
      }
      if (inStockFilter !== undefined && inStockFilter !== '' && inStockFilter !== 'Any') {
        uri = uri + `&inStock=${encodeURIComponent(inStockFilter)}`;
      }
      if (publishedFilter !== undefined && publishedFilter !== '' && publishedFilter !== 'Any') {
        uri = uri + `&published=${encodeURIComponent(publishedFilter)}`;
      }
      if (channelFilter !== undefined && channelFilter !== '' && channelFilter !== 'Any') {
        uri = uri + `&channel=${encodeURIComponent(channelFilter)}`;
      }
      if (skuFilter !== undefined && skuFilter !== '') {
        uri = uri + `&sku=${encodeURIComponent(skuFilter)}`;
      }
      if (barcodeFilter !== undefined && barcodeFilter !== '') {
        uri = uri + `&barcode=${encodeURIComponent(barcodeFilter)}`;
      }
      if (mpnFilter !== undefined && mpnFilter !== '') {
        uri = uri + `&mpn=${encodeURIComponent(mpnFilter)}`;
      }
      if (errorFilter !== undefined && errorFilter !== 'Any') {
        uri = uri + `&error=${encodeURIComponent(errorFilter)}`;
      }
      if (vendorFilter !== undefined && vendorFilter !== '') {
        uri = uri + `&vendor=${encodeURIComponent(vendorFilter.toLowerCase())}`;
      }
      if (titleFilter !== undefined && titleFilter !== '') {
        uri = uri + `&title=${encodeURIComponent(titleFilter.toLowerCase())}`;
      }
      if (isTitleContainsFilter !== undefined && isTitleContainsFilter !== null) {
        uri = uri + `&isTitleContains=${encodeURIComponent(isTitleContainsFilter.toString())}`;
      }
      if (qualityControlFilter !== undefined && qualityControlFilter !== '' && qualityControlFilter !== 'Any') {
        uri = uri + `&qualityControl=${encodeURIComponent(qualityControlFilter)}`;
      }
      
      if (downloadAll == true) {
        let response = await fetch(uri);
        if (response.status >= 400) {
          throw response;
        }

        this.props.addAlert("success", "When download file is ready, you will be informed through teams, Downloads Channel");
      }
      else {
        await fetch(uri.replace("download", "downloadproducts")).then(response => {
          response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'product_list.csv';
            a.click();
          });
          // window.location.href = response.url;
        });
        
        await fetch(uri).then(response => {
          response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'variant_list.csv';
            a.click();
          });
          // window.location.href = response.url;
        });
        
        await fetch(uri.replace("download", "downloadpricing")).then(response => {
          response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'price_list.csv';
            a.click();
          });
          // window.location.href = response.url;
        });
      }

      this.setState({ isDownloading: false });
    } catch (error) {
      errorHandler(
        error,
        'There was an error while downloading. Please try again, if this is not the first time you have seen this error, please report it.',
        this.props.addAlert,
      );
      await this.setState({ isDownloading: false });
    }
  }

  setParentState = stateObject => {
    this.setState(stateObject);
  };

  render() {
    return (
      <React.Fragment>
        <ProductsHeader
          {...this.state}
          {...this.props}
          getProducts={this.getProducts}
          setParentState={this.setParentState}
          exportCsv={this.exportCsv}
        />
        <SearchResultsArea
          {...this.state}
          {...this.props}
          getProducts={this.getProducts}
          setParentState={this.setParentState}
          exportCsv={this.exportCsv}
        />
      </React.Fragment>
    );
  }
}
