import React, { useState } from "react";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { TextField } from "../../Shared/EFBootstrap";

import addAssociation from "../../../utils/Marketplace/AddAssociation";

import SellerVariantMarketplaceVariant from "../../../classes/Marketplace/SellerVariantMarketplaceVariant";
import errorHandler from "../../../utils/ErrorHandler";

import { SellerVariantCardProps } from "./SellerVariantCard";

const AddSellerVariantAssociation = (props: SellerVariantCardProps) => {
  const [isAssociating, setIsAssociating] = useState(false);
  const [isAssociationComplete, setIsAssociationComplete] = useState(false);
  const [marketplaceVariantId, setMarketplaceVariantId] = useState("");

  const addAssociationWithState = async () => {
    setIsAssociating(true);
    const { marketplaceId, sellerVariant, apiKey } = props;
    let sellerVariantMarketplaceVariant = new SellerVariantMarketplaceVariant(
      sellerVariant.id,
      marketplaceVariantId,
      null
    );
    try {
      await addAssociation(
        apiKey,
        marketplaceId,
        sellerVariantMarketplaceVariant
      );
      await setIsAssociating(false);
      await setIsAssociationComplete(true);
    } catch (error) {
      await errorHandler(
        error,
        "There was an error while associating the seller and marketplace variants.",
        props.addAlert
      );
      await setIsAssociating(false);
    }
  };

  return (
    <Row className="mt-3">
      <Col className="align-self-center">
        <TextField
          label="Marketplace Variant Id"
          value={marketplaceVariantId}
          placeholder={"00000000-0000-0000-0000-000000000000"}
          onChange={newValue => setMarketplaceVariantId(newValue)}
        />
      </Col>
      <Col xs="auto" className="align-self-center text-center">
        <Row>
          <Col>
            <Button
              size="sm"
              variant="outline-success"
              onClick={() => addAssociationWithState()}
            >
              {isAssociating ? (
                <React.Fragment>
                  <Spinner size="sm" animation="border" />
                  Adding...
                </React.Fragment>
              ) : (
                "Add Association"
              )}
            </Button>
          </Col>
        </Row>
        {isAssociationComplete && (
          <Row>
            <Col>Items will show on refresh.</Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default AddSellerVariantAssociation;
