const dateFormat = (
  odateTime: string,
  includeTime: boolean = false,
): string => {
  if (odateTime === undefined || odateTime === null) {
    return '';
  } else {
    let dateTime = new Date(odateTime);
    let year = dateTime.getFullYear();
    let month = dateTime.getMonth() + 1;
    let monthString = padLeft2(month);
    let dayString = padLeft2(dateTime.getDate());

    let dateString = `${monthString}-${dayString}-${year}`;
    if (includeTime === true) {
      let hours = dateTime.getHours();
      let minutes = dateTime.getMinutes();
      return `${dateString} ${padLeft2(hours)}:${padLeft2(minutes)}`;
    } else {
      return dateString;
    }
  }
};

const padLeft2 = (number: number): string => {
  if (number >= 10 || number <= 0) {
    return number.toString();
  } else {
    return `0${number}`;
  }
};

export default dateFormat;
