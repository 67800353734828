import React from 'react';


interface WarningDialogProps {
    showWarningDialog: boolean;
    titleText: string;
    contentText: string;
    cancelButtonText: string;
    confirmButtonText: string;
    onCancel: () => void;
    onConfirm: () => void;
}

const WarningDialog = (props: WarningDialogProps) => {
  const { showWarningDialog, titleText, contentText, cancelButtonText, confirmButtonText, onCancel, onConfirm } = props;
  
  return (
    <div role="dialog" aria-modal="true" className="fade modal show" style={{display: showWarningDialog ? "block" : "none", backgroundColor: "#00000094"}} onClick={() => onCancel()}>
      <div role="document" className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title h4">{titleText}</div>
            <button type="button" className="close" onClick={() => onCancel()}>
              <span aria-hidden="true" >×</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body">
            <p className="text-dark">{contentText}</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => onCancel()}>{cancelButtonText}</button>
            <button type="button" className="btn btn-danger" onClick={() => {
              onConfirm();
            }}>{confirmButtonText}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningDialog;
