import React from 'react';
import { Row, Col, Button, Spinner } from 'react-bootstrap';

import LoadingPage from '../Shared/LoadingPage';
import * as Settings from '../../Settings';
import rest from '../../utils/Rest';
import errorHandler from '../../utils/ErrorHandler';
import readableUtcNow from '../../utils/ReadableUtcNow';

import RouteVariableProps from '../../interfaces/RouteVariableProps';

import SellerLog from '../../classes/Seller/SellerLog';

import SellerLogCard from './SellerLogs/SellerLogCard';
import SellerLogFilters from './SellerLogs/SellerLogFilters';
import Seller from '../../classes/Seller/Seller';

interface MatchParams {
  sellerId: string;
}

export interface SellerLogsState {
  isLoading: boolean;
  isFlatFileProcessing: boolean;
  isLoadingMore: boolean;
  hasMore: boolean;
  loaded: number;
  sellerLogs: SellerLog[];
  dropShipType: string;
  status: string;
  seller: Seller;
}

export default class SellerLogs extends React.Component<
  RouteVariableProps<MatchParams>,
  SellerLogsState
> {
  state = {
    isLoading: false,
    isFlatFileProcessing: false,
    isLoadingMore: false,
    hasMore: false,
    loaded: 0,
    sellerLogs: new Array<SellerLog>(),
    dropShipType: 'any',
    status: 'NotSkipped',
    seller: new Seller("0", "Select Seller...")
  };

  componentDidMount() {
    if (this.props.match.params.sellerId !== undefined) {
      this.resetPage();
    }
  }

  setInitialSeller = async () => {
    const { sellerId } = this.props.match.params;
    let seller = this.props.user.sellers.filter(
      seller => seller.id === sellerId,
    );
    if (seller !== undefined && seller.length > 0) {
      this.props.setSeller(seller[0]);
    }
  };

  resetPage = async () => {
    await this.setInitialSeller();
    await this.setState({
      isLoading: false,
      hasMore: false,
      loaded: 0,
      sellerLogs: new Array<SellerLog>(),
    });
    await this.getSellerDetails();
    await this.getLogs(0);
  };

  getSellerDetails = async () => {
    const { sellerId } = this.props.match.params;
    const { apiKey } = this.props;
    try {
      let data = await rest.get(`${Settings.eformedApiBaseUri}seller/sellers/${sellerId}?key=${apiKey}`);
      this.setState({ seller : data });
    } catch (error) {
      await errorHandler(
        error,
        'There was an error retrieving the Seller details. Please try again, if this is not the first time you have seen this error, please report it.',
        this.props.addAlert,
      );
    }
  }

  getLogs = async (skip: number = 0) => {
    const maxLogsPerPull = 25;
    const { sellerId } = this.props.match.params;
    const { apiKey } = this.props;
    const { loaded, sellerLogs, dropShipType, status } = this.state;
    try {
      if (skip === 0) {
        await this.setState({ isLoading: true });
      } else {
        await this.setState({ isLoadingMore: true });
      }

      let uri = `${
        Settings.eformedApiBaseUri
      }seller/${sellerId}/logs?key=${apiKey}&skip=${skip}`;
      if (
        dropShipType !== undefined &&
        dropShipType !== null &&
        dropShipType !== 'any'
      ) {
        uri = uri + `&dropShipType=${dropShipType}`;
      }
      if (status !== undefined && status !== null && status !== 'any') {
        uri = uri + `&status=${encodeURIComponent(status)}`;
      }
      let data = await rest.get(uri);

      if (data.length === maxLogsPerPull) {
        await this.setState({ hasMore: true, loaded: loaded + data.length });
      } else {
        await this.setState({ hasMore: false, loaded: loaded + data.length });
      }
      let tempSellerLogs = sellerLogs;
      await tempSellerLogs.push(...data);
      await this.setState({
        isLoading: false,
        isLoadingMore: false,
        sellerLogs: tempSellerLogs,
      });
    } catch (error) {
      await errorHandler(
        error,
        'There was an error retrieving the Seller Logs. Please try again, if this is not the first time you have seen this error, please report it.',
        this.props.addAlert,
      );
      await this.setState({
        isLoading: false,
        isLoadingMore: false,
      });
    }
  };

  triggerSellerFlatFileImport = async () => {
    this.setState({ isFlatFileProcessing: true });
    const { sellerId } = this.props.match.params;
    const { apiKey } = this.props;
    try {
      await fetch(`${Settings.eformedApiBaseUri}seller/sellers/${sellerId}/productupdate?key=${apiKey}&dropShipType=Products`);
      this.setState({ isFlatFileProcessing: false });
      this.props.addAlert('success', 'Manually triggering of seller flat file sync was success');
    } catch (error) {
      errorHandler(
        error,
        'There was an error while manually triggering seller flat file sync. Please try again, if this is not the first time you have seen this error, please report it.',
        this.props.addAlert,
      );
      this.setState({ isFlatFileProcessing: false });
    }
  }

  setSellerLogsState = stateObject => {
    this.setState(stateObject);
  };

  render() {
    const { selectedSeller } = this.props;
    const {
      isLoading,
      isFlatFileProcessing,
      isLoadingMore,
      hasMore,
      loaded,
      sellerLogs,
      seller
    } = this.state;
    if (isLoading) {
      return (
        <React.Fragment>
          <Row>
            <Col>
              <h1>Seller Logs - {selectedSeller.name}</h1>
            </Col>
          </Row>
          <LoadingPage />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Row>
            <Col>
              <h1>Seller Logs</h1>
            </Col>
          </Row>
          <Row className='mb-5'>
            <Col>
              Times are in Universal Time, current date and time is:{' '}
              <span className='text-dark'>{readableUtcNow()}</span>
            </Col>
          </Row>
          {seller.mainSyncType === "FlatFile" &&
            <React.Fragment>
              <hr/>
                <Row>
                  <Col md='2' className='mt-3'>
                    Process FTP Flatfile:
                  </Col>
                  <Col>
                    <Button variant='info' onClick={() => this.triggerSellerFlatFileImport()}>
                      {isFlatFileProcessing ? (
                        <React.Fragment>
                          <Spinner size='sm' animation='border' />
                          Processing...
                        </React.Fragment>
                      ) : ('Product')}
                    </Button>
                  </Col>
                </Row>
              <hr/>
            </React.Fragment>
          }
          <SellerLogFilters
            {...this.state}
            setSellerLogsState={this.setSellerLogsState}
            resetPage={this.resetPage}
          />
          {sellerLogs.length > 0 ? (
            sellerLogs.map((sellerLog, index) => (
              <SellerLogCard key={index} index={index} sellerLog={sellerLog} />
            ))
          ) : (
            <Row className='mt-5 text-center'>
              <Col>No Logs Found.</Col>
            </Row>
          )}
          {hasMore === true && (
            <Row className='text-center mt-2'>
              <Col>
                <Button
                  variant='outline-info'
                  onClick={() => this.getLogs(loaded)}
                >
                  {isLoadingMore ? (
                    <React.Fragment>
                      <Spinner size='sm' animation='border' />
                      Loading...
                    </React.Fragment>
                  ) : (
                    'Load More...'
                  )}
                </Button>
              </Col>
            </Row>
          )}
        </React.Fragment>
      );
    }
  }
}
