import React from "react";
import { Row, Col } from "react-bootstrap";

import { TextField } from './EFBootstrap';

interface SearchTextBoxProps {
  searchText: string;
  setSearchText: (text: string) => void;
}

const SearchTextBox = (props: SearchTextBoxProps) => {
  return (
    <Row>
      <Col md="4">
        <div className="mr-1 input-with-clear">
          <TextField
            label=''
            placeholder='Search'
            type='text'
            value={props.searchText}
            onChange={newValue => props.setSearchText(newValue)}
          />
          <span
            className="input-close-icon"
            onClick={() => props.setSearchText('')}
            style={{float: "right", right: "5px", top: "-54px"}}
          >
              &times;
          </span>
        </div>
      </Col>
    </Row>
  );
};

export default SearchTextBox;
