import React from "react";
import { Row, Col } from "react-bootstrap";

import { BulkPricingProps } from "./BulkPricing";

const BulkPricingResults = (props: BulkPricingProps) => {
  const { results } = props;
  return (
    <React.Fragment>
      <Row className="mt-3">
        <Col>
          <h3>Results</h3>
        </Col>
      </Row>
      {/* <Row className="mt-3">
        <Col>
          <h5>Price Changes</h5>
        </Col>
      </Row>
      <Row style={{ color: 'black', fontWeight: 'bold' }}>
        <Col></Col>
        <Col>Cost</Col>
        <Col>MSRP</Col>
        <Col>MAP</Col>
        <Col>Price</Col>
        <Col>Handling</Col>
        <Col>Overide</Col>
      </Row>
      {results !== undefined &&
        results.priceChanges.map((priceChange, index) => (
          <React.Fragment key={index}>
            <Row style={{color: 'black'}}>
              <Col xs='auto'>{priceChange.id}</Col>
              <Col>{priceChange.channelName}</Col>
            </Row>
            <Row>
              <Col></Col>
              <Col>{priceChange.cost}</Col>
              <Col>{priceChange.msrp}</Col>
              <Col>{priceChange.map}</Col>
              <Col>{priceChange.price}</Col>
              <Col>{priceChange.handling}</Col>
              <Col>{priceChange.manualPrice}</Col>
            </Row>
          </React.Fragment>
        ))} */}
      <Row className="mt-3">
        <Col>
          <h5>Messages</h5>
        </Col>
      </Row>
      {results !== undefined &&
        results.messages.map((message, index) => (
          <Row key={index}>
            <Col>{message}</Col>
          </Row>
        ))}
    </React.Fragment>
  );
};

export default BulkPricingResults;
