import React, { useState } from "react";
import { Row, Col, Button, Spinner } from "react-bootstrap";

import { eformedApiBaseUri } from "../../../../Settings";
import rest from "../../../../utils/Rest";
import errorHandler from "../../../../utils/ErrorHandler";

import { UpdateProductsProps } from "./UpdateProducts";
import { TextArea } from "../../../Shared/EFBootstrap";


const UpdateProductsEntry = (props: UpdateProductsProps) => {
  const [tsvChanges, setTsvChanges] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { marketplaceId } = props.match.params;
  const { apiKey, setResults } = props;

  const submitUpdateProducts = async () => {
    await setIsSubmitting(true);
    await setResults(undefined);
    let body = { tsvChanges: tsvChanges };
    try {
      let response = await rest.put(
        `${eformedApiBaseUri}marketplace/${marketplaceId}/products/BulkUpdate?key=${apiKey}`,
        body,
        true
      );
      await setResults(response);
      await setIsSubmitting(false);
      await setTsvChanges("");
    } catch (error) {
      await errorHandler(
        error,
        "There was an error while submitting the variant data.",
        props.addAlert
      );
      await setIsSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <Row className="mt-5">
        <Col>
          Enter in tab-delimited changes to be made (copied from a spreadsheet)
        in the order: <br/>Product Status, Product Ignored, Product ID, Vendor, Product Title, Option 1 Name, Option 2 Name, Option 3 Name, Youtube ID, Description, Feature 1, Feature 2, Feature 3, Feature 4, Feature 5
        </Col>
        <Col lg="3" className="text-right">
          <Button
            variant="success"
            disabled={isSubmitting}
            onClick={() => submitUpdateProducts()}
          >
            {isSubmitting ? (
              <React.Fragment>
                <Spinner size="sm" animation="border" />
                Submitting...
              </React.Fragment>
            ) : (
              "Submit"
            )}
          </Button>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <TextArea
            label="Bulk Changes (TSV)"
            rows={15}
            placeholder="ProductStatus  ProductIgnored ProductID	Vendor  ProductTitle	Option1Name	Option2Name	Option3Name	YoutubeID	Description	Feature1 Feature2	Feature3	Feature4	Feature5"
            style={{whiteSpace: "pre"}}
            value={tsvChanges}
            onChange={newValue => setTsvChanges(newValue)}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UpdateProductsEntry;