const support = {
  name: 'Support',
  variant: 'warning',
  text: 'dark',
  description:
    'This stack contains apps that provide support functionalities like centralization, monitoring, or backups.',
  apps: [
    {
      name: 'App Support',
      link: 'https://github.com/eformedpartners/eformedapps',
      description:
        'This app provides many functionalities ranging from installation of Shopify Apps, to Critical Job Logging/Monitoring, to Availability checking.',
      services: [
        {
          name: 'AppSupport.Storage',
          type: 'Storage',
          variant: 'info',
          description: 'Basic storage for all of the logging and settings.',
          location: 'Azure Storage (Mostly tables) - appsupportpersistent',
          language: 'NoSQL',
          framework: 'Azure Storage',
        },
        {
          name: 'AppSupport.DataProcessing',
          type: 'Back-end',
          variant: 'warning',
          description:
            'All of the support functionalities, mostly scheduled but a few API calls, live here.',
          location: 'Azure Serverless - eformedapps',
          language: 'C#',
          framework: 'Azure Functions',
        },
      ],
    },
    {
      name: 'Shopify App Proxy Tester',
      link: '#',
      description:
        'This app has no physical services or code, but exists inside Shopify in specifically to test Shopify Application Proxy services and will install just like any other app (AppSupport knows about it). Steps: 1. Set up another branch (dev). 2. Deploy branch to dev container. 3. Point this app at the dev container. 4. Test live on any Shopify Shop at the test proxy URL (/apps/eftestproxy at time of writing).',
      services: [],
    },
    {
      name: 'eFormed (Library)',
      link: 'https://github.com/eformedpartners/eformedsharp',
      description:
        'This library is shared amongst all apps and provides shells around various items that all apps need. The way certain items are implemented may want to be examined (things like how credentials are stored) eventually, as it was designed for speedy deployment.',
      services: [
        {
          name: 'eFormed.Cache',
          type: 'Storage',
          variant: 'info',
          description:
            'Basic caching for all apps. This is currently pulled down for simplicity of monitoring the stack, but could be easily put back up.',
          location: 'Azure VM - EFCache',
          language: 'NoSQL',
          framework: 'Redis',
        },
        {
          name: 'eFormed',
          type: 'Back-end',
          variant: 'warning',
          description:
            'Library that is split into ~6 Nuget packages, each named eFormed.[Function].',
          location: 'Nuget',
          language: 'C#',
          framework: 'Class Libraries',
        },
      ],
    },
    {
      name: 'AzureLetsEncrypt',
      link: 'https://github.com/eformedpartners/letsencrypt-webapp-renewer',
      description:
        'This handles the automated renewal of SSL/TLS certificates for all of the other apps. Steps: 1. Make a redirect on the app for /.well-known to acmecodestorage. 2. Set up custom domain in DNS and in app. 3. Change the App Settings "webapps" to the correct app(s). 4. Run the webjob.',
      services: [
        {
          name: 'AzureLetsEncrypt.Storage',
          type: 'Storage',
          variant: 'info',
          description: 'Stores the codes temporarily',
          location: 'Azure Storage Blobs - acmecodestorage',
          language: 'NoSQL',
          framework: 'Azure Storage',
        },
        {
          name: 'AzureLetsEncrypt.WebJobs',
          type: 'Back-end',
          variant: 'warning',
          description: 'Handles the actual renewals.',
          location: 'Azure Web App - azureletsencrypt',
          language: 'C#',
          framework: 'Azure Web Jobs',
        },
        {
          name: 'AzureLetsEncrypt.Scheduler',
          type: 'Back-end',
          variant: 'warning',
          description: 'Handles the scheduling for .WebJob',
          location: 'Azure Serverless - azureletsencryptrenewerscheduler',
          language: 'C#',
          framework: 'Azure Functions',
        },
      ],
    },
    {
      name: 'eFormed BI (Business Intelligence)',
      link: 'https://github.com/eformedpartners/eformedbi',
      description:
        'This handles the procurement and movement of data related to business intelligence dashboards and storage.',
      services: [
        {
          name: 'eFormedBI.Short',
          type: 'Back-end',
          variant: 'warning',
          description:
            'The data processing related to quick API calls to download data.',
          location: 'Azure Serverless - eformedbishort',
          language: 'C#',
          framework: 'Azure Functions',
        },
        {
          name: 'eFormedBI.Long',
          type: 'Back-end',
          variant: 'warning',
          description:
            'The data processing related to longer processes of large dataset downloads or data manipulation.',
          location: 'Azure Web App for Containers - eformedbilong',
          language: 'C#',
          framework: 'Azure Functions',
        },
      ],
    },
  ],
};

export default support;
