import React from "react";
import { Row, Col } from "react-bootstrap";

const ImageUploadResults = (props) => {
  const { results } = props;
  return (
      <React.Fragment>
        <Row className="mt-3">
          <Col>
            <h3>Results</h3>
          </Col>
        </Row>
        {results !== undefined && results.successfullUploads.length !== 0 &&
          <React.Fragment>
            <Row className="mt-3">
              <Col>
                <h5>Success</h5>
              </Col>
            </Row>
            {results.successfullUploads.map((url, index) => (
              <Row key={index}>
                <Col>{url}</Col>
              </Row>
            ))}
          </React.Fragment>
        }
        {results !== undefined && results.failedUploads.length !== 0 &&
          <React.Fragment>
            <Row className="mt-3">
              <Col>
                <h5>Failed</h5>
              </Col>
            </Row>
            {results !== undefined &&
              results.failedUploads.map((url, index) => (
                <Row key={index}>
                  <Col>{url}</Col>
                </Row>
            ))}
          </React.Fragment>
        }
      </React.Fragment>
  );
}

export default ImageUploadResults;