import React from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';

import LoadingPage from '../../../Shared/LoadingPage';

import { MarketplaceProductsChildProps } from '../Products';

import SearchResult from './SearchResult';

const SearchResults = (props: MarketplaceProductsChildProps) => {
  const { isLoading, productResults } = props;

  if (isLoading) {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <LoadingPage />
          </Col>
        </Row>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <ListGroup>
              <ListGroup.Item active>
                <Row>
                  <Col md='3' className='font-weight-bold'>
                    Vendor
                  </Col>
                  <Col>Title</Col>
                </Row>
              </ListGroup.Item>
              {productResults !== undefined && productResults.length > 0 ? (
                productResults.map((product, index) => (
                  <SearchResult key={index} {...props} product={product} />
                ))
              ) : (
                <ListGroup.Item>No Products Found</ListGroup.Item>
              )}
            </ListGroup>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
};

export default SearchResults;
