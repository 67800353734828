import React from 'react';
import { Container, Row, Col, Card, Button} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import * as Settings from '../../../../Settings';
import RouteVariableProps from '../../../../interfaces/RouteVariableProps';
import rest from '../../../../utils/Rest';
import errorHandler from '../../../../utils/ErrorHandler';
import dateFormat from '../../../../utils/DateFormat';

import setMarketplaceFromId from '../../../../utils/SetMarketplaceFromId';

import WalmartFeedModel from "../../../../classes/Marketplace/Walmart/WalmartPartnerFeed";
 
interface MatchParams {
  marketplaceId: string;
  feedId: string;
}
  
interface WalmartFeedProps extends RouteVariableProps<MatchParams> {}

interface WalmartFeedState {
  isLoading: boolean;
  walmartFeed: WalmartFeedModel;
}
  
export interface WalmartFeedChildProps extends WalmartFeedProps, WalmartFeedState {
  getWalmartFeeds: (skip: number) => void;
  setParentState: (stateObject: any) => void;
}

export default class WalmartFeed extends React.Component<WalmartFeedProps, WalmartFeedState> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      walmartFeed: new WalmartFeedModel()
    }
  }

  componentDidMount() {
    if (this.props.match.params.marketplaceId !== undefined) {
      this.initialSetMarketplace();
      this.getWalmartFeed();
    }
  }

  initialSetMarketplace = async () => {
    let marketplaceId = this.props.match.params.marketplaceId;
    const { selectedMarketplace, setMarketplace, user } = this.props;

    if (selectedMarketplace === undefined) {
      await setMarketplaceFromId(
        marketplaceId,
        setMarketplace,
        user,
        this.props.history,
      );
    }
  };

  getWalmartFeed = async () => {
    const { marketplaceId, feedId } = this.props.match.params;
    const { apiKey } = this.props;

    this.setState({ isLoading: true });
    try {
      let data = await rest.get(
        `${
          Settings.eformedApiBaseUri
        }marketplace/${marketplaceId}/walmartfeed/partner/${feedId}?key=${apiKey}`,
      );
      this.setState({ isLoading: false, walmartFeed: data });
    } catch (error) {
      errorHandler(
        error,
        'There was an error while retrieving the walmart feed. Please try again, if this is not the first time you have seen this error, please report the issue.',
        this.props.addAlert,
      );
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { walmartFeed } = this.state;
    const { marketplaceId } = this.props.match.params;

    return(
      <Container>
        <Row className='mb-4'>
          <Col>
            <Row><h1>Walmart Partner Feed: </h1></Row>
            <Row><h3>{walmartFeed.id}</h3></Row>
          </Col>
          <Col lg='3' className='text-right'>
            <LinkContainer exact to={`/marketplace/${marketplaceId}/walmartpartnerfeeds`}>
              <Button variant='outline-info'>Back To Feeds</Button>
            </LinkContainer>
          </Col>
        </Row>
        <Row>
          <Col>
          <Card>
            <Card.Header as='h4'>Feed Primary Info</Card.Header>
            <Card.Body>
              <Row>
                <Col md="3">Marketplace Channel Id:</Col>
                <Col>{walmartFeed.marketplaceChannelId}</Col>
              </Row>
              <Row>
                <Col md="3">Feed Id:</Col>
                <Col>{walmartFeed.feedId}</Col>
              </Row>
              <Row>
                <Col>Feed Type:</Col>
                <Col>{walmartFeed.feedType}</Col>
                <Col>Feed Status:</Col>
                <Col>{walmartFeed.status}</Col>
              </Row>
              <Row>
                <Col>Feed Sumbission Date:</Col>
                <Col>{dateFormat(walmartFeed.feedSubmissionDate, true)}</Col>
                <Col>Last Update Date:</Col>
                <Col>{dateFormat(walmartFeed.lastUpdatedDate, true)}</Col>
              </Row>
              <Row>
                <Col>Items Received:</Col>
                <Col>{walmartFeed.itemsReceived}</Col>
                <Col>Items Succeeded:</Col>
                <Col>{walmartFeed.itemsSucceeded}</Col>
              </Row>
              <Row>
                <Col>Items Failed:</Col>
                <Col>{walmartFeed.itemsFailed}</Col>
                <Col>Items Processing:</Col>
                <Col>{walmartFeed.itemsProcessing}</Col>
              </Row>
              <Row>
                <Col>Created At:</Col>
                <Col>{dateFormat(walmartFeed.createdAt, true)}</Col>
                <Col>Updated AT:</Col>
                <Col>{dateFormat(walmartFeed.lastUpdatedDate, true)}</Col>
              </Row>
            </Card.Body>
          </Card>
          </Col>
        </Row>
        <Row className='mt-4'>
          <h3>Walmart Feed Details</h3>
        </Row>
        {walmartFeed.partnerFeedItemDetails !== undefined && walmartFeed.partnerFeedItemDetails !== null &&
        walmartFeed.partnerFeedItemDetails.length > 0 && 
        walmartFeed.partnerFeedItemDetails.map((feed, index) => (
          <Row>
            <Col className='mt-3' key={index}>
              <Card>
                <Card.Header>{feed.id}</Card.Header>
                <Card.Body>
                  <Row>
                    <Col md="3">Marketplace product ID:</Col>
                    <Col>{feed.marketplaceProductId}</Col>
                  </Row>
                  <Row>
                    <Col md="3">Marketplace channel ID:</Col>
                    <Col>{feed.marketplaceChannelId}</Col>
                  </Row>
                  <Row>
                    <Col md="3">Marketplace variant ID:</Col>
                    <Col>{feed.marketplaceVariantId}</Col>
                  </Row>
                  <Row>
                    <Col md="3">Feed ID:</Col>
                    <Col>{feed.feedId}</Col>
                  </Row>
                  <Row>
                    <Col>Mart ID:</Col>
                    <Col>{feed.martId}</Col>
                    <Col>Mart ID Specifed:</Col>
                    <Col>{feed.martIdSpecified}</Col>
                  </Row>
                  <Row>
                    <Col md="3">Wpid:</Col>
                    <Col>{feed.wpid}</Col>
                  </Row>
                  <Row>
                    <Col>Index:</Col>
                    <Col>{feed.index}</Col>
                    <Col>Index Specified:</Col>
                    <Col>{feed.indexSpecified}</Col>
                  </Row>
                  <Row>
                    <Col md="3">Item ID:</Col>
                    <Col>{feed.itemId}</Col>
                    <Col>Sku:</Col>
                    <Col>{feed.sku}</Col>
                  </Row>
                  <Row>
                    <Col>UPC:</Col>
                    <Col>{feed.upc}</Col>
                    <Col>Gtin:</Col>
                    <Col>{feed.gtin}</Col>
                  </Row>
                  <Row>
                    <Col md="3">Product Name:</Col>
                    <Col>{feed.productName}</Col>
                  </Row>
                  <Row>
                    <Col>Product ID:</Col>
                    <Col>{feed.productId}</Col>
                    <Col>Product ID Type:</Col>
                    <Col>{feed.productIdType}</Col>
                  </Row>
                  <Row>
                    <Col>Amount:</Col>
                    <Col>{feed.amount}</Col>
                    <Col>Currency:</Col>
                    <Col>{feed.currency}</Col>
                  </Row>
                  <Row>
                    <Col md="3">Ingestion Status:</Col>
                    <Col>{feed.ingestionStatus}</Col>
                  </Row>
                  <Row>
                    <Col md="3">Ingestion Errors:</Col>
                    <Col>{feed.ingestionErrors}</Col>
                  </Row>
                  <Row>
                    <Col>Created At:</Col>
                    <Col>{dateFormat(feed.createdAt, true)}</Col>
                    <Col>Updated AT:</Col>
                    <Col>{dateFormat(feed.updatedAt, true)}</Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ))}
      </Container>
    );
  }
}