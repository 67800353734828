import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { MarketplaceProductsChildProps } from "../Products";
import ProductGoTo from '../../../Shared/ProductGoTo';

const ProductsHeader = (props: MarketplaceProductsChildProps) => {
  const { marketplaceId } = props.match.params;
  
  return (
    <React.Fragment>
      <Row>
        <Col>
          <h1>Marketplace Products</h1>
        </Col>
        <Col md="3" className="align-self-center text-right">
          <LinkContainer to={`/marketplace/${marketplaceId}/products/new`}>
            <Button variant="success">Add New Product</Button>
          </LinkContainer>
        </Col>
      </Row>
      <ProductGoTo 
        marketplaceId={marketplaceId}
      />
    </React.Fragment>
  );
};

export default ProductsHeader;
