import * as React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import LoadingPage from "../../../Shared/LoadingPage";

import * as Settings from "../../../../Settings";
import BaseProps from "../../../../interfaces/BaseProps";
import { RouteComponentProps } from "react-router";

import Company from "../../../../classes/Logistics/PurchaseOrderCompany";

const eformedApiBaseUri = Settings.eformedApiBaseUri;

interface ManagePurchaseOrderCompaniesProps
  extends BaseProps,
    RouteComponentProps {}

interface ManagePurchaseOrderCompaniesState {
  isLoading: boolean;
  companies: Array<Company>;
}

export default class ManagePurchaseOrderCompanies extends React.Component<
  ManagePurchaseOrderCompaniesProps,
  ManagePurchaseOrderCompaniesState
> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      companies: []
    };
  }

  componentDidMount() {
    this.getCompanies();
  }

  getCompanies = () => {
    this.setState({ isLoading: true });
    fetch(
      eformedApiBaseUri +
        "po/companies.json?key=" +
        this.props.apiKey +
        "&shop=babywise-life.myshopify.com"
    )
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({ companies: data.companies, isLoading: false });
      });
  };

  render() {
    const { companies } = this.state;

    if (this.state.isLoading) {
      return (
        <React.Fragment>
          <h1>Manage Purchase Order Companies</h1>
          <Row className="text-right">
            <Col>
              <LinkContainer to="/logistics/po/companies/add">
                <Button variant="success">Add A Company</Button>
              </LinkContainer>
            </Col>
          </Row>

          <LoadingPage />
        </React.Fragment>
      );
    } else if (
      companies === undefined ||
      companies === null ||
      companies.length === 0
    ) {
      return (
        <React.Fragment>
          <h1>Manage Purchase Order Companies</h1>
          <Row className="text-right">
            <Col>
              <LinkContainer to="/logistics/po/companies/add">
                <Button variant="success">Add A Company</Button>
              </LinkContainer>
            </Col>
          </Row>
          <Row>
            <Card>No companies found...</Card>
          </Row>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h1>Manage Purchase Order Companies</h1>
          <Row className="text-right">
            <Col>
              <LinkContainer to="/logistics/po/companies/add">
                <Button variant="success">Add A Company</Button>
              </LinkContainer>
            </Col>
          </Row>
          {companies.map((company, index) => (
            <CompanyListItem key={index} company={company} />
          ))}
        </React.Fragment>
      );
    }
  }
}

interface CompanyListItemProps {
  company: Company;
}

class CompanyListItem extends React.Component<CompanyListItemProps> {
  render() {
    return (
      <Row className="mt-2">
        <Col>
          <Card>
            <Card.Header>{this.props.company.name}</Card.Header>
            <Card.Body>
              <p>{this.props.company.emailAddress}</p>
              <p>
                {this.props.company.address} {this.props.company.city},{" "}
                {this.props.company.state}
              </p>
              <p>{this.props.company.phone}</p>
              <LinkContainer
                to={"/logistics/po/companies/" + this.props.company.id}
              >
                <Card.Link>Edit</Card.Link>
              </LinkContainer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}
