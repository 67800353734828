import React from 'react';
import { Row, Col, Button, Badge } from 'react-bootstrap';
import { Select } from '../../Shared/EFBootstrap';

import { SellerCardProps } from './SellerCard';
import MarketplaceSeller from '../../../classes/Marketplace/MarketplaceSeller';

interface MarketplaceSelectorProps extends SellerCardProps {
  isEditing: boolean;
  handleSellerChange: (fieldName: string, newValue: any) => void;
}

const formatOptions = marketplaces => {
  return marketplaces.map((marketplace, index) => {
    return { label: marketplace.name, value: marketplace.id };
  });
};

export default class MarketplaceSelector extends React.Component<
  MarketplaceSelectorProps
> {
  state = {
    addMarketplace:
      this.props.marketplaces.length > 0
        ? formatOptions(this.props.marketplaces)[0].value
        : '',
  };

  handleAdd = (marketplaceId: string) => {
    const { seller, handleSellerChange } = this.props;
    let marketplaceSellers = seller.marketplaceSellers;
    let newMarketplaceSeller = new MarketplaceSeller();
    newMarketplaceSeller.marketplaceId = marketplaceId;
    newMarketplaceSeller.sellerId = seller.id;
    newMarketplaceSeller.sellerName = seller.name;
    marketplaceSellers.push(newMarketplaceSeller);

    handleSellerChange('marketplaceSellers', marketplaceSellers);
  };

  handleRemove = (indexParam: number) => {
    const { seller, handleSellerChange } = this.props;
    let marketplaceSellers = seller.marketplaceSellers.filter(
      (marketplace, iiindex) => indexParam !== iiindex,
    );

    handleSellerChange('marketplaceSellers', marketplaceSellers);
  };

  getMarketplaceName = (marketplaceSeller: MarketplaceSeller) : string => {
    const marketplaces = this.props.marketplaces;
    const filteredMp = marketplaces.filter(m => m.id === marketplaceSeller.marketplaceId);
    
    return filteredMp.length > 0 ? filteredMp[0].name : undefined;
  }

  render() {
    const { isEditing, seller, marketplaces } = this.props;
    const options = formatOptions(marketplaces);
    
    if (isEditing === true) {
      return (
        <React.Fragment>
          <Row className="mt-3">
            <Col xs='auto'>Marketplaces</Col>
            <Col>
              {seller.marketplaceSellers.length > 0 ? (
                seller.marketplaceSellers.map((marketplaceSeller, index) => (
                  <Badge
                    key={index}
                    pill
                    variant='danger'
                    onClick={() => {
                      this.handleRemove(index);
                    }}
                  >
                    {marketplaces.filter(m => m.id === marketplaceSeller.marketplaceId)[0].name} x
                  </Badge>
                ))
              ) : (
                <Badge pill variant='primary'>
                  None
                </Badge>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Select
                label='Marketplace'
                value={this.state.addMarketplace}
                options={options}
                onChange={newValue => {
                  this.setState({ addMarketplace: newValue });
                }}
              />
            </Col>
            <Col className='align-self-center' xs='auto'>
              <Button
                size='sm'
                disabled={this.state.addMarketplace === '0'}
                variant='outline-success'
                onClick={() => this.handleAdd(this.state.addMarketplace)}
              >
                Add
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      );
    } else {
      return (
        <Row className="mt-3">
          <Col xs='auto'>Marketplaces</Col>
          <Col>
            {seller.marketplaceSellers.length > 0 ? (
              seller.marketplaceSellers.map((marketplaceSeller, index) => (
                <Badge key={index} pill variant='primary'>
                  {this.getMarketplaceName(marketplaceSeller)}
                </Badge>
              ))
            ) : (
              <Badge pill variant='primary'>
                None
              </Badge>
            )}
          </Col>
        </Row>
      );
    }
  }
}
