import React from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';

import SellerVariantAssociation from './SellerVariantAssociation';
import AddSellerVariantAssociation from './AddSellerVariantAssociation';

import { SellerVariantCardProps } from './SellerVariantCard';

const SellerVariantAssociations = (props: SellerVariantCardProps) => {
  const { sellerVariant } = props;

  return (
    <Row className='mt-5'>
      <Col>
        <Row>
          <Col>
            <h5>Associations</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <ListGroup>
              <ListGroup.Item active>
                <Row>
                  <Col className='h-100 align-self-center small'>
                    Marketplace Vendor
                  </Col>
                  <Col className='h-100 align-self-center small'>
                    Marketplace Product Title
                  </Col>
                  <Col className='h-100 align-self-center small'>
                    Marketplace Variant Title
                  </Col>
                  <Col xs='2' className='text-center' />
                </Row>
              </ListGroup.Item>
              {sellerVariant.sellerVariantMarketplaceVariants.map(
                (connection, index) => (
                  <SellerVariantAssociation
                    key={index}
                    connection={connection}
                    {...props}
                  />
                ),
              )}
            </ListGroup>
          </Col>
        </Row>
        <AddSellerVariantAssociation {...props} />
      </Col>
    </Row>
  );
};

export default SellerVariantAssociations;
