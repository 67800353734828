import MarketplaceChannel from '../../../../../classes/Marketplace/Channel';
import MarketplaceChannelMarketplaceProduct from '../../../../../classes/Marketplace/MarketplaceChannelMarketplaceProduct';

const handleChannelChange = async (
  channel: MarketplaceChannel,
  publish: boolean,
  state,
  setParentState,
) => {
  const { product } = state;
  let tempProduct = product;
  let connections = tempProduct.marketplaceChannelMarketplaceProducts.filter(
    connection => connection.marketplaceChannelId === channel.id,
  );

  if (connections !== undefined && connections.length > 0) {
    let tempConnections = tempProduct.marketplaceChannelMarketplaceProducts.map(
      (tempConnection, index) => {
        if (tempConnection.marketplaceChannelId !== channel.id) {
          return tempConnection;
        } else {
          return { ...tempConnection, active: publish };
        }
      },
    );
    tempProduct.marketplaceChannelMarketplaceProducts = tempConnections;
  } else {
    let connection = new MarketplaceChannelMarketplaceProduct();
    connection.active = publish;
    connection.marketplaceChannelId = channel.id;
    connection.marketplaceProductId = product.id;
    tempProduct.marketplaceChannelMarketplaceProducts.push(connection);
  }

  setParentState({ product: tempProduct });
};

export default handleChannelChange;
