import CreatePurchaseOrders from '../../Logistics/PurchaseOrders/CreatePurchaseOrders';
import ViewPurchaseOrders from '../../Logistics/PurchaseOrders/ViewPurchaseOrders';
import ManagePurchaseOrderDrafts from '../../Logistics/PurchaseOrders/ManagePODrafts';
import ManagePurchaseOrderCompanies from '../../Logistics/PurchaseOrders/Companies/ManageCompanies';
import AddPurchaseOrderCompany from '../../Logistics/PurchaseOrders/Companies/AddCompany';
import EditPurchaseOrderCompany from '../../Logistics/PurchaseOrders/Companies/EditCompany';
import ManagePurchaseOrderVendors from '../../Logistics/PurchaseOrders/Vendors/ManageVendors';
import EditPurchaseOrderVendor from '../../Logistics/PurchaseOrders/Vendors/EditVendor';
import AddPurchaseOrderVendor from '../../Logistics/PurchaseOrders/Vendors/AddVendor';
import AddPurchaseOrderLineItem from '../../Logistics/PurchaseOrders/Items/AddLineItem';
import SearchPurchaseOrderItems from '../../Logistics/PurchaseOrders/Items/ItemSearch';
import ManagePurchaseOrderItems from '../../Logistics/PurchaseOrders/Items/ManageItems';
import ManagePurchaseOrderItemsBulk from '../../Logistics/PurchaseOrders/Items/ManageItemsBulk';
import ReconcilePurchaseOrder from '../../Logistics/PurchaseOrders/ReconcilePurchaseOrder';
import SearchPoItem from '../../Logistics/PoItems/PoItemSearch';

const LogisticsRoutes = [
  { exact: true, path: '/logistics/po', component: ViewPurchaseOrders },
  {
    exact: false,
    path: '/logistics/po/create/:vendorId?',
    component: CreatePurchaseOrders,
  },
  {
    exact: true,
    path: '/logistics/po/drafts',
    component: ManagePurchaseOrderDrafts,
  },

  {
    exact: true,
    path: '/logistics/po/companies',
    component: ManagePurchaseOrderCompanies,
  },
  {
    exact: true,
    path: '/logistics/po/companies/add',
    component: AddPurchaseOrderCompany,
  },
  {
    exact: true,
    path: '/logistics/po/companies/:id',
    component: EditPurchaseOrderCompany,
  },
  {
    exact: true,
    path: '/logistics/po/vendors',
    component: ManagePurchaseOrderVendors,
  },
  {
    exact: true,
    path: '/logistics/po/vendors/add',
    component: AddPurchaseOrderVendor,
  },
  {
    exact: true,
    path: '/logistics/po/vendors/:id',
    component: EditPurchaseOrderVendor,
  },
  {
    exact: true,
    path: '/logistics/po/additem/:poNumber',
    component: AddPurchaseOrderLineItem,
  },
  {
    exact: true,
    path: '/logistics/po/items/search',
    component: SearchPurchaseOrderItems,
  },
  {
    exact: true,
    path: '/logistics/po/items',
    component: ManagePurchaseOrderItems,
  },
  {
    exact: true,
    path: '/logistics/po/items/bulk',
    component: ManagePurchaseOrderItemsBulk,
  },
  {
    exact: true,
    path: '/logistics/po/reconcile/:poNumber',
    component: ReconcilePurchaseOrder,
  },
  {
    exact: true,
    path: '/logistics/po/productsearch',
    component: SearchPoItem,
  },
];

export default LogisticsRoutes;
