import WalmartInventoryFeedDetails from './WalmartInventoryFeedDetails';
import WalmartPriceFeedDetails from './WalmartPriceFeedDetails';

export default class WalmartFeed {
  id: string;
  marketplaceId: string;
  marketplaceChannelId: string;
  feedId: string;
  feedType: number;

  createdAt: string;
  updatedAt: string;
  lastErrorMessage: string;

  inventoryFeedDetails: WalmartInventoryFeedDetails[];
  priceFeedDetails: WalmartPriceFeedDetails[];
}

export const Feed_Type = {
  INVENTORY: 0,
  PRICE: 1
}
  