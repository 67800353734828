import React from "react";

import Variant from '../../../../../classes/Marketplace/Variant';
import { ChannelPricingProps } from './ChannelPricing';
import productFunctions from '../Functions';

export interface VariantPricingProps extends ChannelPricingProps {
  index: number;
  variant: Variant;
}

const VariantPricing = (props: VariantPricingProps) => {
  const {
    channel,
    isEditing,
    index,
    variant,
    handleVariantChange
  } = props;

  let marketplaceChannelMarketplaceVariant = variant.marketplaceChannelMarketplaceVariants !== undefined && 
    variant.marketplaceChannelMarketplaceVariants.filter(c => c.marketplaceChannelId === channel.id)[0];  
  
  let sellers = variant.sellerVariants !== undefined && variant.sellerVariants !== null && variant.sellerVariants.length > 0 &&
     variant.sellerVariants.map(sv => sv.seller);

  let finalPrice: number = 0;
  let inValue: number = 0;
  let floorValue: number = 0;
  
  let discountManualPriceState = false;
  if (marketplaceChannelMarketplaceVariant !== undefined) {
    finalPrice = +productFunctions.getVariantChannelFinalPrice(marketplaceChannelMarketplaceVariant, channel, sellers);
    inValue = productFunctions.getVariantInValue(marketplaceChannelMarketplaceVariant, sellers);
    floorValue = productFunctions.getVariantFloorValue(marketplaceChannelMarketplaceVariant, channel, sellers);

    const startDate = new Date(marketplaceChannelMarketplaceVariant.discountStartDateTz);
    const endDate = new Date(marketplaceChannelMarketplaceVariant.discountEndDateTz);
    const now = new Date(marketplaceChannelMarketplaceVariant.currentDateTz);

    if (startDate < now && endDate > now) {
      discountManualPriceState = true;
    }
  }

  const handleChannelPricingChange = async (
    fieldName,
    newValue
  ) => {
    // validate for negative input
    newValue = newValue && Math.abs(newValue) >= 0 ? Math.abs(newValue) : null;

    const channelPricing = variant.marketplaceChannelMarketplaceVariants.map((variant) => {
      if (variant.marketplaceChannelId !== channel.id) {
        return variant;
      }
      return {
        ...variant,
        [fieldName]: newValue,
      };
    });
    handleVariantChange(index, 'marketplaceChannelMarketplaceVariants', channelPricing)
  };

  if (marketplaceChannelMarketplaceVariant === undefined) {
    return (
      <tr></tr>
    )
  } else {
    return (
      <tr>
        <td>{variant.title}</td>
        <td>
          <input
            type='number'
            step={0.01}
            min={0}
            disabled={!isEditing}
            placeholder="Cost"
            value={marketplaceChannelMarketplaceVariant.cost !== null ? marketplaceChannelMarketplaceVariant.cost : ''}
            style={{ background: "white", border: "none" }}
            onChange={event => handleChannelPricingChange('cost', event.target.value)}
          />
        </td>
        <td>
          {inValue}
        </td>
        <td>
          <input
            type='number'
            step={0.01}
            min={0}
            disabled={!isEditing}
            placeholder="Out"
            value={marketplaceChannelMarketplaceVariant.outValue !== null ? marketplaceChannelMarketplaceVariant.outValue : ''}
            style={{ background: "white", border: "none" }}
            onChange={event => handleChannelPricingChange('outValue', event.target.value)}
          />
        </td>
        <td>
          <input
            type='number'
            step={0.01}
            min={0}
            disabled={!isEditing}
            placeholder="MSRP"
            value={marketplaceChannelMarketplaceVariant.compareAtPrice !== null ? marketplaceChannelMarketplaceVariant.compareAtPrice : ''}
            style={{ background: "white", border: "none" }}
            onChange={event => handleChannelPricingChange('compareAtPrice', event.target.value)}
          />
        </td>
        <td>
          <input
            type='number'
            step={0.01}
            min={0}
            disabled={!isEditing}
            placeholder="MAP"
            value={marketplaceChannelMarketplaceVariant.map !== null ? marketplaceChannelMarketplaceVariant.map : ''}
            style={{ background: "white", border: "none" }}
            onChange={event => handleChannelPricingChange('map', event.target.value)}
          />
        </td>
        {channel.isWholeChannel !== true && <td>
          <input
            type='number'
            step={0.01}
            min={0}
            disabled={!isEditing}
            placeholder="Price"
            value={marketplaceChannelMarketplaceVariant.price !== null ? marketplaceChannelMarketplaceVariant.price : ''}
            style={{ background: "white", border: "none" }}
            onChange={event => handleChannelPricingChange('price', event.target.value)}
          />
        </td>}
        <td>
          <input
            type='number'
            step={0.01}
            min={0}
            disabled={!isEditing}
            placeholder="Handling"
            value={marketplaceChannelMarketplaceVariant.variantHandlingCharge !== null ? marketplaceChannelMarketplaceVariant.variantHandlingCharge : ''}
            style={{ background: "white", border: "none" }}
            onChange={event => handleChannelPricingChange('variantHandlingCharge', event.target.value)}
          />
        </td>
        {discountManualPriceState ? 
          <td className='font-weight-bold' style={{color: "red"}}>
            {marketplaceChannelMarketplaceVariant.discountManualPrice}
          </td> :
          <td>
            <input
              type='number'
              step={0.01}
              min={0}
              disabled={!isEditing}
              placeholder="Override"
              value={marketplaceChannelMarketplaceVariant.manualPrice !== null ? marketplaceChannelMarketplaceVariant.manualPrice : ''}
              style={{ background: "white", border: "none" }}
              onChange={event => handleChannelPricingChange('manualPrice', event.target.value)}
            />
          </td>
        }
        <td>
          {floorValue}
        </td>
        <td>
          {finalPrice}
        </td>
      </tr>
    );
  }
};

export default VariantPricing;
