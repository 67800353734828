import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import RoutingProps from "../../../interfaces/RoutingProps";

const MarketplaceMenu = (props: RoutingProps) => {
  if (
    props.user.marketplaces === undefined ||
    props.user.marketplaces.length === 0
  ) {
    return null;
  }
  return (
    <React.Fragment>
      <NavDropdown
        title={
          props.selectedMarketplace !== undefined
            ? "Marketplace: " + props.selectedMarketplace.name
            : "Please Select Marketplace"
        }
        className="active"
        id="marketplace-dropdown"
      >
        {props.user.marketplaces.map((marketplace, index) => (
          <NavDropdown.Item
            key={index}
            onClick={() => {
              props.setMarketplace(marketplace);
              props.history.push(`/marketplace/${marketplace.id}`);
            }}
          >
            {marketplace.name}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
      <LinkContainer
        exact
        to={`/marketplace/${
          props.selectedMarketplace === undefined
            ? props.user.marketplaces[0].id
            : props.selectedMarketplace.id
        }/`}
      >
        <Nav.Link>Help</Nav.Link>
      </LinkContainer>
      <LinkContainer
        to={`/marketplace/${
          props.selectedMarketplace === undefined
            ? props.user.marketplaces[0].id
            : props.selectedMarketplace.id
        }/orders`}
      >
        <Nav.Link>Orders</Nav.Link>
      </LinkContainer>
      <LinkContainer
        to={`/marketplace/${
          props.selectedMarketplace === undefined
            ? props.user.marketplaces[0].id
            : props.selectedMarketplace.id
        }/products`}
      >
        <Nav.Link>Products</Nav.Link>
      </LinkContainer>
      <LinkContainer
        to={`/marketplace/${
          props.selectedMarketplace === undefined
            ? props.user.marketplaces[0].id
            : props.selectedMarketplace.id
        }/sellers`}
      >
        <Nav.Link>Sellers</Nav.Link>
      </LinkContainer>

      <NavDropdown title="Walmart Feeds" id="Feeds-dropdown">
        <LinkContainer
          to={`/marketplace/${
            props.selectedMarketplace === undefined
              ? props.user.marketplaces[0].id
              : props.selectedMarketplace.id
          }/walmartinventoryfeeds`}
        >
          <NavDropdown.Item>Inventory Feeds</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer
          to={`/marketplace/${
            props.selectedMarketplace === undefined
              ? props.user.marketplaces[0].id
              : props.selectedMarketplace.id
          }/walmartpricefeeds`}
        >
          <NavDropdown.Item>Price Feeds</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer
          to={`/marketplace/${
            props.selectedMarketplace === undefined
              ? props.user.marketplaces[0].id
              : props.selectedMarketplace.id
          }/walmartpartnerfeeds`}
        >
          <NavDropdown.Item>Partner Feeds</NavDropdown.Item>
        </LinkContainer>
      </NavDropdown>

        <NavDropdown title="Tools" id="tools-dropdown">
          <NavDropdown
            title="Product"
            id="tools-sub-dropdown"
            bsPrefix="navbar-dropdown-submenu"
          >
            <LinkContainer
              to={`/marketplace/${
                props.selectedMarketplace === undefined
                  ? props.user.marketplaces[0].id
                  : props.selectedMarketplace.id
              }/tools/productbuilder`}
            >
              <NavDropdown.Item>Product Builder</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer
              to={`/marketplace/${
                props.selectedMarketplace === undefined
                  ? props.user.marketplaces[0].id
                  : props.selectedMarketplace.id
              }/tools/updateproducts`}
            >
              <NavDropdown.Item>Update Products</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer
              to={`/marketplace/${
                props.selectedMarketplace === undefined
                  ? props.user.marketplaces[0].id
                  : props.selectedMarketplace.id
              }/tools/updatevariants`}
            >
              <NavDropdown.Item>Update Variants</NavDropdown.Item>
            </LinkContainer>
          </NavDropdown>

          <NavDropdown
            title="Prices"
            id="tools-sub-dropdown"
            bsPrefix="navbar-dropdown-submenu"
          >
            <LinkContainer
              to={`/marketplace/${
                props.selectedMarketplace === undefined
                  ? props.user.marketplaces[0].id
                  : props.selectedMarketplace.id
              }/tools/bulkpricing`}
            >
              <NavDropdown.Item>Bulk Pricing</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer
              to={`/marketplace/${
                props.selectedMarketplace === undefined
                  ? props.user.marketplaces[0].id
                  : props.selectedMarketplace.id
              }/tools/pricescheduler`}
            >
              <NavDropdown.Item>Price Scheduler</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer
              to={`/marketplace/${
                props.selectedMarketplace === undefined
                  ? props.user.marketplaces[0].id
                  : props.selectedMarketplace.id
              }/tools/priceSchedulerView`}
            >
              <NavDropdown.Item>Price Scheduler View</NavDropdown.Item>
            </LinkContainer>
          </NavDropdown>

          
          <LinkContainer
            to={`/marketplace/${
              props.selectedMarketplace === undefined
                ? props.user.marketplaces[0].id
                : props.selectedMarketplace.id
            }/tools/marketplacechannels`}
          >
            <NavDropdown.Item>Marketplace Channels</NavDropdown.Item>
          </LinkContainer>
          <NavDropdown
            title="Utilities"
            id="tools-sub-dropdown"
            bsPrefix="navbar-dropdown-submenu"
          >
            <LinkContainer
              to={`/marketplace/${
                props.selectedMarketplace === undefined
                  ? props.user.marketplaces[0].id
                  : props.selectedMarketplace.id
              }/tools/preferredvendors`}
            >
              <NavDropdown.Item>Preferred Vendor Names</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer
              to={`/marketplace/${
                props.selectedMarketplace === undefined
                  ? props.user.marketplaces[0].id
                  : props.selectedMarketplace.id
              }/tools/metafields`}
            >
              <NavDropdown.Item>Metafields</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer
              to={`/marketplace/${
                props.selectedMarketplace === undefined
                  ? props.user.marketplaces[0].id
                  : props.selectedMarketplace.id
              }/tools/taxcodes`}
            >
              <NavDropdown.Item>Tax Codes</NavDropdown.Item>
            </LinkContainer>
            <LinkContainer
              to={`/marketplace/${
                props.selectedMarketplace === undefined
                  ? props.user.marketplaces[0].id
                  : props.selectedMarketplace.id
              }/tools/descriptionFormatter`}
            >
              <NavDropdown.Item>Description Cleaner Tags</NavDropdown.Item>
            </LinkContainer>
          </NavDropdown>
        </NavDropdown>

    </React.Fragment>
  );
};

export default MarketplaceMenu;
