import React from 'react';
import { Row, Col } from 'react-bootstrap';

const MarketplaceOrdersHeader = () => {
  return (
    <React.Fragment>
      <Row className='mb-4'>
        <Col>
          <h1>Marketplace Orders</h1>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default MarketplaceOrdersHeader;
