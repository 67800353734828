export default class PurchaseOrder {
  constructor(Shop: string) {
    this.shop = Shop;
  }

  shop: string;
  id: number;
  companyId: number;
  shipName: string;
  shipEmailAddress: string;
  shipAddress: string;
  shipPhone: string;
  shipCity: string;
  shipState: string;
  shipZip: string;
  shipCountry: string;
  vendorId: number;
  vendorCompany: string;
  vendorEmailAddress: string;
  vendorPhone: string;
  vendorAddress: string;
  vendorCity: string;
  vendorState: string;
  vendorZip: string;
  vendorCountry: string;
  vendorFreightTerms: string;
  vendorPaymentTerms: string;
  poDate: string;
  shipByDate: string;
  notes: string;
  poLevelDiscount: number;
  createdAt: string;
  modifiedAt: string;
  closed: boolean;
}
