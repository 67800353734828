export default class AlertObject {
  constructor(
    Variant:
      | 'primary'
      | 'secondary'
      | 'success'
      | 'danger'
      | 'warning'
      | 'info'
      | 'dark'
      | 'light',

    Text: string,
    Title: string = undefined,
  ) {
    this.variant = Variant;
    this.title = Title;
    this.text = Text;
    this.show = true;
    this.timestamp = new Date().getTime();
  }

  variant:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light';
  title: string;
  text: string;
  show: boolean;
  timestamp: number;
}
