import React from 'react';
import { Row, Col, Card, Collapse, Badge } from 'react-bootstrap';
import { MdDragHandle as DragHanlde } from 'react-icons/md';

import { INVENTORY_POLICY } from '../../../../../utils/Constants';
import VariantModel from '../../../../../classes/Marketplace/Variant';
import { VariantDraggableProps } from './VariantDraggable';
import SellerVariantArea from './SellerVariantArea';
import VariantData from './VariantData';
import VariantMetafields from './VariantMetafields';
import VariantImageThumbnail from '../VariantImageThumbnail';

export interface MarketplaceVariantProps extends VariantDraggableProps {
  isOpen: boolean;
  setIsOpen: (bool: boolean) => void;
}

const MarketplaceVariant = React.forwardRef((props: MarketplaceVariantProps, ref: any) => {
  const { productId, marketplaceId, variant, isOpen, setIsOpen, product, isEditing, handleMetafieldChange } = props;

  const metafields = product.metafields !== undefined && product.metafields !== null &&
    product.metafields.length > 0 && product.metafields.filter((metafield) => metafield.ownerId === variant.id.toString());

  return (
    <Card className='mt-4'>
      <Card.Header
        style={{ cursor: 'pointer' }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Row>
          <Col>
            {isEditing && product.isManuallyOrderedVariants && 
              <span ref={ref}>
                <DragHanlde size={30} style={{ cursor: 'move' }}/>
                {' - '}
              </span>
            }
            <VariantImageThumbnail 
              marketplaceId={marketplaceId}
              variant={variant}
              product={product}
              productId={productId}
            />
            {variant.title} - {variant.sku}
          </Col>
          <Col xs='auto'>
            <StockLabelBadge variant={variant} threshold={0}/>
            {variant.archived === true &&
              <Badge pill variant='danger'>
                Archived
              </Badge>
            }
            {variant.sellerVariants !== undefined && variant.sellerVariants !== null &&
            variant.sellerVariants.length > 0 ? (
              <Badge pill variant='success'>
                {`Associated${variant.sellerVariants.length > 1 ? ': ' + variant.sellerVariants.length : ''}`}
              </Badge>
            ) : (
              <Badge pill variant='danger'>
                Unassociated
              </Badge>
            )}
          </Col>
        </Row>{' '}
      </Card.Header>
      <Collapse in={isOpen}>
        <Card.Body>
          <VariantData {...props} />
          <hr />
          {metafields !== undefined && metafields !== null && metafields.length > 0 && 
            <React.Fragment>
              <VariantMetafields 
                metafields={metafields}
                isEditing={isEditing}
                handleMetafieldChange={handleMetafieldChange}
              />
              <hr />
            </React.Fragment>
          }
          <SellerVariantArea {...props} />
        </Card.Body>
      </Collapse>
    </Card>
  );
})

export const MarketplaceVariantStock = (variant: VariantModel) => {
  return variant.sellerVariants !== undefined && variant.sellerVariants !== null &&
    variant.sellerVariants.length > 0 &&
    variant.sellerVariants.reduce((total, sellerVariant) =>
      total + (sellerVariant.seller.active && sellerVariant.inventoryPolicy !== INVENTORY_POLICY.DISABLE ? sellerVariant.quantityAboveThreshold : 0) || 0, 0,
    );
}

interface StockLabelBadgeProp {
  variant: VariantModel;
  threshold: number;
}

export const StockLabelBadge = (props: StockLabelBadgeProp) => {
  const { variant, threshold } = props;
  const allowOOSSale = variant.sellerVariants !== undefined && variant.sellerVariants !== null &&
    variant.sellerVariants.length > 0 &&
    variant.sellerVariants.filter((sellerVariant) => sellerVariant.seller.active && sellerVariant.inventoryPolicy === INVENTORY_POLICY.CONTINUE).length > 0;

  const stock = MarketplaceVariantStock(variant);
  const finalInventory = (stock - threshold) > 0 ? (stock - threshold) : 0;

  if (allowOOSSale) {
    return (
      <Badge pill variant='info'>
        In Stock
      </Badge>
    );
  } else {
    if (finalInventory > 0) {
      return (
        <Badge pill variant='info'>
          Stock: {finalInventory}
        </Badge>
      );
    } else {
      return (
        <React.Fragment></React.Fragment>
      )
    }
  }
}

export default MarketplaceVariant;