import React from 'react';
import { Row, Col } from 'react-bootstrap';

import LoadingPage from '../../Shared/LoadingPage';
import RouteLeavingGuard from '../../Shared/RouteLeavingGuard';
import RouteVariableProps from '../../../interfaces/RouteVariableProps';

import Product from '../../../classes/Marketplace/Product';
import Variant from '../../../classes/Marketplace/Variant';
import WalmartCategory from '../../../classes/Marketplace/Walmart/WalmartCategory';

import MarketplaceProductHeader from './Product/MarketplaceProductHeader';
import ImportToProduct from './Product/ImportToProduct';
import { IdAndButton, SaveButton } from './Product/IdAndButton';
import ArchiveArea from './Product/ArchiveArea';
import ChannelsArea from './Product/Channels/ChannelsArea';
import BasicProductInfo from './Product/BasicProductInfo';
import MarketplaceProductImages from './Product/ImagePanel/Images';
import MarketplaceVariants from './Product/VariantPanel/Variants';
import PricingPanel from './Product/Pricing/PricingPanel';
import ProductMetafields from './Product/Metafields';
import MarketplaceChannel from '../../../classes/Marketplace/Channel';
import FinalPriceWarningModal from './Product/Pricing/FinalPriceWarningModal';

import productFunctions from './Product/Functions';
import MarketplaceChannelMarketplaceVariant from '../../../classes/Marketplace/MarketplaceChannelMarketplaceVariant';

interface MatchParams {
  marketplaceId: string;
  productId: string;
}

export interface MarketplaceProductRouteProps
  extends RouteVariableProps<MatchParams> {}

export interface MarketplaceProductState {
  isLoading: boolean;
  isNotFound: boolean;
  isEditing: boolean;
  isSaving: boolean;
  isImporting: boolean;
  isImported: boolean;
  isDownloading: boolean;
  isScrollPassSaveBtn: boolean;
  isScrollPassBottom: boolean;
  showFinalPriceWarningModal: boolean;
  floatingDivWidth: number;
  product: Product;
  channels: MarketplaceChannel[];
  walmartCategories: WalmartCategory[];
}

export default class MarketplaceProduct extends React.Component<
  MarketplaceProductRouteProps,
  MarketplaceProductState
> {
  myRef: React.RefObject<HTMLInputElement>;
  myRefTwo: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isNotFound: false,
      isEditing: false,
      isSaving: false,
      isImporting: false,
      isImported: true,
      isDownloading: false,
      isScrollPassSaveBtn: false,
      isScrollPassBottom: false,
      showFinalPriceWarningModal: false,
      floatingDivWidth: 0,
      product: new Product(this.props.match.params.marketplaceId),
      channels: new Array<MarketplaceChannel>(),
      walmartCategories: new Array<WalmartCategory>(),
    };
    this.myRef = React.createRef();
    this.myRefTwo = React.createRef();
  }

  componentDidMount() {
    const { marketplaceId, productId } = this.props.match.params;
    if (marketplaceId !== undefined) {
      if (productId !== 'new') {
        this.getProduct();
      } else {
        this.setState({ isEditing: true, isImported: false });
      }
      this.getMarketplaceChannels();
      this.getWalmartCategories();
    }
    document.addEventListener('scroll', this.trackingScroll);
    window.addEventListener('resize', this.trackingScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackingScroll);
    window.removeEventListener('resize', this.trackingScroll);
  }

  isBottomSaveBtn = (ref: React.RefObject<HTMLInputElement>) => {
    return ref.current.getBoundingClientRect().bottom < 0;
  }

  isBottomOfProduct = (ref: React.RefObject<HTMLInputElement>) => {
    return ref.current.getBoundingClientRect().bottom + 60 > window.innerHeight;
  }

  setFloatingSaveWidth = (ref: React.RefObject<HTMLInputElement>) => {
    this.setState({
      floatingDivWidth: ref.current.getBoundingClientRect().width + 40
    });
  }

  trackingScroll = () => {
    let isRefNull = this.myRef.current === null && this.myRefTwo.current === null;
    if (!isRefNull) {
      if(this.isBottomSaveBtn(this.myRef)) {
        this.setState({
          isScrollPassSaveBtn: true
        });
      } else {
        this.setState({
          isScrollPassSaveBtn: false
        });
      }

      if(this.isBottomOfProduct(this.myRefTwo)) {
        this.setState({
          isScrollPassBottom: true
        });
      } else {
        this.setState({
          isScrollPassBottom: false
        });
      }

      this.setFloatingSaveWidth(this.myRefTwo);
    }
  }

  getProduct = async () => {
    await productFunctions.getProduct(this.props, this.setParentState);

    this.setMarketplaceChannelMarketplaceVariantRecords();
  };

  getMarketplaceChannels = async () => {
    await productFunctions.getMarketplaceChannels(
      this.props,
      this.setParentState,
    );
  };

  removeProductAssociations = async () => {
    await productFunctions.removeProductAssociations(
      this.props,
      this.state.product,
      this.setParentState
    );
  }

  getWalmartCategories = async () => {
    await productFunctions.getWalmartCategories(
      this.props,
      this.setParentState,
    );
  }

  saveProduct = async () => {
    //await this.checkFinalPrice();

    if (!this.state.showFinalPriceWarningModal) {
      await productFunctions.saveProduct(
        this.props,
        this.state,
        this.setParentState,
      );
    }
  };

  // checkFinalPrice = async () => {
  //   const { product, channels } =  this.state;
  //   let shouldWarn = false;
  //   product.variants.forEach((variant) => {
  //     variant.marketplaceChannelMarketplaceVariants.forEach((channelPrice) => {
  //       let finalPrice = productFunctions.getVariantChannelFinalPrice(channelPrice);
  //       if (finalPrice < channelPrice.cost) {
  //         shouldWarn = true;
  //       }
  //     })
  //   })

  //   if (shouldWarn) {
  //     await this.setState({ showFinalPriceWarningModal: true });
  //   }
  // }

  forceSaveProduct = async () => {
    await productFunctions.saveProduct(
      this.props,
      this.state,
      this.setParentState,
    );
  };

  setMarketplaceChannelMarketplaceVariantRecords = () => {
    const { product, channels } = this.state;
    let tempProduct = product;

    let variants = product.variants.map(v => {
      let marketplaceChannelMarketplaceVariants = v.marketplaceChannelMarketplaceVariants;

      channels.forEach(c => {
        if (v.marketplaceChannelMarketplaceVariants.filter(mcmv => mcmv.marketplaceChannelId === c.id).length === 0) {
          let marketplaceChannelMarketplaceVariant = new MarketplaceChannelMarketplaceVariant();
          marketplaceChannelMarketplaceVariant.active = false;
          marketplaceChannelMarketplaceVariant.error = false;
          marketplaceChannelMarketplaceVariant.marketplaceChannelId = c.id;
          marketplaceChannelMarketplaceVariant.marketplaceVariantId = v.id;
          marketplaceChannelMarketplaceVariant.price = 0;
  
          marketplaceChannelMarketplaceVariants.push(marketplaceChannelMarketplaceVariant);
        }
      });

      return v;
    });

    tempProduct.variants = variants;
    this.setState({ product: tempProduct });
  }

  setParentState = stateObject => {
    this.setState(stateObject);
  };

  handleProductChange = (fieldName, newValue) => {
    let tempProduct = this.state.product;
    tempProduct[fieldName] = newValue;
    this.setState({ product: tempProduct });
  };

  handleVariantChange = async (index, fieldName, newValue) => {
    await productFunctions.handleVariantChange(
      index,
      fieldName,
      newValue,
      this.state,
      this.setParentState,
    );
  };

  handleChannelChange = async (
    channel: MarketplaceChannel,
    publish: boolean,
  ) => {
    productFunctions.handleChannelChange(
      channel,
      publish,
      this.state,
      this.setParentState,
    );
  };

  handleAddVariant = () => {
    let tempProduct = this.state.product;
    tempProduct.variants.push(new Variant());
    this.setState({ product: tempProduct });
  };

  handleMetafieldChange = (mfield, newValue) => {
    const metafields = this.state.product.metafields.map((metafield) => {
      if (!(metafield.key === mfield.key &&
        metafield.namespace === mfield.namespace &&
        metafield.ownerId === mfield.ownerId)) 
      {
        return metafield;
      } else {
        return {
          ...metafield,
          'value': newValue,
        };
      }
    });

    let tempProduct = this.state.product;
    tempProduct.metafields = metafields;
    this.setState({ product: tempProduct });
  }

  handleRemoveVariant = (variantId: string) => {
    let tempProduct = this.state.product;
    let tempVariants = tempProduct.variants.filter(
      variant => variant.id !== variantId,
    );
    tempProduct.variants = tempVariants;
    this.setState({ product: tempProduct });
  };

  downloadProductInfo = async () => {
    await productFunctions.downloadProduct(this.props, this.setParentState);
  }

  handleRoutingClick = (link: string) => {
    this.props.history.push(link)
  }

  render() {
    const { marketplaceId, productId } = this.props.match.params;
    const { selectedMarketplace } = this.props;
    const { isLoading, isNotFound, product, isEditing } = this.state;
    if (isNotFound) {
      return (
        <React.Fragment>
          <MarketplaceProductHeader
            marketplaceId={marketplaceId}
            productId={productId}
            isEditing={isEditing}
            setParentState={this.setParentState}
          />
          <Row className='mt-5'>
            <Col>
              <h5 className='text-danger'>
                No Product Found for ID {productId} in{' '}
                {selectedMarketplace.name} Marketplace
              </h5>
            </Col>
          </Row>
        </React.Fragment>
      );
    } else if (isLoading) {
      return (
        <React.Fragment>
          <MarketplaceProductHeader
            marketplaceId={marketplaceId}
            productId={productId}
            isEditing={isEditing}
            setParentState={this.setParentState}
          />
          <LoadingPage />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <RouteLeavingGuard 
            when={isEditing}
            navigate={(path) => this.props.history.push(path)}
            shouldBlockNavigation={location => true}
          />
          <MarketplaceProductHeader
            marketplaceId={marketplaceId}
            productId={productId}
            title={product.title}
            vendor={product.vendor}
            isEditing={isEditing}
            setParentState={this.setParentState}
          />
          <div ref={this.myRef}>
          <IdAndButton
            marketplaceId={marketplaceId}
            product={product}
            {...this.state}
            setParentState={this.setParentState}
            handleProductChange={this.handleProductChange}
            handleVariantChange={this.handleVariantChange}
            saveProduct={this.saveProduct}
          />
          </div>
          <ImportToProduct
            {...this.props}
            {...this.state}
            marketplaceId={marketplaceId}
            setParentState={this.setParentState}
          />
          <ArchiveArea
            marketplaceId={marketplaceId}
            product={product}
            {...this.state}
            setParentState={this.setParentState}
            handleProductChange={this.handleProductChange}
            handleVariantChange={this.handleVariantChange}
            saveProduct={this.saveProduct}
            downloadProductInfo={this.downloadProductInfo}
          />
          <ChannelsArea
            marketplaceId={marketplaceId}
            product={product}
            productId={productId}
            {...this.state}
            {...this.props}
            setParentState={this.setParentState}
            handleProductChange={this.handleProductChange}
            handleVariantChange={this.handleVariantChange}
            handleChannelChange={this.handleChannelChange}
            saveProduct={this.saveProduct}
          />
          <BasicProductInfo
            marketplaceId={marketplaceId}
            product={product}
            {...this.state}
            setParentState={this.setParentState}
            handleProductChange={this.handleProductChange}
            handleVariantChange={this.handleVariantChange}
            saveProduct={this.saveProduct}
          />
          <ProductMetafields 
            product={product}
            {...this.state}
            {...this.props}
            handleMetafieldChange={this.handleMetafieldChange}
          />
          <MarketplaceProductImages
            marketplaceId={marketplaceId}
            product={product}
            {...this.state}
            setParentState={this.setParentState}
            handleProductChange={this.handleProductChange}
            handleVariantChange={this.handleVariantChange}
            saveProduct={this.saveProduct}
            apiKey={this.props.apiKey}
            productId={productId}
            addAlert={this.props.addAlert}
          />
          <PricingPanel 
            marketplaceId={marketplaceId}
            product={product}
            {...this.state}
            setParentState={this.setParentState}
            handleProductChange={this.handleProductChange}
            handleVariantChange={this.handleVariantChange}
            saveProduct={this.saveProduct}
            productId={productId}
            apiKey={this.props.apiKey}
            addAlert={this.props.addAlert}
          />
          <div ref={this.myRefTwo}>
          <MarketplaceVariants
            marketplaceId={marketplaceId}
            product={product}
            {...this.state}
            setParentState={this.setParentState}
            handleProductChange={this.handleProductChange}
            handleVariantChange={this.handleVariantChange}
            saveProduct={this.saveProduct}
            handleAddVariant={this.handleAddVariant}
            handleRemoveVariant={this.handleRemoveVariant}
            handleMetafieldChange={this.handleMetafieldChange}
            productId={productId}
            apiKey={this.props.apiKey}
            addAlert={this.props.addAlert}
            handleRoutingClick={this.handleRoutingClick}
            removeProductAssociations={this.removeProductAssociations}
          />
          </div>
          <FinalPriceWarningModal 
            marketplaceId={marketplaceId}
            product={product}
            {...this.state}
            setParentState={this.setParentState}
            handleProductChange={this.handleProductChange}
            handleVariantChange={this.handleVariantChange}
            saveProduct={this.saveProduct}
            forceSaveProduct={this.forceSaveProduct}
          />
          <SaveButton
            marketplaceId={marketplaceId}
            product={product}
            {...this.state}
            setParentState={this.setParentState}
            handleProductChange={this.handleProductChange}
            handleVariantChange={this.handleVariantChange}
            saveProduct={this.saveProduct}
          />
        </React.Fragment>
      );
    }
  }
}
