import React from "react";
import { Row, Col } from "react-bootstrap";
import { TextField } from "../../Shared/EFBootstrap";

import { SellerVariantsForSellerState } from "../SellerVariantsForSeller";

interface SellerVariantFiltersForSellerProps
  extends SellerVariantsForSellerState {
  setSellerVariantsState: (any) => void;
  resetPage: () => void;
}

const SellerVariantFiltersForSeller = (
  props: SellerVariantFiltersForSellerProps
) => {
  const { setSellerVariantsState, resetPage, productTitle, sku, barcode, mpn } = props;
  return (
    <Row className="mt-3">
      <Col xs='12' md="6" lg="3" style={{height: "111px"}}>
        <div className="mr-1 input-with-clear">
          <TextField
            label="Product Title"
            value={productTitle}
            placeholder="Product Title"
            helpText="Starts With, Case Insensitive"
            onChange={newValue =>
              setSellerVariantsState({ productTitle: newValue })
            }
            onEnterKeyPress={() => {
              resetPage();
              setSellerVariantsState({ isInitialLoading: false });
            }}
          />
          <span
            className="input-close-icon"
            onClick={() => setSellerVariantsState({ productTitle: '' })}
            style={{float: "right", right: "5px", top: "-75px"}}
          >
            &times;
          </span>
        </div>
      </Col>
      <Col xs='12' md="6" lg="3" style={{height: "111px"}}>
        <div className="mr-1 input-with-clear">
          <TextField
            label="SKU"
            value={sku}
            placeholder="00000-0000"
            helpText="Starts With, Case Insensitive"
            onChange={newValue => setSellerVariantsState({ sku: newValue })}
            onEnterKeyPress={() => {
              resetPage();
              setSellerVariantsState({ isInitialLoading: false });
            }}
          />
          <span
            className="input-close-icon"
            onClick={() => setSellerVariantsState({ sku: '' })}
            style={{float: "right", right: "5px", top: "-75px"}}
          >
            &times;
          </span>
        </div>
      </Col>
      <Col xs='12' md="6" lg="3" style={{height: "111px"}}>
        <div className="mr-1 input-with-clear">
          <TextField
            label="Barcode"
            value={barcode}
            placeholder="000000000000"
            helpText="Starts With, Case Insensitive"
            onChange={newValue => setSellerVariantsState({ barcode: newValue })}
            onEnterKeyPress={() => {
              resetPage();
              setSellerVariantsState({ isInitialLoading: false });
            }}
          />
          <span
            className="input-close-icon"
            onClick={() => setSellerVariantsState({ barcode: '' })}
            style={{float: "right", right: "5px", top: "-75px"}}
          >
            &times;
          </span>
        </div>
      </Col>
      <Col xs='12' md="6" lg="3" style={{height: "111px"}}>
        <div className="mr-1 input-with-clear">
          <TextField
            label="MPN"
            value={mpn}
            placeholder="#######"
            helpText="Starts With, Case Insensitive"
            onChange={newValue => setSellerVariantsState({ mpn: newValue })}
            onEnterKeyPress={() => {
              resetPage();
              setSellerVariantsState({ isInitialLoading: false });
            }}
          />
          <span
            className="input-close-icon"
            onClick={() => setSellerVariantsState({ mpn: '' })}
            style={{float: "right", right: "5px", top: "-75px"}}
          >
            &times;
          </span>
        </div>
      </Col>
    </Row>
  );
};

export default SellerVariantFiltersForSeller;
