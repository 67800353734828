import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { TextField, Select } from '../../../Shared/EFBootstrap';

import * as Settings from '../../../../Settings';

const eformedApiBaseUri = Settings.eformedApiBaseUri;

const SelectCompany = props => {
  const handleCompanyChange = newValue => {
    const { purchaseOrder } = props;
    const tempPO = purchaseOrder;
    if (newValue === 0) {
      tempPO.companyId = newValue;
      tempPO.shipName = '';
      tempPO.shipEmailAddress = '';
      tempPO.shipPhone = '';
      tempPO.shipAddress = '';
      tempPO.shipCity = '';
      tempPO.shipState = '';
      tempPO.shipZip = '';
      tempPO.shipCountry = '';
      props.setParentState({ purchaseOrder: tempPO });
    } else {
      tempPO.companyId = newValue;
      fetch(`
        ${eformedApiBaseUri}po/companies/${newValue}?key=${
        props.apiKey
      }&shop=babywise-life.myshopify.com`)
        .then(response => {
          if (response.status >= 400 && response.status <= 500) {
            throw response;
          } else {
            return response.json();
          }
        })
        .then(data => {
          console.log(data);
          tempPO.shipName = data.company.name;
          tempPO.shipEmailAddress = data.company.emailAddress;
          tempPO.shipPhone = data.company.phone;
          tempPO.shipAddress = data.company.address;
          tempPO.shipCity = data.company.city;
          tempPO.shipState = data.company.state;
          tempPO.shipZip = data.company.zip;
          tempPO.shipCountry = data.company.country;
          props.setParentState({ purchaseOrder: tempPO });
        })
        .catch(error => {
          props.setParentState({ isLoading: false });
          console.error(error);
          try {
            error.text().then(errorText => console.error(errorText));
          } catch {}
          props.addAlert(
            'danger',
            'There was an error while getting company info, please try again (reselect in the list). If this is not the first error, please report the issue.',
          );
        });
    }
  };

  return (
    <Container className='mt-5'>
      <h5>Select A Company</h5>
      <Row>
        <Col>
          <Select
            label='Select Company'
            options={props.companyOptions}
            value={props.purchaseOrder.companyId}
            onChange={handleCompanyChange}
          />
        </Col>
      </Row>
      <h6>Optionally change the ship to address</h6>
      <Row>
        <Col>
          <TextField
            label='Company Name'
            value={props.purchaseOrder.shipName}
            onChange={newValue => {
              var tempPO = { ...props.purchaseOrder };
              tempPO.shipName = newValue;
              props.setParentState({ purchaseOrder: tempPO });
            }}
          />
        </Col>
        <Col>
          <TextField
            label='Email Address'
            value={props.purchaseOrder.shipEmailAddress}
            onChange={newValue => {
              var tempPO = { ...props.purchaseOrder };
              tempPO.shipEmailAddress = newValue;
              props.setParentState({ purchaseOrder: tempPO });
            }}
            type='email'
          />
        </Col>
        <Col>
          <TextField
            label='Phone'
            value={props.purchaseOrder.shipPhone}
            onChange={newValue => {
              var tempPO = { ...props.purchaseOrder };
              tempPO.shipPhone = newValue;
              props.setParentState({ purchaseOrder: tempPO });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            label='Address'
            value={props.purchaseOrder.shipAddress}
            onChange={newValue => {
              var tempPO = { ...props.purchaseOrder };
              tempPO.shipAddress = newValue;
              props.setParentState({ purchaseOrder: tempPO });
            }}
          />
        </Col>
        <Col>
          <TextField
            label='City'
            value={props.purchaseOrder.shipCity}
            onChange={newValue => {
              var tempPO = { ...props.purchaseOrder };
              tempPO.shipCity = newValue;
              props.setParentState({ purchaseOrder: tempPO });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            label='State/Province'
            value={props.purchaseOrder.shipState}
            onChange={newValue => {
              var tempPO = { ...props.purchaseOrder };
              tempPO.shipState = newValue;
              props.setParentState({ purchaseOrder: tempPO });
            }}
          />
        </Col>
        <Col>
          <TextField
            label='Zip/Postal Code'
            value={props.purchaseOrder.shipZip}
            onChange={newValue => {
              var tempPO = { ...props.purchaseOrder };
              tempPO.shipZip = newValue;
              props.setParentState({ purchaseOrder: tempPO });
            }}
          />
        </Col>
        <Col>
          <TextField
            label='Country'
            value={props.purchaseOrder.shipCountry}
            onChange={newValue => {
              var tempPO = { ...props.purchaseOrder };
              tempPO.shipCountry = newValue;
              props.setParentState({ purchaseOrder: tempPO });
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default SelectCompany;
