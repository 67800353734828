import React from "react";
import { Row, Col, Card, Button, Spinner } from "react-bootstrap";

import LoadingPage from "../../Shared/LoadingPage";
import * as Settings from "../../../Settings";
import PreferredVendor from "../../../classes/Marketplace/PreferredVendor";
import { TextField } from "../../Shared/EFBootstrap";
import rest from "../../../utils/Rest";
import errorHandler from "../../../utils/ErrorHandler";
import setMarketplaceFromId from "../../../utils/SetMarketplaceFromId";

import RouteVariableProps from "../../../interfaces/RouteVariableProps";

interface MatchParams {
  marketplaceId: string;
}

interface PreferredVendorNamesState {
  isLoading: boolean;
  isSubmitting: boolean;
  isSearching: boolean;
  searchString: string;
  preferredVendors: PreferredVendor[];
  addPreferredVendor: PreferredVendor;
}

export default class PreferredVendorNames extends React.Component<
  RouteVariableProps<MatchParams>,
  PreferredVendorNamesState
> {
  state = {
    isLoading: false,
    isSubmitting: false,
    isSearching: false,
    searchString: '',
    preferredVendors: [],
    addPreferredVendor: new PreferredVendor()
  };

  componentDidMount() {
    if (this.props.match.params.marketplaceId !== undefined) {
      this.getPreferredVendors();
    }
  }

  getPreferredVendors = async () => {
    const marketplaceId = this.props.match.params.marketplaceId;
    const { apiKey, selectedMarketplace, setMarketplace, user } = this.props;
    if (selectedMarketplace === undefined) {
      setMarketplaceFromId(
        marketplaceId,
        setMarketplace,
        user,
        this.props.history
      );
    }

    this.setState({ isLoading: true });
    try {
      let data = await rest.get(
        `${
          Settings.eformedApiBaseUri
        }marketplace/${marketplaceId}/preferredvendornames?key=${apiKey}`
      );

      this.setState({ preferredVendors: data, isLoading: false });
    } catch (error) {
      errorHandler(
        error,
        "There was an error while retrieving the preferred vendors, please try again. If this is not the first error, please report the issue.",
        this.props.addAlert
      );
      this.setState({ isLoading: false });
    }
  };

  addPreferredVendor = async () => {
    const { addPreferredVendor } = this.state;
    const { apiKey, selectedMarketplace } = this.props;
    if (
      addPreferredVendor.alternateName !== undefined &&
      addPreferredVendor.preferredName !== undefined &&
      addPreferredVendor.alternateName.length > 0 &&
      addPreferredVendor.preferredName.length > 0
    ) {
      this.setState({ isSubmitting: true });
      let tempPreferredVendor = addPreferredVendor;
      tempPreferredVendor.marketplaceId = selectedMarketplace.id;
      try {
        await rest.post(
          `${Settings.eformedApiBaseUri}marketplace/${
            selectedMarketplace.id
          }/preferredvendornames?key=${apiKey}`,
          tempPreferredVendor
        );
        this.setState({ isSubmitting: false });
        this.getPreferredVendors();
      } catch (error) {
        errorHandler(
          error,
          "There was an error while adding the preferred vendor, please try again. If this is not the first error, please report the issue.",
          this.props.addAlert
        );
        this.setState({ isSubmitting: false });
      }
    }
  };

  searchPreferredNames = async () => {
    const { searchString } = this.state;
    const { apiKey, selectedMarketplace } = this.props;

    this.setState({ isSearching: true });
    try {
      let data = await rest.get(
        `${Settings.eformedApiBaseUri}marketplace/${
          selectedMarketplace.id
        }/preferredvendornames?key=${apiKey}&searchString=${searchString}`
      );

      this.setState({ preferredVendors: data, isSubmitting: false, isSearching: false });
    } catch (error) {
      errorHandler(
        error,
        "There was an error while searching for Preferred vendor names, please try again. If this is not the first error, please report the issue.",
        this.props.addAlert
      );
      this.setState({ isSearching: false });
    }
  }

  removePreferredVendor = async (preferredVendor: PreferredVendor) => {
    const { apiKey, selectedMarketplace } = this.props;
    this.setState({ isSubmitting: true });
    try {
      await rest.delete(
        `${Settings.eformedApiBaseUri}marketplace/${
          selectedMarketplace.id
        }/preferredvendornames/${preferredVendor.id}?key=${apiKey}`
      );
      this.setState({ isSubmitting: false });
      this.getPreferredVendors();
    } catch (error) {
      errorHandler(
        error,
        "There was an error while removing the preferred vendor, please try again. If this is not the first error, please report the issue.",
        this.props.addAlert
      );
      this.setState({ isSubmitting: false });
    }
  };

  render() {
    const { isLoading, isSubmitting, preferredVendors, isSearching } = this.state;
    if (isLoading) {
      return <LoadingPage />;
    }
    return (
      <React.Fragment>
        <Row>
          <Col>
            <h1>Preferred Vendor Names</h1>
          </Col>
        </Row>
        <Row>
          <Col className="text-muted">
            Preferred vendor names are CaSe-SeNsiTivE
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <TextField
              label="Alternate Name"
              value={this.state.addPreferredVendor.alternateName}
              helpText="What to change FROM"
              onChange={newValue => {
                let tempPreferredVendor = this.state.addPreferredVendor;
                tempPreferredVendor.alternateName = newValue;
                this.setState({ addPreferredVendor: tempPreferredVendor });
              }}
            />
          </Col>
          <Col>
            <TextField
              label="Preferred Name"
              value={this.state.addPreferredVendor.preferredName}
              helpText="What to change TO"
              onChange={newValue => {
                let tempPreferredVendor = this.state.addPreferredVendor;
                tempPreferredVendor.preferredName = newValue;
                this.setState({ addPreferredVendor: tempPreferredVendor });
              }}
            />
          </Col>
          <Col className="align-self-center" md="2">
            <Button variant="success" onClick={() => this.addPreferredVendor()}>
              {isSubmitting ? <Spinner size="sm" animation="border" /> : "Add"}
            </Button>
          </Col>
        </Row>
        <hr/>
        <Row>
          <Col className="md-3">
            <TextField
              label="Search"
              value={this.state.searchString}
              onChange={newValue => {
                let tempsearchString = this.state.searchString;
                tempsearchString = newValue;
                this.setState({ searchString: tempsearchString });
              }}
              onEnterKeyPress={() => this.searchPreferredNames()}
            />
          </Col>
          <Col className="align-self-center" md="2" style={{marginTop: "15px"}}>
            <Button variant="info" onClick={() => this.searchPreferredNames()}>
              {isSearching ? <Spinner size="sm" animation="border" /> : "Find"}
            </Button>
          </Col>
          <Col className="md-3"></Col>
        </Row>
        <Row className="mt-5">
          <Col>
            {preferredVendors.length !== 0 ? preferredVendors.map((preferredVendor, index) => (
              <Row key={index}>
                <Col>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col className="align-self-center">
                          {preferredVendor.alternateName} will be changed to{" "}
                          {preferredVendor.preferredName}
                        </Col>
                        <Col md="2">
                          <Button
                            variant="danger"
                            onClick={() =>
                              this.removePreferredVendor(preferredVendor)
                            }
                          >
                            {isSubmitting ? (
                              <Spinner size="sm" animation="border" />
                            ) : (
                              "Remove"
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )) :
              <Row>
                No preferred names for the current search key.
              </Row>
            }
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
