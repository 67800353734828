import uuidv4 from 'uuid/v4';

export default class DescriptionCleanerTag {
  constructor() {
    this.id = uuidv4();
  }

  id: string;
  marketplaceId: string;
  type: number | string;
  tag: string;
  replaceTag: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  isNewField: boolean;
}
