import Seller from '../classes/Seller/SellerPermission';
import User from '../classes/Core/User';

const setSellerFromId = async (
  sellerId: string,
  setSeller: (seller: Seller) => void,
  user: User,
  history: any,
) => {
  let tempSellers = user.sellers.filter(
    seller => seller.id === sellerId,
  );

  if (
    tempSellers !== undefined &&
    tempSellers !== null &&
    tempSellers.length > 0
  ) {
    await setSeller(tempSellers[0]);
  } else {
    history.push(`/seller/${user.sellers[0].id}/`);
  }
};

export default setSellerFromId;