import React, { useState } from 'react';
import { Row, Col, Card, Button, Spinner } from 'react-bootstrap';

import Order from '../../../../classes/Marketplace/Order';

import OrderLineItemCard from './OrderLineItem';

export interface OrderLineItemsProps {
  apiKey: string;
  marketplaceId: string;
  order: Order;
  shouldSubmit: boolean;
  setParentState: (any) => void;
  resyncLineItem: (lineItemId: string) => void;
  fulfillLineItem: (lineItemId: string) => void;
  reSubmitOrder: () => void;
  addAlert: any;
}

const OrderLineItems = (props: OrderLineItemsProps) => {
  const { order, shouldSubmit, reSubmitOrder } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitOrder = async () => {
    setIsSubmitting(true);
    await reSubmitOrder();
    setIsSubmitting(false);
  }

  return (
    <Row className='mt-2'>
      <Col>
        <Card bg='info' text='dark'>
          <Card.Header>
            <Row> 
            <Col><h3 className='text-light'>Line Items</h3></Col>
            {shouldSubmit &&
            <Col md='3' className='text-right'>
              <Button
                size='sm'
                variant='warning'
                onClick={() => submitOrder()}
              >
                {isSubmitting ? (
                  <React.Fragment>
                    <Spinner size='sm' animation='border' />
                    &nbsp;Submitting...
                  </React.Fragment>
                ) : (
                  'Submit to Seller(s)')}
              </Button>
            </Col>
            }
            </Row>
          </Card.Header>
          {order.lineItems !== undefined &&
            order.lineItems.map((lineItem, index) => (
              <OrderLineItemCard 
                key={index}
                {...props}
                lineItem={lineItem}
                index={index}
              />
            ))}
        </Card>
      </Col>
    </Row>
  );
};

export default OrderLineItems;
