import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { TextField } from "../../../Shared/EFBootstrap";

import formDateFormat from "../../../../utils/FormDateFormat";

const Dates = props => {
  return (
    <Container className="mt-5">
      <h5>Enter purchase order information</h5>
      <br />

      <Row>
        <Col>
          <TextField
            label="PO Date"
            value={formDateFormat(props.purchaseOrder.poDate)}
            onChange={newValue => {
              var tempPO = { ...props.purchaseOrder };
              tempPO.poDate = newValue;
              props.setParentState({ purchaseOrder: tempPO });
            }}
            type="date"
          />
        </Col>
        <Col>
          <TextField
            label="Ship By Date"
            value={formDateFormat(props.purchaseOrder.shipByDate)}
            onChange={newValue => {
              var tempPO = { ...props.purchaseOrder };
              tempPO.shipByDate = newValue;
              props.setParentState({ purchaseOrder: tempPO });
            }}
            type="date"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Dates;
