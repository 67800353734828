import React, { useState } from 'react';
import { Row, Col, Button, ListGroup, Spinner, Dropdown, Container } from 'react-bootstrap';
import { MdMoreHoriz as ThreeDots } from "react-icons/md";
import { CopyToClipboard } from "react-copy-to-clipboard";

import * as Settings from '../../../../../Settings';
import rest from '../../../../../utils/Rest';
import { INVENTORY_POLICY } from '../../../../../utils/Constants';
import errorHandler from '../../../../../utils/ErrorHandler';
import { MarketplaceVariantStock } from './Variant';
import { importSellerVariantToVariant, reUpdateProduct, updateProductChannelPrices } from '../Functions/ImportSellerVariant';
import { MarketplaceVariantProps } from './Variant';

const SellerVariantList = (props: MarketplaceVariantProps) => {
  const { variant, isImporting, index, setParentState } = props;
  const stock = MarketplaceVariantStock(variant);

  return (
    <React.Fragment>
      <ListGroup>
        <ListGroup.Item active>
          <Row>
            <Col>Seller</Col>
            <Col>Product Title</Col>
            <Col>Variant Title</Col>
            <Col>
              Total Stock:{' ' + (stock ? stock : '0')}
            </Col>
            <Col md="3"></Col>
          </Row>
        </ListGroup.Item>
        {variant.sellerVariants !== undefined && variant.sellerVariants !== null &&
          variant.sellerVariants.map((sellerVariant, indexx) => (
            <SellerVariantDisplay
              key={indexx}
              index={index}
              isImporting={isImporting}
              setParentState={setParentState}
              sellerVariantIndex={indexx}
              sellerVariant={sellerVariant}
              {...props}
            />
          ))}
      </ListGroup>
    </React.Fragment>
  );
};

const SellerVariantDisplay = props => {
  const { marketplaceId, variant, sellerVariant, index, setParentState, handleRoutingClick } = props;
  const [showModal, setShowModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const importSellerVariantToVariantFunc = async () => {
    setIsProcessing(!isProcessing);
    await importSellerVariantToVariant(
      index,
      sellerVariant.id,
      props,
      setParentState,
    );
    setIsProcessing(false);
    setParentState({isEditing: true});
  }

  const updateProductFields = async () => {
    setIsProcessing(!isProcessing);
    await reUpdateProduct(
      sellerVariant.id,
      props,
      setParentState,
    );
    setIsProcessing(false);
    setParentState({isEditing: true});
  }

  const updateVariantChannelPricesFunc = async () => {
    setIsProcessing(!isProcessing);
    await updateProductChannelPrices(
      variant.id,
      sellerVariant.id,
      props,
      setParentState,
    );
    setIsProcessing(false);
    setParentState({isEditing: true});
  }

  return (
    <React.Fragment>
      <Container onClick={() => handleRoutingClick(`/marketplace/${marketplaceId}/sellers/${sellerVariant.sellerId}/variants/${sellerVariant.id}`)}>
        <ListGroup.Item action>
          <Row>
            <Col className='align-self-center'>{sellerVariant.seller.name}</Col>
            <Col className='align-self-center'>{sellerVariant.productTitle}</Col>
            <Col className='align-self-center'>
              {sellerVariant.option1Name && sellerVariant.option1Value !== "Default Title" ? `${sellerVariant.option1Name}: ${sellerVariant.option1Value}` : ' '}
              {sellerVariant.option2Name ? ` / ${sellerVariant.option2Name}: ${sellerVariant.option2Value}` : ' '}
              {sellerVariant.option3Name ? ` / ${sellerVariant.option3Name}: ${sellerVariant.option3Value}` : ' '}
            </Col>
            <Col className='align-self-center'>
              {sellerVariant.seller.active && sellerVariant.inventoryPolicy !== INVENTORY_POLICY.DISABLE ?
                (sellerVariant.inventoryPolicy === INVENTORY_POLICY.CONTINUE ? "In Stock" : sellerVariant.quantityAboveThreshold) : 0
              }
            </Col>
            <Col className='align-self-center text-right'>
              <Dropdown onClick={event => {
                event.preventDefault();
                event.stopPropagation();
              }}>
                <Dropdown.Toggle<any>
                  as={CustomToggle}
                  id={`${sellerVariant.id}-dropdown-toggle`}
                  isProcessing={isProcessing}
                />
                <Dropdown.Menu>
                  <CopyToClipboard text={sellerVariant.id}>
                    <Dropdown.Item className="dropdown-menu-item">
                        Copy ID
                    </Dropdown.Item>
                  </CopyToClipboard>
                  <Dropdown.Item className="dropdown-menu-item" onClick={() => updateProductFields()}>
                    Update Product
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-menu-item" onClick={() => {
                    importSellerVariantToVariantFunc();
                  }}>
                    Update Variant
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-menu-item" onClick={() => {
                    updateVariantChannelPricesFunc();
                  }}>
                    Update Prices
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-menu-unassociation" style={{}} onClick={() => setShowModal(true)}>
                    Unassociate
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </ListGroup.Item>
      </Container>
      <SellerVariantUnassociateModal
        {...props}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </React.Fragment>
  );
};

interface CustomToggleProps {
  isProcessing: boolean;
  onClick: (event: any) => void;
  children: React.ReactNode;
}
const CustomToggle = React.forwardRef((props: CustomToggleProps, ref: any) => {
  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    props.onClick(e);
  };

  if (props.isProcessing) {
    return (
      <Button ref={ref} size="sm" variant="outline-success" onClick={handleClick}>
        <Spinner size="sm" animation="border">
          {props.children}
        </Spinner>
      </Button>
    );
  } else {
    return (
      <Button ref={ref} size="sm" variant="outline-success" onClick={handleClick}>
        <ThreeDots size={20}>
          {props.children}
        </ThreeDots>
      </Button>
    );
  }
})

const SellerVariantUnassociateModal = props => {
  const { showModal, setShowModal, sellerVariant, variant, sellerVariantIndex, index, handleVariantChange } = props;
  const unassociate = async (
    sellerVariantId: string,
    marketplaceVariantId: string,
  ) => {
    const { apiKey, marketplaceId } = props;

    try {
      await rest.delete(
        `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/sellervariantmarketplacevariants/${sellerVariantId}?key=${apiKey}&marketplaceVariantId=${marketplaceVariantId}`,
      );

      // update variant association area
      let sellerVariants = variant.sellerVariants;
      sellerVariants.splice(sellerVariantIndex, 1);
      handleVariantChange(index, 'sellerVariants', sellerVariants);
    } catch (error) {
      errorHandler(
        error,
        'There was an error while unassociation.',
        props.addAlert,
      );
    }
  };
  return (
    <div role="dialog" aria-modal="true" className="fade modal show" style={{display: showModal ? "block" : "none", backgroundColor: "#00000094"}} onClick={() => setShowModal(false)}>
      <div role="document" className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title h4">Are you sure?</div>
            <button type="button" className="close" onClick={() => setShowModal(false)}>
              <span aria-hidden="true" >×</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body">
            <p className="text-dark">You are removing seller variant {sellerVariant.id}. Changes will not be immediately reflected on the page, but will show on refresh.</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
            <button type="button" className="btn btn-danger" onClick={() => {
              unassociate(sellerVariant.id, variant.id);
              setShowModal(false);
            }}>Delete</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerVariantList;
