import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { TextField, Select } from '../../Shared/EFBootstrap';
import SearchFilter from '../../Shared/SearchFilterComponents';

import {
  MarketplaceSellerVariantsProps,
  MarketplaceSellerVariantsState,
} from './SellerVariants';

interface SellerVariantFiltersProps
  extends MarketplaceSellerVariantsProps,
    MarketplaceSellerVariantsState {
  getSellerVariants: (skip: number) => void;
  setParentState: (stateObject: any) => void;
  exportCsv: (limitCount: number, downloadAll: boolean) => void;
}

const SellerVariantFilters = (props: SellerVariantFiltersProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const {
    isDownloading,
    getSellerVariants,
    setParentState,
    orderByFilter,
    vendorFilter,
    productTitleFilter,
    skuFilter,
    mpnFilter,
    barcodeFilter,
    associatedFilter,
    inStockFilter,
    inventoryPolicyFilter,
    archivedFilter,
    ignoredFilter,
    isProductTitleContainsFilter,
    connectionsFilter,
    exportCsv
  } = props;

  const changeisTitleContainsFilter = (newValue) => {
    setParentState({ isProductTitleContainsFilter: newValue });

    localStorage.setItem("sellervariants_isTitleContainsFilter", newValue);
  }

  const defaultStatusValues = {
    isInitialLoading: false,
    vendorFilter: '',
    productTitleFilter: '',
    skuFilter: '',
    mpnFilter: '',
    barcodeFilter: '',
    associatedFilter: 'Any',
    inStockFilter: 'Any',
    inventoryPolicyFilter: 'Any',
    archivedFilter: 'Any',
    ignoredFilter: false,
    connectionsFilter: 'Any',
  }

  return (
    <SearchFilter 
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      enableDownload={true}
      isDownloading={isDownloading}
      defaultStatus={defaultStatusValues}
      setParentState={setParentState}
      exportCsv={exportCsv}
      getResults={getSellerVariants}
      advancedDownloadOptions={true}
    >
      <Row className='mt-2'>
        <Col>
          <Select
            label='Order By'
            options={[
              { label: 'Updated At - Desc', value: 'updatedatdesc' },
              { label: 'Created At - Desc', value: 'createdatdesc' },
              { label: 'Vendor (A-Z)', value: 'vendor' },
              { label: 'Title (A-Z)', value: 'title' },
              { label: 'Variant Title (A-Z)', value: 'varianttitle' }
            ]}
            value={orderByFilter}
            onChange={newValue =>
              setParentState({
                orderByFilter: newValue,
              })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col className='align-self-top' style={{height: "111px"}}>
          <div className="mr-1 input-with-clear">
            <TextField
              label='Vendor'
              value={vendorFilter}
              onChange={newValue =>
                setParentState({ vendorFilter: newValue })
              }
              placeholder='Example Vendor'
              helpText='Case Insensitive, Starts With'
              onEnterKeyPress={() => {
                getSellerVariants(0);
                setParentState({ isInitialLoading: false });
              }}
            />
            <span
              className="input-close-icon"
              onClick={() => setParentState({ vendorFilter: '' })}
              style={{float: "right", right: "5px", top: "-75px"}}
            >
              &times;
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className='align-self-top' style={{height: "111px"}}>
          <div className="mr-1 input-with-clear">
            <TextField
              label='Product Title'
              value={productTitleFilter}
              onChange={newValue =>
                setParentState({ productTitleFilter: newValue })
              }
              placeholder='Example Product Title'
              helpText={isProductTitleContainsFilter ? 'Case Insensitive, Contains' : 'Case Insensitive, Starts With'}
              onEnterKeyPress={() => {
                getSellerVariants(0);
                setParentState({ isInitialLoading: false });
              }}
              checkBoxValue={isProductTitleContainsFilter}
              labelCheckBox={true}
              onChangeCheckBox={newValue =>
                changeisTitleContainsFilter(newValue)
              }
            />
            <span
              className="input-close-icon"
              onClick={() => setParentState({ productTitleFilter: '' })}
              style={{float: "right", right: "5px", top: "-75px"}}
            >
              &times;
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className='align-self-top' style={{height: "111px"}}>
          <div className="mr-1 input-with-clear">
            <TextField
              label='SKU'
              value={skuFilter}
              onChange={newValue => setParentState({ skuFilter: newValue })}
              placeholder='10000-1000'
              helpText='Case Insensitive, Starts With'
              onEnterKeyPress={() => {
                getSellerVariants(0);
                setParentState({ isInitialLoading: false });
              }}
            />
            <span
              className="input-close-icon"
              onClick={() => setParentState({ skuFilter: '' })}
              style={{float: "right", right: "5px", top: "-75px"}}
            >
              &times;
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className='align-self-top' style={{height: "111px"}}>
          <div className="mr-1 input-with-clear">
            <TextField
              label='MPN'
              value={mpnFilter}
              onChange={newValue => setParentState({ mpnFilter: newValue })}
              placeholder='#######'
              helpText='Case Insensitive, Starts With'
              onEnterKeyPress={() => {
                getSellerVariants(0);
                setParentState({ isInitialLoading: false });
              }}
            />
            <span
              className="input-close-icon"
              onClick={() => setParentState({ mpnFilter: '' })}
              style={{float: "right", right: "5px", top: "-75px"}}
            >
              &times;
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className='align-self-top' style={{height: "111px"}}>
          <div className="mr-1 input-with-clear">
            <TextField
              label='Barcode'
              value={barcodeFilter}
              onChange={newValue => setParentState({ barcodeFilter: newValue })}
              placeholder='000000000000'
              helpText='Case Insensitive, Starts With'
              onEnterKeyPress={() => {
                getSellerVariants(0);
                setParentState({ isInitialLoading: false });
              }}
            />
            <span
              className="input-close-icon"
              onClick={() => setParentState({ barcodeFilter: '' })}
              style={{float: "right", right: "5px", top: "-75px"}}
            >
              &times;
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className='align-self-top'>
          <Select
            label='Associations'
            options={[
              { label: 'Any', value: undefined },
              { label: 'Zero Associations', value: 'false' },
              { label: '1+ Associations', value: 'true' },
              { label: '2+ Associations', value: 'multiple' },
            ]}
            value={associatedFilter}
            onChange={newValue =>
              setParentState({
                associatedFilter:
                  newValue === 'Any' ? undefined : newValue,
              })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col className='align-self-top'>
          <Select
            label='In Stock'
            options={[
              { label: 'Any', value: undefined },
              { label: 'In Stock', value: true },
              { label: 'Out of Stock', value: false },
            ]}
            value={inStockFilter}
            onChange={newValue =>
              setParentState({
                inStockFilter: newValue === 'Any' ? undefined : newValue,
              })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col className='align-self-top'>
          <Select
            label='Inventory Policy'
            options={[
              { label: 'Any', value: undefined },
              { label: 'Continue', value: 'continue' },
              { label: 'Deny', value: 'deny' },
              { label: 'Disable', value: 'disable' },
            ]}
            value={inventoryPolicyFilter}
            onChange={newValue =>
              setParentState({
                inventoryPolicyFilter: newValue === 'Any' ? undefined : newValue,
              })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col className='align-self-top'>
          <Select
            label='Archived'
            options={[
              { label: 'Any', value: undefined },
              { label: 'Archived', value: true },
              { label: 'Not Archived', value: false },
            ]}
            value={archivedFilter}
            onChange={newValue =>
              setParentState({
                archivedFilter: newValue === 'Any' ? undefined : newValue,
              })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col className='align-self-top'>
          <Select
            label='Ignored'
            options={[
              { label: 'Any', value: undefined },
              { label: 'Ignored', value: true },
              { label: 'Not Ignored', value: false },
            ]}
            value={ignoredFilter}
            onChange={newValue =>
              setParentState({
                ignoredFilter: newValue === 'Any' ? undefined : newValue,
              })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col className='align-self-top'>
          <Select
            label='Connections'
            options={[
              { label: 'Any', value: undefined },
              { label: 'Duplicate', value: '0' },
              { label: 'Matched', value: '1' },
            ]}
            value={connectionsFilter}
            onChange={newValue =>
              setParentState({
                connectionsFilter: newValue === 'Any' ? undefined : newValue,
              })
            }
          />
        </Col>
      </Row>
    </SearchFilter>
  );
};

export default SellerVariantFilters;
