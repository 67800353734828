import * as React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { TextField, TextArea } from '../../../Shared/EFBootstrap';

import * as Settings from '../../../../Settings';
import BaseProps from '../../../../interfaces/BaseProps';
import LoadingPage from '../../../Shared/LoadingPage';
import searchPoItem, { SearchKeys } from "./SearchPoItem";

const eformedApiBaseUri = Settings.eformedApiBaseUri;

export default class ManagePurchaseOrderItems extends React.Component<
  BaseProps
> {
  state = {
    item: {
      shop: 'babywise-life.myshopify.com',
      itemNumber: '',
      sellerVariantId: '',
      vendor: '',
      mpn: '',
      upc: '',
      sku: '',
      description: '',
      cost: 0,
      casePack: 0,
    },
    isLoading: false,
    isSuccess: false,
    response: { successes: 0, errorLog: [] },
  };

  handleItemNumberChange = searchKey => async newValue => {
    let result = await searchPoItem(newValue, searchKey, this.props.apiKey);

    let tempItem = { ...this.state.item, ...result };
    this.setState({ item: tempItem });
  }

  handleSubmit = () => {
    this.setState({ isLoading: true });
    const { item } = this.state;

    var body = {
      item: item,
    };

    fetch(
      eformedApiBaseUri +
        'po/items.json?key=' +
        this.props.apiKey +
        '&shop=babywise-life.myshopify.com&type=single',
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
      .then(response => {
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          return response.json();
        }
      })
      .then(data => {
        this.setState({ isLoading: false, isSuccess: true, response: data });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        console.error(error);
        try {
          error.text().then(errorText => console.error(errorText));
        } catch {}
        this.props.addAlert(
          'danger',
          'There was an error while adding, please try again. If this is not the first error, please report the issue.',
        );
      });
  };

  render() {
    const { item } = this.state;

    if (this.state.isLoading) {
      return (
        <React.Fragment>
          <h1>Add Purchase Order Items</h1>
          <Row className='text-right'>
            <Col>
              <LinkContainer to='/logistics/po/items/search'>
                <Button>Search Items</Button>
              </LinkContainer>
            </Col>
            <Col sm='auto'>
              <LinkContainer to='/logistics/po/items/bulk'>
                <Button>Add Items in Bulk</Button>
              </LinkContainer>
            </Col>
          </Row>
          <LoadingPage />
        </React.Fragment>
      );
    } else if (!this.state.isSuccess) {
      return (
        <React.Fragment>
          <h1>Add Purchase Order Items</h1>
          <Row className='text-right'>
            <Col>
              <LinkContainer to='/logistics/po/items/search'>
                <Button>Search Items</Button>
              </LinkContainer>
            </Col>
            <Col sm='auto'>
              <LinkContainer to='/logistics/po/items/bulk'>
                <Button>Add Items in Bulk</Button>
              </LinkContainer>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6>Enter Item Information</h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className='text-secondary'>
                You can either type an OSC ID, and it will auto-detect the
                information so you can edit it, OR you can enter new information
                and create a new item.
              </p>
            </Col>
          </Row>
          <Form>
            <Row>
              <Col>
                <TextField
                  label='Seller Variant ID'
                  placeholder='Seller Variant ID - Enter to Auto Fill Other Fields'
                  value={item.sellerVariantId}
                  onChange={this.handleItemNumberChange(SearchKeys.SELLER_VARIANT_KEY)}
                />
              </Col>
              <Col>
                <TextField
                  label='OSC ID'
                  placeholder='OSC ID - Enter to Auto Fill Other Fields'
                  value={item.itemNumber}
                  onChange={this.handleItemNumberChange(SearchKeys.OSC_ID_KEY)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextField
                  label='Vendor'
                  placeholder='Vendor'
                  value={item.vendor}
                  onChange={newValue => {
                    var tempItem = { ...this.state.item };
                    tempItem.vendor = newValue;
                    this.setState({ item: tempItem });
                  }}
                />
              </Col>
              <Col>
                <TextField
                  label='Description (Title)'
                  placeholder='Description (Title)'
                  value={item.description}
                  onChange={newValue => {
                    var tempItem = { ...this.state.item };
                    tempItem.description = newValue;
                    this.setState({ item: tempItem });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextField
                  label='UPC'
                  placeholder='UPC'
                  value={item.upc}
                  onChange={newValue => {
                    var tempItem = { ...this.state.item };
                    tempItem.upc = newValue;
                    this.setState({ item: tempItem });
                  }}
                />
              </Col>
              <Col>
                <TextField
                  label='SKU'
                  placeholder='SKU'
                  value={item.sku}
                  onChange={newValue => {
                    var tempItem = { ...this.state.item };
                    tempItem.sku = newValue;
                    this.setState({ item: tempItem });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <TextField
                  label='MPN'
                  placeholder='MPN'
                  value={item.mpn}
                  onChange={newValue => {
                    var tempItem = { ...this.state.item };
                    tempItem.mpn = newValue;
                    this.setState({ item: tempItem });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextField
                  label='Cost'
                  placeholder='Cost'
                  value={item.cost}
                  onChange={newValue => {
                    var tempItem = { ...this.state.item };
                    tempItem.cost = newValue;
                    this.setState({ item: tempItem });
                  }}
                  type='currency'
                  prefix='$'
                  helpText='Changing Cost=Permanent Standard Cost Change'
                />
              </Col>
              <Col>
                <TextField
                  label='Case Pack'
                  value={item.casePack}
                  onChange={newValue => {
                    var tempItem = { ...this.state.item };
                    tempItem.casePack = newValue;
                    this.setState({ item: tempItem });
                  }}
                  type='number'
                  helpText='AKA Minimum Order Quantity'
                />
              </Col>
            </Row>
          </Form>
          <Row>
            <Col>
              <Button
                block
                onClick={this.handleSubmit}
                disabled={this.state.isLoading}
              >
                {this.state.isLoading === true ? 'Saving...' : 'Add/Edit Item'}
              </Button>
            </Col>
          </Row>

          <Row>
            <Col>
              <TextArea
                rows={10}
                label='Copy To Bulk Upload Template'
                value={
                  item.itemNumber +
                  '\t' +
                  item.mpn +
                  '\t' +
                  item.upc +
                  '\t' +
                  item.description +
                  '\t' +
                  item.cost +
                  '\t' +
                  item.casePack
                }
                readOnly
              />
            </Col>
          </Row>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h1>Add Purchase Order Items</h1>
          <Row className='text-right'>
            <Col>
              <LinkContainer to='/logistics/po/items/bulk'>
                <Button onClick={() => this.setState({ isSuccess: false })}>
                  Add/Edit Another Item
                </Button>
              </LinkContainer>
            </Col>
          </Row>

          <Row>
            <Col>
              <h2>Report</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Successes: {this.state.response.successes}</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Error Log: </h3>
            </Col>
          </Row>
          {this.state.response.errorLog.map((errorLog, index) => (
            <p>
              Item: {errorLog.itemNumber} - Error: {errorLog.error}
            </p>
          ))}
        </React.Fragment>
      );
    }
  }
}
