import React from 'react';
import { Row, Col, Card, Button, Spinner } from 'react-bootstrap';

import DescriptionCleanerTag from '../../../../classes/Marketplace/DescriptionCleanerTag';
import { TextField, Select } from '../../../Shared/EFBootstrap';

import { DescriptionFormatterRouteProps } from './DescriptionFormatter';

export interface FormattingTagProps extends DescriptionFormatterRouteProps {
  formatterTag: DescriptionCleanerTag;
  index: number;
  saveFormatterTag: (formatterTag: DescriptionCleanerTag) => void;
  handleFormatterTagChange: (formatterTag: DescriptionCleanerTag, fieldName: string, newValue: string | number | boolean) => void;
  deleteFomatterTagModal: (formatterTag: DescriptionCleanerTag, show: boolean) => void;
}

export interface FormattingTagState {
  isOpen: boolean;
  isEditing: boolean;
  isSaving: boolean;
}


export default class FormattingTag extends React.Component<
  FormattingTagProps, FormattingTagState
> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isEditing: false, 
      isSaving: false
    };
  }

  saveFormatterTag = async () => {
    const { formatterTag, saveFormatterTag } = this.props;
    await this.setState({ isSaving: true });
    await saveFormatterTag(formatterTag);
    await this.setState({ isSaving: false, isEditing: false });
  }

  render() {
    const { isOpen, isEditing, isSaving } = this.state;
    const { formatterTag, handleFormatterTagChange, deleteFomatterTagModal } = this.props;

    return (
      <Card className='mt-3'>
        <Card.Header onClick={() => this.setState({ isOpen: !isOpen })}>
          <Row>
            <Col>
              <h4>
                {formatterTag.tag}
              </h4>
            </Col>
            <React.Fragment>
            <Col md={2}>
              <Button
                className="float-right"
                size="sm"
                variant={isEditing ? 'success' : 'warning'}
                onClick={() =>
                  isEditing ? this.saveFormatterTag() : this.setState({ isEditing: !isEditing })
                }
              >
                {isEditing ? (
                  isSaving ? (
                    <React.Fragment>
                      <Spinner size='sm' animation='border' />
                      Saving...
                    </React.Fragment>
                  ) : (
                    'Save'
                  )
                ) : (
                  'Edit'
                )}
              </Button>
            </Col>
            <Col md={1}>
              <Button
                className="float-right"
                size="sm"
                variant="danger"
                onClick={() => deleteFomatterTagModal(formatterTag, true)}
              >
                Delete
              </Button>
            </Col>
            </React.Fragment>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Select
                label='Tag Formatting Type'
                readOnly={!isEditing}
                options={[
                  { label: 'Remove Single Tag', value: 0 },
                  { label: 'Remove Open Close Tag', value: 1 },
                  { label: 'Remove Attribute', value: 2 },
                  { label: 'Remove Empty Tag', value: 3 },
                  { label: 'Remove Element', value: 4 },
                  { label: 'Replace', value: 5 }
                ]}
                value={formatterTag.type}
                onChange={newValue =>
                  handleFormatterTagChange(formatterTag, 'type', newValue)
                }
              />
            </Col>
            <Col>
              <TextField
                label='Tag'
                readOnly={!isEditing}
                value={formatterTag.tag}
                onChange={newValue =>
                  handleFormatterTagChange(formatterTag, 'tag', newValue)
                }
              />
            </Col>
            {(formatterTag.type === 5 || formatterTag.type === '5')&&
            <Col>
              <TextField
                label='Replacing Tag'
                readOnly={!isEditing}
                value={formatterTag.replaceTag}
                onChange={newValue =>
                  handleFormatterTagChange(formatterTag, 'replaceTag', newValue)
                }
              />
            </Col>
            }
          </Row>
        </Card.Body>
      </Card>
    );
  }
}
