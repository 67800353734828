import * as React from 'react';
import { Container, Row, Col, Form, ListGroup, Badge } from 'react-bootstrap';
import { Select } from '../../Shared/EFBootstrap';
import LoadingPage from '../../Shared/LoadingPage';

import * as Settings from '../../../Settings';
import BaseProps from '../../../interfaces/BaseProps';

const eformedApiBaseUri =
  Settings.eformedApiBaseUri; /*'https://eformed-staging.azurewebsites.net/api/';*/

export default class AccountingChecklist extends React.Component<BaseProps> {
  state = {
    isLoading: false,
    isError: false,
    apiKey: this.props.apiKey,
    month:
      new Date().getMonth() === 0 ? '12' : new Date().getMonth().toString(),
    year: new Date().getFullYear().toString(),
    Ceo: false,
    Cfo: false,
    Accountant: false,
    BusinessFinalSignOff: null,
    AccountingFinalSignOff: null,
    BankAccountReconciliation: null,
    InventoryReconciliationCOGS: null,
    AMZRevenueFees: null,
    WalmartRevenueFees: null,
    ConsignmentBillingEntry: null,
    ShippingAdjustment: null,
  };

  componentDidMount() {
    this.getChecklist(this.state.month, this.state.year);
  }

  getChecklist = (month, year) => {
    this.setState({ isLoading: true });
    fetch(
      eformedApiBaseUri +
        'accounting/completed.json?key=' +
        this.props.apiKey +
        '&month=' +
        month +
        '&year=' +
        year,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'applcation/json',
        },
      },
    )
      .then(response => {
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          return response.json();
        }
      })
      .then(data => {
        console.log(data);
        this.setState({
          BusinessFinalSignOff: data.checklist.businessFinalSignOff,
          AccountingFinalSignOff: data.checklist.accountingFinalSignOff,
          BankAccountReconciliation: data.checklist.bankAccountReconciliation,
          InventoryReconciliationCOGS:
            data.checklist.inventoryReconciliationCOGS,
          AMZRevenueFees: data.checklist.amzRevenueFees,
          WalmartRevenueFees: data.checklist.walmartRevenueFees,
          ConsignmentBillingEntry: data.checklist.consignmentBillingEntry,
          ShippingAdjustment: data.checklist.shippingAdjustment,
          isLoading: false,
          Ceo: data.ceo,
          Cfo: data.cfo,
          Accountant: data.accountant,
        });
      })
      .catch(error => {
        console.error(error);
        try {
          error.text().then(errorText => console.error(errorText));
        } catch {}
        this.props.addAlert(
          'danger',
          'There was an error while retrieving the checklist.',
        );
      });
  };

  markCompleted = field => {
    this.setState({ isLoading: true });
    fetch(
      eformedApiBaseUri +
        'accounting/completed.json?key=' +
        this.props.apiKey +
        '&month=' +
        this.state.month +
        '&year=' +
        this.state.year +
        '&field=' +
        field,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
      .then(response => {
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          this.getChecklist(this.state.month, this.state.year);
        }
      })
      .catch(error => {
        console.error(error);
        try {
          error.text().then(errorText => console.error(errorText));
        } catch {}

        this.props.addAlert('danger', 'Changed not saved due to an error');
      });
  };

  disabledButton = (value, permission) => {
    if ((value !== undefined && value !== null) || permission === false) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    if (this.state.isLoading) {
      return (
        <div>
          <h1>Accounting Checklist</h1>
          <Form>
            <Row>
              <Col>
                <Select
                  label='Year'
                  value={this.state.year}
                  options={[
                    { label: '2018', value: '2018' },
                    { label: '2019', value: '2019' },
                    { label: '2020', value: '2020' },
                  ]}
                  onChange={newValue => {
                    this.setState({ year: newValue });
                    this.getChecklist(this.state.month, newValue);
                  }}
                />
              </Col>
              <Col>
                <Select
                  label='Month'
                  value={this.state.month}
                  options={[
                    { label: 'January', value: '1' },
                    { label: 'Feburary', value: '2' },
                    { label: 'March', value: '3' },
                    { label: 'April', value: '4' },
                    { label: 'May', value: '5' },
                    { label: 'June', value: '6' },
                    { label: 'July', value: '7' },
                    { label: 'August', value: '8' },
                    { label: 'September', value: '9' },
                    { label: 'October', value: '10' },
                    { label: 'November', value: '11' },
                    { label: 'December', value: '12' },
                  ]}
                  onChange={newValue => {
                    this.setState({ month: newValue });
                    this.getChecklist(newValue, this.state.year);
                  }}
                />
              </Col>
            </Row>
          </Form>
          <Container className='mt-5'>
            <LoadingPage />
          </Container>
        </div>
      );
    } else {
      return (
        <div>
          <h1>Accounting Checklist</h1>
          <Form>
            <Row>
              <Col>
                <Select
                  label='Year'
                  value={this.state.year}
                  options={[
                    { label: '2018', value: '2018' },
                    { label: '2019', value: '2019' },
                    { label: '2020', value: '2020' },
                  ]}
                  onChange={newValue => {
                    this.setState({ year: newValue });
                    this.getChecklist(this.state.month, newValue);
                  }}
                />
              </Col>
              <Col>
                <Select
                  label='Month'
                  value={this.state.month}
                  options={[
                    { label: 'January', value: '1' },
                    { label: 'Feburary', value: '2' },
                    { label: 'March', value: '3' },
                    { label: 'April', value: '4' },
                    { label: 'May', value: '5' },
                    { label: 'June', value: '6' },
                    { label: 'July', value: '7' },
                    { label: 'August', value: '8' },
                    { label: 'September', value: '9' },
                    { label: 'October', value: '10' },
                    { label: 'November', value: '11' },
                    { label: 'December', value: '12' },
                  ]}
                  onChange={newValue => {
                    this.setState({ month: newValue });
                    this.getChecklist(newValue, this.state.year);
                  }}
                />
              </Col>
            </Row>
          </Form>
          <p>Click on the item to sign off on it</p>
          <ListGroup>
            <CheckListItem
              name='BusinessFinalSignOff'
              label='Business Final Sign Off'
              value={this.state.BusinessFinalSignOff}
              permission={this.state.Ceo}
              disabledButton={this.disabledButton}
              markCompleted={this.markCompleted}
            />
            <CheckListItem
              name='AccountingFinalSignOff'
              label='Accounting Final Sign Off'
              value={this.state.AccountingFinalSignOff}
              permission={this.state.Cfo}
              disabledButton={this.disabledButton}
              markCompleted={this.markCompleted}
            />
            <CheckListItem
              name='BankAccountReconciliation'
              label='Bank Account Reconciliation'
              value={this.state.BankAccountReconciliation}
              permission={this.state.Accountant}
              disabledButton={this.disabledButton}
              markCompleted={this.markCompleted}
            />
            <CheckListItem
              name='InventoryReconciliationCOGS'
              label='Inventory Reconciliation with COGS'
              value={this.state.InventoryReconciliationCOGS}
              permission={this.state.Accountant}
              disabledButton={this.disabledButton}
              markCompleted={this.markCompleted}
            />
            <CheckListItem
              name='AMZRevenueFees'
              label='Amazon Revenue & Fees'
              value={this.state.AMZRevenueFees}
              permission={this.state.Accountant}
              disabledButton={this.disabledButton}
              markCompleted={this.markCompleted}
            />
            <CheckListItem
              name='WalmartRevenueFees'
              label='Walmart Revenue & Fees'
              value={this.state.WalmartRevenueFees}
              permission={this.state.Accountant}
              disabledButton={this.disabledButton}
              markCompleted={this.markCompleted}
            />
            <CheckListItem
              name='ConsignmentBillingEntry'
              label='Consignment Billing Entry'
              value={this.state.ConsignmentBillingEntry}
              permission={this.state.Accountant}
              disabledButton={this.disabledButton}
              markCompleted={this.markCompleted}
            />
            <CheckListItem
              name='ShippingAdjustment'
              label='Shipping Adjustment'
              value={this.state.ShippingAdjustment}
              permission={this.state.Accountant}
              disabledButton={this.disabledButton}
              markCompleted={this.markCompleted}
            />
          </ListGroup>
        </div>
      );
    }
  }
}

const CheckListItem = props => {
  return (
    <ListGroup.Item
      action
      disabled={props.disabledButton(props.value, props.permission)}
      onClick={() => props.markCompleted(props.name)}
    >
      <Row>
        <Col>{props.label}</Col>
        <Col>
          <Badge variant='primary'>{props.value || 'Not Completed'}</Badge>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};
