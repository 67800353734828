import React from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { RouteComponentProps } from "react-router";

import { TextField, TextArea } from "../../Shared/EFBootstrap";
import LoadingPage from "../../Shared/LoadingPage";
import * as Settings from "../../../Settings";
import BaseProps from "../../../interfaces/BaseProps";
import total from "../../../utils/Total";

import PurchaseOrder from "../../../classes/Logistics/PurchaseOrder";
import PurchaseOrderItem from "../../../classes/Logistics/PurchaseOrderItem";

import SelectCompany from "./CreatePurchaseOrders/SelectCompany";
import SelectVendor from "./CreatePurchaseOrders/SelectVendor";
import Dates from "./CreatePurchaseOrders/Dates";
import PurchaseOrderItemEdit from "./CreatePurchaseOrders/PurchaseOrderItemEdit";
import PoLineItemSearchAndAssignModal from "./CreatePurchaseOrders/ItemSearchAndAssign";

const eformedApiBaseUri = Settings.eformedApiBaseUri;

interface MatchParams {
  vendorId: string;
}

interface CreatePurchaseOrderProps
  extends BaseProps,
    RouteComponentProps<MatchParams> {}

interface CreatePurchaseOrderState {
  purchaseOrder: PurchaseOrder;
  items: Array<PurchaseOrderItem>;

  submitting: boolean;
  companyOptions: [];
  vendorOptions: [];
  isLoading: boolean;
  poItemAssignModalShow: boolean;
}

export default class CreatePurchaseOrder extends React.Component<
  CreatePurchaseOrderProps,
  CreatePurchaseOrderState
> {
  constructor(props) {
    super(props);
    this.state = {
      purchaseOrder: new PurchaseOrder("babywise-life.myshopify.com"),
      items: [new PurchaseOrderItem()],
      submitting: false,
      companyOptions: [],
      vendorOptions: [],
      isLoading: true,
      poItemAssignModalShow: false
    };
  }

  componentDidMount() {
    this.checkVendor();
    this.getOptions();
  }

  setParentState = stateObject => {
    this.setState(stateObject);
  };

  setPoItemAssignModalShow = () => {
    this.setState({ poItemAssignModalShow: !this.state.poItemAssignModalShow });
  }

  getOptions = () => {
    fetch(
      eformedApiBaseUri +
        "po/companies.json?key=" +
        this.props.apiKey +
        "&shop=babywise-life.myshopify.com&options=true"
    )
      .then(response => {
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          return response.json();
        }
      })
      .then(data => {
        this.setState({ companyOptions: data.companyOptions });
        fetch(
          eformedApiBaseUri +
            "po/vendors.json?key=" +
            this.props.apiKey +
            "&shop=babywise-life.myshopify.com&options=true"
        )
          .then(response => {
            if (response.status >= 400 && response.status <= 500) {
              throw response;
            } else {
              return response.json();
            }
          })
          .then(data => {
            this.setState({ vendorOptions: data.vendorOptions });
          })
          .catch(error => {
            this.setState({ isLoading: false });
            console.error(error);
            try {
              error.text().then(errorText => console.error(errorText));
            } catch {}
            this.props.addAlert(
              "danger",
              "There was an error while retrieving options."
            );
          });
        this.setState({ isLoading: false });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        console.error(error);
        try {
          error.text().then(errorText => console.error(errorText));
        } catch {}
        this.props.addAlert(
          "danger",
          "There was an error while retrieving prices."
        );
      });
  };

  checkVendor = () => {
    if (
      this.props.match.params.vendorId !== undefined &&
      this.props.match.params.vendorId !== null
    ) {
      const vendorId = this.props.match.params.vendorId;

      fetch(`
        ${eformedApiBaseUri}po/drafts/${vendorId}?key=${
        this.props.apiKey
      }&shop=babywise-life.myshopify.com`)
        .then(response => {
          return response.json();
        })
        .then(data => {
          console.log(data);
          this.setState({
            purchaseOrder: data.draft.purchaseOrder,
            items: data.draft.lineItems
          });
        });
    }
  };

  handleAddItem = (poItem: PurchaseOrderItem = new PurchaseOrderItem()) => {
    let items = this.state.items;
    if (items.length === 1 && items[0].itemNumber === undefined && items[0].sellerVariantId === undefined) {
      items = [poItem];
    } else {
      items = this.state.items.concat([poItem]);
    }
    this.setState({
      items: items
    });
  };

  handleDraftSubmit = () => {
    this.setState({ submitting: true });
    const { purchaseOrder, items } = this.state;

    let tempPurchaseOrder = purchaseOrder;
    tempPurchaseOrder.shop = "babywise-life.myshopify.com";

    var body = {
      draft: {
        shop: "babywise-life.myshopify.com",
        vendorId: tempPurchaseOrder.vendorId,
        purchaseOrder: tempPurchaseOrder,
        lineItems: items
      }
    };
    console.log(JSON.stringify(body));
    fetch(
      eformedApiBaseUri +
        "po/drafts.json?key=" +
        this.props.apiKey +
        "&shop=babywise-life.myshopify.com&vendorId=" +
        purchaseOrder.vendorId,
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    )
      .then(response => {
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          return response.json();
        }
      })
      .then(data => {
        this.props.addAlert("success", "Draft saved.");
        this.setState({ submitting: false });
      })
      .catch(error => {
        this.setState({ submitting: false });
        console.error(error);
        try {
          error.text().then(errorText => console.error(errorText));
        } catch {}
        this.props.addAlert(
          "danger",
          "There was an error while saving the draft."
        );
      });
  };

  handleSubmit = () => {
    this.setState({ submitting: true });
    const { purchaseOrder, items } = this.state;
    const history = this.props.history;

    if (
      purchaseOrder.companyId === undefined ||
      purchaseOrder.companyId === null ||
      purchaseOrder.vendorId === undefined ||
      purchaseOrder.vendorId === null
    ) {
      this.props.addAlert(
        "warning",
        "No company or vendor selected, please select one before saving."
      );
      this.setState({ submitting: false });
    } else {
      let tempPurchaseOrder: any = purchaseOrder;
      tempPurchaseOrder.shop = "babywise-life.myshopify.com";
      tempPurchaseOrder.lineItems = items;

      var body = {
        purchaseOrder: tempPurchaseOrder
      };

      fetch(
        eformedApiBaseUri +
          "po/purchaseorders?key=" +
          this.props.apiKey +
          "&shop=babywise-life.myshopify.com",
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        }
      )
        .then(response => {
          if (response.status >= 400 && response.status <= 500) {
            throw response;
          } else {
            return response.json();
          }
        })
        .then(data => {
          this.props.addAlert("success", "PO created.");
          history.push("/logistics/po");
        })
        .catch(error => {
          this.setState({ submitting: false });
          console.error(error);
          try {
            error.text().then(errorText => console.error(errorText));
          } catch {}
          this.props.addAlert(
            "danger",
            "There was an error while creating the PO."
          );
        });
    }
  };

  render() {
    if (this.state.isLoading) {
      return (
        <React.Fragment>
          <h1>Create Purchase Order</h1>
          <LoadingPage message="Loading in all of the company and vendor information..." />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h1>Create Purchase Order</h1>
          <PoLineItemSearchAndAssignModal
            apiKey={this.props.apiKey}
            addAlert={this.props.addAlert}
            handleAddItem={this.handleAddItem}
            show={this.state.poItemAssignModalShow}
            onHide={() => this.setPoItemAssignModalShow()}
          />
          <Form>
            <SelectCompany
              {...this.props}
              {...this.state}
              setParentState={this.setParentState}
            />
            <SelectVendor
              {...this.props}
              {...this.state}
              setParentState={this.setParentState}
            />
            <Dates
              {...this.props}
              {...this.state}
              setParentState={this.setParentState}
            />
            {/* <ItemSearch {...this.props} /> */}
            <Container className="mt-5">
              <Row>
                <Col>
                  <h5>Enter the items you wish to order</h5>
                </Col>
              </Row>
              {this.state.items.map((item, index) => (
                <PurchaseOrderItemEdit
                  key={index}
                  {...this.props}
                  {...this.state}
                  index={index}
                  item={item}
                  setParentState={this.setParentState}
                />
              ))}
            </Container>
            <Container>
              <Row>
                <Col className="align-self-center">
                  <Button
                    variant="outline-success"
                    block
                    onClick={() => this.handleAddItem()}
                  >
                    Add an Item
                  </Button>
                </Col>
                <Col className="align-self-center">
                  <Button
                    variant="outline-info"
                    block
                    onClick={() => this.setPoItemAssignModalShow()}
                  >
                    Search an Item
                  </Button>
                </Col>
                <Col>
                  <TextField
                    label="PO Level Discount"
                    value={this.state.purchaseOrder.poLevelDiscount}
                    onChange={newValue => {
                      var tempPO = { ...this.state.purchaseOrder };
                      tempPO.poLevelDiscount = newValue;
                      this.setState({ purchaseOrder: tempPO });
                    }}
                    type="number"
                    helpText="This will apply to items proportionally"
                    prefix="$"
                    step={0.02}
                  />
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                <Col>
                  <TextArea
                    label="Notes/Memo"
                    rows={2}
                    placeholder="Notes..."
                    value={this.state.purchaseOrder.notes || ""}
                    onChange={newValue => {
                      var tempPO = { ...this.state.purchaseOrder };
                      tempPO.notes = newValue;
                      this.setState({ purchaseOrder: tempPO });
                    }}
                  />
                </Col>
                <Col className="align-self-center">
                  <h2>
                    <p className="justify-content-center">
                      Total: ${" "}
                      {total(
                        this.state.items,
                        this.state.purchaseOrder.poLevelDiscount
                      ).toFixed(2)}
                    </p>
                  </h2>
                </Col>
              </Row>
            </Container>
            <Container className="mb-2">
              <hr />
              <Row>
                <Col>
                  <Button
                    block
                    variant="outline-primary"
                    onClick={this.handleDraftSubmit}
                    disabled={this.state.submitting}
                  >
                    {this.state.submitting === true ? (
                      <React.Fragment>
                        <Spinner
                          as="span"
                          size="sm"
                          animation="border"
                          role="status"
                        />
                        <span>Saving...</span>
                      </React.Fragment>
                    ) : (
                      "Save As Draft"
                    )}
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant="success"
                    block
                    onClick={this.handleSubmit}
                    disabled={this.state.submitting}
                  >
                    {this.state.submitting === true ? (
                      <React.Fragment>
                        <Spinner
                          as="span"
                          size="sm"
                          animation="border"
                          role="status"
                        />
                        <span>Creating...</span>
                      </React.Fragment>
                    ) : (
                      "Create Purchase Order"
                    )}
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </React.Fragment>
      );
    }
  }
}
