import uuidv4 from 'uuid/v4';

import MarketplaceProduct from '../classes/Marketplace/Product';
import MarketplaceProductImage from '../classes/Marketplace/Image';
import SellerVariant from '../classes/Seller/SellerVariant';
import MarketplaceProductTag from '../classes/Marketplace/Tag';
import MarketplaceVariant from '../classes/Marketplace/Variant';

import createVariantTitle from './CreateVariantTitle';

const createMarketplaceProductFromSellerVariant = async (
  sellerVariant: SellerVariant,
  marketplaceId: string,
): Promise<MarketplaceProduct> => {
  let product: MarketplaceProduct = new MarketplaceProduct(marketplaceId);

  await mapSellerVariantToProduct(product, sellerVariant);
  product.variants.push(
    await createMarketplaceVariantFromSellerVariant(sellerVariant, product.id),
  );

  return product;
};

const mapSellerVariantToProduct = async (
  product: MarketplaceProduct,
  sellerVariant: SellerVariant,
) => {
  product.id = uuidv4();
  product.archived = false;
  product.description = sellerVariant.description;

  [1, 2, 3, 4, 5].forEach(x => {
    let fieldName = `feature${x}`;
    product[fieldName] = sellerVariant[fieldName];
  });

  [1, 2, 3].forEach(x => {
    let fieldName = `option${x}`;
    product[fieldName] = sellerVariant[`${fieldName}Name`];
  });
  product.productType = sellerVariant.productType;
  product.tags = sellerVariant.tags.split(',').map(tag => {
    return new MarketplaceProductTag(tag.trim(), product.id);
  });
  product.title = sellerVariant.productTitle;
  product.vendor = sellerVariant.vendor;
  product.youtubeVideoIds = sellerVariant.youtubeVideoId;

  product.images = createProductImagesFromSellerVariantImages(product.id, sellerVariant);
};

export const createMarketplaceVariantFromSellerVariant = async (
  sellerVariant: SellerVariant,
  marketplaceProductId: string,
): Promise<MarketplaceVariant> => {
  let variant = new MarketplaceVariant();
  variant.marketplaceProductId = marketplaceProductId;
  variant.archived = false;
  await mapSellerVariantToVariant(variant, sellerVariant);

  return variant;
};

export const mapSellerVariantToVariant = async (
  variant: MarketplaceVariant,
  sellerVariant: SellerVariant,
) => {
  variant.barcode = sellerVariant.barcode;
  variant.inventoryPolicy = sellerVariant.inventoryPolicy;
  variant.compareAtPrice = sellerVariant.compareAtPrice;
  variant.cost = sellerVariant.cost;
  variant.dimH = sellerVariant.dimH;
  variant.dimL = sellerVariant.dimL;
  variant.dimW = sellerVariant.dimW;
  variant.dimUnit = sellerVariant.dimUnit;
  variant.leadTime = sellerVariant.leadTime;
  variant.map = sellerVariant.map;
  variant.mpn = sellerVariant.mpn;
  [1, 2, 3].forEach(x => {
    let fieldName = `option${x}`;
    variant[fieldName] = sellerVariant[`${fieldName}Value`];
  });
  variant.handlingCharge = sellerVariant.handlingCharge;
  variant.price = sellerVariant.price;
  variant.requiresShipping = true;
  variant.title = createVariantTitle(
    variant.option1,
    variant.option2,
    variant.option3,
  );
  variant.sku = sellerVariant.sku !== undefined && sellerVariant.sku !== null && sellerVariant.sku !== '' ?
    sellerVariant.sku : variant.title;
  variant.weight = sellerVariant.weight;
  variant.weightUnit = sellerVariant.weightUnit;
};

export const createProductImagesFromSellerVariantImages = (
  productId: string,
  sellerVariant: SellerVariant
  ): MarketplaceProductImage[] => {
  let images = new Array<MarketplaceProductImage>();

  ["featuredImageURL", "variantImageURL", "additionalImageURLs"].forEach(x => {
    if (
      sellerVariant[`${x}`] !== undefined &&
      sellerVariant[`${x}`] !== null &&
      sellerVariant[`${x}`] !== ""
      ) {
        sellerVariant[`${x}`].split(',').forEach(imageUrl => {
          images.push(mapVariantImageToProductImage(productId, imageUrl));
        })
      }
  });

  return images;
};

export const mapVariantImageToProductImage = (
  productId: string,
  imageUrl: string
  ): MarketplaceProductImage => {
  let image = new MarketplaceProductImage();
  image.marketplaceProductId = productId;
  image.originalImageSrc = imageUrl;
  image.fileExtension = imageUrl.substr(imageUrl.lastIndexOf('.') + 1);

  return image;
};

export default createMarketplaceProductFromSellerVariant;
