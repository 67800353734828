import React from 'react';
import { Row, Col, Collapse } from "react-bootstrap";

import RouteVariableProps from "../../../../interfaces/RouteVariableProps";
import setMarketplaceFromId from "../../../../utils/SetMarketplaceFromId";
import UpdateProductsEntry from "./UpdateProductsEntry";
import UpdateProductsResults from "./UpdateProductsResults";

interface MatchParams {
  marketplaceId: string;
}

export interface UpdateProductsProps extends RouteVariableProps<MatchParams>, UpdateProductsState {
  setResults: (any) => void;
}

export interface UpdateProductsState {
  results: any;
}

export default class UpdateProducts extends React.Component<RouteVariableProps<MatchParams>> {
  state = {
    results: undefined,
    hasResults: false
  }

  componentDidMount() {
    if (this.props.match.params.marketplaceId !== undefined) {
      this.initialSetMarketplace();
    }
  }

  initialSetMarketplace = async () => {
    let marketplaceId = this.props.match.params.marketplaceId;
    const { selectedMarketplace, setMarketplace, user } = this.props;

    if (selectedMarketplace === undefined) {
      await setMarketplaceFromId(
        marketplaceId,
        setMarketplace,
        user,
        this.props.history
      );
    }
  };

  setResults = results => {
    this.setState({ results: results, hasResults: true });
  }

  render() {
    const { hasResults } = this.state;

    return (
      <React.Fragment>
        <Row className="mt-5">
          <Col>
            <h1>Update Products</h1>
          </Col>
        </Row>
        <Collapse in={hasResults}>
          <Row>
            <Col>
              <UpdateProductsResults
                {...this.props}
                {...this.state}
                setResults={this.setResults}
              />
            </Col>
          </Row>
        </Collapse>
        <UpdateProductsEntry
          {...this.props}
          {...this.state}
          setResults={this.setResults}
        />
      </React.Fragment>
    )
  }
}