import React from 'react';
import { Badge } from 'react-bootstrap';

interface SellerLogStatusBadgeProps {
  status:
    | 'Processing'
    | 'Skipped'
    | 'Success'
    | 'Partial Failure'
    | 'Failure'
    | 'Timed Out'
    | 'Not Implemented';
}

const SellerLogStatusBadge = (props: SellerLogStatusBadgeProps) => {
  const { status } = props;
  return (
    <Badge pill variant={sellerLogStatusToBootstrapVariant(status)}>
      {status}
    </Badge>
  );
};

export const sellerLogStatusToBootstrapVariant = (
  status:
    | 'Processing'
    | 'Skipped'
    | 'Success'
    | 'Partial Failure'
    | 'Failure'
    | 'Timed Out'
    | 'Not Implemented',
):
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark' => {
  if (status === 'Success') {
    return 'success';
  } else if (status === 'Processing' || status === 'Skipped') {
    return 'dark';
  } else if (status === 'Partial Failure' || status === 'Timed Out') {
    return 'warning';
  } else if (status === 'Failure') {
    return 'danger';
  } else {
    return 'secondary';
  }
};

export default SellerLogStatusBadge;
