export default class rest {
  static get = async uri => {
    let response = await fetch(uri);
    if (response.status >= 400 && response.status <= 500) {
      throw response;
    } else {
      return await response.json();
    }
  };

  static post = async (uri, body) => {
    let response;
    if (body !== undefined && body !== null) {
      response = await fetch(uri, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    } else {
      response = await fetch(uri, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      });
    }
    if (response.status >= 400 && response.status <= 500) {
      throw response;
    } else {
      return await response.json();
    }
  };

  static put = async (uri, body, json = false) => {
    let response;
    if (body !== undefined && body !== null) {
      response = await fetch(uri, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    } else {
      response = await fetch(uri, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
        },
      });
    }

    if (response.status >= 400 && response.status <= 500) {
      throw response;
    } else {
      return json ? await response.json() : response;
    }
  };

  static delete = async uri => {
    let response = await fetch(uri, { method: 'DELETE' });
    if (response.status >= 400 && response.status <= 500) {
      throw response;
    } else {
      return response;
    }
  };
}
