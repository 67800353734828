import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Row, Col, Card, Button, Table } from 'react-bootstrap';

import * as Settings from '../../../Settings';
import BaseProps from '../../../interfaces/BaseProps';
import dateFormat from '../../../utils/DateFormat';
import LoadingPage from '../../Shared/LoadingPage';

const eformedApiBaseUri = Settings.eformedApiBaseUri;

interface MatchParams {
  poNumber: string;
}

interface ReconcilePurchaseOrderProps
  extends BaseProps,
    RouteComponentProps<MatchParams> {}

export default class ReconcilePurchaseOrder extends React.Component<
  ReconcilePurchaseOrderProps
> {
  state = {
    isLoading: true,
    purchaseOrder: {},
    rows: [],
  };

  componentDidMount() {
    this.getPurchaseOrder();
  }

  getPurchaseOrder = () => {
    this.setState({ isLoading: true });
    const poNumber = this.props.match.params.poNumber;

    if (poNumber !== undefined && poNumber !== null) {
      fetch(
        `${eformedApiBaseUri}po/purchaseorders/${poNumber}?key=${
          this.props.apiKey
        }&shop=babywise-life.myshopify.com`,
      )
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (
            data.purchaseOrder.shipByDate === undefined ||
            data.purchaseOrder.shipByDate === null
          ) {
            data.purchaseOrder.shipByDate = 'ASAP';
          }

          if (
            data.purchaseOrder.poLevelDiscount === undefined ||
            data.purchaseOrder.poLevelDiscount === null
          ) {
            data.purchaseOrder.poLevelDiscount = 0;
          }

          var rows = [];
          data.purchaseOrder.lineItems.forEach(item => {
            var received = 0;
            if (item.received !== undefined && item.received !== null) {
              received = item.received;
            }

            var row = [
              item.upc,
              item.sku,
              item.mpn,
              item.itemNumber,
              item.quantity,
              item.cost.toFixed(2),
              (item.quantity * item.cost).toFixed(2),
              received,
              this.closedButton(item.id, item.closed),
              dateFormat(item.lastReceivedAt),
              `${item.vendor} ${item.description}`,
            ];
            rows.push(row);
          });

          this.setState({
            purchaseOrder: data.purchaseOrder,
            rows: rows,
            isLoading: false,
          });
        });
    } else {
      this.props.addAlert('warning', 'No PO Number was included.');
    }
  };

  closedButton = (id, closed) => {
    if (closed === true) {
      return (
        <Button
          onClick={() => {
            this.closePOItem(id, false);
          }}
        >
          Open
        </Button>
      );
    } else {
      return (
        <Button
          variant='danger'
          onClick={() => {
            this.closePOItem(id, true);
          }}
        >
          Close
        </Button>
      );
    }
  };

  closePOItem = (id, closed) => {
    this.setState({ isLoading: true });
    fetch(
      eformedApiBaseUri +
        'po/lineitems.json?key=' +
        this.props.apiKey +
        '&shop=babywise-life.myshopify.com&id=' +
        id +
        '&closed=' +
        closed,
      { method: 'PUT' },
    )
      .then(response => {
        this.getPurchaseOrder();
      })
      .catch(error => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { purchaseOrder } = this.state;

    if (this.state.isLoading) {
      return <LoadingPage />;
    } else if (purchaseOrder === undefined || purchaseOrder === null) {
      return (
        <Row className='justify-content-center'>
          <Col>
            <Card>Nothing to show here...</Card>
          </Col>
        </Row>
      );
    } else {
      return (
        <PurchaseOrder
          purchaseOrder={this.state.purchaseOrder}
          rows={this.state.rows}
        />
      );
    }
  }
}

const PurchaseOrder = props => {
  const purchaseOrder = props.purchaseOrder;
  const poDate = dateFormat(purchaseOrder.poDate);
  let shipByDate;

  if (purchaseOrder.shipByDate === 'ASAP') {
    shipByDate = purchaseOrder.shipByDate;
  } else {
    shipByDate = dateFormat(purchaseOrder.shipByDate);
  }

  return (
    <React.Fragment>
      <h1>Reconcile a Purchase Order</h1>
      <h3>PO: {purchaseOrder.id}</h3>
      <h5>{purchaseOrder.vendor.name}</h5>
      <Row>
        <Col>
          <p>
            Date: {poDate} Ship by Date: {shipByDate}
          </p>
        </Col>
      </Row>
      <DataTable
        truncate={true}
        columnContentTypes={[
          'text',
          'text',
          'text',
          'numeric',
          'numeric',
          'numeric',
          'text',
          'text',
          'text',
        ]}
        headings={[
          'UPC',
          'SKU',
          'MPN',
          'OSC ID',
          'Ordered',
          'Unit Cost',
          'Line Total',
          'Received',
          'Open/Close',
          'Last Received',
          'Description',
        ]}
        rows={props.rows}
      />
    </React.Fragment>
  );
};

const DataTable = props => {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          {props.headings.map((heading, index) => (
            <th key={index}>{heading}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.rows.map((row, index) => (
          <tr key={index}>
            {row.map((item, iindex) => (
              <td key={iindex}>{item}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
