import rest from '../../../../../utils/Rest';
import errorHandler from '../../../../../utils/ErrorHandler';
import * as Settings from '../../../../../Settings';

const removeProductAssociations = async (props, product, setParentState) => {
  const { marketplaceId, productId } = props.match.params;
  const { apiKey } = props;

  try {
    await rest.delete(
      `${
        Settings.eformedApiBaseUri
      }marketplace/${marketplaceId}/SellerVariantMarketplaceVariants/product/${productId}?key=${apiKey}`,
    );

    let productTemp = product;
    let variants = product.variants.map(v => {
      v.sellerVariants = null;
      return v;
    });

    productTemp.variants = variants;

    await setParentState({ product: productTemp });
    props.addAlert("success", `Marketplace product associations successfully removed.`);
  } catch (error) {
    await errorHandler(
      error,
      'There was an error while removing the variant associations. Please try again, if this is not the first time you have seen this error, please report the issue.',
      props.addAlert,
    );
    await setParentState({ isLoading: false });
  }
};

export default removeProductAssociations;