import firebase from 'firebase/app';
import { eformedApiBaseUri } from '../Settings';

import rest from './Rest';
import errorHandler from './ErrorHandler';
import { Cookies } from 'react-cookie';

const config = {
  apiKey: 'AIzaSyAPDI7ybuzzbkVc7XwN3715C-udg-kuIOc',
  authDomain: 'eformed-api.firebaseapp.com',
  databaseURL: 'https://eformed-api.firebaseio.com',
  projectId: 'eformed-api',
  storageBucket: 'eformed-api.appspot.com',
  messagingSenderId: '774689104376',
};

export const firebaseApp = firebase.initializeApp(config);

export const unregisterAuthObserver = async (state, setAppState, addAlert) => {
  await firebaseApp.auth().onAuthStateChanged(async user => {
    await setAppState({ authenticated: !!user });
    await firebaseApp
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    if (user && state.apiKey === '') {
      var email = await firebaseApp.auth().currentUser.email;
      await setAppState({ username: email });
      let idToken = await firebaseApp.auth().currentUser.getIdToken(true);

      let body = {
        email: email,
        token: idToken,
      };
      try {
        let data = await rest.post(`${eformedApiBaseUri}auth/auth.json`, body);

        await setAppState({
          apiKey: data.apiKey,
          user: data,
        });

        const cookies = new Cookies();
        cookies.set('user-session', true, { path: '/', maxAge: 3600 });

      } catch (error) {
        await errorHandler(
          error,
          'Your user does not appear to be able to access any apps. Please reach out your eFormed contact with your email address used to login to gain access.',
          addAlert,
        );
      }
    }
  });
};