import React, { useState } from "react";
import { Card, Collapse, Table, Row, Col } from "react-bootstrap";

import Channel from '../../../../../classes/Marketplace/Channel';
import { PricingPanelProps } from './PricingPanel';
import VariantPricing from './VariantPricing';
import { getFormattedValue } from '../Functions/GetVariantPriceCalculations';

export interface ChannelPricingProps extends PricingPanelProps {
  channel: Channel;
}

const ChannelPricing = (props: ChannelPricingProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const {
    product,
    channel
  } = props;

  return (
    <Card className="mt-3 variant-pricing">
      <Card.Header onClick={() => setIsOpen(!isOpen)}>
        <Row>
          <Col xs='auto'><h6>{channel.name}</h6></Col>
          <Col><h6>{`(Commission: ${channel.commission}%, Min Margin: ${channel.minimumMargin}%, Threshold: ${getFormattedValue(channel.freeShippingThreshold)}, AVG Ship Rev: ${getFormattedValue(channel.avgShippingRevenue)})`}</h6></Col>
          {channel.isWholeChannel === true &&<Col xs='auto' style={{fontWeight: "bold", color: "black"}}>Wholesale: Cost x {channel.costMultiplier}</Col>}
        </Row>
      </Card.Header>
      <Collapse in={isOpen}>
        <Card.Body className='variant-pricing-table'>
          <Table bordered hover size="sm">
            <thead>
              <tr>
                <th>Variant</th>
                <th>Cost</th>
                <th>In</th>
                <th>Out</th>
                <th>MSRP</th>
                <th>MAP</th>
                {channel.isWholeChannel !== true && <th>Price</th>}
                <th>Handling</th>
                <th>Overide</th>
                <th>Floor</th>
                <th>Final</th>
              </tr>
            </thead>
            <tbody>
              {product.variants !== undefined && product.variants !== null && product.variants.length > 0 &&
              product.variants.map((variant, index) => (
                variant.archived !== true &&            
                <VariantPricing
                  key={index}
                  {...props}
                  index={index}
                  variant={variant}
                />
              ))
              }
            </tbody>
        </Table>
        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default ChannelPricing;
