import React from "react";
import { Row, Col, ListGroup, Button, Spinner } from "react-bootstrap";

import LoadingPage from "../../../Shared/LoadingPage";
import * as Settings from "../../../../Settings";
import TaxCode from "../../../../classes/Marketplace/TaxCode";
import rest from "../../../../utils/Rest";
import errorHandler from "../../../../utils/ErrorHandler";
import setMarketplaceFromId from "../../../../utils/SetMarketplaceFromId";
import { addUrlParam, removeAllUrlParams } from '../../../../utils/SetUrlParameters';

import RouteVariableProps from "../../../../interfaces/RouteVariableProps";

import TaxCodeView from "./TaxCode";
import TaxCodeFilters from "./TaxCodeFilters";

interface MatchParams {
  marketplaceId: string;
}

interface TaxCodesState {
  isInitialLoading: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  hasMore: boolean;
  hasLoaded: number;
  taxCodes: TaxCode[];
  genreCategoryFilter: string;
  gsIdFilter: string;
  gsDescFilter: string;
}

export default class TaxCodes extends React.Component<
  RouteVariableProps<MatchParams>,
  TaxCodesState
> {
  state = {
    isInitialLoading: true,
    isLoading: false,
    isLoadingMore: false,
    hasMore: false,
    hasLoaded: 0,
    taxCodes: [],
    genreCategoryFilter: '',
    gsIdFilter: '',
    gsDescFilter: ''
  };

  componentDidMount() {
    if (this.props.match.params.marketplaceId !== undefined) {
      this.initialSetMarketplace();
      this.getTaxCodes(0);
    }
  }

  initialSetMarketplace = async () => {
    let marketplaceId = this.props.match.params.marketplaceId;
    const { selectedMarketplace, setMarketplace, user } = this.props;
    this.setState({ isInitialLoading: true });

    if (selectedMarketplace === undefined) {
      await setMarketplaceFromId(
        marketplaceId,
        setMarketplace,
        user,
        this.props.history,
      );
    }
  };

  getTaxCodes = async (skip: number) => {
    const { marketplaceId } = this.props.match.params;
    const { apiKey } = this.props;
    const { genreCategoryFilter, gsIdFilter, gsDescFilter, taxCodes, hasLoaded } = this.state;

    if (skip === 0) {
      await this.setState({ isLoading: true });
    } else {
      await this.setState({ isLoadingMore: true });
    }

    try {      
      if(!this.state.isInitialLoading) {
        removeAllUrlParams(this.props);

        if (genreCategoryFilter !== undefined && genreCategoryFilter !== '') {
          addUrlParam(this.props, 'genreCategory', genreCategoryFilter.toLowerCase());
        }
        if (gsIdFilter !== undefined && gsIdFilter !== '') {
          addUrlParam(this.props, 'gsId', gsIdFilter.toLowerCase());
        }
        if (gsDescFilter !== undefined && gsDescFilter !== '') {
          addUrlParam(this.props, 'gsDesc', gsDescFilter.toLowerCase());
        }
      }

      let uri = `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/taxcodes?key=${apiKey}&skip=${skip}`;
      let searchParams = new URLSearchParams(this.props.location.search);

      searchParams.forEach((value: string, key: string) => {        
        let tempState = {...this.state, [key+'Filter']:value };
        this.setState(tempState);

        uri = uri + `&${key}=${value}`;
      });

      let data: TaxCode[] = await rest.get(uri);

      let tempResults = taxCodes;
      if (skip === 0) {
        tempResults = new Array<TaxCode>();
        await this.setState({ hasLoaded: 0 });
      }
      tempResults.push(...data);

      await this.setState({
        taxCodes: tempResults,
        hasLoaded: hasLoaded + data.length,
        isLoading: false,
        isLoadingMore: false,
      });

      if (data.length >= 25) {
        await this.setState({ hasMore: true });
      } else {
        await this.setState({ hasMore: false });
      }
    } catch (error) {
      errorHandler(
        error,
        "There was an error while retrieving the tax codes, please try again. If this is not the first error, please report the issue.",
        this.props.addAlert
      );
      this.setState({ isLoading: false });
    }
  };
  
  setParentState = stateObject => {
    this.setState(stateObject);
  };

  render() {
    const { isLoading, taxCodes, hasMore, hasLoaded, isLoadingMore } = this.state;
    
    return (
      <React.Fragment>
        <Row>
          <Col className="mb-3">
            <h1>Tax Codes</h1>
          </Col>
        </Row>
        <Row>
          <Col lg="auto">
            <TaxCodeFilters 
              getTaxCodes={this.getTaxCodes}
              setParentState={this.setParentState}            
              genreCategoryFilter={this.state.genreCategoryFilter}
              gsIdFilter={this.state.gsIdFilter}
              gsDescFilter={this.state.gsDescFilter}
            />
          </Col>
          <Col>
            <Row>
              {isLoading ? 
                <Col>
                  <LoadingPage />
                </Col>
                :
                <Col>
                  <ListGroup>
                    <ListGroup.Item active>
                      <Row>
                        <Col>Genre Category</Col>
                        <Col>GS ID</Col>
                        <Col>GS Desc</Col>
                        <Col>Sub Category Desc</Col>
                        <Col>Category Desc</Col>
                        <Col xs="auto" style={{ opacity: 0 }}>
                          <Button size="sm" variant="success" style={{ cursor: "unset" }}>Copy GS ID</Button>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    {taxCodes !== undefined && taxCodes.length > 0 ? (
                      taxCodes.map((taxCode, index) => (
                        <TaxCodeView key={index} taxCode={taxCode} />
                      ))
                    ) : (
                      <ListGroup.Item>No Tax Codes Found</ListGroup.Item>
                    )}
                  </ListGroup>
                </Col>
              }
            </Row>
          </Col>
        </Row>
        
        <Row className="mt-3 text-center">
          <Col>
            {hasMore && (
              <Button
                variant="outline-info"
                onClick={() => {
                  this.setState({ isInitialLoading: false });
                  this.getTaxCodes(hasLoaded)
                }}
              >
                {isLoadingMore ? (
                  <React.Fragment>
                    <Spinner size="sm" animation="border" />
                    Loading...
                  </React.Fragment>
                ) : (
                  "Load More..."
                )}
              </Button>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
