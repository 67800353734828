import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { TextField, Select } from '../../../Shared/EFBootstrap';

import * as Settings from '../../../../Settings';

const eformedApiBaseUri = Settings.eformedApiBaseUri;

const SelectVendor = props => {
  const handleVendorChange = newValue => {
    const { purchaseOrder } = props;
    const tempPO = purchaseOrder;
    if (newValue === 0) {
      tempPO.vendorId = newValue;
      tempPO.vendorCompany = '';
      tempPO.vendorEmailAddress = '';
      tempPO.vendorPhone = '';
      tempPO.vendorAddress = '';
      tempPO.vendorCity = '';
      tempPO.vendorState = '';
      tempPO.vendorZip = '';
      tempPO.vendorCountry = '';
      tempPO.vendorFreightTerms = '';
      tempPO.vendorPaymentTerms = '';
      props.setParentState({ purchaseOrder: tempPO });
    } else {
      tempPO.vendorId = newValue;
      fetch(
        `${eformedApiBaseUri}po/vendors/${newValue}?key=${
          props.apiKey
        }&shop=babywise-life.myshopify.com`,
      )
        .then(response => {
          if (response.status >= 400 && response.status <= 500) {
            throw response;
          } else {
            return response.json();
          }
        })
        .then(data => {
          tempPO.vendorCompany = data.vendor.name;
          tempPO.vendorEmailAddress = data.vendor.emailAddress;
          tempPO.vendorPhone = data.vendor.phone;
          tempPO.vendorAddress = data.vendor.address;
          tempPO.vendorCity = data.vendor.city;
          tempPO.vendorState = data.vendor.state;
          tempPO.vendorZip = data.vendor.zip;
          tempPO.vendorCountry = data.vendor.country;
          tempPO.vendorFreightTerms = data.vendor.freightTerms;
          tempPO.vendorPaymentTerms = data.vendor.paymentTerms;
          props.setParentState({ purchaseOrder: tempPO });
        })
        .catch(error => {
          props.setParentState({ isLoading: false });
          console.error(error);
          try {
            error.text().then(errorText => console.error(errorText));
          } catch {}
          props.addAlert(
            'danger',
            'There was an error while getting vendor info, please try again (reselect in the list). If this is not the first error, please report the issue.',
          );
        });
    }
  };

  return (
    <Container className='mt-5'>
      <h5>Select A Vendor</h5>
      <br />
      <Row>
        <Col>
          <Select
            label='Select Vendor'
            options={props.vendorOptions}
            value={props.purchaseOrder.vendorId}
            onChange={handleVendorChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            label='Company Name'
            value={props.purchaseOrder.vendorCompany}
            readOnly
          />
        </Col>
        <Col>
          <TextField
            label='Email Address'
            value={props.purchaseOrder.vendorEmailAddress}
            //type="email"
            readOnly
          />
        </Col>
        <Col>
          <TextField
            label='Phone'
            value={props.purchaseOrder.vendorPhone}
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            label='Address'
            value={props.purchaseOrder.vendorAddress}
            readOnly
          />
        </Col>
        <Col>
          <TextField
            label='City'
            value={props.purchaseOrder.vendorCity}
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            label='State/Province'
            value={props.purchaseOrder.vendorState}
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            label='Zip/Postal Code'
            value={props.purchaseOrder.vendorZip}
            readOnly
          />
        </Col>
        <Col>
          <TextField
            label='Country'
            value={props.purchaseOrder.vendorCountry}
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            label='Freight Terms'
            value={props.purchaseOrder.vendorFreightTerms}
            readOnly
          />
        </Col>
        <Col>
          <TextField
            label='Payment Terms'
            value={props.purchaseOrder.vendorPaymentTerms}
            readOnly
          />
        </Col>
      </Row>
    </Container>
  );
};

export default SelectVendor;
