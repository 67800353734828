import React from 'react';
import { Row, Col, Button, Spinner } from 'react-bootstrap';

import { EDIProps } from './EDI';
import EDIOrder from './EDIOrder';

export interface EDIOrdersProps extends EDIProps {
  getTransactions: (skip: number) => void;
}

const EDIOrders = (props: EDIOrdersProps) => {
  const { transactions, isLoadingMore, hasLoaded, getTransactions } = props;
  const orderIds = transactions
    .map(transaction => transaction.orderId)
    .filter(onlyUnique);
  return (
    <React.Fragment>
      <Row className='mt-4'>
        <Col>
          {orderIds.map((orderId, index) => (
            <EDIOrder key={index} {...props} orderId={orderId} />
          ))}
        </Col>
      </Row>
      <Row className='mt-3 text-center'>
        <Col>
          <Button
            variant='outline-info'
            onClick={() => getTransactions(hasLoaded)}
          >
            {isLoadingMore === true ? (
              <React.Fragment>
                <Spinner size='sm' animation='border' />
                Loading...
              </React.Fragment>
            ) : (
              'Load More...'
            )}
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default EDIOrders;

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
