import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import { TextField } from "../../../Shared/EFBootstrap";

import searchPoItem, { SearchKeys } from "../Items/SearchPoItem";

const PurchaseOrderItemEdit = props => {
  const handleRemoveItem = index => () => {
    props.setParentState({
      items: props.items.filter((i, iindex) => index !== iindex)
    });
  };

  const handleItemNumberChange = (index, searchKey) => async newValue => {
    let result = await searchPoItem(newValue, searchKey, props.apiKey);

    const newItems = props.items.map((item, iindex) => {
      if (index !== iindex) return item;
      return { ...item, ...result };
    });

    props.setParentState({ items: newItems });
  }

  const handleQuantityChange = index => newValue => {
    const newItems = props.items.map((item, iindex) => {
      if (index !== iindex) return item;
      return { ...item, quantity: newValue };
    });

    props.setParentState({ items: newItems });
  };

  const handleCostChange = index => newValue => {
    const newItems = props.items.map((item, iindex) => {
      if (index !== iindex) return item;
      return { ...item, cost: newValue };
    });

    props.setParentState({ items: newItems });
  };

  const handleCasePackChange = index => newValue => {
    const newItems = props.items.map((item, iindex) => {
      if (index !== iindex) return item;
      return { ...item, casePack: newValue };
    });

    props.setParentState({ items: newItems });
  };

  return (
    <Container className="mt-2" fluid key={props.index}>
      <Row>
        <Col>
          <TextField
            label="Seller Variant ID"
            placeholder="Seller Variant ID - Enter to Auto Fill Other Fields"
            value={props.item.sellerVariantId}
            onChange={handleItemNumberChange(props.index, SearchKeys.SELLER_VARIANT_KEY)}
          />
        </Col>
        <Col>
          <TextField
            label="OSC ID"
            placeholder="OSC ID - Enter to Auto Fill Other Fields"
            value={props.item.itemNumber}
            onChange={handleItemNumberChange(props.index, SearchKeys.OSC_ID_KEY)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            label="Vendor"
            placeholder="Vendor"
            value={props.item.vendor}
            readOnly
          />
        </Col>
        <Col>
          <TextField
            label="Description (Title)"
            placeholder="Description (Title)"
            value={props.item.description}
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            label="MPN"
            placeholder="MPN"
            value={props.item.mpn}
            readOnly
          />
        </Col>
        <Col>
          <TextField
            label="UPC"
            placeholder="UPC"
            value={props.item.upc}
            readOnly
          />
        </Col>
        <Col>
          <TextField
            label="SKU"
            placeholder="SKU"
            value={props.item.sku}
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            label="Case Pack"
            value={props.item.casePack}
            onChange={handleCasePackChange(props.index)}
            type="number"
            step={1}
            helpText="AKA Minimum Order Quantity"
          />
        </Col>
        <Col>
          <TextField
            label="Quantity"
            placeholder="Quantity"
            value={props.item.quantity}
            onChange={handleQuantityChange(props.index)}
            type="number"
            step={1}
            helpText="To Order"
          />
        </Col>
        <Col>
          <TextField
            label="Cost"
            placeholder="Cost"
            value={props.item.cost}
            onChange={handleCostChange(props.index)}
            type="number"
            prefix="$"
            helpText="Changing Cost=Item Discount"
            step={0.01}
          />
        </Col>
        <Col md="1" className="align-self-center">
          <Button
            variant="outline-danger"
            onClick={handleRemoveItem(props.index)}
          >
            x
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default PurchaseOrderItemEdit;
