import MarketplaceHelp from "../../Marketplace/MarketplaceHelp";
import MarketplaceOrders from "../../Marketplace/Orders/Orders";
import MarketplaceOrder from "../../Marketplace/Orders/Order";
import MarketplaceProducts from "../../Marketplace/Products/Products";
import AddImages from "../../Marketplace/Products/AddImages";
import MarketplaceProduct from "../../Marketplace/Products/Product";
import PreferredVendorNames from "../../Marketplace/Tools/PreferredVendorNames";
import MarketplaceSellers from "../../Marketplace/SellerVariants/MarketplaceSellers";
import MarketplaceSellerVariants from "../../Marketplace/SellerVariants/SellerVariants";
import BulkPricing from "../../Marketplace/Tools/BulkPricing/BulkPricing";
import ProductBuilder from "../../Marketplace/Tools/ProductBuilder/ProductBuilder";
import PriceScheduler from "../../Marketplace/Tools/PriceScheduler/PriceScheduler";
import PriceSchedulerView from "../../Marketplace/Tools/PriceSchedulerView/PriceSchedulerView";
import UpdateProducts from "../../Marketplace/Tools/UpdateProducts/UpdateProducts";
import UpdateVariants from "../../Marketplace/Tools/UpdateVariants/UpdateVariants";
import MarketplaceChannels from "../../Marketplace/Tools/MarketplaceChannels/MarketplaceChannels";
import Metafields from "../../Marketplace/Tools/Metafields/Metafields";
import TaxCodes from "../../Marketplace/Tools/TaxCodes/TaxCodes";
import WalmartInventoryFeeds from "../../Marketplace/WalmartFeeds/WalmartInventoryFeeds/WalmartFeeds";
import WalmartInventoryFeed from "../../Marketplace/WalmartFeeds/WalmartInventoryFeeds/WalmartFeed";
import WalmartPriceFeeds from "../../Marketplace/WalmartFeeds/WalmartPriceFeeds/WalmartFeeds";
import WalmartPriceFeed from "../../Marketplace/WalmartFeeds/WalmartPriceFeeds/WalmartFeed";
import WalmartPartnerFeeds from "../../Marketplace/WalmartFeeds/WalmartPartnerFeeds/WalmartFeeds";
import WalmartPartnerFeed from "../../Marketplace/WalmartFeeds/WalmartPartnerFeeds/WalmartFeed";
import DescriptionFormatter from "../../Marketplace/Tools/DescriptionFormatter/DescriptionFormatter";

const MarketplaceRoutes = [
  {
    exact: true,
    path: "/marketplace/:marketplaceId?",
    component: MarketplaceHelp
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/orders",
    component: MarketplaceOrders
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/orders/:orderId",
    component: MarketplaceOrder
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/products",
    component: MarketplaceProducts
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/products/:productId",
    component: MarketplaceProduct
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/products/:productId/addimages",
    component: AddImages
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/preferredvendors",
    component: PreferredVendorNames
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/tools/preferredvendors",
    component: PreferredVendorNames
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/sellers",
    component: MarketplaceSellers
  },
  {
    exact: true,
    path:
      "/marketplace/:marketplaceId/sellers/:sellerId/(variants)?/:sellerVariantId?",
    component: MarketplaceSellerVariants
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/tools/productbuilder",
    component: ProductBuilder
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/tools/pricescheduler",
    component: PriceScheduler
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/tools/priceSchedulerView",
    component: PriceSchedulerView
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/tools/updateproducts",
    component: UpdateProducts
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/tools/updatevariants",
    component: UpdateVariants
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/tools/bulkpricing",
    component: BulkPricing
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/tools/marketplacechannels",
    component: MarketplaceChannels
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/tools/metafields",
    component: Metafields
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/tools/taxcodes",
    component: TaxCodes
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/tools/descriptionFormatter",
    component: DescriptionFormatter
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/walmartinventoryfeeds",
    component: WalmartInventoryFeeds
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/walmartinventoryfeeds/:feedId",
    component: WalmartInventoryFeed
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/walmartpricefeeds",
    component: WalmartPriceFeeds
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/walmartpricefeeds/:feedId",
    component: WalmartPriceFeed
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/walmartpartnerfeeds",
    component: WalmartPartnerFeeds
  },
  {
    exact: true,
    path: "/marketplace/:marketplaceId/walmartpartnerfeeds/:feedId",
    component: WalmartPartnerFeed
  }
];

export default MarketplaceRoutes;
