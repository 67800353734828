import React from "react";
import { LinkContainer } from "react-router-bootstrap";

import dateFormat from "../../../../utils/DateFormat";
import total from "../../../../utils/Total";

const PrintablePurchaseOrder = props => {
  const purchaseOrder = props.purchaseOrder;
  var poDate = dateFormat(purchaseOrder.poDate);
  var shipByDate;

  if (purchaseOrder.shipByDate === "ASAP") {
    shipByDate = purchaseOrder.shipByDate;
  } else {
    shipByDate = dateFormat(purchaseOrder.shipByDate);
  }

  return (
    <React.Fragment>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr style={{ width: "100%" }}>
            <td style={{ width: "50%", verticalAlign: "top" }}>
              <h5>{purchaseOrder.vendor.name}</h5>
              <p>{purchaseOrder.vendor.address}</p>
              <p>
                {purchaseOrder.vendor.city}, {purchaseOrder.vendor.state}{" "}
                {purchaseOrder.vendor.zip} {purchaseOrder.vendor.country}
              </p>
              <br />
              <p>{purchaseOrder.vendor.emailAddress}</p>
              <p>{purchaseOrder.vendor.phone}</p>
              <br />
            </td>
            <td style={{ width: "50%", verticalAlign: "top" }}>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr style={{ width: "100%" }}>
                    <td style={{ width: "50%", verticalAlign: "top" }}>
                      <p>
                        <b>PO#</b>
                      </p>
                      <p>
                        <b>PO Date</b>
                      </p>
                      <p>
                        <b>Payment Terms</b>
                      </p>
                      <p>
                        <b>Ship By Date</b>
                      </p>
                      <p>
                        <b>Shipping Terms</b>
                      </p>
                      <br />
                    </td>
                    <td style={{ width: "50%", verticalAlign: "top", textAlign: "right" }}>
                      <p>&nbsp;{purchaseOrder.id}</p>
                      <p>&nbsp;{poDate}</p>
                      <p>&nbsp;{purchaseOrder.vendor.paymentTerms}</p>
                      <p>&nbsp;{shipByDate}</p>
                      <p>{purchaseOrder.vendor.freightTerms}</p>
                      <br />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr style={{ width: "100%" }}>
            <td style={{ width: "50%", verticalAlign: "top" }}>
              <h6>BILL TO</h6>
              <LinkContainer to="/logistics/po">
                <h5>{purchaseOrder.company.name}</h5>
              </LinkContainer>
              <p>{purchaseOrder.company.address}</p>
              <p>
                {purchaseOrder.company.city}, {purchaseOrder.company.state}{" "}
                {purchaseOrder.company.zip} {purchaseOrder.company.country}
              </p>
              <br />
              <p>{purchaseOrder.company.emailAddress}</p>
              <p>{purchaseOrder.company.phone}</p>
            </td>
            <td style={{ width: "50%", verticalAlign: "top" }}>
              <h6>SHIP TO</h6>
              <h5>{purchaseOrder.shipName}</h5>
              <p>{purchaseOrder.shipAddress}</p>
              <p>
                {purchaseOrder.shipCity}, {purchaseOrder.shipState}{" "}
                {purchaseOrder.shipZip} {purchaseOrder.shipCountry}
              </p>
              <br />
              <p>{purchaseOrder.shipEmailAddress}</p>
              <p>{purchaseOrder.shipPhone}</p>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <table style={{ width: "100%" }} cellSpacing="0" cellPadding="5">
        <thead>
          <tr style={{ width: "100%" }}>
            <th style={{ width: "13%", backgroundColor: "#DEE2E7" }}>
              <b>UPC</b>
            </th>
            <th style={{ width: "12%", backgroundColor: "#DEE2E7" }}>
              <b>SKU</b>
            </th>
            <th style={{ width: "9%", backgroundColor: "#DEE2E7" }}>
              <b>MPN</b>
            </th>
            <th style={{ width: "13%", backgroundColor: "#DEE2E7" }}>
              <b>OSC ID</b>
            </th>
            <th style={{ width: "22%", backgroundColor: "#DEE2E7" }}>
              <b>Description</b>
            </th>
            <th style={{ width: "9%", backgroundColor: "#DEE2E7" }}>
              <b>Qty</b>
            </th>
            <th style={{ width: "11%", backgroundColor: "#DEE2E7" }}>
              <b>Unit Cost</b>
            </th>
            <th style={{ width: "11%", backgroundColor: "#DEE2E7" }}>
              <b>Line Total</b>
            </th>
          </tr>
        </thead>
        <tbody>
          {purchaseOrder.lineItems.map((item, index) => (
            <tr style={{ width: "100%", border: 1 }} key={index}>
              <td style={{ width: "13%" }}>{item.upc}</td>
              <td style={{ width: "12%" }}>{item.sku}</td>
              <td style={{ width: "9%" }}>{item.mpn}</td>
              <td style={{ width: "13%" }}>{item.itemNumber}</td>
              <td style={{ width: "22%" }}>{item.vendor} {item.description}</td>
              <td style={{ width: "9%", textAlign: "center" }}>{item.quantity}</td>
              <td style={{ width: "11%", textAlign: "center" }}>
                $ {item.cost.toFixed(2)}
              </td>
              <td style={{ width: "11%", textAlign: "center" }}>
                $ {(item.quantity * item.cost).toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <table style={{ width: "100%" }} cellSpacing="0" cellPadding="10">
        <tbody>
          <tr style={{ width: "100%", verticalAlign: "top" }}>
            <td style={{ width: "50%" }} />
            <td style={{ width: "25%", backgroundColor: "#DEE2E7" }}>
              <h5>Discount:</h5>
              <br />
              <h4>
                <b>Total</b>
              </h4>
            </td>
            <td style={{ width: "25%", backgroundColor: "#DEE2E7", textAlign: "right" }}>
              <h4>
                ${" "}
                {purchaseOrder.poLevelDiscount < 0
                  ? "(" + purchaseOrder.poLevelDiscount.toFixed(2) + ")"
                  : purchaseOrder.poLevelDiscount.toFixed(2)}
              </h4>
              <br />
              <h4>
                <b>
                  ${" "}
                  {total(
                    purchaseOrder.lineItems,
                    purchaseOrder.poLevelDiscount
                  ).toFixed(2)}
                </b>
              </h4>
            </td>
          </tr>
        </tbody>
      </table>
      <table style={{ width: "100%" }} cellSpacing="0" cellPadding="10">
        <tbody>
          <tr style={{ width: "100%", verticalAlign: "top" }}>
            <td style={{ width: "10%", backgroundColor: "#DEE2E7" }}>
              <h6>Notes</h6>
            </td>
            <td style={{ width: "90%" }}>
              <table style={{ width: "100%", border: "0" }}>
                <tbody>
                  <tr style={{ width: "100%", border: "1" }}>
                    <td style={{ width: "100%" }}>{purchaseOrder.notes}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default PrintablePurchaseOrder;
