import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { TextField, Select } from '../../../Shared/EFBootstrap';
import SearchFilter from '../../../Shared/SearchFilterComponents';

import { MarketplaceProductsChildProps } from '../Products';

interface SelectOption {
  label: string;
  value: string | number;
}

const SearchFilters = (props: MarketplaceProductsChildProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const {
    isDownloading,
    channels,
    getProducts,
    exportCsv,
    setParentState,
    orderByFilter,
    ignoredFilter,
    archivedFilter,
    publishedFilter,
    channelFilter,
    vendorFilter,
    skuFilter,
    barcodeFilter,
    mpnFilter,
    errorFilter,
    titleFilter,
    isTitleContainsFilter,
    inStockFilter,
    qualityControlFilter,
  } = props;

  const channelFilterBool = (publishedFilter !== undefined && ["published", "publishedlive", "publishednotlive", "unpublished"].includes(publishedFilter));

  let channelOptions: SelectOption[] = [{label: "Any", value: undefined}];

  channelOptions = channelOptions.concat(
    channels !== undefined && channels !== null && channels.length > 0 ?
    channels.map(channel => {
      return { label: channel.name, value: channel.id };
    }) : []
  );

  const changeisTitleContainsFilter = (newValue) => {
    setParentState({ isTitleContainsFilter: newValue });

    localStorage.setItem("isTitleContainsFilter", newValue);
  }

  const formattedIsTitleContainsFilter = isTitleContainsFilter === 'false' ? false : (isTitleContainsFilter === 'true' ? true : isTitleContainsFilter);

  const defaultStatusValues = {
    isInitialLoading: false,
    orderByFilter: 'updatedatdesc',
    ignoredFilter: false,
    archivedFilter: 'Any',
    publishedFilter: 'Any',
    channelFilter: 'Any',
    vendorFilter: '',
    titleFilter: '',
    skuFilter: '',
    barcodeFilter: '',
    mpnFilter: '',
    errorFilter: 'Any',
    inStockFilter: 'Any',
    qualityControlFilter: 'Any'
  }

  return (
    <SearchFilter 
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      enableDownload={true}
      isDownloading={isDownloading}
      defaultStatus={defaultStatusValues}
      setParentState={setParentState}
      exportCsv={exportCsv}
      getResults={getProducts}
      advancedDownloadOptions={true}
    >
      <Row className='mt-2'>
        <Col style={{height: "111px"}}>
          <div className="mr-1 input-with-clear">
            <TextField
              label='Vendor'
              placeholder='Vendor'
              value={vendorFilter}
              onChange={newValue =>
                setParentState({ vendorFilter: newValue })
              }
              helpText='Not Case Sensitive, Starts With'
              onEnterKeyPress={() => {
                setParentState({ isInitialLoading: false });
                getProducts(0);
              }}
            />
            <span
              className="input-close-icon"
              onClick={() => setParentState({ vendorFilter: '' })}
              style={{float: "right", right: "5px", top: "-75px"}}
            >
              &times;
            </span>
          </div>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col style={{height: "111px"}}>
          <div className="mr-1 input-with-clear">
            <TextField
              label='Title'
              placeholder='Title'
              checkBoxValue={formattedIsTitleContainsFilter}
              labelCheckBox={true}
              onChangeCheckBox={newValue =>
                changeisTitleContainsFilter(newValue)
              }
              value={titleFilter}
              onChange={newValue =>
                setParentState({ titleFilter: newValue })
              }
              helpText={formattedIsTitleContainsFilter ? 'Not Case Sensitive, Contains' : 'Not Case Sensitive, Starts With'}
              onEnterKeyPress={() => {
                setParentState({ isInitialLoading: false });
                getProducts(0);
              }}
            />
            <span
              className="input-close-icon"
              onClick={() => setParentState({ titleFilter: '' })}
              style={{float: "right", right: "5px", top: "-75px"}}
            >
              &times;
            </span>
          </div>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <Select
            label='Order By'
            options={[
              { label: 'Updated At - Desc', value: 'updatedatdesc' },
              { label: 'Vendor (A-Z)', value: 'vendor' },
              { label: 'Title (A-Z)', value: 'title' }
            ]}
            value={orderByFilter}
            onChange={newValue =>
              setParentState({
                orderByFilter: newValue,
              })
            }
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <Select
            label='Ignored'
            options={[
              { label: 'Any', value: undefined },
              { label: 'Ignored', value: true },
              { label: 'Not Ignored', value: false },
            ]}
            value={ignoredFilter}
            onChange={newValue =>
              setParentState({
                ignoredFilter: newValue === 'Any' ? undefined : newValue,
              })
            }
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <Select
            label='Archived'
            options={[
              { label: 'Any', value: undefined },
              { label: 'Archived', value: true },
              { label: 'Not Archived', value: false },
            ]}
            value={archivedFilter}
            onChange={newValue =>
              setParentState({
                archivedFilter: newValue === 'Any' ? undefined : newValue,
              })
            }
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <Select
            label='Published'
            options={[
              { label: 'Any', value: undefined },
              { label: 'Published', value: 'published' },
              { label: 'Published & Live', value: 'publishedlive' },
              { label: 'Published & Not Live', value: 'publishednotlive' },
              { label: 'Unpublished', value: 'unpublished' },
              { label: 'Needs Review', value: 'needsreview' },
            ]}
            value={publishedFilter}
            onChange={newValue =>
              setParentState({
                publishedFilter:
                  newValue === 'Any' ? undefined : newValue,
              })
            }
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <Select
            label='Channel'
            readOnly={!channelFilterBool}
            options={channelOptions}
            value={channelFilter}
            onChange={newValue =>
              setParentState({
                channelFilter:
                  newValue === 'Any' ? undefined : newValue,
              })
            }
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col style={{height: "111px"}}>
          <div className="mr-1 input-with-clear">
            <TextField
              label='SKU'
              placeholder='SKU'
              value={skuFilter}
              onChange={newValue =>
                setParentState({ skuFilter: newValue })
              }
              helpText='Not Case Sensitive, Starts With'
              onEnterKeyPress={() => {
                setParentState({ isInitialLoading: false });
                getProducts(0);
              }}
            />
            <span
              className="input-close-icon"
              onClick={() => setParentState({ skuFilter: '' })}
              style={{float: "right", right: "5px", top: "-75px"}}
            >
              &times;
            </span>
          </div>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col style={{height: "111px"}}>
          <div className="mr-1 input-with-clear">
            <TextField
              label='Barcode'
              placeholder='Barcode'
              value={barcodeFilter}
              onChange={newValue =>
                setParentState({ barcodeFilter: newValue })
              }
              helpText='Not Case Sensitive, Starts With'
              onEnterKeyPress={() => {
                setParentState({ isInitialLoading: false });
                getProducts(0);
              }}
            />
            <span
              className="input-close-icon"
              onClick={() => setParentState({ barcodeFilter: '' })}
              style={{float: "right", right: "5px", top: "-75px"}}
            >
              &times;
            </span>
          </div>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col style={{height: "111px"}}>
          <div className="mr-1 input-with-clear">
            <TextField
              label='MPN'
              placeholder='MPN'
              value={mpnFilter}
              onChange={newValue =>
                setParentState({ mpnFilter: newValue })
              }
              helpText='Not Case Sensitive, Starts With'
              onEnterKeyPress={() => {
                setParentState({ isInitialLoading: false });
                getProducts(0);
              }}
            />
            <span
              className="input-close-icon"
              onClick={() => setParentState({ mpnFilter: '' })}
              style={{float: "right", right: "5px", top: "-75px"}}
            >
              &times;
            </span>
          </div>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <Select
            label='Error'
            options={[
              { label: 'Any', value: undefined },
              { label: 'Currently Erroring', value: true },
              { label: 'Not Erroring', value: false },
            ]}
            value={errorFilter}
            onChange={newValue =>
              setParentState({
                errorFilter: newValue === 'Any' ? undefined : newValue,
              })
            }
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <Select
            label='In Stock'
            options={[
              { label: 'Any', value: undefined },
              { label: 'In Stock', value: true },
              { label: 'Out of Stock', value: false },
              { label: 'Unknown', value: 'unknown' },
            ]}
            value={inStockFilter}
            onChange={newValue =>
              setParentState({
                inStockFilter: newValue === 'Any' ? undefined : newValue,
              })
            }
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <Select
            label='Quality Control'
            options={[
              { label: 'Any', value: undefined },
              { label: 'No Images', value: '1' },
              { label: 'Short Description', value: '2' },
              { label: 'Overide Prices', value: '3' },
              { label: 'Missing Cost', value: '4' },
              { label: 'Missing Weight/Dimensions', value: '5' },
              { label: 'With Zero Associations', value: '6' },
            ]}
            value={qualityControlFilter}
            onChange={newValue =>
              setParentState({
                qualityControlFilter: newValue === 'Any' ? undefined : newValue,
              })
            }
          />
        </Col>
      </Row>
    </SearchFilter>
  );
};

export default SearchFilters;
