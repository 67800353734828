import * as Settings from "../../../../Settings";

const eformedApiBaseUri = Settings.eformedApiBaseUri;

export const SearchKeys = {
  SELLER_VARIANT_KEY: 'sellervariantid',
  OSC_ID_KEY: 'oscid'
}

const handleItemNumberChange = async (newValue: string, searchKey: string, apiKey: string) => {
  let keyLength = 10;
  let searchParam = `id=${newValue}`;

  if (searchKey === SearchKeys.SELLER_VARIANT_KEY) {
    keyLength = 32;
    searchParam = `sellerVariantId=${newValue}`
  }

  let keyValueObj: Object;
  let otherValueObj: Object;

  // default values
  if (searchKey === SearchKeys.SELLER_VARIANT_KEY) {
    keyValueObj = {
      sellerVariantId: newValue,
      itemNumber: ''
    };
  } else {
    keyValueObj = {
      itemNumber: newValue,
      sellerVariantId: ''
    };
  }

  otherValueObj = {
    vendor: "",
    description: "No Item Found",
    mpn: "",
    upc: "",
    sku: "",
    casePack: 0,
    quantity: 0,
    cost: 0
  };

  if (newValue.length >= keyLength) {
    await fetch(`${eformedApiBaseUri}po/items.json?key=${apiKey}&shop=babywise-life.myshopify.com&${searchParam}`)
      .then(response => {
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (!data.item.isNullOrUndefined) {
          if (searchKey === SearchKeys.SELLER_VARIANT_KEY) {
            keyValueObj = {
              sellerVariantId: newValue,
              itemNumber: data.item.itemNumber
            };
          } else {
            keyValueObj = {
              itemNumber: newValue,
              sellerVariantId: data.item.sellerVariantId
            };
          }
          otherValueObj = {
            vendor: data.item.vendor || "",
            description: data.item.description || "",
            mpn: data.item.mpn || "",
            upc: data.item.upc || "",
            sku: data.item.sku || "",
            casePack: data.item.casePack || 0,
            quantity: data.item.casePack || 0,
            cost: data.item.cost || 0
          };
        }
      })
      .catch(error => {
        return { ...keyValueObj, ...otherValueObj };
      });
  }

  return { ...keyValueObj, ...otherValueObj };
};

export default handleItemNumberChange;