export default class LogisticsCompanyPermission {
  constructor(id, name) {
    this.id = id;
    this.name = name;
    this.default = false;
  }

  id: string;
  name: string;
  default: boolean;
}
