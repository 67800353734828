import OrderLineItem from './OrderLineItem';
import Channel from './Channel';

export default class Order {
  id: string;
  marketplaceId: string;
  marketplaceChannelId: string;
  createdAt: string;
  updatedAt: string;
  orderedAt: string;
  cancelledAt: string;
  syncedAt: string;
  syncStatus:
    | 'Created'
    | 'Succeeded'
    | 'Failed'
    | 'Processed'
    | 'Partially Processed'
    | 'Cancelled';
  externalId: string;
  externalOrderNumber: string;
  customerFirstName: string;
  customerLastName: string;
  email: string;
  phone: string;
  shippingFirstName: string;
  shippingLastName: string;
  shipToCompany: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  billingAddress: string;
  billingAddress2: string;
  billingCity: string;
  billingState: string;
  billingPostalCode: string;
  paymentType: string;
  cardNo: string;
  subtotal: number;
  shippingCost: number;
  shippingCode: string;
  taxes: number;
  total: number;
  orderNotes: string;
  orderStatus:
    | 'Created'
    | 'Cancelled'
    | 'Deleted'
    | 'Shipped';
  paymentStatus:
    | 'Pending'
    | 'Partially Paid'
    | 'Paid'
    | 'Partially Refunded'
    | 'Refunded'
    | 'Voided';
  fulfillmentStatus: 
    | 'Fulfilled'
    | 'Partially Fulfilled';
  orderShippingAction: number;
  risky: boolean;
  riskApproved: boolean;
  lineItems: OrderLineItem[];
  marketplaceChannel: Channel;
}

export const ORDER_SHIPPING_ACTION = {
  NO_SHIPPING_REQUIRED: 0
}
