import React from 'react';

import MetafieldModel from '../../../../classes/Marketplace/Metafield';

import { MetafieldsRouteProps } from './Metafields';

interface MetafieldSetAsNonEditableModelProps extends MetafieldsRouteProps {
  metafield: MetafieldModel;
  show: boolean;
  saveMetafield: (metafield: MetafieldModel) => void;
  saveNonEditableMetafieldModal: (metafield: MetafieldModel, show: boolean) => void;
}

const MetafieldSetAsNonEditableModel = (props: MetafieldSetAsNonEditableModelProps) => {
  const { show, metafield, saveMetafield, saveNonEditableMetafieldModal } = props;
  
  return (
    <div role="dialog" aria-modal="true" className="fade modal show" style={{display: show ? "block" : "none", backgroundColor: "#00000094"}} onClick={() => saveNonEditableMetafieldModal(undefined, false)}>
      <div role="document" className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title h4">Are you sure?</div>
            <button type="button" className="close" onClick={() => saveNonEditableMetafieldModal(undefined, false)}>
              <span aria-hidden="true" >×</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body">
            <p className="text-dark">You are going to set this metafield as non-editable metafield. You won't be able to edit or delete it again.</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => saveNonEditableMetafieldModal(undefined, false)}>Close</button>
            <button type="button" className="btn btn-danger" onClick={() => {
              saveMetafield(metafield);
              saveNonEditableMetafieldModal(undefined, false);
            }}>Save</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetafieldSetAsNonEditableModel;
