import MarketplaceSeller from "../Marketplace/MarketplaceSeller";

export default class Seller {
  constructor(id, name) {
    this.id = id;
    this.name = name;
    this.marketplaceSellers = new Array<MarketplaceSeller>();
  }

  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  tag: string;
  website: string;
  orderEmailAddress: string;
  orderCCEmailAddress: string;
  orderIncludeCustomerEmail?: boolean;
  orderIncludeShippingPhone?: boolean;
  thresholdInventory: number;
  inboundShipping: number;
  active: boolean;
  leadTime: number;
  thirdPartySeller: boolean;
  mainSyncType: string;
  orderSyncType: string;
  ftpAddress: string;
  ftpPort: string;
  ftpUsername: string;
  ftpPassword: string;
  ftpProductFilename: string;
  ftpInventoryFilename: string;
  ftpMappingFilename: string;
  shopifyHost: string;
  shopifyToken: string;
  isPoSyncEnable: boolean;
  vendors: string[];
  marketplaceSellers: MarketplaceSeller[];
}
