import React from "react";
import { Row, Col } from "react-bootstrap";

import { BulkInventoryProps } from "./BulkInventory";

const BulkInventoryResults = (props: BulkInventoryProps) => {
  const { results } = props;
  return (
    <React.Fragment>
        <Row className="mt-3">
        <Col>
          <h3>Results</h3>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <h5>Messages</h5>
        </Col>
      </Row>
      {results !== undefined &&
        results.messages.map((message, index) => (
          <Row key={index}>
            <Col>{message}</Col>
          </Row>
        ))}
    </React.Fragment>
  );
};

export default BulkInventoryResults;