import React from 'react';
import { Row, Col, ListGroup, Badge } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import * as Settings from '../../../Settings';
import RouteVariableProps from '../../../interfaces/RouteVariableProps';
import rest from '../../../utils/Rest';
import errorHandler from '../../../utils/ErrorHandler';
import setMarketplaceFromId from '../../../utils/SetMarketplaceFromId';
import LoadingPage from '../../Shared/LoadingPage';

import MarketplaceSeller from '../../../classes/Marketplace/MarketplaceSeller';

interface MatchParams {
  marketplaceId: string;
}

export default class MarketplaceSellers extends React.Component<
  RouteVariableProps<MatchParams>
> {
  state = {
    isLoading: false,
    marketplaceSellers: new Array<MarketplaceSeller>(),
  };

  componentDidMount() {
    if (this.props.match.params.marketplaceId !== undefined) {
      this.initialSetMarketplace();
      this.getSellers();
    }
  }

  initialSetMarketplace = async () => {
    let marketplaceId = this.props.match.params.marketplaceId;
    const { selectedMarketplace, setMarketplace, user } = this.props;

    if (selectedMarketplace === undefined) {
      await setMarketplaceFromId(
        marketplaceId,
        setMarketplace,
        user,
        this.props.history,
      );
    }
  };

  getSellers = async () => {
    const { marketplaceId } = this.props.match.params;
    const { apiKey } = this.props;
    await this.setState({ isLoading: true });
    try {
      let data: MarketplaceSeller[] = await rest.get(
        `${
          Settings.eformedApiBaseUri
        }marketplace/${marketplaceId}/sellers?key=${apiKey}`,
      );
      await this.setState({
        marketplaceSellers: data.sort((a, b) => {
          if (a.sellerName < b.sellerName) {
            return -1;
          } else if (a.sellerName > b.sellerName) {
            return 1;
          } else {
            return 0;
          }
        }),
        isLoading: false,
      });
    } catch (error) {
      errorHandler(
        error,
        'There was an error while retrieving sellers. Please try again, if this is not the first time you have seen this error, please report it.',
        this.props.addAlert,
      );
      await this.setState({ isLoading: false });
    }
  };

  render() {
    const { marketplaceId } = this.props.match.params;
    const { isLoading, marketplaceSellers } = this.state;
    if (isLoading) {
      return (
        <React.Fragment>
          <MarketplaceSellersHeader />
          <LoadingPage />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <MarketplaceSellersHeader />
          <Row className='mt-5'>
            <Col>
              <ListGroup>
                {marketplaceSellers !== undefined &&
                  marketplaceSellers.length > 0 &&
                  marketplaceSellers.map((marketplaceSeller, index) => (
                    <LinkContainer
                      key={index}
                      to={`/marketplace/${marketplaceId}/sellers/${
                        marketplaceSeller.sellerId
                      }`}
                    >
                      <ListGroup.Item action>
                        <Row>
                          <Col md={marketplaceSeller.active && marketplaceSeller.isNewVariantsAvailable === true ? "8" : "10"}>{marketplaceSeller.sellerName}</Col>
                          {marketplaceSeller.active && marketplaceSeller.isNewVariantsAvailable === true && 
                            <LinkContainer to={`/marketplace/${marketplaceId}/sellers/${
                              marketplaceSeller.sellerId
                            }?orderBy=updatedatdesc&associated=false&inStock=true`}>
                              <Col md="2" style={{textAlign: "right"}}>
                                <span data-toggle="tooltip" title="Unassociate and In stock items">
                                  NEW ITEMS
                                </span>
                              </Col>
                            </LinkContainer>
                          }
                          <Col md="2" xs='auto' style={{textAlign: "right"}}>
                            {marketplaceSeller.active === false && (
                              <Badge pill variant='danger'>
                                Inactive
                              </Badge>
                            )}
                            <Badge pill variant='warning'>
                              {marketplaceSeller.mainSyncType}
                            </Badge>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    </LinkContainer>
                  ))}
              </ListGroup>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
}

const MarketplaceSellersHeader = () => {
  return (
    <Row>
      <Col>
        <h1>Marketplace Sellers</h1>
      </Col>
    </Row>
  );
};
