export default class MarketplaceProductImage {
  id: string;
  marketplaceProductId: string;
  handle: string;
  fileExtension: string;
  originalImageSrc: string;
  position: number;
  createdAt: string;
  updatedAt: string;
}
