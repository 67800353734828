import * as React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { Select, TextArea } from "../../../Shared/EFBootstrap";

import * as Settings from "../../../../Settings";
import BaseProps from "../../../../interfaces/BaseProps";
import LoadingPage from "../../../Shared/LoadingPage";

const eformedApiBaseUri = Settings.eformedApiBaseUri;

interface SearchPurchaseOrderItemsProps extends BaseProps {}

const searchTypes = [
  {
    label: "OSC ID Fuzzy Match",
    value: "itemnumberfuzzy",
    helpText:
      "Input all or part of a single OSC ID and items will be returned if any part of the OSC ID matches."
  },
  {
    label: "OSC IDs",
    value: "itemnumbers",
    helpText:
      "Input OSC IDs separated by new lines and each will return a single result if matched."
  },
  {
    label: "MPNs",
    value: "mpn",
    helpText:
      "Input MPNs separated by new lines and each will return a single result if matched."
  },
  {
    label: "UPCs",
    value: "upc",
    helpText:
      "Input UPCs separated by new lines and each will return a single result if matched."
  }
];

export default class SearchPurchaseOrderItems extends React.Component<
  SearchPurchaseOrderItemsProps
> {
  state = {
    searchType: searchTypes[0].value,
    searchValue: "",
    results: [
      {
        itemNumber: "",
        mpn: "",
        upc: "",
        description: "",
        casePack: "",
        cost: ""
      }
    ],
    isLoading: false
  };

  handleSubmit = () => {
    this.setState({ isLoading: true });
    const { searchType, searchValue } = this.state;

    const searchArray = searchValue.split("\n");

    this.setState({ results: [] });

    if (searchType === "itemnumberfuzzy") {
      searchArray.forEach(this.getItemFuzzyResults);
    } else if (searchType === "itemnumbers") {
      searchArray.forEach(this.getItemNumberResult);
    } else if (searchType === "mpn") {
      searchArray.forEach(this.getMPNResult);
    } else if (searchType === "upc") {
      searchArray.forEach(this.getUPCResult);
    }

    this.setState({ isLoading: false });
  };

  getItemFuzzyResults = search => {
    fetch(
      eformedApiBaseUri +
        "po/items.json?key=" +
        this.props.apiKey +
        "&shop=babywise-life.myshopify.com&type=fuzzy&id=" +
        search
    )
      .then(response => {
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data !== undefined && data !== null) {
          if (data.items.length > 0) {
            const { results } = this.state;
            let tempResults = results;
            console.log(data.items);
            tempResults = data.items;

            this.setState({ results: tempResults });
          } else {
            const noResultTemp = {
              itemNumber: "",
              mpn: "",
              upc: "",
              description: "",
              casePack: "",
              cost: ""
            };
            noResultTemp.itemNumber = "No Results";

            this.setState({ results: [noResultTemp] });
          }
        } else {
          const noResultTemp = {
            itemNumber: "",
            mpn: "",
            upc: "",
            description: "",
            casePack: "",
            cost: ""
          };
          noResultTemp.itemNumber = "No Results";

          this.setState({ results: [noResultTemp] });
        }
      })
      .catch(error => {
        const noResultTemp = {
          itemNumber: "",
          mpn: "",
          upc: "",
          description: "",
          casePack: "",
          cost: ""
        };
        noResultTemp.itemNumber = "No Results";

        this.setState({ results: [noResultTemp] });
      });
  };

  getItemNumberResult = search => {
    fetch(
      eformedApiBaseUri +
        "po/items.json?key=" +
        this.props.apiKey +
        "&shop=babywise-life.myshopify.com&id=" +
        search
    )
      .then(response => {
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data !== undefined && data !== null) {
          if (data.item !== undefined && data.item !== null) {
            const { results } = this.state;
            results.push(data.item);

            this.setState({ results: results });
          } else {
            const { results } = this.state;
            const noResultTemp = {
              itemNumber: "",
              mpn: "",
              upc: "",
              description: "",
              casePack: "",
              cost: ""
            };
            noResultTemp.itemNumber = search;
            noResultTemp.description = "No Result";
            results.push(noResultTemp);

            this.setState({ results: results });
          }
        } else {
          const { results } = this.state;
          const noResultTemp = {
            itemNumber: "",
            mpn: "",
            upc: "",
            description: "",
            casePack: "",
            cost: ""
          };
          noResultTemp.itemNumber = search;
          noResultTemp.description = "No Result";
          results.push(noResultTemp);

          this.setState({ results: results });
        }
      })
      .catch(error => {
        const { results } = this.state;
        const noResultTemp = {
          itemNumber: "",
          mpn: "",
          upc: "",
          description: "",
          casePack: "",
          cost: ""
        };
        noResultTemp.itemNumber = search;
        noResultTemp.description = "No Result";
        results.push(noResultTemp);

        this.setState({ results: results });
      });
  };

  getMPNResult = search => {
    fetch(
      eformedApiBaseUri +
        "po/items.json?key=" +
        this.props.apiKey +
        "&shop=babywise-life.myshopify.com&mpn=" +
        search
    )
      .then(response => {
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data !== undefined && data !== null) {
          if (data.item !== undefined && data.item !== null) {
            const { results } = this.state;
            results.push(data.item);

            this.setState({ results: results });
          } else {
            const { results } = this.state;
            const noResultTemp = {
              itemNumber: "",
              mpn: "",
              upc: "",
              description: "",
              casePack: "",
              cost: ""
            };
            noResultTemp.itemNumber = search;
            noResultTemp.description = "No Result";
            results.push(noResultTemp);

            this.setState({ results: results });
          }
        } else {
          const { results } = this.state;
          const noResultTemp = {
            itemNumber: "",
            mpn: "",
            upc: "",
            description: "",
            casePack: "",
            cost: ""
          };
          noResultTemp.mpn = search;
          noResultTemp.description = "No Result";
          results.push(noResultTemp);

          this.setState({ results: results });
        }
      })
      .catch(error => {
        const { results } = this.state;
        const noResultTemp = {
          itemNumber: "",
          mpn: "",
          upc: "",
          description: "",
          casePack: "",
          cost: ""
        };
        noResultTemp.itemNumber = search;
        noResultTemp.description = "No Result";
        results.push(noResultTemp);

        this.setState({ results: results });
      });
  };

  getUPCResult = search => {
    fetch(
      eformedApiBaseUri +
        "po/items.json?key=" +
        this.props.apiKey +
        "&shop=babywise-life.myshopify.com&upc=" +
        search
    )
      .then(response => {
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data !== undefined && data !== null) {
          if (data.item !== undefined && data.item !== null) {
            const { results } = this.state;
            results.push(data.item);

            this.setState({ results: results });
          } else {
            const { results } = this.state;
            const noResultTemp = {
              itemNumber: "",
              mpn: "",
              upc: "",
              description: "",
              casePack: "",
              cost: ""
            };
            noResultTemp.itemNumber = search;
            noResultTemp.description = "No Result";
            results.push(noResultTemp);

            this.setState({ results: results });
          }
        } else {
          const { results } = this.state;
          const noResultTemp = {
            itemNumber: "",
            mpn: "",
            upc: "",
            description: "",
            casePack: "",
            cost: ""
          };
          noResultTemp.upc = search;
          noResultTemp.description = "No Result";
          results.push(noResultTemp);

          this.setState({ results: results });
        }
      })
      .catch(error => {
        const { results } = this.state;
        const noResultTemp = {
          itemNumber: "",
          mpn: "",
          upc: "",
          description: "",
          casePack: "",
          cost: ""
        };
        noResultTemp.upc = search;
        noResultTemp.description = "No Result";
        results.push(noResultTemp);

        this.setState({ results: results });
      });
  };

  formatResults = results => {
    const formattedArray = results.map((result, index) =>
      this.formatResult(result)
    );
    return formattedArray.join("\n");
  };

  formatResult = result => {
    return (
      (result.sellerVariantId || "") +
      "\t" +
      (result.itemNumber || "") +
      "\t" +
      (result.mpn || "") +
      "\t" +
      (result.upc || "") +
      "\t" +
      (result.sku || "") +
      "\t" +
      (result.vendor || "") +
      "\t" +
      (result.description || "") +
      "\t" +
      (result.cost || 0) +
      "\t" +
      (result.casePack || 0)
    );
  };

  render() {
    if (this.state.isLoading) {
      return (
        <React.Fragment>
          <h1>Search Purchase Order Items</h1>
          <LoadingPage />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h1>Search Purchase Order Items</h1>
          <Form>
            <Row>
              <Col>
                <Select
                  label="Type of Search"
                  options={searchTypes}
                  onChange={newValue => this.setState({ searchType: newValue })}
                  value={this.state.searchType}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h6>What to do</h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  {
                    searchTypes.find(s => s.value === this.state.searchType)
                      .helpText
                  }
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <TextArea
                  label="Search"
                  rows={10}
                  value={this.state.searchValue}
                  onChange={newValue =>
                    this.setState({ searchValue: newValue })
                  }
                />
              </Col>
              <Col className="align-self-center">
                <Button block onClick={this.handleSubmit}>
                  Search
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">SV ID</Col>
              <Col className="text-center">Item</Col>
              <Col className="text-center">MPN</Col>
              <Col className="text-center">UPC</Col>
              <Col className="text-center">SKU</Col>
              <Col className="text-center">Vendor</Col>
              <Col className="text-center">Description</Col>
              <Col className="text-center">Unit Cost</Col>
              <Col className="text-center">Quantity</Col>
            </Row>
            <Row>
              <Col>
                <TextArea
                  readOnly
                  style={{whiteSpace: "pre"}}
                  rows={10}
                  value={this.formatResults(this.state.results)}
                />
              </Col>
            </Row>
          </Form>
        </React.Fragment>
      );
    }
  }
}
