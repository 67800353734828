import React from 'react';
import { Row, Col, Button, Spinner } from 'react-bootstrap';

import * as Settings from '../../../Settings';
import RouteVariableProps from '../../../interfaces/RouteVariableProps';
import rest from '../../../utils/Rest';
import errorHandler from '../../../utils/ErrorHandler';
import setMarketplaceFromId from '../../../utils/SetMarketplaceFromId';
import { addUrlParam, removeAllUrlParams } from '../../../utils/SetUrlParameters';
import LoadingPage from '../../Shared/LoadingPage';

import MarketplaceSeller from '../../../classes/Marketplace/MarketplaceSeller';
import SellerVariant from '../../../classes/Seller/SellerVariant';

import SellerVariantHeader from './SellerVariantHeader';
import SellerVariantFilters from './SellerVariantFilters';
import SellerVariantCard from './SellerVariantCard';

interface MatchParams {
  marketplaceId: string;
  sellerId: string;
  sellerVariantId: string;
}

export interface MarketplaceSellerVariantsProps
  extends RouteVariableProps<MatchParams> {}

export interface MarketplaceSellerVariantsState {
  isDownloading: boolean;
  isInitialLoading: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  hasMore: boolean;
  hasLoaded: number;
  seller: MarketplaceSeller;
  sellerVariants: SellerVariant[];
  orderByFilter: string;
  vendorFilter: string;
  productTitleFilter: string;
  skuFilter: string;
  mpnFilter: string;
  barcodeFilter: string;
  associatedFilter?: string;
  inStockFilter?: boolean;
  inventoryPolicyFilter?: string;
  archivedFilter?: boolean;
  ignoredFilter?: boolean;
  isProductTitleContainsFilter?: boolean;
  connectionsFilter: string;
}

export default class MarketplaceSellerVariants extends React.Component<
  MarketplaceSellerVariantsProps,
  MarketplaceSellerVariantsState
> {
  state = {
    isDownloading: false,
    isInitialLoading: true,
    isLoading: false,
    isLoadingMore: false,
    hasMore: false,
    hasLoaded: 0,
    seller: new MarketplaceSeller(),
    sellerVariants: new Array<SellerVariant>(),
    orderByFilter: 'title',
    vendorFilter: '',
    productTitleFilter: '',
    skuFilter: '',
    mpnFilter: '',
    barcodeFilter: '',
    associatedFilter: undefined,
    inStockFilter: undefined,
    inventoryPolicyFilter: undefined,
    archivedFilter: undefined,
    ignoredFilter: false,
    isProductTitleContainsFilter: false,
    connectionsFilter: undefined,
  };

  componentDidMount() {
    if (localStorage.getItem("sellervariants_isTitleContainsFilter") !== null) {
      this.setState({ isProductTitleContainsFilter: localStorage.getItem("sellervariants_isTitleContainsFilter") === "true" ? true : false });
    }

    if (this.props.match.params.marketplaceId !== undefined) {
      this.initialSetMarketplace();
      this.getSeller();
      if (this.props.match.params.sellerVariantId === undefined) {
        this.getSellerVariants(0);
      } else {
        this.getSellerVariant();
      }
    }
  }

  initialSetMarketplace = async () => {
    let marketplaceId = this.props.match.params.marketplaceId;
    const { selectedMarketplace, setMarketplace, user } = this.props;
    this.setState({ isInitialLoading: true });

    if (selectedMarketplace === undefined) {
      await setMarketplaceFromId(
        marketplaceId,
        setMarketplace,
        user,
        this.props.history,
      );
    }
  };

  getSeller = async () => {
    const { marketplaceId, sellerId } = this.props.match.params;
    const { apiKey } = this.props;
    try {
      let data: MarketplaceSeller = await rest.get(
        `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/sellers/${sellerId}?key=${apiKey}`,
      );
      await this.setState({
        seller: data,
      });
    } catch (error) {
      errorHandler(
        error,
        'There was an error while retrieving sellers. Please try again, if this is not the first time you have seen this error, please report it.',
        this.props.addAlert,
      );
    }
  };

  getSellerVariants = async (skip: number = 0) => {
    const { marketplaceId, sellerId } = this.props.match.params;
    const { apiKey } = this.props;
    const {
      sellerVariants,
      hasLoaded,
      ignoredFilter,
    } = this.state;
    try {
      if (skip === 0) {
        await this.setState({ isLoading: true });
      } else {
        await this.setState({
          isLoadingMore: true,
        });
      }

      if(!this.state.isInitialLoading) {
        removeAllUrlParams(this.props);      
        if (this.state.orderByFilter !== undefined) {
          addUrlParam(this.props, 'orderBy', this.state.orderByFilter.toString());
        }
        if (this.state.vendorFilter.length > 0) {
          addUrlParam(this.props, 'vendor', this.state.vendorFilter.toLowerCase());
        }
        if (this.state.productTitleFilter.length > 0) {
          addUrlParam(this.props, 'productTitle', this.state.productTitleFilter.toLowerCase());
        }        
        if (this.state.isProductTitleContainsFilter !== undefined && this.state.isProductTitleContainsFilter !== null) {
          addUrlParam(this.props, 'isTitleContains', this.state.isProductTitleContainsFilter.toString());
        }
        if (this.state.skuFilter.length > 0) {
          addUrlParam(this.props, 'sku', this.state.skuFilter.toLowerCase());
        }
        if (this.state.mpnFilter.length > 0) {
          addUrlParam(this.props, 'mpn', this.state.mpnFilter.toLowerCase());
        }
        if (this.state.barcodeFilter.length > 0) {
          addUrlParam(this.props, 'barcode', this.state.barcodeFilter.toLowerCase());
        }
        if (this.state.associatedFilter !== undefined && this.state.associatedFilter !== 'Any') {
          addUrlParam(this.props, 'associated', this.state.associatedFilter);
        }
        if (this.state.inStockFilter !== undefined && this.state.inStockFilter !== 'Any') {
          addUrlParam(this.props, 'inStock', this.state.inStockFilter);
        }
        if (this.state.inventoryPolicyFilter !== undefined && this.state.inventoryPolicyFilter !== 'Any') {
          addUrlParam(this.props, 'inventoryPolicy', this.state.inventoryPolicyFilter);
        }
        if (this.state.archivedFilter !== undefined && this.state.archivedFilter !== 'Any') {
          addUrlParam(this.props, 'archived', this.state.archivedFilter);
        }
        if (ignoredFilter !== undefined) {
          addUrlParam(this.props, 'ignored', ignoredFilter.toString());
        }
        if (this.state.connectionsFilter !== undefined && this.state.connectionsFilter !== 'Any') {
          addUrlParam(this.props, 'connections', this.state.connectionsFilter);
        }
      } else {
        let preSearchParams = new URLSearchParams(this.props.location.search);
        if (preSearchParams.get('orderBy') === null) {
          addUrlParam(this.props, 'orderBy', this.state.orderByFilter.toString());
        }
        if (preSearchParams.get('ignored') === null) {
          addUrlParam(this.props, 'ignored', ignoredFilter.toString());
        }
      }

      let uri = `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/sellers/${sellerId}/sellervariants?key=${apiKey}&skip=${skip}`;
      let searchParams = new URLSearchParams(this.props.location.search);

      searchParams.forEach((value: string, key: string) => {        
        let tempState= {...this.state, [key+'Filter']:value };
        this.setState(tempState);

        uri = uri + `&${key}=${value}`;
      });
      
      let data: SellerVariant[] = await rest.get(uri);
      let tempVariants = sellerVariants;
      if (skip === 0) {
        tempVariants = new Array<SellerVariant>();
      }
      tempVariants.push(...data);
      await this.setState({
        sellerVariants: tempVariants,
        isLoading: false,
        isLoadingMore: false,
        hasLoaded: hasLoaded + data.length,
      });
    } catch (error) {
      errorHandler(
        error,
        'There was an error while retrieving seller variants. Please try again, if this is not the first time you have seen this error, please report it.',
        this.props.addAlert,
      );
      await this.setState({
        isLoading: false,
        isLoadingMore: false,
      });
    }
  };

  exportCsv = async (limitCount: number, downloadAll: boolean = false) => {
    const { marketplaceId, sellerId } = this.props.match.params;
    const { apiKey } = this.props;
    const {
      vendorFilter,
      orderByFilter,
      productTitleFilter,
      isProductTitleContainsFilter,
      skuFilter,
      mpnFilter,
      barcodeFilter,
      associatedFilter,
      inStockFilter,
      inventoryPolicyFilter,
      archivedFilter,
      ignoredFilter,
      connectionsFilter,
    } = this.state;

    try {
      let uri = `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/sellers/${sellerId}/sellervariants/download?key=${apiKey}&limitCount=${limitCount}&isallrecords=${downloadAll}`;

      if (orderByFilter !== undefined) {
        uri = uri + `&orderBy=${encodeURIComponent(orderByFilter.toString())}`;
      }
      if (vendorFilter.length > 0) {
        uri = uri + `&vendor=${encodeURIComponent(vendorFilter.toLowerCase())}`;
      }
      if (productTitleFilter.length > 0) {
        uri = uri + `&productTitle=${encodeURIComponent(productTitleFilter.toLowerCase())}`;
      }
      if (isProductTitleContainsFilter !== undefined && isProductTitleContainsFilter !== null) {
        uri = uri + `&isTitleContains=${encodeURIComponent(isProductTitleContainsFilter.toString())}`;
      }
      if (skuFilter.length > 0) {
        uri = uri + `&sku=${encodeURIComponent(skuFilter.toLowerCase())}`;
      }
      if (mpnFilter.length > 0) {
        uri = uri + `&mpn=${encodeURIComponent(mpnFilter.toLowerCase())}`;
      }
      if (barcodeFilter.length > 0) {
        uri = uri + `&barcode=${encodeURIComponent(barcodeFilter.toLowerCase())}`;
      }
      if (associatedFilter !== undefined && associatedFilter !== 'Any') {
        uri = uri + `&associated=${encodeURIComponent(associatedFilter)}`;
      }
      if (inStockFilter !== undefined && inStockFilter !== 'Any') {
        uri = uri + `&inStock=${encodeURIComponent(inStockFilter)}`;
      }
      if (inventoryPolicyFilter !== undefined && inventoryPolicyFilter !== 'Any') {
        uri = uri + `&inventoryPolicy=${encodeURIComponent(inventoryPolicyFilter)}`;
      }
      if (archivedFilter !== undefined && archivedFilter !== 'Any') {
        uri = uri + `&archived=${encodeURIComponent(archivedFilter.toString())}`;
      }
      if (ignoredFilter !== undefined) {
        uri = uri + `&ignored=${encodeURIComponent(ignoredFilter.toString())}`;
      }
      if (connectionsFilter !== undefined && connectionsFilter !== 'Any') {
        uri = uri + `&connections=${encodeURIComponent(connectionsFilter)}`;
      }

      if (downloadAll == true) {
        let response = await fetch(uri);
        if (response.status >= 400) {
          throw response;
        }

        this.props.addAlert("success", "When download file is ready, you will be informed through teams, Downloads Channel");
      } else {
        await fetch(uri).then(response => {
          response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'seller_variants.csv';
            a.click();
          });
          // window.location.href = response.url;
        });
      }
      
      this.setState({ isDownloading: false });
    } catch (error) {
      errorHandler(
        error,
        'There was an error while downloading. Please try again, if this is not the first time you have seen this error, please report it.',
        this.props.addAlert,
      );
      this.setState({ isDownloading: false });
    }
  }

  getSellerVariant = async () => {
    const {
      marketplaceId,
      sellerId,
      sellerVariantId,
    } = this.props.match.params;
    const { apiKey } = this.props;
    const { sellerVariants } = this.state;
    await this.setState({ isLoading: true });
    try {
      let data: SellerVariant = await rest.get(
        `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/sellers/${sellerId}/sellervariants/${sellerVariantId}?key=${apiKey}`,
      );
      let tempVariants = sellerVariants;
      tempVariants.push(data);
      await this.setState({
        sellerVariants: tempVariants,
        isLoading: false,
        isLoadingMore: false,
        hasLoaded: 1,
      });
    } catch (error) {
      errorHandler(
        error,
        'There was an error while retrieving seller variants. Please try again, if this is not the first time you have seen this error, please report it.',
        this.props.addAlert,
      );
      await this.setState({
        isLoading: false,
        isLoadingMore: false,
      });
    }
  };

  setParentState = (stateObject: any) => {
    this.setState(stateObject);
  };

  render() {
    const { marketplaceId } = this.props.match.params;
    const {
      isLoading,
      isLoadingMore,
      hasLoaded,
      seller,
      sellerVariants,
    } = this.state;
    if (isLoading) {
      return (
        <React.Fragment>
          <SellerVariantHeader />
          <Row className='mt-3'>
            <Col lg='auto'>
              <SellerVariantFilters
                {...this.props}
                {...this.state}
                getSellerVariants={this.getSellerVariants}
                setParentState={this.setParentState}
                exportCsv={this.exportCsv}
              />
            </Col>
            <Col>
              <LoadingPage />
            </Col>
          </Row>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <SellerVariantHeader sellerName={seller.sellerName} />
          <Row className='mt-3'>
            <Col lg='auto'>
              <SellerVariantFilters
                {...this.props}
                {...this.state}
                getSellerVariants={this.getSellerVariants}
                setParentState={this.setParentState}
                exportCsv={this.exportCsv}
              />
            </Col>
            <Col className="overflow-hidden">
              {sellerVariants === undefined || sellerVariants.length === 0
                ? 'No Seller Variants Found.'
                : sellerVariants.map((sellerVariant, index) => (
                    <SellerVariantCard
                      key={index}
                      marketplaceId={marketplaceId}
                      sellerVariant={sellerVariant}
                      getSellerVariants={this.getSellerVariants}
                      setParentState={this.setParentState}
                      {...this.props}
                    />
                  ))}
            </Col>
          </Row>
          <Row className='mt-5 text-center'>
            <Col>
              <Button
                variant='outline-info'
                onClick={() => this.getSellerVariants(hasLoaded)}
              >
                {isLoadingMore === true ? (
                  <React.Fragment>
                    <Spinner size='sm' animation='border' />
                    Loading...
                  </React.Fragment>
                ) : (
                  'Load More...'
                )}
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
}
