import React, { useState } from 'react';
import { Row, Col, Collapse } from 'react-bootstrap';
import { TextField } from '../../../Shared/EFBootstrap';
import { SearchFilterHeader, SearchFilterFooter } from '../../../Shared/SearchFilterComponents';

interface TaxCodeFilterProps {
  genreCategoryFilter: string;
  gsIdFilter: string;
  gsDescFilter: string;
  getTaxCodes: (skip: number) => void;
  setParentState: (stateObject: any) => void;
}

const TaxCodeFilters = (props: TaxCodeFilterProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const {
    genreCategoryFilter,
    gsIdFilter,
    gsDescFilter,
    getTaxCodes,
    setParentState
  } = props;

  const defaultStatusValues = {
    isInitialLoading: false,
    genreCategoryFilter: '',
    gsIdFilter: '',
    gsDescFilter: ''
  }

  return (
    <React.Fragment>
      <SearchFilterHeader 
        isOpen={isOpen}
        setParentState={setParentState}
        getResults={getTaxCodes}
        setIsOpen={setIsOpen}
      />
      <Collapse in={isOpen}>
        <Row>
          <Col>
            <Row className='mt-2'>
              <Col style={{height: "111px"}}>
                <div className="mr-1 input-with-clear">
                  <TextField
                    label='GS ID'
                    placeholder='GS ID'
                    value={gsIdFilter}
                    onChange={newValue =>
                      setParentState({ gsIdFilter: newValue })
                    }
                    helpText='Not Case Sensitive, Starts With'
                    onEnterKeyPress={() => {
                      setParentState({ isInitialLoading: false });
                      getTaxCodes(0);
                    }}
                  />
                  <span
                    className="input-close-icon"
                    onClick={() => setParentState({ gsIdFilter: '' })}
                    style={{float: "right", right: "5px", top: "-75px"}}
                  >
                    &times;
                  </span>
                </div>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col style={{height: "111px"}}>
                <div className="mr-1 input-with-clear">
                  <TextField
                    label='Genre Category'
                    placeholder='Genre Category'
                    value={genreCategoryFilter}
                    onChange={newValue =>
                      setParentState({ genreCategoryFilter: newValue })
                    }
                    helpText='Not Case Sensitive, Starts With'
                    onEnterKeyPress={() => {
                      setParentState({ isInitialLoading: false });
                      getTaxCodes(0);
                    }}
                  />
                  <span
                    className="input-close-icon"
                    onClick={() => setParentState({ genreCategoryFilter: '' })}
                    style={{float: "right", right: "5px", top: "-75px"}}
                  >
                    &times;
                  </span>
                </div>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col style={{height: "111px"}}>
                  <div className="mr-1 input-with-clear">
                  <TextField
                    label='GS Desc'
                    placeholder='GS Desc'
                    value={gsDescFilter}
                    onChange={newValue =>
                      setParentState({ gsDescFilter: newValue })
                    }
                    helpText='Not Case Sensitive, Starts With'
                    onEnterKeyPress={() => {
                      setParentState({ isInitialLoading: false });
                      getTaxCodes(0);
                    }}
                  />
                  <span
                    className="input-close-icon"
                    onClick={() => setParentState({ gsDescFilter: '' })}
                    style={{float: "right", right: "5px", top: "-75px"}}
                  >
                    &times;
                  </span>
                </div>
              </Col>
            </Row>
            <SearchFilterFooter 
              defaultStatus={defaultStatusValues}
              setParentState={setParentState}
              getResults={getTaxCodes}
            />
          </Col>
        </Row>
      </Collapse>
    </React.Fragment>
  );
}

export default TaxCodeFilters;