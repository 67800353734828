export default class LogisticsCompany {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }

  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}
