import React from "react";
import { Row, Col, Button, Spinner } from "react-bootstrap";

import LoadingPage from "../Shared/LoadingPage";
import * as Settings from "../../Settings";
import rest from "../../utils/Rest";
import errorHandler from "../../utils/ErrorHandler";
import { addUrlParam, removeAllUrlParams } from "../../utils/SetUrlParameters"

import RouteVariableProps from "../../interfaces/RouteVariableProps";
import SellerVariant from "../../classes/Seller/SellerVariant";
import SellerVariantCard from "./SellerVariants/SellerVariantCardForSeller";
import SellerVariantFilters from "./SellerVariants/SellerVariantFiltersForSeller";

interface MatchParams {
  sellerId: string;
}

export interface SellerVariantsForSellerState {
  isInitialLoading: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  hasMore: boolean;
  loaded: number;
  sellerVariants: SellerVariant[];
  productTitle: string;
  sku: string;
  barcode: string;
  mpn: string;
}

export default class SellerVariantsForSeller extends React.Component<
  RouteVariableProps<MatchParams>,
  SellerVariantsForSellerState
> {
  state = {
    isInitialLoading: true,
    isLoading: false,
    isLoadingMore: false,
    hasMore: false,
    loaded: 0,
    sellerVariants: new Array<SellerVariant>(),
    productTitle: "",
    sku: "",
    barcode: "",
    mpn: ""
  };

  componentDidMount() {
    if (this.props.match.params.sellerId !== undefined) {
      this.resetPage();
    }
    this.setState({ isInitialLoading: true });
  }

  setInitialSeller = async () => {
    const { sellerId } = this.props.match.params;
    let seller = this.props.user.sellers.filter(
      seller => seller.id === sellerId
    );
    if (seller !== undefined && seller.length > 0) {
      this.props.setSeller(seller[0]);
    }
  };

  resetPage = async () => {
    await this.setInitialSeller();
    await this.setState({
      isLoading: false,
      hasMore: false,
      loaded: 0,
      sellerVariants: new Array<SellerVariant>()
    });
    await this.getVariants(0);
  };

  getVariants = async (skip: number = 0) => {
    const maxProductsPerPull = 50;
    const { sellerId } = this.props.match.params;
    const { apiKey } = this.props;
    const { loaded, sellerVariants, productTitle, sku, barcode, mpn, isInitialLoading } = this.state;
    try {
      if (skip === 0) {
        await this.setState({ isLoading: true });
      } else {
        await this.setState({ isLoadingMore: true });
      }
      let uri = `${
        Settings.eformedApiBaseUri
      }seller/${sellerId}/variants?key=${apiKey}&skip=${skip}`;

      if (!isInitialLoading) {
        removeAllUrlParams(this.props);
        if (productTitle !== undefined && productTitle !== "") {
          addUrlParam(this.props, 'productTitle', encodeURIComponent(productTitle));
        }
        if (sku !== undefined && sku !== "") {
          addUrlParam(this.props, 'sku', encodeURIComponent(sku));
        }
        if (barcode !== undefined && barcode !== "") {
          addUrlParam(this.props, 'barcode', encodeURIComponent(barcode));
        }
        if (mpn !== undefined && mpn !== "") {
          addUrlParam(this.props, 'mpn', encodeURIComponent(mpn));
        }
      }

      let searchParams = new URLSearchParams(this.props.location.search);

      searchParams.forEach((value: string, key: string) => {        
        let tempState = {...this.state, [key]:decodeURIComponent(value) };
        this.setState(tempState);

        uri = uri + `&${key}=${value}`;
      });

      let data = await rest.get(uri);
      if (data.length === maxProductsPerPull) {
        await this.setState({ hasMore: true, loaded: loaded + data.length });
      } else {
        await this.setState({ hasMore: false, loaded: loaded + data.length });
      }
      let tempSellerVariants = sellerVariants;
      if (skip === 0) {
        tempSellerVariants = new Array<SellerVariant>();
      }
      await tempSellerVariants.push(...data);
      await this.setState({
        isLoading: false,
        isLoadingMore: false,
        sellerVariants: tempSellerVariants
      });
    } catch (error) {
      await errorHandler(
        error,
        "There was an error retrieving the Seller Variants. Please try again, if this is not the first time you have seen this error, please report it.",
        this.props.addAlert
      );
      await this.setState({
        isLoading: false,
        isLoadingMore: false
      });
    }
  };

  handleFieldChange = (index: number, fieldName: string, newValue: any) => {
    const newSellerVariants = this.state.sellerVariants.map(
      (sellerVariant, iindex) => {
        if (index !== iindex) return sellerVariant;
        return { ...sellerVariant, [fieldName]: newValue };
      }
    );

    this.setState({ sellerVariants: newSellerVariants });
  };

  saveSellerVariant = async (
    sellerVariant: SellerVariant
  ): Promise<boolean> => {
    const { sellerId } = this.props.match.params;
    const { apiKey } = this.props;
    try {
      await rest.put(
        `${Settings.eformedApiBaseUri}seller/${sellerId}/variants/${
          sellerVariant.id
        }?key=${apiKey}`,
        sellerVariant
      );
      await this.props.addAlert("success", "Variant successfully saved!");
      return true;
    } catch (error) {
      errorHandler(
        error,
        "There was an error while saving the variant.  Please try again, if this is not the first time you have seen this error, please report it.",
        this.props.addAlert
      );
      return false;
    }
  };

  setSellerVariantsState = (stateObject: any): void => {
    this.setState(stateObject);
  };

  render() {
    const {
      isLoading,
      isLoadingMore,
      hasMore,
      loaded,
      sellerVariants
    } = this.state;
    if (isLoading) {
      return (
        <React.Fragment>
          <Row>
            <Col>
              <h1>Seller Variants</h1>
            </Col>
          </Row>
          <LoadingPage />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Row>
            <Col>
              <h1>Seller Variants</h1>
            </Col>
            <Col lg="3">
              <Button variant="outline-info" onClick={() => {
                this.resetPage();
                this.setState({ isInitialLoading: false });
              }}>
                Apply / Reload Products
              </Button>
            </Col>
          </Row>
          <SellerVariantFilters
            {...this.state}
            setSellerVariantsState={this.setSellerVariantsState}
            resetPage={this.resetPage}
          />
          {sellerVariants.length > 0 ? (
            sellerVariants.map((sellerVariant, index) => (
              <SellerVariantCard
                key={index}
                index={index}
                sellerVariant={sellerVariant}
                handleFieldChange={this.handleFieldChange}
                saveSellerVariant={this.saveSellerVariant}
              />
            ))
          ) : (
            <Row className="mt-5 text-center">
              <Col>No Variants Found.</Col>
            </Row>
          )}
          {hasMore === true && (
            <Row className="text-center mt-2">
              <Col>
                <Button
                  variant="outline-info"
                  onClick={() => this.getVariants(loaded)}
                >
                  {isLoadingMore ? (
                    <React.Fragment>
                      <Spinner size="sm" animation="border" />
                      Loading...
                    </React.Fragment>
                  ) : (
                    "Load More..."
                  )}
                </Button>
              </Col>
            </Row>
          )}
        </React.Fragment>
      );
    }
  }
}
