import rest from "../../../../../utils/Rest";
import errorHandler from "../../../../../utils/ErrorHandler";
import * as Settings from "../../../../../Settings";

const saveProduct = async (props, state, setParentState) => {
  let goodToGo = true;
  const { marketplaceId, productId } = props.match.params;
  const { apiKey } = props;
  const { product, channels } = state;

  let warnedOnTitle = [];
  let warnedOnSku = [];

  product.variants.forEach((variant, index) => {
    variant.marketplaceChannelMarketplaceVariants.forEach((mcmv, indexx) => {
      const channel = channels.filter(c => c.id === mcmv.marketplaceChannelId)[0];

      if (
        mcmv.compareAtPrice === 0 ||
        mcmv.compareAtPrice === "0" ||
        mcmv.compareAtPrice === "" ||
        mcmv.compareAtPrice === null ||
        mcmv.compareAtPrice === undefined
      ) {
        variant.marketplaceChannelMarketplaceVariants[indexx].compareAtPrice = null;
      }
      if (
        mcmv.manualPrice === 0 ||
        mcmv.manualPrice === "0" ||
        mcmv.manualPrice === "" ||
        mcmv.manualPrice === null ||
        mcmv.manualPrice === undefined
      ) {
        variant.marketplaceChannelMarketplaceVariants[indexx].manualPrice = null;
      }
      if (
        mcmv.map === 0 ||
        mcmv.map === "0" ||
        mcmv.map === "" ||
        mcmv.map === null ||
        mcmv.map === undefined
      ) {
        variant.marketplaceChannelMarketplaceVariants[indexx].map = null;
      }

      if (
        variant.marketplaceChannelMarketplaceVariants[indexx].price < 0 ||
        variant.marketplaceChannelMarketplaceVariants[indexx].map < 0 ||
        variant.marketplaceChannelMarketplaceVariants[indexx].cost < 0 ||
        variant.marketplaceChannelMarketplaceVariants[indexx].compareAtPrice < 0 ||
        variant.marketplaceChannelMarketplaceVariants[indexx].manualPrice < 0 ||
        variant.marketplaceChannelMarketplaceVariants[indexx].variantHandlingCharge < 0 ||
        variant.marketplaceChannelMarketplaceVariants[indexx].outValue < 0
      ) {
        props.addAlert("warning", `Negative price field found. Channel: ${channel.name} | Variant: ${variant.title}`);
        goodToGo = false;
      }

      product.variants[index] = variant;
    });

    if (
      variant.option1 === "" ||
      variant.option1 === undefined ||
      variant.option1 === null
    ) {
      product.variants[index].option1 = "Default Title";
      product.variants[index].title = "Default Title";
    }

    if (variant.sku === undefined || variant.sku === null || variant.sku === '') {
      props.addAlert("warning", `Empty SKU on variant: ${variant.title}`);
      goodToGo = false;
    }

    if (
      product.variants.filter(v => v.title === variant.title).length > 1 &&
      !warnedOnTitle.includes(variant.title)
    ) {
      props.addAlert("warning", `Duplicate variant title: ${variant.title}`);
      warnedOnTitle.push(variant.title);
      goodToGo = false;
    }

    if (
      product.variants.filter(v => v.sku === variant.sku).length > 1 &&
      !warnedOnSku.includes(variant.sku)
    ) {
      props.addAlert("warning", `Duplicate variant sku: ${variant.sku}`);
      warnedOnSku.push(variant.sku);
      goodToGo = false;
    }
  });

  [1, 2, 3, 4, 5].forEach((number, index) => {
    let fieldName = `feature${number}`;
    if (product[fieldName] === "") {
      product[fieldName] = null;
    }
  });

  if (goodToGo === true) {
    await setParentState({ isSaving: true });
    try {
      if (productId !== "new") {
        let data = await rest.put(
          `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/products/${productId}?key=${apiKey}`,
          product,
          true
        );
        
        await setParentState({ product: data });
        props.addAlert("success", `Product updated successfully.`);
      } else {
        let data = await rest.post(
          `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/products?key=${apiKey}`,
          product
        );
        
        // setting match param of url to product id by removing new
        let pathname = props.location.pathname;
        props.history.push({
                 pathname: pathname.replace('new', data.id),
                 search: ''
          });
        await setParentState({ product: data });

        props.addAlert("success", `Marketplace product was successfully crated.`);
      }
      await setParentState({ isSaving: false, isEditing: false });
    } catch (error) {
      await errorHandler(
        error,
        "There was an error while saving your product changes. Please try again, if this is not the first time you have seen this error, please report the issue.",
        props.addAlert
      );
      await setParentState({ isSaving: false });
    }
  }
};

export default saveProduct;
