export const addUrlParam = (props : any, key : string, value: string) => {
	let pathname = props.location.pathname;
	let searchParams = new URLSearchParams(props.location.search);
	searchParams.set(key, value);
	props.history.push({
		pathname: pathname,
		search: searchParams.toString()
	});
};

export const removeUrlParam = (props : any, key : string) => {
	let pathname = props.location.pathname;
	let searchParams = new URLSearchParams(props.location.search);
	searchParams.delete(key);
	props.history.push({
		pathname: pathname,
		search: searchParams.toString()
	});
};

export const removeAllUrlParams = (props : any) => {
	let pathname = props.location.pathname;
	props.history.push({
		pathname: pathname,
		search: ''
	});
};

export const createParameterizedUrl = (props: any, state: any, url : string, setParentState : (newState) => void) => {
	let searchParams = new URLSearchParams(props.location.search);

	searchParams.forEach((value: string, key: string) => {   
		console.log(key, value);     
		let tempState = {...state, [key+'Filter']:value };
		setParentState(tempState);

		url = url + `&${key}=${value}`;
	});

	setParentState({ getUrlString : url });
};