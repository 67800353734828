import React, { useState } from 'react';
import { Row, Col, Button, Collapse, FormLabel, Spinner } from 'react-bootstrap';
import DatePicker, { Day } from 'react-modern-calendar-datepicker';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';

import { TextField, Select } from '../../../Shared/EFBootstrap';
import { SearchFilterHeader, SearchFilterFooter } from '../../../Shared/SearchFilterComponents';

import { PriceSchedulerViewChildProps } from './PriceSchedulerView';

interface SelectOption {
  label: string;
  value: string | number;
}

const PriceScheduleFilters = (props: PriceSchedulerViewChildProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [startDate, setStartDate] = useState<Day>(null);
  const [endDate, setEndDate] = useState<Day>(null);
  const {
    isDownloading,
    exportCsv,
    getPriceSchedules,
    setParentState,
    channels,
    channelFilter,
    variantIdFilter,
    vendorFilter,
    productTitleFilter,
    discountNameFilter
  } = props;

  let channelOptions: SelectOption[] = [{label: "Any", value: undefined}];

  channelOptions = channelOptions.concat(
    channels !== undefined && channels !== null && channels.length > 0 ?
    channels.map(channel => {
      return { label: channel.name, value: channel.id };
    }) : []
  );

  const renderStartDateInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      placeholder="Start Date"
      value={startDate !== undefined && startDate !== null ? 
        `${startDate.month.toString()}-${startDate.day.toString()}-${startDate.year.toString()}` : ''}
      style={{
        padding: '0.75rem 2rem',
        border: 'none',
        background: 'rgb(247 247 249)',
        outline: 'none',
        width: '100%'
      }}
    />
  )

  const renderEndDateInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      placeholder="End Date"
      value={endDate !== undefined && endDate !== null ?
      `${endDate.month.toString()}-${endDate.day.toString()}-${endDate.year.toString()}` : ''}
      style={{
        padding: '0.75rem 2rem',
        border: 'none',
        background: 'rgb(247 247 249)',
        outline: 'none',
        width: '100%'
      }}
    />
  )

  const changeStartDate = (newDate) => {
    setStartDate(newDate);
    if (newDate !== undefined && newDate !== null && newDate !== "") {
      setParentState({ startDateFilter: `${newDate.month.toString()}/${newDate.day.toString()}/${newDate.year.toString()}`});
    } else {
      setParentState({ startDateFilter: undefined});
    }
  }

  const changeEndDate = (newDate) => {
    setEndDate(newDate);
    if (newDate !== undefined && newDate !== null && newDate !== "") {
      setParentState({ endDateFilter: `${newDate.month.toString()}/${newDate.day.toString()}/${newDate.year.toString()}`});
    } else {
      setParentState({ endDateFilter: undefined});
    }
  }

  const defaultStatusValues = {
    isInitialLoading: false,
    channelFilter: 'Any',    
    vendorFilter: undefined,
    productTitleFilter: undefined,
    variantIdFilter: undefined,
    startDateFilter: undefined,
    endDateFilter: undefined,
    discountNameFilter: undefined,
    orderByFilter: 'createdatdesc'
  }

  return (
    <React.Fragment>
      <SearchFilterHeader 
        isOpen={isOpen}
        setParentState={setParentState}
        getResults={getPriceSchedules}
        setIsOpen={setIsOpen}
      />
      <Collapse in={isOpen}>
        <Row>
          <Col>
            <Row className='mt-2'>
              <Col>
                <Select
                  label='Channel'
                  options={channelOptions}
                  value={channelFilter}
                  onChange={newValue =>
                    setParentState({
                      channelFilter: newValue === 'Any' ? undefined : newValue,
                    })
                  }
                />
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col style={{height: "111px"}}>
                <div className="mr-1 input-with-clear">
                  <TextField
                    label='Variant ID'
                    placeholder='Variant ID'
                    value={variantIdFilter}
                    onChange={newValue =>
                      setParentState({ variantIdFilter: newValue })
                    }
                    helpText='Not Case Sensitive, Starts With'
                    onEnterKeyPress={() => {
                      setParentState({ isInitialLoading: false });
                      getPriceSchedules(0);
                    }}
                  />
                  <span
                    className="input-close-icon"
                    onClick={() => setParentState({ variantIdFilter: undefined })}
                    style={{float: "right", right: "5px", top: "-75px"}}
                  >
                    &times;
                  </span>
                </div>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col style={{height: "111px"}}>
                <div className="mr-1 input-with-clear">
                  <TextField
                    label='Vendor'
                    placeholder='Vendor'
                    value={vendorFilter}
                    onChange={newValue =>
                      setParentState({ vendorFilter: newValue })
                    }
                    helpText='Not Case Sensitive, Starts With'
                    onEnterKeyPress={() => {
                      setParentState({ isInitialLoading: false });
                      getPriceSchedules(0);
                    }}
                  />
                  <span
                    className="input-close-icon"
                    onClick={() => setParentState({ vendorFilter: undefined })}
                    style={{float: "right", right: "5px", top: "-75px"}}
                  >
                    &times;
                  </span>
                </div>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col style={{height: "111px"}}>
                <div className="mr-1 input-with-clear">
                  <TextField
                    label='Product Title'
                    placeholder='Product Title'
                    value={productTitleFilter}
                    onChange={newValue =>
                      setParentState({ productTitleFilter: newValue })
                    }
                    helpText='Not Case Sensitive, Starts With'
                    onEnterKeyPress={() => {
                      setParentState({ isInitialLoading: false });
                      getPriceSchedules(0);
                    }}
                  />
                  <span
                    className="input-close-icon"
                    onClick={() => setParentState({ productTitleFilter: undefined })}
                    style={{float: "right", right: "5px", top: "-75px"}}
                  >
                    &times;
                  </span>
                </div>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col style={{height: "111px"}}>
                <div className="mr-1 input-with-clear">
                  <TextField
                    label='Discount Name'
                    placeholder='Discount Name'
                    value={discountNameFilter}
                    onChange={newValue =>
                      setParentState({ discountNameFilter: newValue })
                    }
                    helpText='Not Case Sensitive, Starts With'
                    onEnterKeyPress={() => {
                      setParentState({ isInitialLoading: false });
                      getPriceSchedules(0);
                    }}
                  />
                  <span
                    className="input-close-icon"
                    onClick={() => setParentState({ discountNameFilter: undefined })}
                    style={{float: "right", right: "5px", top: "-75px"}}
                  >
                    &times;
                  </span>
                </div>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <FormLabel style={{color: 'black'}}>Start Date</FormLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="input-with-clear">
                  <DatePicker
                    value={startDate}
                    onChange={changeStartDate}
                    inputPlaceholder="Start Date"
                    renderInput={renderStartDateInput}
                    maximumDate={endDate}
                    calendarPopperPosition="top"
                    wrapperClassName="datepicker-wrapper"
                    shouldHighlightWeekends
                  />
                  <span className="input-close-icon date-input" onClick={() => changeStartDate(undefined)} title="Remove vendor">&times;</span>
                </div>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <FormLabel style={{color: 'black'}}>End Date</FormLabel>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="input-with-clear">
                  <DatePicker
                    value={endDate}
                    onChange={changeEndDate}
                    inputPlaceholder="End Date"
                    renderInput={renderEndDateInput}
                    minimumDate={startDate}
                    calendarPopperPosition="top"
                    wrapperClassName="datepicker-wrapper"
                    shouldHighlightWeekends
                  />
                <span className="input-close-icon date-input" onClick={() => changeEndDate(undefined)} title="Remove vendor">&times;</span>
                </div>
              </Col>
            </Row>
            <SearchFilterFooter 
              enableDownload={true}
              isDownloading={isDownloading}
              defaultStatus={defaultStatusValues}
              setParentState={setParentState}
              exportCsv={exportCsv}
              getResults={getPriceSchedules}
            />
          </Col>
        </Row>
      </Collapse>
    </React.Fragment>
  );
}

export default PriceScheduleFilters;