import React from 'react';
import { Row, Col, Button, Jumbotron } from 'react-bootstrap';
import { Link as ScrollLink } from 'react-scroll';
import Arrow from 'react-arrow';

const Footer = props => {
  return (
    <footer>
      <Jumbotron className='mt-5'>
        <Row className='text-center'>
          <Col>
            <ScrollLink to='top' smooth={true}>
              <Button variant='info'>
                <Row>
                  <Col>
                    <Arrow
                      direction='up'
                      shaftWidth={30}
                      shaftLength={20}
                      headWidth={60}
                      headLength={30}
                      fill='white'
                      stroke='black'
                      strokeWidth={0}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>To The Top</Col>
                </Row>
              </Button>
            </ScrollLink>
          </Col>
        </Row>
        <Row className='mt-5 text-center'>
          <Col>
            © <a href='https://eformedpartners.com'>eFormed Partners, LLC</a>
          </Col>
        </Row>
      </Jumbotron>
    </footer>
  );
};

export default Footer;
