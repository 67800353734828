import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { TextField } from "../Shared/EFBootstrap";

export interface ProductGoToProps {
  marketplaceId: string;
}

const ProductGoTo = (props: ProductGoToProps) => {
  const [ productId, setProductId ] = useState("");
  const { marketplaceId } = props;

  return (
    <Row className="mt-2">
      <Col>
        <TextField
          label="Marketplace Product Id"
          value={productId}
          onChange={newValue => setProductId(newValue)}
          placeholder={"00000000-0000-0000-0000-000000000000"}
          helpText="Use this to specifically navigate to a single product"
        />
      </Col>
      <Col lg="2" className="align-self-center text-right">
        <LinkContainer
          exact
          to={`/marketplace/${marketplaceId}/products/${productId}`}
        >
          <Button disabled={productId === ""} variant="info">
            Go To
          </Button>
        </LinkContainer>
      </Col>
    </Row>
  );
};

export default ProductGoTo;
