import React from "react";
import LazyHero from "react-lazy-hero";
import { Container, Row, Col, Button } from "react-bootstrap";

const Splash = props => {
  return (
    <LazyHero
      color="#000000"
      opacity={0.7}
      imageSrc="/adventure-alps.jpg"
      isCentered
      minHeight="100vh"
    >
      <Container>
        <Row className="mb-5 justify-content-center">
          <Col xs="12">
            <img src="/eformed-logo.png" alt="EFormed Portal" />
          </Col>
        </Row>
        <Row className="mt-5 justify-content-center">
          <Col xs="12">
            <h4 className="text-light">Home of eFormed Enterprise Apps</h4>
          </Col>
        </Row>
        <Row className="mt-2 justify-content-center">
          <Col xs="12">
            <Button variant="info" size="lg" href="/home">
              Go To Portal
            </Button>
          </Col>
        </Row>
      </Container>
    </LazyHero>
  );
};

export default Splash;
