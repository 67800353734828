import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import BaseProps from '../../interfaces/BaseProps';

const FamilywiseHome = (props: BaseProps) => {
  return (
    <React.Fragment>
      <Row className='mt-5'>
        <Col>
          <h1>Familywise Portal</h1>
        </Col>
      </Row>
      <Row className='mt-5'>
        <Col>
          <Card bg='info' text='light'>
            <Card.Header as='h3'>Navigation</Card.Header>
            <Card.Body>
              <Card.Text>
                The various functions you have access to should be linked in the
                upper left.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FamilywiseHome;
