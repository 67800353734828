import PurchaseOrderItem from "../classes/Logistics/PurchaseOrderItem";

const total = (
  items: PurchaseOrderItem[],
  po_level_discount: number | undefined | null
): number => {
  let totalAmount: number = -po_level_discount || 0;
  items.forEach(item => {
    let lineItemTotal = item.quantity * item.cost;
    totalAmount = totalAmount + lineItemTotal;
  });

  return totalAmount || 0;
};

export default total;
