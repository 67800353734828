import React from 'react';

import MarketplaceProductProps from "../../../../../interfaces/MarketplaceProductProps";

interface FinalPriceWarningModalProps extends MarketplaceProductProps {
  forceSaveProduct: () => void;
}

const FinalPriceWarningModal = (props: FinalPriceWarningModalProps) => {
  const { showFinalPriceWarningModal, forceSaveProduct, setParentState } = props;
  
  return (
    <div role="dialog" aria-modal="true" className="fade modal show" style={{display: showFinalPriceWarningModal ? "block" : "none", backgroundColor: "#00000094"}} onClick={() => setParentState({showFinalPriceWarningModal: false})}>
      <div role="document" className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title h4">PRICE ALERT!</div>
            <button type="button" className="close" onClick={() => setParentState({showFinalPriceWarningModal: false})}>
              <span aria-hidden="true" >×</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body">
            <p className="text-dark">One or more of the Final prices on this product is below Cost. Please confirm.</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={() => setParentState({showFinalPriceWarningModal: false})}>Edit</button>
            <button type="button" className="btn btn-danger" onClick={() => {
              forceSaveProduct();
              setParentState({showFinalPriceWarningModal: false});
            }}>Save</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalPriceWarningModal;
