import React, { useState } from 'react';
import { Collapse, Row, Col, Button, Jumbotron } from 'react-bootstrap';

import App from './App';

const Stack = props => {
  const [isOpen, setIsOpen] = useState(false);
  const { stack } = props;
  return (
    <Jumbotron className={`bg-${stack.variant}`}>
      <h3 className={`text-${stack.text}`}>
        {stack.name}{' '}
        {stack.apps !== undefined && (
          <React.Fragment>
            ({stack.apps.length} App{stack.apps.length !== 1 && 's'})
          </React.Fragment>
        )}
      </h3>

      <Row>
        <Col className={`align-self-center text-${stack.text}`}>
          {stack.description}
        </Col>
        <Col>
          <Button variant='primary' onClick={() => setIsOpen(!isOpen)}>
            {isOpen === true ? 'Less' : 'More'}
          </Button>
        </Col>
      </Row>
      <Collapse in={isOpen}>
        <Row>
          <Col>
            {stack.apps !== undefined &&
              stack.apps.map((app, index) => <App key={index} app={app} />)}
          </Col>
        </Row>
      </Collapse>
    </Jumbotron>
  );
};

export default Stack;
