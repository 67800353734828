import React, { useState } from "react";
import { Row, Col, Button, Card, Collapse, FormCheck, Spinner } from "react-bootstrap";
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

import { TextField } from '../../../../Shared/EFBootstrap';
import dateFormat from "../../../../../utils/DateFormat";
import MarketplaceProductProps from "../../../../../interfaces/MarketplaceProductProps";
import MetafieldModel from '../../../../../classes/Marketplace/Metafield';

import VariantsDraggable from "./VariantsDraggable";

export interface MarketplaceVariantsProps extends MarketplaceProductProps {
  handleAddVariant: () => void;
  handleRemoveVariant: (variantId: string) => void;
  handleMetafieldChange: (mfield: MetafieldModel, newValue: string) => void;
  handleRoutingClick: (link: string) => void;
  removeProductAssociations: () => void;
  productId: string;
  apiKey: string;
  addAlert: any;
}

const MarketplaceVariants = (props: MarketplaceVariantsProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isRemovingAssociation, setRemovingAssociation] = useState(false);
  const {
    isEditing,
    product,
    handleAddVariant,
    handleProductChange,
    removeProductAssociations
  } = props;

  const removeAssociation = () => {
    setRemovingAssociation(true);
    removeProductAssociations();
    setRemovingAssociation(false);
  }

  const getMostRecentTimeStamp = () => {
    let dateList = new Array<Date>();
    product.variants.forEach(v => {
      if (v.sellerVariants !== undefined && v.sellerVariants !== null && v.sellerVariants.length > 0) {
        let recentDate = new Date(Math.max.apply(null, v.sellerVariants.map(function(e) {
          return new Date(e.inventoryChangedAt);
        })));

        dateList.push(recentDate);
      }
    });

    let mostLeastDate = new Date("2020-01-01T00:00:00.0000000");
    let resultDate =  new Date(Math.max.apply(null, dateList));

    return resultDate > mostLeastDate ? resultDate : null;
  }

  const mostRecentInventoryUpdateTime = getMostRecentTimeStamp();

  return (
    <Card className="mt-3">
      <Card.Header onClick={() => setIsOpen(!isOpen)}>
        <Row>
          <Col className='align-self-center'>
            <h4>Variants ({product.variants.length})</h4>
          </Col>
          {mostRecentInventoryUpdateTime !== undefined && mostRecentInventoryUpdateTime !== null &&
            <Col className='text-right' style={{ color: 'black' }}>
              Inventory Updated: {dateFormat(mostRecentInventoryUpdateTime.toString(), true)}
            </Col>
          }
        </Row>
      </Card.Header>
      <Collapse in={isOpen}>
        <Card.Body>
          <Row className="mb-3">
            <Col>
              <TextField
                label='Option 1 Category Name'
                readOnly={!isEditing}
                value={product.option1}
                onChange={newValue => handleProductChange('option1', newValue)}
              />
            </Col>
            <Col>
              <TextField
                label='Option 2 Category Name'
                readOnly={!isEditing}
                value={product.option2}
                onChange={newValue => handleProductChange('option2', newValue)}
              />
            </Col>
            <Col>
              <TextField
                label='Option 3 Category Name'
                readOnly={!isEditing}
                value={product.option3}
                onChange={newValue => handleProductChange('option3', newValue)}
              />
            </Col>
          </Row>
          {product.archived === true &&
            <Row>
              <Col className="text-right">
                <Button
                  size="sm"
                  variant="danger"
                  onClick={() => removeAssociation()}
                >  
                  {isRemovingAssociation ?
                    <React.Fragment>
                      <Spinner size='sm' animation='border' />
                      Removing...
                    </React.Fragment> : 
                    'Unassocation All Variants'
                  }
                </Button>
              </Col>
            </Row>
          }
          {product.variants.length > 1 &&
            <FormCheck 
              disabled={!isEditing}
              label={"set variant order manually"}
              type={'checkbox'}
              checked={product.isManuallyOrderedVariants}
              onChange={(e) => handleProductChange('isManuallyOrderedVariants', e.target.checked)}
            />
          }
          <DndProvider backend={Backend}>
            <VariantsDraggable {...props}/>
          </DndProvider>
          <Row className="mt-2 text-center">
            <Col>
              <Button
                disabled={!isEditing}
                variant="outline-success"
                onClick={() => handleAddVariant()}
              >
                Add New Variant
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default MarketplaceVariants;
