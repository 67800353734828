import React from "react";
import { Row, Col } from "react-bootstrap";
import Loader from "react-loader-spinner";

interface LoadingPageProps {
  type?: string;
  message?: string;
}

const LoadingPage = (props: LoadingPageProps) => {
  return (
    <Row
      className="justify-content-center align-items-center"
      style={{ height: "60vh" }}
    >
      <Col xs="auto">
        <Row className="justify-content-center">
          <Col xs="auto">
            <Loader type={props.type || "ThreeDots"} width="40vw" />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            <p>{props.message || ""}</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default LoadingPage;
