import React, { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { TextField } from '../Shared/EFBootstrap';

import data from './Data';
import Plans from './Plans';
import Statistics from './Statistics';
import Stack from './Stack';

const SchematicDisplay = () => {
  const [password, setPassword] = useState('');

  if (password !== 'eFormedschematic,please') {
    return (
      <Container className='mt-5'>
        <Row className='mt-5 text-center'>
          <Col>
            <TextField
              label='Password'
              value={password}
              onChange={newValue => setPassword(newValue)}
            />
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <React.Fragment>
        <Container className='mt-5'>
          <Row className='mt-2'>
            <Col>
              <h1>eFormed Application Architecture Schematic</h1>
            </Col>
          </Row>
          <Plans data={data} />
          <Statistics data={data} />
        </Container>
        <Row className='mt-5'>
          <Col>
            {data !== undefined &&
              data.stacks.map((stack, index) => (
                <Stack key={index} stack={stack} />
              ))}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
};

export default SchematicDisplay;
