import React from 'react';
import { Button } from 'react-bootstrap';

import MarketplaceVariant from '../../../../../classes/Marketplace/Variant';
import MarketplaceChannel from '../../../../../classes/Marketplace/Channel';

import { ShopifyChannelAreaProps } from './ShopifyChannelArea';
import { changePublishableStateOnVariantConnection } from './ChannelVariantArea';
import { validateVariantAssociation, validateVariantPricing, validateValue, validateVariantArchive } from './WalmartPublishButton';

const ChannelPublishButton = (props: ShopifyChannelAreaProps) => {
  const { isEditing, product, channel, handleChannelChange, handleVariantChange, addAlert } = props;
  const connections = product.marketplaceChannelMarketplaceProducts.filter(
    connection => connection.marketplaceChannelId === channel.id,
  );
  const published =
    connections !== undefined &&
    connections !== null &&
    connections.length === 1 &&
    connections[0].active === true;

  const validateProductFieldsAndPublish = () => {
    if (published === false) {
      product.variants !== undefined && product.variants !== null && product.variants.length > 0 &&
      product.variants.forEach((variant, index) => {
        variant.marketplaceChannelMarketplaceVariants.forEach(mcmv => {
          if (channel.id === mcmv.marketplaceChannelId) {
            if (validateVariantFieldsForShopifyPublish(variant, channel, addAlert, false)) {
              changePublishableStateOnVariantConnection(variant, mcmv, true, index, handleVariantChange);
            }
          }
        });
      });
    }

    handleChannelChange(channel, !published);
  }

  return (
    <Button
      disabled={!isEditing}
      variant={published === false ? 'outline-success' : 'outline-danger'}
      onClick={() => validateProductFieldsAndPublish()}
    >
      {published === true ? 'Unpublish' : 'Publish'}
    </Button>
  );
};

export const validateVariantFieldsForShopifyPublish = (variant: MarketplaceVariant, channel: MarketplaceChannel, addAlert: any, showAlarts: boolean = true) : boolean => {
  let isFieldsValid = true;
  let marketplaceChannelMarketplaceVariants = variant.marketplaceChannelMarketplaceVariants
    .filter(v => v.marketplaceChannelId === channel.id); 
  
  marketplaceChannelMarketplaceVariants !== undefined &&
    marketplaceChannelMarketplaceVariants !== null &&
    marketplaceChannelMarketplaceVariants.length > 0 &&
    marketplaceChannelMarketplaceVariants.forEach(mcmv => {
      if (channel.isWholeChannel !== true) {
        isFieldsValid = validateValue(variant.title, +mcmv.price, 'Price', addAlert, showAlarts, channel.name) && isFieldsValid;
      } else {
        isFieldsValid = validateValue(variant.title, +mcmv.cost, 'Cost', addAlert, showAlarts, channel.name) && isFieldsValid;
      }
    });

  isFieldsValid = validateVariantArchive(variant, addAlert, showAlarts) && isFieldsValid;
  isFieldsValid = validateVariantAssociation(variant, addAlert, showAlarts) && isFieldsValid;
  isFieldsValid = validateVariantPricing(variant, channel, addAlert, showAlarts) && isFieldsValid;

  return isFieldsValid;
}

export default ChannelPublishButton;
