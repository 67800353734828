import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import * as Settings from '../../../../Settings';
import { TextField } from '../../../Shared/EFBootstrap';
import RouteVariableProps from '../../../../interfaces/RouteVariableProps';
import rest from '../../../../utils/Rest';
import errorHandler from '../../../../utils/ErrorHandler';
import setMarketplaceFromId from '../../../../utils/SetMarketplaceFromId';
import LoadingPage from '../../../Shared/LoadingPage';

import DescriptionCleanerTag from '../../../../classes/Marketplace/DescriptionCleanerTag';
import FormattingTag from './FormattingTag';
import FormattingTagDeleteModal from './FormattingTagDeleteModal';
import DescriptionEntry from './DescriptionEntry';

interface MatchParams {
  marketplaceId: string;
}

export interface DescriptionFormatterRouteProps
  extends RouteVariableProps<MatchParams> {}

export default class DescriptionFormatter extends React.Component<
  DescriptionFormatterRouteProps
  > {
  state = {
    isLoading: false,
    searchText: '',
    deleteFormatterTag: undefined,
    showDeleteModal: false,
    descriptionFormatterTags: new Array<DescriptionCleanerTag>(),
    filteredDescriptionFormatterTags: new Array<DescriptionCleanerTag>(),
    isOpen: false
  };

  componentDidMount() {
    if (this.props.match.params.marketplaceId !== undefined) {
      this.initialSetMarketplace();
      this.getDescriptionFormatterTags();
    }
  }

  initialSetMarketplace = async () => {
    let marketplaceId = this.props.match.params.marketplaceId;
    const { selectedMarketplace, setMarketplace, user } = this.props;

    if (selectedMarketplace === undefined) {
      await setMarketplaceFromId(
        marketplaceId,
        setMarketplace,
        user,
        this.props.history,
      );
    }
  };

  getDescriptionFormatterTags = async () => {
    const { marketplaceId } = this.props.match.params;
    const { apiKey } = this.props;
    await this.setState({ isLoading: true });
    try {
      let data: DescriptionCleanerTag[] = await rest.get(
        `${
        Settings.eformedApiBaseUri
        }marketplace/${marketplaceId}/descriptioncleanertag?key=${apiKey}`,
      );
      await this.setState({
        descriptionFormatterTags: data,
        isLoading: false,
      });

    } catch (error) {
      errorHandler(
        error,
        'There was an error while retrieving formatting tags. Please try again, if this is not the first time you have seen this error, please report it.',
        this.props.addAlert,
      );
      await this.setState({ isLoading: false });
    }
  };

  saveFormatterTag = async (formatterTag: DescriptionCleanerTag) => {
    const { marketplaceId } = this.props.match.params;
    const { apiKey } = this.props;
    if (formatterTag.type === "0") {
      formatterTag.type = 0;
    }
    try {
      if (formatterTag.isNewField) {
        let tempFormatterTag = await rest.post(
          `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/descriptioncleanertag?key=${apiKey}`,
          formatterTag
        )
        
        const newFormatterTags = this.state.descriptionFormatterTags.map((tag, iindex) => {
          if (tag.id !== tempFormatterTag.id) {
            return tempFormatterTag;
          } 
          
          return tag;
        });
    
        await this.setState({ descriptionFormatterTags: newFormatterTags });
      } else {
        await rest.put(
          `${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/descriptioncleanertag/${formatterTag.id}?key=${apiKey}`,
          formatterTag
        );
      }
      
      await this.handleFormatterTagChange(formatterTag, 'isNewField', 'false');
      await this.getDescriptionFormatterTags();
    } catch (error) {
      errorHandler(
        error,
        'There was an error while retrieving formatting tags. Please try again, if this is not the first time you have seen this error, please report it.',
        this.props.addAlert,
      );
    }
  }

  handleAddNewFormatterTag = () => {
    const { marketplaceId } = this.props.match.params;
    let formatterTags = this.state.descriptionFormatterTags;
    formatterTags = formatterTags !== undefined && formatterTags !== null ?
      formatterTags : new Array<DescriptionCleanerTag>();
    let formatterTag  = new DescriptionCleanerTag(); 
    formatterTag.marketplaceId = marketplaceId;
    formatterTag.active = true;
    formatterTag.isNewField = true;
    formatterTags.unshift(formatterTag);
    
    this.setState({ descriptionFormatterTags: formatterTags });
  };

  handleRemoveFormatterTag = (formatterTagId: string) => {
    let formatterTags = this.state.descriptionFormatterTags.filter(
      formatterTag => formatterTag.id !== formatterTagId,
    );
    this.setState({ descriptionFormatterTags: formatterTags });
  }

  handleFormatterTagChange = async (tag, fieldName, newValue) => {
    const newFormatterTags = this.state.descriptionFormatterTags.map((formatterTag, iindex) => {
      if (tag.id !== formatterTag.id) {
        return formatterTag;
      } else {
        return {
          ...formatterTag,
          [fieldName]: newValue,
        };
      }
    });

    await this.setState({ descriptionFormatterTags: newFormatterTags });
  }

  deleteFomatterTagModal = async (formatterTag: DescriptionCleanerTag, show: boolean) => {
    await this.setState({ deleteFormatterTag: formatterTag, showDeleteModal: show });
  };

  setIsDeleting = (bool: boolean) => {
    this.setState({ isDeleting: bool });
  };

  render() {
    const { isLoading, searchText, descriptionFormatterTags } = this.state;

    if (isLoading) {
      return (
        <React.Fragment>
          <DescriptionFormatterHeader />
          <LoadingPage />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <DescriptionFormatterHeader />
          <Row>
            {/* <Col md="4">
              <TextField
                label=''
                value={searchText}
                placeholder="search"
                onChange={newValue => this.setState({ searchText: newValue })}
              />
            </Col>  */}
            <Col>
              <Button
                className="float-right"
                variant="outline-success"
                onClick={() => this.handleAddNewFormatterTag()}
              >
                Add new Formatter Tag
              </Button>
            </Col>
          </Row>
          <DescriptionEntry {...this.props} />
          <Row className='mt-5'>
            <Col>
              {descriptionFormatterTags !== undefined &&
                descriptionFormatterTags.length > 0 &&
                descriptionFormatterTags.map((descriptionFormatterTag, index) => (
                  <FormattingTag 
                    formatterTag = {descriptionFormatterTag}
                    index={index}
                    handleFormatterTagChange={this.handleFormatterTagChange}
                    deleteFomatterTagModal={this.deleteFomatterTagModal}
                    saveFormatterTag={this.saveFormatterTag}
                    {...this.props}
                  />
                ))}
            </Col>
          </Row>
          <FormattingTagDeleteModal
            {...this.props}
            formatterTag={this.state.deleteFormatterTag}
            show={this.state.showDeleteModal}
            deleteFormattingTagModal={this.deleteFomatterTagModal}
            setIsDeleting={this.setIsDeleting}
            handleRemoveFormatterTag={this.handleRemoveFormatterTag}
          />
        </React.Fragment>
      );
    }
  }
}

const DescriptionFormatterHeader = () => {
  return (
    <Row>
      <Col>
        <h1>Description Cleaner Tags</h1>
      </Col>
    </Row>
  );
};
