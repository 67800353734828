import React from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';

import LoadingPage from '../../../Shared/LoadingPage';

import { WalmartFeedsChildProps } from './WalmartFeeds';
import WalmartFeedResult from './WalmartFeedResult';

const WalmartFeedResults = (props: WalmartFeedsChildProps) => {
  const { isLoading, feedResults } = props;

  if (isLoading) {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <LoadingPage />
          </Col>
        </Row>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <ListGroup>
              <ListGroup.Item active>
                <Row>
                  <Col md="2" className='font-weight-bold'>
                    Channel Name
                  </Col>
                  <Col md="4">Feed ID</Col>
                  <Col md="3">Feed Status</Col>
                  <Col md="3">Updated At</Col>
                </Row>
              </ListGroup.Item>
              {feedResults !== undefined && feedResults.length > 0 ? (
                feedResults.map((feed, index) => (
                  <WalmartFeedResult key={index} {...props} feed={feed} />
                ))
              ) : (
                <ListGroup.Item>No Feed Found</ListGroup.Item>
              )}
            </ListGroup>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
};

export default WalmartFeedResults;
