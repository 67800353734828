export default class SellerVariantMarketplaceVariant {
  constructor(
    sellerVariantId: string,
    marketplaceVariantId: string,
    marketplaceProductId: string,
  ) {
    this.sellerVariantId = sellerVariantId;
    this.marketplaceVariantId = marketplaceVariantId;
    this.marketplaceProductId = marketplaceProductId;
  }

  sellerVariantId: string;
  marketplaceProductId: string;
  marketplaceVariantId: string;

  marketplaceProductVendor: string;
  marketplaceProductTitle: string;
  marketplaceVariantTitle: string;
}
