import React from 'react';
import { Row, Col } from 'react-bootstrap';

import MetafieldModel from '../../../../../classes/Marketplace/Metafield';
import MetafieldTextfield from '../Metafield';

export interface VariantMetafieldsProps {
  metafields: MetafieldModel[];
  isEditing: boolean;
  handleMetafieldChange: (mfield: MetafieldModel, newValue: string) => void;
}

const VariantMetafields = (props: VariantMetafieldsProps) => {
  const { isEditing, metafields, handleMetafieldChange } = props;

  return (
    <React.Fragment>
      <Row>
        <Col>
          <h4>Variant Metafields</h4>
        </Col>
      </Row>
      {metafields !== undefined && metafields !== null && metafields.length > 0 &&
        metafields.map((metafield, index) => (
        <MetafieldTextfield 
          isEditing={isEditing}
          key={index}
          metafield={metafield}
          handleMetafieldChange={handleMetafieldChange}
        />
      ))}
    </React.Fragment>
  );
};

export default VariantMetafields;
