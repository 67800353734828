import React, { useState } from 'react';
import { Row, Col, Card, Collapse, Badge } from 'react-bootstrap';

const Service = props => {
  const [isOpen, setIsOpen] = useState(false);
  const { service } = props;
  return (
    <Col className='mt-3' lg='6'>
      <Card>
        <Card.Header onClick={() => setIsOpen(!isOpen)}>
          <Row>
            <Col>
              <h5>{service.name}</h5>
            </Col>
            <Col xs='auto' className='align-self-center text-right'>
              <Badge pill variant={service.variant}>
                {service.type}
              </Badge>
            </Col>
          </Row>
        </Card.Header>
        <Collapse in={isOpen}>
          <Card.Body className='text-dark'>
            <Row>
              <Col className='font-weight-bold' md='2'>
                Location:
              </Col>
              <Col>{service.location}</Col>
            </Row>
            <Row>
              <Col className='font-weight-bold' md='2'>
                Language:
              </Col>
              <Col>{service.language}</Col>
            </Row>
            <Row>
              <Col className='font-weight-bold' md='2'>
                Framework:
              </Col>
              <Col>{service.framework}</Col>
            </Row>
            <Row>
              <Col className='font-weight-bold' md='2'>
                Description:
              </Col>
              <Col>{service.description}</Col>
            </Row>
          </Card.Body>
        </Collapse>
      </Card>
    </Col>
  );
};

export default Service;
