import React from "react";
import { Row, Col, Collapse } from "react-bootstrap";

import setMarketplaceFromId from "../../../../utils/SetMarketplaceFromId";

import RouteVariableProps from "../../../../interfaces/RouteVariableProps";

import BulkPricingResults from "./BulkPricingResults";
import BulkPricingEntry from "./BulkPricingEntry";

interface MatchParams {
  marketplaceId: string;
}

export interface BulkPricingProps
  extends RouteVariableProps<MatchParams>,
    BulkPricingState {
  setResults: (any) => void;
}

export interface BulkPricingState {
  results: any;
}

export default class BulkPricing extends React.Component<
  RouteVariableProps<MatchParams>
> {
  state = {
    results: undefined,
    hasResults: false
  };

  componentDidMount() {
    if (this.props.match.params.marketplaceId !== undefined) {
      this.initialSetMarketplace();
    }
  }

  initialSetMarketplace = async () => {
    let marketplaceId = this.props.match.params.marketplaceId;
    const { selectedMarketplace, setMarketplace, user } = this.props;

    if (selectedMarketplace === undefined) {
      await setMarketplaceFromId(
        marketplaceId,
        setMarketplace,
        user,
        this.props.history
      );
    }
  };

  setResults = results => {
    this.setState({ results: results, hasResults: true });
  };

  render() {
    const { hasResults } = this.state;
    return (
      <React.Fragment>
        <Row className="mt-5">
          <Col>
            <h1>Bulk Pricing</h1>
          </Col>
        </Row>
        <Collapse in={hasResults}>
          <Row>
            <Col>
              <BulkPricingResults
                {...this.props}
                {...this.state}
                setResults={this.setResults}
              />
            </Col>
          </Row>
        </Collapse>
        <BulkPricingEntry
          {...this.props}
          {...this.state}
          setResults={this.setResults}
        />
      </React.Fragment>
    );
  }
}
