import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import * as Settings from '../../../../Settings';
import RouteVariableProps from '../../../../interfaces/RouteVariableProps';
import rest from '../../../../utils/Rest';
import errorHandler from '../../../../utils/ErrorHandler';
import setMarketplaceFromId from '../../../../utils/SetMarketplaceFromId';
import MetafieldModel from '../../../../classes/Marketplace/Metafield';
import MetafieldDeleteModel from './MetafieldDeleteModal';
import MetafieldSetAsNonEditableModel from './MetafieldSetAsNonEditableModel'

import ProductMetafield from './Metafield';

interface MatchParams {
  marketplaceId: string;
}

export interface MetafieldsRouteProps extends RouteVariableProps<MatchParams> {} 

export interface MetafieldsState {
  isOpen: boolean;
  isLoading: boolean;
  isDeleting: boolean;
  showNonEditableMetafieldSaveModal: boolean;
  savingMetafield: MetafieldModel;
  deleteMetafield: MetafieldModel;
  showDeleteModal: boolean;
  metafields: MetafieldModel[];
}

export default class Metafields extends React.Component<
MetafieldsRouteProps, MetafieldsState
  > {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isLoading: false,
      isDeleting: false,
      showNonEditableMetafieldSaveModal: false,
      savingMetafield: undefined,
      deleteMetafield: undefined,
      showDeleteModal: false,
      metafields: new Array<MetafieldModel>()
    };
  }

  componentDidMount() {
    if (this.props.match.params.marketplaceId !== undefined) {
      this.initialSetMarketplace();
      this.getMetafields();
    }
  }

  initialSetMarketplace = async () => {
    let marketplaceId = this.props.match.params.marketplaceId;
    const { selectedMarketplace, setMarketplace, user } = this.props;

    if (selectedMarketplace === undefined) {
      await setMarketplaceFromId(
        marketplaceId,
        setMarketplace,
        user,
        this.props.history,
      );
    }
  };

  getMetafields = async () => {
    const { marketplaceId } = this.props.match.params;
    const { apiKey } = this.props;
    await this.setState({ isLoading: true });
    try {
      let data: MetafieldModel[] = await rest.get(
        `${
        Settings.eformedApiBaseUri
        }marketplace/${marketplaceId}/metafields?key=${apiKey}`,
      );
      await this.setState({ metafields: data, isLoading: false });
    } catch (error) {
      errorHandler(
        error,
        'There was an error while retrieving metafields. Please try again, if this is not the first time you have seen this error, please report it.',
        this.props.addAlert,
      );
      await this.setState({ isLoading: false });
    }
  }

  saveMetafield = async (metafield) => {
    const { marketplaceId } = this.props.match.params;
    const { apiKey } = this.props;
    try {
      metafield.isNewField ?
        await rest.post(
          `${
          Settings.eformedApiBaseUri
          }marketplace/${marketplaceId}/metafields?key=${apiKey}`,
          metafield
        ) :  
        await rest.put(
          `${
          Settings.eformedApiBaseUri
          }marketplace/${marketplaceId}/metafields/${metafield.id}?key=${apiKey}`,
          metafield
        );
      
      await this.handleMetafieldChange(metafield, 'isNewField', 'false');
      await this.getMetafields();
    } catch (error) {
      errorHandler(
        error,
        'There was an error while retrieving metafields. Please try again, if this is not the first time you have seen this error, please report it.',
        this.props.addAlert,
      );
    }
  }

  checkAndSaveMetafield = async (metafield: MetafieldModel) => {
    metafield.isNonEditable ? 
      this.saveNonEditableMetafieldModal(metafield, true) : await this.saveMetafield(metafield);
  }

  saveNonEditableMetafieldModal = async (metafield: MetafieldModel, show: boolean) => {
    await this.setState({ savingMetafield: metafield, showNonEditableMetafieldSaveModal: show });
  };

  handleAddNewMetafield = () => {
    const { marketplaceId } = this.props.match.params;
    let metafields = this.state.metafields;
    metafields = metafields !== undefined && metafields !== null ?
      metafields : new Array<MetafieldModel>();
    let metafield  = new MetafieldModel(); 
    metafield.marketplaceId = marketplaceId;
    metafield.isNewField = true;
    metafield.isNonEditable = false;
    metafield.ownerResource = "product";
    metafield.valueType = "integer";
    metafields.unshift(metafield);
    
    this.setState({ metafields: metafields });
  };

  handleRemoveMetafield = (metafieldId: string) => {
    let metafields = this.state.metafields.filter(
      metafield => metafield.id !== metafieldId,
    );
    this.setState({ metafields: metafields });
  }

  handleMetafieldChange = async (mfield, fieldName, newValue) => {
    const newMetafields = this.state.metafields.map((metafield, iindex) => {
      if (mfield.id !== metafield.id) {
        return metafield;
      } else {
        return {
          ...metafield,
          [fieldName]: newValue,
        };
      }
    });

    await this.setState({ metafields: newMetafields });
  }

  deleteMetafieldModal = async (metafield: MetafieldModel, show: boolean) => {
    await this.setState({ deleteMetafield: metafield, showDeleteModal: show });
  };

  setIsDeleting = (bool: boolean) => {
    this.setState({ isDeleting: bool });
  };

  render() {
    const { metafields } = this.state;

    const newMetafields = metafields !== undefined && metafields !== null &&
      metafields.filter(m => m.isNewField);

    return (
      <React.Fragment>
        <Row>
          <Col>
            <h1>Metafields</h1>
          </Col>
          <Col>
            <Button
              className="float-right"
              variant="outline-success"
              onClick={() => this.handleAddNewMetafield()}
            >
              Add new metafield
            </Button>
          </Col>
        </Row>

        {newMetafields !== undefined && newMetafields !== null && newMetafields.length > 0 &&
        <React.Fragment>
          <Row>
            <Col className="mt-5">
              <h3>New Metafields</h3>
            </Col>
          </Row>
          {metafields !== undefined && metafields !== null &&
          metafields.filter(m => m.isNewField).map((metafield, index) => (
            <ProductMetafield
              key={index}
              metafield={metafield}
              index={index}
              saveMetafield={this.checkAndSaveMetafield}
              handleMetafieldChange={this.handleMetafieldChange}
              deleteMetafieldModal={this.deleteMetafieldModal}
              {...this.props}
            />
          ))}
        </React.Fragment>
        }

        <Row>
          <Col className="mt-5">
            <h3>Non Editable Metafields</h3>
          </Col>
        </Row>
        {metafields !== undefined && metafields !== null &&
        metafields.filter(m => m.isNonEditable && !m.isNewField).map((metafield, index) => (
          <ProductMetafield
            key={index}
            metafield={metafield}
            index={index}
            saveMetafield={this.checkAndSaveMetafield}
            handleMetafieldChange={this.handleMetafieldChange}
            deleteMetafieldModal={this.deleteMetafieldModal}
            {...this.props}
          />
        ))}
      
        <Row>
          <Col className="mt-5">
            <h3>Editable Metafields</h3>
          </Col>
        </Row>
        {metafields !== undefined && metafields !== null &&
        metafields.filter(m => !m.isNonEditable && !m.isNewField).map((metafield, index) => (
          <ProductMetafield
            key={index}
            metafield={metafield}
            index={index}
            saveMetafield={this.checkAndSaveMetafield}
            handleMetafieldChange={this.handleMetafieldChange}
            deleteMetafieldModal={this.deleteMetafieldModal}
            {...this.props}
          />
        ))}

        <MetafieldDeleteModel
          {...this.props}
          metafield={this.state.deleteMetafield}
          show={this.state.showDeleteModal}
          deleteMetafieldModal={this.deleteMetafieldModal}
          setIsDeleting={this.setIsDeleting}
          handleRemoveMetafield={this.handleRemoveMetafield}
        />
        <MetafieldSetAsNonEditableModel
          {...this.props}
          metafield={this.state.savingMetafield}
          show={this.state.showNonEditableMetafieldSaveModal}
          saveMetafield={this.saveMetafield}
          saveNonEditableMetafieldModal={this.saveNonEditableMetafieldModal}
        />
      </React.Fragment>
    );
  }
}
