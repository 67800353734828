import React from "react";
import { Row, Col, Badge, Button, Spinner, Card, Collapse } from "react-bootstrap";

import * as Settings from '../../../../Settings';
import rest from '../../../../utils/Rest';
import errorHandler from '../../../../utils/ErrorHandler';

import Channel from '../../../../classes/Marketplace/Channel';
import { MarketplaceChannelRouteProps } from './MarketplaceChannels';
import { TextField } from '../../../Shared/EFBootstrap';
import * as CONSTANTS from '../../../../utils/Constants';

export interface MarketplaceChannelProps extends MarketplaceChannelRouteProps {
  marketplaceChannel: Channel;
}

export interface MarketplaceChannelState {
  isOpen: boolean,
  isEditing: boolean, 
  isSaving: boolean,
  isWholeSaleChannel: boolean,
  shipCompleteOrders: boolean,
  marketplaceChannel : Channel
}

export default class MarketplaceChannel extends React.Component<
  MarketplaceChannelProps, MarketplaceChannelState
  > {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isEditing: false, 
      isSaving: false,
      isWholeSaleChannel: this.props.marketplaceChannel.isWholeChannel,
      shipCompleteOrders: !this.props.marketplaceChannel.shipCompleteOrders,
      marketplaceChannel : this.props.marketplaceChannel
    };
  }

  setParentState = stateObject => {
    this.setState(stateObject);
  };

  changeChannelType = e => {
    this.setState({ isWholeSaleChannel: e.target.checked });
  }

  changeShipCompleteOrders = e => {
    this.setState({ shipCompleteOrders: e.target.checked });
  }

  saveChannel = async () => {
    const { marketplaceId } = this.props.match.params;
    const { apiKey, addAlert } = this.props;
    const { marketplaceChannel } = this.state;
    
    marketplaceChannel.shipCompleteOrders = !this.state.shipCompleteOrders;
    marketplaceChannel.isWholeChannel = this.state.isWholeSaleChannel;
    marketplaceChannel.costMultiplier = marketplaceChannel.isWholeChannel && marketplaceChannel.costMultiplier === null ?
      CONSTANTS.COST_MULTIPLIER.DEFAULT_VALUE : marketplaceChannel.costMultiplier;

    if (marketplaceChannel.isWholeChannel && (marketplaceChannel.costMultiplier >= CONSTANTS.COST_MULTIPLIER.UPPER_BOUND || 
      marketplaceChannel.costMultiplier < CONSTANTS.COST_MULTIPLIER.LOWER_BOUND)) {
      errorHandler(
        '',
        `Cost multiplier value should be within ${CONSTANTS.COST_MULTIPLIER.LOWER_BOUND} & ${CONSTANTS.COST_MULTIPLIER.UPPER_BOUND}`,
        this.props.addAlert,
      );
    } else {
      await this.setState({ isSaving: true });
      try {
        await rest.put(
          `${
          Settings.eformedApiBaseUri
          }marketplace/${marketplaceId}/channels/${marketplaceChannel.id}?key=${apiKey}`, marketplaceChannel
        );

        await this.setState({ 
          isSaving : false,
          isEditing : false,
          isOpen : false,
          marketplaceChannel: marketplaceChannel
        });

        addAlert("success", "Marketplace channel was successfully saved.");
      } catch (error) {
        errorHandler(
          error,
          'There was an error while saving channel. Please try again, if this is not the first time you have seen this error, please report it.',
          this.props.addAlert,
        );
        await this.setState({ 
          isSaving : false,
          isEditing : false,
          isOpen : false
        });
      }
    }
  };

  handleChange = fieldName => newValue => {
    let tempChannel = this.state.marketplaceChannel;
    tempChannel[fieldName] = newValue;
    this.setState({ marketplaceChannel: tempChannel });
  };

  render() {
    return (
      <Card className='mt-1'>
        <Card.Header onClick={() => this.setState({isOpen: !this.state.isOpen})}>
          <Row>
            <Col>
            <Col>
              <h6>{this.state.marketplaceChannel.name}</h6>
            </Col>
            <Row>
            <Col lg='2' className='align-self-center text-right'>
              {this.state.marketplaceChannel.active === false ? (
                <Badge pill variant='danger'>
                  Inactive
                </Badge>
              ) : (
                  <Badge pill variant='success'>
                    Active
                </Badge>
                )}
            </Col>
            <Col lg='2' className='align-self-center text-right'>
              {this.state.marketplaceChannel.isWholeChannel === false ? (
                <Badge pill variant='info'>
                  Retail
                </Badge>
              ) : (
                <Badge pill variant='warning'>
                  Wholesale
                </Badge>
              )}
            </Col></Row></Col>
            <Col className='align-self-center text-right'>
              <ChannelEditButton 
                isEditing = {this.state.isEditing}
                isSaving = {this.state.isSaving}
                isOpen = {this.state.isOpen}
                marketplaceChannel = {this.props.marketplaceChannel}
                saveChannel = {this.saveChannel}
                setParentState = {this.setParentState}

              />
            </Col>
          </Row>
        </Card.Header>
        <Collapse in={this.state.isOpen}>
          <Card.Body>
            <Row className='mt-3'>
              <Col lg="auto">
                <Row>
                  <Col lg="auto" style={{color: 'black'}}>Retail</Col>
                  <Col lg="auto">
                    <label className="switch">
                      <input 
                        type="checkbox"
                        checked={this.state.isWholeSaleChannel}
                        onChange={this.changeChannelType}
                        disabled={!this.state.isEditing}
                      />
                      <span className="slider"></span>
                    </label>
                  </Col>
                  <Col lg="auto" style={{color: 'black'}}>Wholesale</Col>
                </Row>
              </Col>
              <Col lg="auto">
                <Row>
                  <Col lg="auto" style={{color: 'black'}}>Ship Complete Orders only</Col>
                  <Col lg="auto">
                    <label className="switch">
                      <input 
                        type="checkbox"
                        checked={this.state.shipCompleteOrders}
                        onChange={this.changeShipCompleteOrders}
                        disabled={!this.state.isEditing}
                      />
                      <span className="slider"></span>
                    </label>
                  </Col>
                  {/* <Col lg="auto" style={{color: 'black'}}>Wholesale</Col> */}
                </Row>
              </Col>
              {this.state.isWholeSaleChannel && 
              <Col lg="3">
                <TextField
                  label="Cost Multiplier"
                  value={this.state.marketplaceChannel.costMultiplier ? 
                    this.state.marketplaceChannel.costMultiplier : CONSTANTS.COST_MULTIPLIER.DEFAULT_VALUE}
                  readOnly={!this.state.isEditing}
                  onChange={this.handleChange("costMultiplier")}
                  type="number"
                  step={0.1}
                  helpText={`Cost multiplier value should be greater than ${CONSTANTS.COST_MULTIPLIER.LOWER_BOUND} and less than ${CONSTANTS.COST_MULTIPLIER.UPPER_BOUND}`}
                  isValid={(
                    this.state.marketplaceChannel.costMultiplier >= CONSTANTS.COST_MULTIPLIER.LOWER_BOUND && 
                    this.state.marketplaceChannel.costMultiplier < CONSTANTS.COST_MULTIPLIER.UPPER_BOUND
                  )}
                />
              </Col>
              }
            </Row>
            <Row>
              <Col lg="3">
                <TextField
                  label="Inventory Threshold"
                  value={this.state.marketplaceChannel.thresholdInventory}
                  readOnly={!this.state.isEditing}
                  onChange={this.handleChange("thresholdInventory")}
                  type="number"
                  step={0.1}
                />
              </Col>
              <Col lg="3">
                <TextField
                  label="Commission"
                  value={this.state.marketplaceChannel.commission}
                  readOnly={!this.state.isEditing}
                  onChange={this.handleChange("commission")}
                  type="number"
                  step={1}
                />
              </Col>
              <Col lg="3">
                <TextField
                  label="Minimum Margin"
                  value={this.state.marketplaceChannel.minimumMargin}
                  readOnly={!this.state.isEditing}
                  onChange={this.handleChange("minimumMargin")}
                  type="number"
                  step={1}
                />
              </Col>
              <Col lg="3">
                <TextField
                  label="Site ID"
                  value={this.state.marketplaceChannel.siteId}
                  readOnly={!this.state.isEditing}
                  onChange={this.handleChange("siteId")}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <TextField
                  label="Location ID"
                  value={this.state.marketplaceChannel.locationId}
                  readOnly={!this.state.isEditing}
                  onChange={this.handleChange("locationId")}
                />
              </Col>
              <Col lg="3">
                <TextField
                  label="Vendor Contract Number"
                  value={this.state.marketplaceChannel.vendorContractNumber}
                  readOnly={!this.state.isEditing}
                  onChange={this.handleChange("vendorContractNumber")}
                />
              </Col>
              <Col lg="3">
                <TextField
                  label="Storefront URL"
                  value={this.state.marketplaceChannel.storefrontUrl}
                  readOnly={!this.state.isEditing}
                  onChange={this.handleChange("storefrontUrl")}
                />
              </Col>
              <Col lg="3">
                <TextField
                  label="Marketplace Position"
                  value={this.state.marketplaceChannel.marketplacePosition}
                  readOnly={!this.state.isEditing}
                  onChange={this.handleChange("marketplacePosition")}
                  type="number"
                  step={1}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <TextField
                  label="AVG Shipping Revenue"
                  value={this.state.marketplaceChannel.avgShippingRevenue}
                  readOnly={!this.state.isEditing}
                  onChange={this.handleChange("avgShippingRevenue")}
                  type="number"
                  step={0.1}
                />
              </Col>
              <Col lg="3">
                <TextField
                  label="Free Shipping Threshold"
                  value={this.state.marketplaceChannel.freeShippingThreshold}
                  readOnly={!this.state.isEditing}
                  onChange={this.handleChange("freeShippingThreshold")}
                  type="number"
                  step={0.1}
                />
              </Col>
              <Col lg="3">
                <TextField
                  label="Channel App URL"
                  value={this.state.marketplaceChannel.channelAppUrl}
                  readOnly={!this.state.isEditing}
                  onChange={this.handleChange("channelAppUrl")}
                />
              </Col>
            </Row>
          </Card.Body>
        </Collapse>
      </Card>
    );
  }
}

const ChannelEditButton = (props) => {
  const { isEditing, isSaving, isOpen, saveChannel, setParentState } = props;
  const onClickSave = e => {
    e.stopPropagation();
    if (isEditing) {
      saveChannel();
    } else {
      setParentState({ isEditing: !isEditing });
      if (!isEditing && !isOpen) setParentState({ isOpen: !isOpen });
    }
  }

  return (
    <Button
      variant={isEditing ? 'success' : 'warning'}
      onClick={onClickSave}
    >
      {isEditing ? (
        isSaving ? (
          <React.Fragment>
            <Spinner size='sm' animation='border' />
            Saving...
          </React.Fragment>
        ) : (
          'Save Changes'
        )
      ) : (
        'Edit This Channel'
      )}
    </Button>
  );
};

