import React, { useState } from 'react';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import { TextField } from '../../../Shared/EFBootstrap';

import { importSellerVariantToProduct } from './Functions/ImportSellerVariant';

const ImportToProduct = props => {
  const [sellerVariantId, setSellerVariantId] = useState('');

  const { productId } = props.match.params;
  const { isEditing, isImporting, setParentState } = props;

  if (productId === 'new') {
    return (
      <Row className='mt-3'>
        <Col>
          <TextField
            label='Seller Variant Id'
            readOnly={!isEditing}
            value={sellerVariantId}
            placeholder={'00000000-0000-0000-0000-000000000000'}
            helpText='Hit Edit Above, then use this field to fill out a valid Seller Variant Id to import the data to the whole product'
            onChange={newValue => setSellerVariantId(newValue)}
          />
        </Col>
        <Col className='text-right align-self-center' lg='3'>
          <Button
            disabled={!isEditing}
            variant='success'
            onClick={() =>
              importSellerVariantToProduct(
                sellerVariantId,
                props,
                setParentState,
              )
            }
          >
            {isImporting === true ? (
              <React.Fragment>
                <Spinner size='sm' animation='border' />
                Importing...
              </React.Fragment>
            ) : (
              'Import'
            )}
          </Button>
        </Col>
      </Row>
    );
  } else {
    return null;
  }
};

export default ImportToProduct;
