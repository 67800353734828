import React from 'react';
import { useDrop } from 'react-dnd';
import update from 'immutability-helper';

import { MarketplaceVariantsProps } from './Variants';
import VariantDraggable from './VariantDraggable';

const VariantsDraggable = (props: MarketplaceVariantsProps) => {
  const {
    isEditing,
    isImporting,
    product,
    marketplaceId,
    channels,
    handleVariantChange,
    handleRemoveVariant,
    productId,
    setParentState,
    handleMetafieldChange,
    handleRoutingClick
  } = props;

  let cards = product.variants;
  let tempProduct = product;

  const moveCard = (id: string, atIndex: number) => {
    const { card, index } = findCard(id);
    cards = update(cards, {
        $splice: [
          [index, 1],
          [atIndex, 0, card],
        ],
    });
    
    cards.forEach(card => {
      card.position = cards.indexOf(card) + 1;
    });
    
    tempProduct.variants = cards;
    setParentState({ product: tempProduct });
  }

  const findCard = (id: string) => {
    const card = cards.filter((c) => `${c.id}` === id)[0]
    return {
      card,
      index: cards.indexOf(card),
    }
  }

  const [, drop] = useDrop({ accept: 'card' });
  return (
    <div ref={drop}>
      {product.variants !== undefined &&
        product.variants.map((variant, index) => (
          <VariantDraggable
            key={variant.id}
            id={variant.id}
            moveCard={moveCard}
            findCard={findCard}
            index={index}
            isEditing={isEditing}
            isImporting={isImporting}
            marketplaceId={marketplaceId}
            product={product}
            variant={variant}
            channels={channels}
            handleVariantChange={handleVariantChange}
            handleRemoveVariant={handleRemoveVariant}
            productId={productId}
            apiKey={props.apiKey}
            addAlert={props.addAlert}
            setParentState={setParentState}
            handleMetafieldChange={handleMetafieldChange}
            handleRoutingClick={handleRoutingClick}
          />
        ))}
    </div>
  )
}

export default VariantsDraggable;
