import React from 'react';
import { Row, Col, Card, Button, Spinner } from 'react-bootstrap';

import Order from '../../../../classes/Marketplace/Order';
import { TextField } from '../../../Shared/EFBootstrap';

interface CustomerInformationProps {
  order: Order;
  isSaving: boolean;
  isEditing: boolean;
  handleOrderChange: (fieldName: string, newValue: any) => void;
  saveOrder: () => void;
  setParentState: (stateObject: any) => void;
}

const CustomerInformation = (props: CustomerInformationProps) => {
  const { order, isSaving, isEditing, handleOrderChange, saveOrder, setParentState } = props;

  return (
    <Row className='mt-2'>
      <Col>
        <Card border='success' text='dark'>
          <Card.Header>
            <Row>
              <Col><h3>Customer Information</h3></Col>
              <Col md='2' className='text-right'>
                <Button
                  size='sm'
                  variant={isEditing ? 'outline-success' : 'outline-warning'}
                  onClick={() => isEditing ? saveOrder() : setParentState({ isEditing: !isEditing} )}
                >
                  {isEditing ? (isSaving ? (
                    <React.Fragment>
                      <Spinner size='sm' animation='border' />
                      &nbsp;Saving...
                    </React.Fragment>
                  ) : (
                    'Save'
                  )) : 'Edit'}
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <Card>
                  <Card.Header as='h4'>Billing Address</Card.Header>
                  <Card.Body>
                    {!isEditing ? 
                    <React.Fragment>
                      <Row>
                        <Col>
                          {order.customerFirstName} {order.customerLastName}
                        </Col>
                      </Row>
                      <Row>
                        <Col>{order.email}</Col>
                      </Row>
                      <Row>
                        <Col>{order.phone}</Col>
                      </Row>
                      <Row>
                        <Col>{order.billingAddress}</Col>
                      </Row>
                      <Row>
                        <Col>{order.billingAddress2}</Col>
                      </Row>
                      <Row>
                        <Col>
                          {order.billingCity}, {order.billingState}{' '}
                          {order.billingPostalCode}
                        </Col>
                      </Row>
                    </React.Fragment> :
                    <React.Fragment>
                      <Row>
                        <Col>
                          <TextField
                            label='Customer First Name'
                            placeholder='Customer First Name'
                            value={order.customerFirstName}
                            onChange={newValue =>
                              handleOrderChange('customerFirstName', newValue)
                            }
                          />
                        </Col>
                        <Col>
                          <TextField
                            label='Customer Last Name'
                            placeholder='Customer Last Name'
                            value={order.customerLastName}
                            onChange={newValue =>
                              handleOrderChange('customerLastName', newValue)
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextField
                            label='Customer Email'
                            placeholder='Customer Email'
                            value={order.email}
                            onChange={newValue =>
                              handleOrderChange('email', newValue)
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextField
                            label='Customer Phone Number'
                            placeholder='Customer Phone'
                            value={order.phone}
                            onChange={newValue =>
                              handleOrderChange('phone', newValue)
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextField
                            label='Billing Address'
                            placeholder='Billing Address'
                            value={order.billingAddress}
                            onChange={newValue =>
                              handleOrderChange('billingAddress', newValue)
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextField
                            label='Billing Address 2'
                            placeholder='Billing Address 2'
                            value={order.billingAddress2}
                            onChange={newValue =>
                              handleOrderChange('billingAddress2', newValue)
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextField
                            label='Billing City'
                            placeholder='Billing City'
                            value={order.billingCity}
                            onChange={newValue =>
                              handleOrderChange('billingCity', newValue)
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextField
                            label='Billing State'
                            placeholder='Billing State'
                            value={order.billingState}
                            onChange={newValue =>
                              handleOrderChange('billingState', newValue)
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextField
                            label='Billing Postal Code'
                            placeholder='Billing Postal Code'
                            value={order.billingPostalCode}
                            onChange={newValue =>
                              handleOrderChange('billingPostalCode', newValue)
                            }
                          />
                        </Col>
                      </Row>
                    </React.Fragment>
                    }
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Header as='h4'>Shipping Address</Card.Header>
                  <Card.Body>
                    {!isEditing ? 
                    <React.Fragment>
                      <Row>
                        <Col>
                          {order.shippingFirstName} {order.shippingLastName}
                        </Col>
                      </Row>
                      <Row>
                        <Col>{order.shipToCompany}</Col>
                      </Row>
                      <Row>
                        <Col>{order.address}</Col>
                      </Row>
                      <Row>
                        <Col>{order.address2}</Col>
                      </Row>
                      <Row>
                        <Col>
                          {order.city}, {order.state} {order.postalCode}{' '}
                          {order.country}
                        </Col>
                      </Row>
                    </React.Fragment> :
                    <React.Fragment>
                      <Row>
                        <Col>
                          <TextField
                            label='Shipping First Name'
                            placeholder='Shipping First Name'
                            value={order.shippingFirstName}
                            onChange={newValue =>
                              handleOrderChange('shippingFirstName', newValue)
                            }
                          />
                        </Col>
                        <Col>
                          <TextField
                            label='Shipping Last Name'
                            placeholder='Shipping Last Name'
                            value={order.shippingLastName}
                            onChange={newValue =>
                              handleOrderChange('shippingLastName', newValue)
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextField
                            label='Shipping Address'
                            placeholder='Shipping Address'
                            value={order.address}
                            onChange={newValue =>
                              handleOrderChange('address', newValue)
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextField
                            label='Shipping Address 2'
                            placeholder='Shipping Address 2'
                            value={order.address2}
                            onChange={newValue =>
                              handleOrderChange('address2', newValue)
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextField
                            label='Shipping City'
                            placeholder='Shipping City'
                            value={order.city}
                            onChange={newValue =>
                              handleOrderChange('city', newValue)
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextField
                            label='Shipping State'
                            placeholder='Shipping State'
                            value={order.state}
                            onChange={newValue =>
                              handleOrderChange('state', newValue)
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextField
                            label='Shipping Postal Code'
                            placeholder='Shipping Postal Code'
                            value={order.postalCode}
                            onChange={newValue =>
                              handleOrderChange('postalCode', newValue)
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextField
                            label='Shipping Country'
                            placeholder='Shipping Country'
                            value={order.country}
                            onChange={newValue =>
                              handleOrderChange('country', newValue)
                            }
                          />
                        </Col>
                      </Row>
                    </React.Fragment>
                    }
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default CustomerInformation;
