import React, { useState } from 'react';
import { RouteComponentProps } from "react-router";
import { Row, Col, ListGroup } from 'react-bootstrap';

import * as Settings from "../../../Settings";
import BaseProps from "../../../interfaces/BaseProps";
import rest from '../../../utils/Rest';
import errorHandler from '../../../utils/ErrorHandler';
import LoadingPage from '../../Shared/LoadingPage';

import PurchaseOrderItem from "../../../classes/Logistics/PurchaseOrderItem";

import PoItemSearchFilters from "./PoItemSearch/PoItemSearchFilters";
import PoItem from "./PoItemSearch/PoItem";

interface SearchPoItemProps extends BaseProps, RouteComponentProps {}

const SearchPoItem = (props: SearchPoItemProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [poItems, setPoItems] = useState(new Array<PurchaseOrderItem>());
  const [searchFilter, setSearchFilter] = useState("UPC");
  const [searchValues, setSearchValues] = useState("");

  const getPurchaseOrderItems = async () => {
    setIsLoading(true);

    try {
      let data = await rest.get(
        `${Settings.eformedApiBaseUri}po/items.json/search?key=${
          props.apiKey
        }&shop=babywise-life.myshopify.com&skip=0&searchkey=${encodeURIComponent(searchValues)}&searchfilter=${encodeURIComponent(searchFilter)}`,
      );      
      let poItemsResponse: PurchaseOrderItem[] = data.items;

      setPoItems(poItemsResponse);
      setSearchValues("");
      setIsLoading(false);
    } catch (error) {
      errorHandler(
        error,
        'There was an error while retrieving the po items. Please try again, if this is not the first time you have seen this error, please report the issue.',
        props.addAlert,
      );
      setIsLoading(false);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <h1>Product Search</h1>
        </Col>
      </Row>
      <PoItemSearchFilters 
        searchFilter={searchFilter}
        searchValues={searchValues}
        isLoading={isLoading}
        setSearchFilter={setSearchFilter}
        setSearchValues={setSearchValues}
        getPurchaseOrderItems={getPurchaseOrderItems}
      />
      <Row>
        <Col>
          <ListGroup>
            <ListGroup.Item active>
              <Row>
                <Col className="px-0">UPC</Col>
                <Col className="px-0">SKU</Col>
                <Col className="px-0">MPN</Col>
                <Col className="px-0">OSC ID</Col>
                <Col md="4" className="px-0">Seller Variant ID</Col>
                <Col md="1" className="px-0">Case Pack</Col>
                <Col md="1" className="px-0">Cost</Col>
              </Row>
            </ListGroup.Item>

            {!isLoading ?
              poItems.length > 0 ? 
              (poItems.map((poItem, index) => 
                <PoItem key={index} poItem={poItem}/>
              )) : (
                <ListGroup.Item>No PO item found</ListGroup.Item>
              )
              :
              <LoadingPage />
            }
          </ListGroup>
        </Col>
      </Row>
    </>
  );
}

export default SearchPoItem;