import React from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';

import RouteVariableProps from '../../interfaces/RouteVariableProps';

interface MatchParams {
  sellerId: string;
}

export interface SellerRouteProps
  extends RouteVariableProps<MatchParams> {}

const Sellers = (props: SellerRouteProps) => {
  return (
    <React.Fragment>
      <Row>
        <Col>
          <h1>Change Seller Selection</h1>
        </Col>
      </Row>
      <Row className='mt-5'>
        <Col>
          <ListGroup>
            {props.user.sellers !== undefined &&
              props.user.sellers.length > 0 &&
              props.user.sellers.map((seller, index) => (
                <ListGroup.Item
                  key={index}
                  action
                  active={props.selectedSeller !== undefined && seller.id === props.selectedSeller.id}
                  onClick={() => props.setSeller(seller)}
                >
                  <Row>
                    <Col>{seller.name}</Col>
                  </Row>
                </ListGroup.Item>
              ))}
          </ListGroup>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Sellers;
