export default class Company {
  constructor(shop?: string) {
    this.shop = shop;
  }

  id: number;
  name: string;
  shop?: string;
  emailAddress: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
}
