import React, { useState } from 'react';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import { TextField } from '../../../../Shared/EFBootstrap';

import errorHandler from '../../../../../utils/ErrorHandler';
import addAssociation from '../../../../../utils/Marketplace/AddAssociation';
import { importSellerVariantToVariant } from '../Functions/ImportSellerVariant';

import SellerVariantMarketplaceVariant from '../../../../../classes/Marketplace/SellerVariantMarketplaceVariant';

import SellerVariantList from './SellerVariantList';

import { MarketplaceVariantProps } from './Variant';

const SellerVariantArea = (props: MarketplaceVariantProps) => {
  const { variant } = props;

  return (
    <React.Fragment>
      <Row className='mt-3'>
        <Col>
          <h4>Seller Variants</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          {variant.sellerVariants !== undefined && (
            <SellerVariantList
              {...props}
            />
          )}
        </Col>
      </Row>
      <AddSellerVariant {...props} />
    </React.Fragment>
  );
};

const AddSellerVariant = props => {
  const [isAssociating, setIsAssociating] = useState(false);
  const [sellerVariantId, setSellerVariantId] = useState('');
  const {
    isImporting,
    index,
    setParentState,
    product,
    variant,
    apiKey,
    marketplaceId,
  } = props;

  const addAssociationWithState = async (sellerVariantId: string) => {
    await setIsAssociating(true);
    let sellerVariantMarketplaceVariant = new SellerVariantMarketplaceVariant(
      sellerVariantId,
      variant.id,
      product.id,
    );
    try {
      let productData = await addAssociation(
        apiKey,
        marketplaceId,
        sellerVariantMarketplaceVariant,
      );
      setParentState({ product: productData });
      await setIsAssociating(false);
    } catch (error) {
      errorHandler(
        error,
        'There was an error while associating the seller and marketplace variants.',
        props.addAlert,
      );
      await setIsAssociating(false);
    }
  };

  return (
    <Row className='mt-2'>
      <Col className='align-self-center'>
        <TextField
          label='Seller Variant Id'
          value={sellerVariantId}
          placeholder={'00000000-0000-0000-0000-000000000000'}
          onChange={newValue => setSellerVariantId(newValue)}
        />
      </Col>
      <Col xs='auto' className='align-self-center text-center'>
        <Row>
          <Col>
            <Button
              size='sm'
              variant='outline-info'
              onClick={() => {
                importSellerVariantToVariant(
                  index,
                  sellerVariantId,
                  props,
                  setParentState,
                );
              }}
            >
              {isImporting ? (
                <React.Fragment>
                  <Spinner size='sm' animation='border' />
                  Importing...
                </React.Fragment>
              ) : (
                'Import Data'
              )}
            </Button>
            <Button
              size='sm'
              variant='outline-success'
              onClick={() => addAssociationWithState(sellerVariantId)}
            >
              {isAssociating ? (
                <React.Fragment>
                  <Spinner size='sm' animation='border' />
                  Adding...
                </React.Fragment>
              ) : (
                'Add Association'
              )}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SellerVariantArea;
