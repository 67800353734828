import React, { useState } from "react";
import { Row, Col, Card, Collapse, Button, Spinner } from "react-bootstrap";

import dateFormat from "../../../../../utils/DateFormat";
import * as Settings from '../../../../../Settings';
import rest from '../../../../../utils/Rest';
import errorHandler from '../../../../../utils/ErrorHandler';

import MarketplaceProductProps from "../../../../../interfaces/MarketplaceProductProps";
import ChannelPricing from './ChannelPricing';

export interface PricingPanelProps extends MarketplaceProductProps {
  productId: string,
  apiKey: string;
  addAlert: any;
}

const PricingPanel = (props: PricingPanelProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOutUpdating, setIsOutUpdating] = useState(false);
  const {
    channels,
    product
  } = props;

  const getMostRecentTimeStamp = () => {
    let dateList = new Array<Date>();
    product.variants.forEach(v => {
      if (v.marketplaceChannelMarketplaceVariants !== undefined && v.marketplaceChannelMarketplaceVariants !== null && v.marketplaceChannelMarketplaceVariants.length > 0) {
        let recentDate = new Date(Math.max.apply(null, v.marketplaceChannelMarketplaceVariants.map(function(e) {
          return new Date(e.priceUpdatedAt);
        })));

        dateList.push(recentDate);
      }
    });

    let mostLeastDate = new Date("2020-01-01T00:00:00.0000000");
    let resultDate =  new Date(Math.max.apply(null, dateList));

    return resultDate > mostLeastDate ? resultDate : null;
  }

  const updateOutValues = async () => {
    if (!checkForVariantDimensions()) {
      props.addAlert("warning", "There are variants with empty dimension values. Those variants will not update it's Out values.");
    }
    
    setIsOutUpdating(true);
    try {
      await rest.put(
        `${Settings.eformedApiBaseUri}marketplace/${product.marketplaceId}/products/${product.id}/updateoutvalue?key=${props.apiKey}`,
        product
      );
      
      props.addAlert("success", "Out values updated successfully. Reload the page to see the changes.");
    } catch (error) {
      errorHandler(
        error,
        'There was an error while connecting to the out cost update service. Please try again, if this is not the first time you have seen this error, please report the issue.',
        props.addAlert,
      );
    }

    setIsOutUpdating(false);
  }

  const checkForVariantDimensions = () : boolean => {
    let checkDimensions = true;
    product.variants.forEach(v => {
      if (!(checkValue(v.dimH) && checkValue(v.dimL) && checkValue(v.dimW) && checkValue(v.weight))) {
        checkDimensions = false;
      }
    });

    return checkDimensions;
  }

  const checkValue = (value: number | string) : boolean => {
    return value !== undefined && value !== null && value !== '' && value > 0;
  }

  const mostRecentPriceUpdateTime = getMostRecentTimeStamp();

  return (
    <Card className="mt-3">
      <Card.Header onClick={() => setIsOpen(!isOpen)}>
        <Row>
          <Col className='align-self-center'>
            <h4>Pricing</h4>
          </Col>
          {mostRecentPriceUpdateTime !== undefined && mostRecentPriceUpdateTime !== null &&
            <Col className='text-right' style={{ color: 'black' }}>
              Prices Updated: {dateFormat(mostRecentPriceUpdateTime.toString(), true)}
            </Col>
          }
        </Row>
      </Card.Header>
      <Collapse in={isOpen}>
        <Card.Body>
          <Row>
            <Col className='text-right'>
              <Button size='sm' variant='outline-info' onClick={() => updateOutValues()}>
                {!isOutUpdating ? 
                  "Update 'Out' Costs" :
                  <React.Fragment>
                    <Spinner size='sm' animation='border' />
                    <span>&nbsp;Updating 'Out' Costs...</span>
                  </React.Fragment>
                }
              </Button>
            </Col>
          </Row>
          <Row><Col>
          {channels !== undefined &&
          channels.map((channel, index) => (
            <ChannelPricing 
              key={index}
              {...props}
              channel={channel}
            />
          ))}
          </Col></Row>
        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default PricingPanel;
