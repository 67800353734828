import React from 'react';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import { TextField } from '../../../Shared/EFBootstrap';

import MarketplaceProductProps from '../../../../interfaces/MarketplaceProductProps';

export const IdAndButton = (props: MarketplaceProductProps) => {
  const { product } = props;
  return (
    <Row className='mt-3'>
      <Col>
        <TextField label='Marketplace Product Id' readOnly value={product.id} />
      </Col>
      <Col lg='3' className='align-self-center text-right'>
        <ProductEditButton {...props} />
      </Col>
    </Row>
  );
};

export const SaveButton = (props: MarketplaceProductProps) => {
  const { isScrollPassSaveBtn, isScrollPassBottom, floatingDivWidth } = props;

  if (isScrollPassSaveBtn) {
    return (
      <Row className={`mt-3 product-save-row ${isScrollPassBottom ? 'product-save-row-fixed' : ''}`}
        style={isScrollPassBottom ? {width: floatingDivWidth + 'px'} : {}}>
        <Col className='align-self-center text-center'>
          <ProductEditButton {...props} />
        </Col>
      </Row>
    );
  } else {
    return (
      <React.Fragment></React.Fragment>
    );
  }
};

const ProductEditButton = (props: MarketplaceProductProps) => {
  const { isEditing, isSaving, isImported, setParentState, saveProduct } = props;
  return (
    <Button
      variant={isEditing ? 'success' : 'warning'}
      disabled={!isImported}
      onClick={() =>
        isEditing ? saveProduct() : setParentState({ isEditing: !isEditing })
      }
    >
      {isEditing ? (
        isSaving ? (
          <React.Fragment>
            <Spinner size='sm' animation='border' />
            Saving...
          </React.Fragment>
        ) : (
          'Save Changes'
        )
      ) : (
        'Edit This Product'
      )}
    </Button>
  );
};

export default IdAndButton;
