import React, { useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Row, Col, Card, Badge, ListGroup, Button, Modal, Spinner, Collapse } from 'react-bootstrap';

import * as Settings from '../../../../Settings';
import rest from '../../../../utils/Rest';
import errorHandler from '../../../../utils/ErrorHandler';

import Order from '../../../../classes/Marketplace/Order';
import OrderLineItem from '../../../../classes/Marketplace/OrderLineItem';
import SellerVariant from '../../../../classes/Seller/SellerVariant';
import dateFormat from '../../../../utils/DateFormat';

import syncStatusToVariant from '../../../../utils/SyncStatusToVariant';
import { TextField, Select } from '../../../Shared/EFBootstrap';
import { getSellerVariantOptionString } from '../../SellerVariants/SellerVariantCard';
import { OrderLineItemsProps } from './OrderLineItems';
import LineItemStatusDropdown from './LineItemStatusDropdown';

interface OrderLineItemProps extends OrderLineItemsProps {
  apiKey: string;
  marketplaceId: string;
  order: Order;
  lineItem: OrderLineItem;
  index: number;
  setParentState: (any) => void;
  resyncLineItem: (lineItemId: string) => void;
  fulfillLineItem: (lineItemId: string) => void;
  addAlert: any;
}


const OrderLineItemCard = (props: OrderLineItemProps) => {
  const [isOpenLineItemDates, setIsOpenLineItemDates] = useState(false);
  const [sellerAssignModalShow, setSellerAssignModalShow] = useState(false);
  const [lineItemCancelModalShow, setLineItemCancelModalShow] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { order, lineItem, setParentState, addAlert } = props;

  const saveOrderLineItem = async (lineItem: OrderLineItem) => {
    setIsProcessing(true);
    try {
      let data: OrderLineItem = await rest.put(
        `${
          Settings.eformedApiBaseUri
        }marketplace/${props.marketplaceId}/orders/${order.id}/lineitems/orderLineItemUpdate/${lineItem.id}?key=${props.apiKey}`,
        lineItem,
        true
      );

      let orderTemp = order;
      let orderLineItems = order.lineItems.map((item) => {
        if (item.id !== lineItem.id) {
          return item;
        }
        return data;
      });

      orderTemp.lineItems = orderLineItems;
      setParentState({ isLoading: false, order: orderTemp });
      props.addAlert("success", `Marketplace order was successfully updated.`);
    } catch (error) {
      errorHandler(
        error,
        'There was an error while saving the order. Please try again, if this is not the first time you have seen this error, please report the issue.',
        addAlert,
      );
      setParentState({ isLoading: false });
    }
    setIsProcessing(false);
  }

  const assignSellerVariantToLineItem = (lineItem: OrderLineItem, sellerVariantId: string) => {
    let orderTemp = order;
    let orderLineItems = order.lineItems.map((item) => {
      if (item.id !== lineItem.id) {
        return item;
      }

      lineItem.sellerVariantId = sellerVariantId;
      return lineItem;
    });

    orderTemp.lineItems = orderLineItems;
    setParentState({ isLoading: false, order: orderTemp });
  }

  const changeLineItemStatus = (lineItemStatus: 
    | "Created"
    | "Acknowledged"
    | "Shipped"
    | "Cancelled"
    | "Refund") => {
    let orderTemp = order;
    let lineItemTemp = lineItem;
    let orderLineItems = order.lineItems.map((item) => {
      if (item.id !== lineItem.id) {
        return item;
      }

      lineItemTemp.status = lineItemStatus;
      return lineItemTemp;
    });

    orderTemp.lineItems = orderLineItems;
    setParentState({ isLoading: false, order: orderTemp });
    saveOrderLineItem(lineItem);
  }
  
  return (
    <Card text='dark' className='mt-1'>
    <Card.Header onClick={() => setIsOpenLineItemDates(!isOpenLineItemDates)}>
      <Row>
        <Col className='align-self-center align-middle' xs='auto'>
          {lineItem.quantity}x
        </Col>
        <Col className='align-self-center align-middle' xs='auto'>
          {lineItem.unitPrice !== undefined && "$"+lineItem.unitPrice.toFixed(2)}
        </Col>
        <Col className='align-self-center align-middle' xs='auto'>
          {lineItem.subtotal !== undefined && "$"+lineItem.subtotal.toFixed(2)}
        </Col>
        <Col className='align-self-center text-right'>
          <span className='font-weight-bold'>Sync Status:</span>{' '}
          <Badge
            pill
            variant={syncStatusToVariant(lineItem.syncStatus)}
          >
            {lineItem.syncStatus || 'Created'}
          </Badge>{' '}
          {
            (lineItem.sellerVariantId === undefined ||
            lineItem.sellerVariantId === null) && (
            <Button
              size='sm'
              variant='outline-info'
              onClick={() => props.resyncLineItem(lineItem.id)}
            >
              Auto Assign
            </Button>
            )
          }
        </Col>
        {/* <Col className='align-self-center text-right'>
          <span className='font-weight-bold'>
            Fulfillment Status:
          </span>{' '}
          <Badge
            pill
            variant={
              lineItem.fulfillmentStatus === 'Fulfilled'
                ? 'success'
                : 'warning'
            }
          >
            {lineItem.fulfillmentStatus || 'Unfulfilled'}
          </Badge>{' '}
          <Button
            size='sm'
            variant='outline-info'
            onClick={() => props.fulfillLineItem(lineItem.id)}
          >
            Fulfill
          </Button>
        </Col> */}
      </Row>
    </Card.Header>
    <ListGroup variant='flush'>
      <Collapse in={isOpenLineItemDates}>
        <ListGroup.Item>
          <Row className='mt-2'>
            <Col className='font-weight-bold' md='3'>Order Submitted At:</Col>
            <Col md="3">{dateFormat(lineItem.orderSubmittedAt, true)}</Col>
            <Col className='font-weight-bold' md='3'>Seller Assigned At:</Col>
            <Col md="3">{dateFormat(lineItem.sellerAssignedAt, true)}</Col>
          </Row>
          <Row className='mt-2'>
            <Col className='font-weight-bold' md='3'>Shipped At:</Col>
            <Col md="3">{dateFormat(lineItem.shippedAt, true)}</Col>
            <Col className='font-weight-bold' md='3'>Refunded At:</Col>
            <Col md="3">{dateFormat(lineItem.refundedAt, true)}</Col>
          </Row>
          <Row className='mt-2'>
            <Col className='font-weight-bold' md='3'>Cancellation Notified At:</Col>
            <Col md="3">{dateFormat(lineItem.cancelationNotifiedAt, true)}</Col>
          </Row>
        </ListGroup.Item>
      </Collapse>
      <ListGroup.Item>
        <Row className='mt-2'>
          <Col className='align-self-center align-middle' xs='auto'>
            <span className='font-weight-bold'>Seller:</span>{' '}
            {lineItem.seller !== undefined && lineItem.seller !== null
              ? lineItem.seller.name
              : 'None Assigned'}{' '}
            {lineItem.outOfStock === true && (
              <Badge pill variant='danger'>
                Out of Stock
              </Badge>
            )}
          </Col>
          <Col>
          {lineItem.sellerVariant !== undefined && lineItem.sellerVariant !== null &&
            <Row>
              <Col xs='auto'>
                <span className='font-weight-bold'>Vendor:</span>{' '}
                {lineItem.sellerVariant.vendor}
              </Col>
              <Col xs='auto'>
                <span className='font-weight-bold'>Title:</span>{' '}
                {lineItem.sellerVariant.productTitle}
              </Col>
              <Col xs='auto'>
                <span className='font-weight-bold'>SKU:</span>{' '}
                {lineItem.sellerVariant.sku}
              </Col>
              <Col xs='auto'>
                <span className='font-weight-bold'>MPN:</span>{' '}
                {lineItem.sellerVariant.mpn}
              </Col>
              <Col xs='auto'>
                <span className='font-weight-bold'>Barcode:</span>{' '}
                {lineItem.sellerVariant.barcode}
              </Col>
              <Col xs='auto'>
                <span className='font-weight-bold'>Options:</span>{' '}
                {getSellerVariantOptionString(lineItem.sellerVariant)}
              </Col>
            </Row>
          }
          </Col>
          <Col className="align-self-center text-right" xs='auto'>
            <React.Fragment>
              <Button size="sm" variant="dark" disabled={isProcessing} onClick={() => setSellerAssignModalShow(true)}>
                {
                // isSellerAssigning ? 
                //   <React.Fragment>
                //     <Spinner size='sm' animation='border' />
                //     Assigning...
                //   </React.Fragment> :
                  (lineItem.seller !== undefined && lineItem.seller !== null ?
                    "RE-Assign" :
                    "Manually Assign"
                  )
                }
              </Button>

              <OrderLineItemSellerChangeModal
                marketplaceId={props.marketplaceId}
                apiKey={props.apiKey}
                addAlert={props.addAlert}
                lineItem={lineItem}
                assignSellerVariantToLineItem={assignSellerVariantToLineItem}
                saveOrderLineItem={saveOrderLineItem}
                show={sellerAssignModalShow}
                onHide={() => setSellerAssignModalShow(false)}
              />
            </React.Fragment>
          </Col>
          <Col className="align-self-center text-right" xs='auto'>
            <React.Fragment>
              <Row>
                <Col className='align-self-center align-middle' style={{padding: 0}}>
                  <span className='font-weight-bold'>Status:</span>
                </Col>
                <LineItemStatusDropdown   
                  lineItem={lineItem}
                  isProcessing={isProcessing}
                  changeLineItemStatus={changeLineItemStatus}
                />
              </Row>

              <OrderLineItemCancelModal
                lineItem={lineItem}
                cancelLineItem={changeLineItemStatus}
                show={lineItemCancelModalShow}
                onHide={() => setLineItemCancelModalShow(false)}
              />
            </React.Fragment>
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item>
        <Row className='mt-2'>
          <Col xs='auto'>
            <span className='font-weight-bold'>Vendor:</span>{' '}
            {lineItem.vendor}
          </Col>
          <Col>
            <span className='font-weight-bold'>Product:</span>{' '}
            {lineItem.marketplaceProductId !== null || lineItem.sellerVariantId !== null ?
              <LinkContainer to={lineItem.marketplaceProductId !== null ? `/marketplace/${order.marketplaceId}/products/${lineItem.marketplaceProductId}`
              : `/marketplace/${order.marketplaceId}/sellers/${lineItem.sellerId}/variants/${lineItem.sellerVariantId}`}>
                <span className="clickable-text">
                  {lineItem.productTitle}
                  {lineItem.variantTitle !== undefined &&
                  lineItem.variantTitle !== ''
                    ? ' - '
                    : ''}
                  {lineItem.variantTitle}
                </span>
              </LinkContainer>
              :
              <span>
                {lineItem.productTitle}
                {lineItem.variantTitle !== undefined &&
                lineItem.variantTitle !== ''
                  ? ' - '
                  : ''}
                {lineItem.variantTitle}
              </span>
            }
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item>
        <Row className='mt-2'>
          <Col xs='auto'>
            <span className='font-weight-bold'>SKU:</span>{' '}
            {lineItem.sku}
          </Col>
          <Col xs='auto'>
            <span className='font-weight-bold'>MPN:</span>{' '}
            {lineItem.mpn}
          </Col>
          <Col>
            <span className='font-weight-bold'>Barcode:</span>{' '}
            {lineItem.barcode}
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item>
        <Row className='mt-2'>
          <Col xs='auto'>
            <span className='font-weight-bold'>
              Tracking Company:
            </span>{' '}
            {lineItem.trackingCompany}
          </Col>
          <Col>
            <span className='font-weight-bold'>
              Tracking Number:
            </span>{' '}
            {lineItem.trackingNumber}
          </Col>
        </Row>
      </ListGroup.Item>
    </ListGroup>
    </Card>
  )
}

const OrderLineItemSellerChangeModal = (props) => {
  const [sellerVariantId, setSellerVariantId] = useState("");
  const [searchFilter, setSearchFilter] = useState("SKU");
  const [searchKey, setSearchKey] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [sellerVariants, setSellerVariants] = useState(new Array<SellerVariant>());
  const [hasLoaded, setHasLoaded] = useState(0);

  const getSellerVariants = async (fromBegin: boolean = true) => {
    setIsProcessing(true);
    if (fromBegin) {     
      setHasLoaded(0);
      setSellerVariants(new Array<SellerVariant>()); 
    }
    try {
      let results: SellerVariant[] = await rest.get(
        `${
          Settings.eformedApiBaseUri
        }marketplace/${props.marketplaceId}/orders/${props.lineItem.marketplaceOrderId}/lineitems/sellervariants?key=${props.apiKey}&skip=${hasLoaded}&searchkey=${encodeURIComponent(searchKey)}&searchfilter=${encodeURIComponent(searchFilter)}`
      );
      if (results.length > 0) {
        let currentLoaded = hasLoaded + results.length;
        setHasLoaded(currentLoaded);
        let sellervariantsTemp = sellerVariants;
        sellervariantsTemp.push(...results);
        setSellerVariants(sellervariantsTemp);
      }
    } catch (error) {
      props.onHide();
      errorHandler(
        error,
        'There was an error while getting seller variants. Please try again, if this is not the first time you have seen this error, please report the issue.',
        props.addAlert,
      );
    }
    setIsProcessing(false);
  }
  
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md="8">
            <TextField
              label='Assign Seller Variant'
              placeholder="Seller Variant ID"
              value={sellerVariantId}
              onChange={newValue => {
                setSellerVariantId(newValue);
                props.assignSellerVariantToLineItem(props.lineItem, newValue);
              }}
            />
          </Col>
          <Col md="4">
            <Button
              style={{marginTop: "28px", height: "45px"}}
              className='btn-block'
              size='sm'
              variant='outline-info'
              onClick={() => {
                setSellerVariantId("");
                props.saveOrderLineItem(props.lineItem);
                props.onHide();
              }}
            >
              Assign
            </Button>
          </Col>
        </Row>
        <hr/>
        <Row>
          <Col md="5">
            <TextField
              label='Find Seller Variant'
              placeholder={searchFilter}
              value={searchKey}
              onChange={newValue => setSearchKey(newValue)}
            />
          </Col>
          <Col md="3">
            <Select
              label='Filter'
              options={[
                { label: 'SKU', value: 'SKU' },
                { label: 'UPC', value: 'UPC' },
                { label: 'MPN', value: 'MPN' }
              ]}
              value={searchFilter}
              onChange={newValue => setSearchFilter(newValue)}
            />
          </Col>
          <Col md="4">
            <Button
              style={{marginTop: "28px", height: "45px"}}
              className='btn-block'
              size='sm'
              variant='success'
              onClick={() => {
                getSellerVariants();
              }}
            >
              {isProcessing ?
              <React.Fragment>
                <Spinner size='sm' animation='border' />
                Searching...
              </React.Fragment> :
              "Search"
              }
            </Button>
          </Col>
        </Row>
        {sellerVariants !== undefined && sellerVariants.length > 0 &&
        <Row className="mt-3">
          <Col style={{color: "black"}}>
            Search Results
          </Col>
        </Row>
        }
        {sellerVariants !== undefined && sellerVariants.length > 0 && 
        sellerVariants.map((sellerVariant, index) => (
          <Row key={index} className="mt-3">
            <Col md="7">
              <Row className="mt-0">
                <Col>{`${sellerVariant.vendor} ${sellerVariant.productTitle}`}</Col>
              </Row>
              <Row className="mt-0">
                <Col>{getSellerVariantOptionString(sellerVariant)}</Col>
              </Row>
              <Row className="mt-0">
                <Col>{`SKU: ${sellerVariant.sku !== null ? sellerVariant.sku : ""} / UPC: ${sellerVariant.barcode !== null ? sellerVariant.barcode : ""}`}</Col>
              </Row>
            </Col>
            <Col md="2">
              {sellerVariant.quantityAboveThreshold}
            </Col>
            <Col md="3">
              <Button
                className='btn-block'
                size='sm'
                variant='outline-success'
                onClick={() => {
                  props.assignSellerVariantToLineItem(props.lineItem, sellerVariant.id);
                  props.saveOrderLineItem(props.lineItem);
                  setSearchKey("");
                  setSellerVariants(new Array<SellerVariant>());
                  props.onHide();
                }}
              >
                Assign
              </Button>
            </Col>
          </Row>
        ))
        }
        {sellerVariants !== undefined && sellerVariants.length > 0 && hasLoaded > 4 &&
          <Row className="mt-3 justify-content-center">
            <Button
              variant='info'
              onClick={() => {
                getSellerVariants(false);
              }}
            >
              Load More
            </Button>
          </Row>
        }
      </Modal.Body>
    </Modal>
  );
}

const OrderLineItemCancelModal = (props) => {
  let cancellabel = props.lineItem.status !== "Cancelled" ? "Cancel": "Undo-Cancel";
  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>{`${cancellabel} Order Line Item`}</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onHide()}>
          Close
        </Button>
        <Button variant="danger" 
          onClick={() => {
            props.cancelLineItem(props.lineItem);
            props.onHide();
          }}
        >
          {`${cancellabel} Line Item`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default OrderLineItemCard;