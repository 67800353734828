import React from "react";
import { Row, Col, Collapse } from "react-bootstrap";

import RouteVariableProps from "../../../../interfaces/RouteVariableProps";
import setSellerFromId from "../../../../utils/SetSellerFromId";
import BulkInventoryEntry from "./BulkInventoryEntry";
import BulkInventoryResults from "./BulkInventoryResults";

interface MatchParams {
  sellerId: string;
}

export interface BulkInventoryProps
  extends RouteVariableProps<MatchParams>, BulkInventoryState {
    setResults: (any) => void;
}

export interface BulkInventoryState {
  results: any;
}

export default class BulkInventory extends React.Component<
  RouteVariableProps<MatchParams>
> {
  state = {
    results: undefined,
    hasResults: false
  };

  componentDidMount() {
    if (this.props.match.params.sellerId !== undefined) {
      this.setInitialSeller();
    }
  }

  setInitialSeller = async () => {
    let sellerId = this.props.match.params.sellerId;
    const { selectedSeller, setSeller, user } = this.props;

    if (selectedSeller === undefined) {
      await setSellerFromId(
        sellerId,
        setSeller,
        user,
        this.props.history
      );
    }
  };

  setResults = results => {
    this.setState({ results: results, hasResults: true });
  };

  render() {
    const { hasResults } = this.state;
    return (
      <React.Fragment>
        <Row className="mt-5">
          <Col>
            <h1>Bulk Inventory</h1>
          </Col>
        </Row>
        <Collapse in={hasResults}>
          <Row>
            <Col>
              <BulkInventoryResults
                {...this.props}
                {...this.state}
                setResults={this.setResults}
              />
            </Col>
          </Row>
        </Collapse>
        <BulkInventoryEntry
          {...this.props}
          {...this.state}
          setResults={this.setResults}
        />
      </React.Fragment>
    );
  }
}
