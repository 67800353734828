import React from 'react';
import { Row, Col, Card, Badge, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import dateFormat from '../../../../utils/DateFormat';
import syncStatusToVariant from '../../../../utils/SyncStatusToVariant';
import MarketplaceOrder from '../../../../classes/Marketplace/Order';

interface MarketplaceOrderCardProps {
  marketplaceId: string;
  order: MarketplaceOrder;
}

const MarketplaceOrderCard = (props: MarketplaceOrderCardProps) => {
  const { marketplaceId, order } = props;
  return (
    <Card className='mt-1'>
      <Card.Header>
        <Row>
          <Col className='align-self-center text-dark'>
            {order.externalId} - {order.externalOrderNumber}
          </Col>
          <Col className='align-self-center' xs='auto'>
            {order.risky === true && order.riskApproved !== true ? (
              <Badge pill variant='danger'>
                Risky
              </Badge>
            ) : order.risky === true && order.riskApproved === true ? (
              <Badge pill variant='success'>
                Risk-Approved
              </Badge>
            ) : null}
            {order.lineItems !== undefined &&
              order.lineItems.length > 0 &&
              order.lineItems.some(lineItem => lineItem.outOfStock) && (
                <Badge pill variant='danger'>
                  Out of Stock
                </Badge>
              )}
            <Badge pill variant='primary'>
              {order.orderStatus}
            </Badge>
            <Badge pill variant='info'>
              {order.paymentStatus}
            </Badge>
            <Badge pill variant={syncStatusToVariant(order.syncStatus)}>
              {order.syncStatus}
            </Badge>
          </Col>
          <Col lg='2'>
            <LinkContainer
              to={`/marketplace/${marketplaceId}/orders/${order.id}`}
            >
              <Button size='sm' variant='outline-info'>
                Details
              </Button>
            </LinkContainer>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row className='text-dark'>
          <Col>{dateFormat(order.orderedAt, true)}</Col>
          <Col>
            {order.customerFirstName} {order.customerLastName}
          </Col>
          <Col>{order.lineItems.length} Line Items</Col>
          <Col>${order.total.toFixed(2)}</Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default MarketplaceOrderCard;
