import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import RouteVariableProps from '../../interfaces/RouteVariableProps';

interface MatchParams {
  sellerId: string;
}

class SellerHelp extends React.Component<RouteVariableProps<MatchParams>> {
  componentDidMount() {
    if (this.props.match.params.sellerId !== undefined) {
      this.setInitialSeller();
    }
  }

  setInitialSeller = async () => {
    const { sellerId } = this.props.match.params;
    let seller = this.props.user.sellers.filter(
      seller => seller.id === sellerId,
    );
    if (seller !== undefined && seller.length > 0) {
      this.props.setSeller(seller[0]);
    }
  };

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <h1>Seller App Help</h1>
          </Col>
        </Row>
        <Row className='mt-5'>
          <Col>
            <Card bg='info' text='light'>
              <Card.Header as='h3'>Instructions</Card.Header>
              <Card.Body>
                <Card.Text>
                  Use the seller selector in the navbar to switch between
                  sellers.
                </Card.Text>
                <Card.Text>
                  Use the menus to switch between functionality.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default SellerHelp;
