import React, { useState } from 'react';
import { Row, Col, Card, Collapse } from 'react-bootstrap';

import setMarketplaceFromId from '../../utils/SetMarketplaceFromId';

import RouteVariableProps from '../../interfaces/RouteVariableProps';
import ProductGoTo from '../Shared/ProductGoTo';
import SellerVariantGoTo from '../Shared/SellerVariantGoTo';
import MarketplaceVariantGoTo from '../Shared/MarketplaceVariantGoTo';

interface MatchParams {
  marketplaceId: string;
}

class MarketplaceHelp extends React.Component<RouteVariableProps<MatchParams>> {  
  componentDidMount() {
    if (this.props.match.params.marketplaceId !== undefined) {
      this.setMarketplaceFromIdOnLoad();
    }
  }

  setMarketplaceFromIdOnLoad = async () => {
    const marketplaceId = this.props.match.params.marketplaceId;
    const { selectedMarketplace, setMarketplace, user } = this.props;
    if (selectedMarketplace === undefined) {
      await setMarketplaceFromId(
        marketplaceId,
        setMarketplace,
        user,
        this.props.history,
      );
    }
  };

  render() {
    const marketplaceId = this.props.match.params.marketplaceId !== undefined ?
      this.props.match.params.marketplaceId : this.props.user.marketplaces[0].id;

    return (
      <React.Fragment>
        <Row>
          <Col>
            <h1>Marketplace App Help</h1>
          </Col>
        </Row>
        <Row className='my-5'>
          <Col>
            <Card bg='info' text='light'>
              <Card.Header as='h3'>Instructions</Card.Header>
              <Card.Body>
                <Card.Text>
                  Use the marketplace selector in the navbar to switch between
                  marketplaces.
                </Card.Text>
                <Card.Text>
                  Use the menus to switch between functionality.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <ProductGoTo 
          marketplaceId={marketplaceId}
        />
        <SellerVariantGoTo 
          marketplaceId={marketplaceId}
          apiKey={this.props.apiKey}
        />
        <MarketplaceVariantGoTo 
          marketplaceId={marketplaceId}
          apiKey={this.props.apiKey}
        />
        <Row className='mt-5 mb-2'>
          <Col>
            <h3>FAQs</h3>
          </Col>
        </Row>
        {faqs.map((faq, index) => (
          <FAQCard key={index} faq={faq} />
        ))}
      </React.Fragment>
    );
  }
}

export default MarketplaceHelp;

const FAQCard = props => {
  const [isOpen, setIsOpen] = useState(false);
  const { faq } = props;
  return (
    <Row className='mt-2'>
      <Col>
        <Card>
          <Card.Header
            style={{ cursor: 'pointer' }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className='text-dark text-large'>{faq.question}</span>
          </Card.Header>
          <Collapse in={isOpen}>
            <Card.Body>
              {faq.steps.map((step, iindex) => (
                <FAQStep key={iindex} step={step} stepNumber={iindex + 1} />
              ))}
            </Card.Body>
          </Collapse>
        </Card>
      </Col>
    </Row>
  );
};

const FAQStep = props => {
  const { step, stepNumber } = props;
  return (
    <React.Fragment>
      {stepNumber > 1 && <hr className='mt-5 text-dark' />}
      <Row className='mt-3'>
        <Col className='text-dark'>
          <span className='h3'>{stepNumber}.</span> {step.text}
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col>
          <img src={step.image} className='w-100' alt={step.text} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

const faqs = [
  {
    question: 'How do I Re-Sync an Order?',
    steps: [
      {
        image: '/images/faq/orderReSync.jpg',
        text:
          'Click Orders in the nav bar, click the order in question, then find the line item, and click re-sync. Note that you should not do this unless you are very sure the order is not present, as it may cause duplicates.',
      },
    ],
  },
  {
    question:
      'What is the easiest way to create a Marketplace Product from a Seller Variant?',
    steps: [
      {
        image: '/images/faq/sellerVariantCopyId.jpg',
        text:
          'Click Sellers in the nav bar, then choose the Seller you wish to add a product from, and find the variant you wish to add, then click the "Copy ID" button.',
      },
      {
        image: '/images/faq/importToMarketplaceProduct.jpg',
        text:
          '(Likely in a new tab) Click Products in the nav bar, then the "Add New Product" button. Click the "Edit This Product" button. A special text field will appear that says "Seller Variant Id". Paste the ID into this box, and click the "Import" button.',
      },
    ],
  },
  {
    question:
      'What is the easiest way to add a new Marketplace Variant based on a Seller Variant?',
    steps: [
      {
        image: '/images/faq/sellerVariantCopyId.jpg',
        text:
          'Click Sellers in the nav bar, then choose the Seller you wish to add a product from, and find the variant you wish to add, then click the "Copy ID" button.',
      },
      {
        image: '/images/faq/importToMarketplaceVariant.jpg',
        text:
          '(Likely in a new tab) Click Products in the nav bar, then find the product you wish to add the variant to. Click the "Edit This Product" button. Click Variants, then the "Add New Variant" button. You will see a text field to enter in the Seller Variant Id, then click the "Import Data" button. If you want to associate to an existing variant without importing the data, click the "Add Association" button instead.',
      },
    ],
  },
];
