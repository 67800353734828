import React from 'react';
import { Row, Col, Badge, Button } from 'react-bootstrap';

interface EDIHeaderProps {
  getTransactions: (skip: number) => void;
  getOscUpdate: () => void;
}

const EDIHeader = (props: EDIHeaderProps) => {
  return (
    <React.Fragment>
      <Row className='mt-5'>
        <Col>
          <h1>EDI Transactions</h1>
        </Col>
        <Col className='text-right'>
          <Button
            variant='outline-success'
            onClick={() => props.getOscUpdate()}
          >
            Update From OSC
          </Button>
          <Button
            variant='outline-info'
            onClick={() => props.getTransactions(0)}
          >
            Reload
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs='2'>
          <Badge pill variant='info'>
            Created
          </Badge>
        </Col>
        <Col>This order has been downloaded, but never sent.</Col>
      </Row>
      <Row>
        <Col xs='2'>
          <Badge pill variant='warning'>
            Sent
          </Badge>
        </Col>
        <Col>
          This order has been sent, but no MDN (response) has been received.
        </Col>
      </Row>
      <Row>
        <Col xs='2'>
          <Badge pill variant='success'>
            MDN Received
          </Badge>
        </Col>
        <Col>This order has been sent, and an MDN has been received.</Col>
      </Row>
    </React.Fragment>
  );
};

export default EDIHeader;
