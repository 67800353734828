import MarketplaceChannel from "../../../../../classes/Marketplace/Channel";
import MarketplaceChannelMarketplaceVariant from "../../../../../classes/Marketplace/MarketplaceChannelMarketplaceVariant";
import Seller from "../../../../../classes/Seller/Seller";

// IMPORTANT //
// if any change to this calculations should reflect in VariantPriceHelper of EFEnterprise.Core project

const getVariantChannelFinalPrice = (
  marketplaceChannelMarketplaceVariant: MarketplaceChannelMarketplaceVariant, 
  channel: MarketplaceChannel, sellers: Seller[]
  ) => {

    if (marketplaceChannelMarketplaceVariant === undefined || marketplaceChannelMarketplaceVariant === null) return 0;

    let manualPrice = getManualPrice(marketplaceChannelMarketplaceVariant);
    if (checkValue(manualPrice)) {
      return (+manualPrice).toFixed(2);
    }

    let floorValue = getVariantFloorValue(marketplaceChannelMarketplaceVariant, channel, sellers);

    let finalPrice: number = 0;

    if (channel.isWholeChannel === true) {
      let markupPrice = getFormattedValue(marketplaceChannelMarketplaceVariant.cost) * channel.costMultiplier;
      finalPrice = Math.max(markupPrice, floorValue);
      
    } else {
      finalPrice = Math.max(
        getFormattedValue(marketplaceChannelMarketplaceVariant.map),
        getFormattedValue(marketplaceChannelMarketplaceVariant.price),
        floorValue
        );
    }

    // inline condition to stop sending NaN values
    return finalPrice > 0 ? +finalPrice.toFixed(2) : 0;
}

export const getVariantFloorValue = (
  marketplaceChannelMarketplaceVariant: MarketplaceChannelMarketplaceVariant,
  channel: MarketplaceChannel,
  sellers: Seller[]
  ): number => {

    let floorValue = floorValueCalc(marketplaceChannelMarketplaceVariant, channel, sellers);
    let basePrice = getBasePrice(marketplaceChannelMarketplaceVariant);

    if (basePrice > getFormattedValue(channel.freeShippingThreshold)) {
      return floorValue;
    }

    floorValue = floorValue - getFormattedValue(channel.avgShippingRevenue)
    return floorValue > 0 ? +floorValue.toFixed(2) : 0;
}

const floorValueCalc = (
  marketplaceChannelMarketplaceVariant: MarketplaceChannelMarketplaceVariant,
  channel: MarketplaceChannel,
  sellers: Seller[]
  ): number => {
    
    let floorValue = getFormattedValue(marketplaceChannelMarketplaceVariant.cost) +
      getVariantInValue(marketplaceChannelMarketplaceVariant, sellers) +
      getFormattedValue(marketplaceChannelMarketplaceVariant.outValue) +
      (getFormattedValue(marketplaceChannelMarketplaceVariant.cost) * getFormattedValue(channel.commission) / 100) +
      (getFormattedValue(marketplaceChannelMarketplaceVariant.cost) * getFormattedValue(channel.minimumMargin) / 100);

    return floorValue > 0 ? +floorValue.toFixed(2) : 0;
}

const getBasePrice = (marketplaceChannelMarketplaceVariant: MarketplaceChannelMarketplaceVariant): number => {
  let basePrice = Math.max(
      getFormattedValue(marketplaceChannelMarketplaceVariant.map),
      getFormattedValue(marketplaceChannelMarketplaceVariant.price)
  );

  return basePrice;
}

export const getVariantInValue = (
  marketplaceChannelMarketplaceVariant: MarketplaceChannelMarketplaceVariant,
  sellers: Seller[]
  ): number => {
    if (marketplaceChannelMarketplaceVariant === undefined || marketplaceChannelMarketplaceVariant === null) 
      return 0;

    if (checkValue(marketplaceChannelMarketplaceVariant.handlingCharge)) 
      return +getFormattedValue(marketplaceChannelMarketplaceVariant.handlingCharge).toFixed(2);

    let inboundShipping: number = sellers !== undefined && sellers !== null && sellers.length > 0 ? Math.max.apply(Math, sellers.map(s => s.inboundShipping)) : 5;
    let inValue: number = getFormattedValue(marketplaceChannelMarketplaceVariant.cost) * getFormattedValue(inboundShipping) / 100;

    return inValue > 0 ? +inValue.toFixed(2) : 0;
}

const getManualPrice = (marketplaceChannelMarketplaceVariant: MarketplaceChannelMarketplaceVariant) => {
  const startDate = new Date(marketplaceChannelMarketplaceVariant.discountStartDateTz);
  const endDate = new Date(marketplaceChannelMarketplaceVariant.discountEndDateTz);
  const now = new Date(marketplaceChannelMarketplaceVariant.currentDateTz);

  if (startDate < now && endDate > now) {
    return marketplaceChannelMarketplaceVariant.discountManualPrice;
  }

  return marketplaceChannelMarketplaceVariant.manualPrice;
}

const checkValue = (value: number | string) : boolean => {
  return value !== undefined && value !== null && value !== '' && value > 0;
}

export const getFormattedValue = (value: number | string) : number => {
  return value !== undefined && value !== null && value !== '' && value > 0 ? +value : 0;
}

export default getVariantChannelFinalPrice;