export default class Marketplace {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }

  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  email: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  site: string;
  phone: string;
}
