const syncStatusToVariant = (
  syncStatus:
    | 'Created'
    | 'Succeeded'
    | 'Failed'
    | 'Processed'
    | 'Partially Processed'
    | 'Cancelled',
) => {
  if (syncStatus === 'Created') {
    return 'secondary';
  } else if (syncStatus === 'Failed' || syncStatus === 'Cancelled') {
    return 'danger';
  } else if (syncStatus === 'Partially Processed') {
    return 'warning';
  } else if (syncStatus === 'Succeeded' || syncStatus === 'Processed') {
    return 'success';
  }
};

export default syncStatusToVariant;
