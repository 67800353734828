import React from 'react';
import { Row, Col } from 'react-bootstrap';

const SellerVariantHeader = props => {
  return (
    <React.Fragment>
      <Row>
        <Col>
          <h1>
            {props.sellerName !== undefined && props.sellerName} Seller Variants
          </h1>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SellerVariantHeader;
