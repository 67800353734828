import React from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import BaseProps from '../../interfaces/BaseProps';

export default class Home extends React.Component<BaseProps> {
  displayName = Home.name;

  render() {
    const {
      user,
      changeApp,
      setLogisticsCompany,
      setMarketplace,
      setSeller,
    } = this.props;
    return (
      <React.Fragment>
        <h1>Welcome!</h1>

        <Row className='mt-5'>
          <Col>
            <Row>
              <Col>
                <h4>Apps</h4>
              </Col>
            </Row>
            <Row>
              {(user.admin === true || user.sellerAdmin === true) && (
                <Col className='text-center'>
                  <LinkContainer
                    to={user.admin === true ? '/admin/users' : '/admin/sellers'}
                  >
                    <Button
                      variant='outline-info'
                      onClick={() => changeApp('Admin')}
                    >
                      Admin
                    </Button>
                  </LinkContainer>
                </Col>
              )}
              {user.babywise === true && (
                <Col className='text-center'>
                  <LinkContainer to='/familywise'>
                    <Button
                      variant='outline-info'
                      onClick={() => changeApp('Familywise')}
                    >
                      Familywise
                    </Button>
                  </LinkContainer>
                </Col>
              )}
              {user.logisticsCompanies !== undefined &&
                user.logisticsCompanies.length > 0 && (
                  <Col className='text-center'>
                    <LinkContainer to='/logistics/po'>
                      <Button
                        variant='outline-info'
                        onClick={() => {
                          changeApp('Logistics');
                          setLogisticsCompany(user.logisticsCompanies[0]);
                        }}
                      >
                        Logistics
                      </Button>
                    </LinkContainer>
                  </Col>
                )}
              {user.marketplaces !== undefined && user.marketplaces.length > 0 && (
                <Col className='text-center'>
                  <LinkContainer to='/marketplace'>
                    <Button
                      variant='outline-info'
                      onClick={() => {
                        changeApp('Marketplace');
                        setMarketplace(user.marketplaces[0]);
                      }}
                    >
                      Marketplace
                    </Button>
                  </LinkContainer>
                </Col>
              )}
              {user.sellers !== undefined && user.sellers.length > 0 && (
                <Col className='text-center'>
                  <LinkContainer to={`/seller`}>
                    <Button
                      variant='outline-info'
                      onClick={() => {
                        changeApp('Seller');
                        setSeller(user.sellers[0]);
                      }}
                    >
                      Seller
                    </Button>
                  </LinkContainer>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row className='mt-5'>
          <Col>
            <Card bg='info' text='light'>
              <Card.Header as='h4'>Navigation</Card.Header>
              <Card.Body>
                <Card.Text>
                  The upper right menu can be used to switch between various
                  apps you have access to.
                </Card.Text>
                <Card.Text>
                  The rest of the menu can be used to access various functions
                  within that app. This part of the menu will change from app to
                  app.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card bg='warning' text='light'>
              <Card.Header as='h4'>Issues</Card.Header>
              <Card.Body>
                <Card.Text>
                  Because this portal is designed to work even if you have
                  intermittent connectivity problems (like say, a mobile app),
                  you may run into an issue where you have an old version
                  cached.
                </Card.Text>
                <Card.Text className='font-weight-bold'>
                  If you see something odd, first press Shift-F5 (or hold shift
                  and click the reload button) to ensure you are running on the
                  newest version.
                </Card.Text>
                <Card.Text>
                  If the issues continue, please report this to an appropriate
                  person.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
