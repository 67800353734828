import React, { useState } from 'react';
import { Row, Col, Card, Collapse, Button } from 'react-bootstrap';

import Service from './Service';

const App = props => {
  const [isOpen, setIsOpen] = useState(false);
  const { app } = props;
  return (
    <Row className='mt-3'>
      <Col>
        <Card>
          <Card.Header onClick={() => setIsOpen(!isOpen)}>
            <h4>
              {app.name}{' '}
              {app.services !== undefined && (
                <React.Fragment>
                  ({app.services.length} Service
                  {app.services.length !== 1 && 's'})
                </React.Fragment>
              )}
            </h4>
          </Card.Header>
          <Collapse in={isOpen}>
            <Card.Body>
              <Row>
                <Col>
                  <p className='text-dark'>{app.description}</p>
                </Col>
                <Col lg='2' className='align-self-top text-right'>
                  {/* 
                  // @ts-ignore */}
                  <Button
                    variant='outline-info'
                    onClick={()=> window.open(app.link, "_blank")}
                  >
                    GitHub
                  </Button>
                </Col>
              </Row>
              <Row>
                {app.services !== undefined &&
                  app.services.map((service, index) => (
                    <Service key={index} service={service} />
                  ))}
              </Row>
            </Card.Body>
          </Collapse>
        </Card>
      </Col>
    </Row>
  );
};

export default App;
