import React from "react";
import { Row, Col } from "react-bootstrap";

import { UpdateProductsProps } from "./UpdateProducts";

const UpdateProductsResults = (props: UpdateProductsProps) => {
  const { results } = props;
  return (
    <React.Fragment>
        <Row className="mt-3">
        <Col>
          <h3>Results</h3>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <h5>Update Products Changes</h5>
        </Col>
      </Row>
      {results !== undefined &&
        <Row>
          <Col>Processed: {results.processed}</Col>
          <Col>Successes: {results.successes}</Col>
          <Col>Failures: {results.failures}</Col>
        </Row>
        }
      <Row className="mt-3">
        <Col>
          <h5>Messages</h5>
        </Col>
      </Row>
      {results !== undefined &&
        results.messages.map((message, index) => (
          <Row key={index}>
            <Col>{message}</Col>
          </Row>
        ))}
    </React.Fragment>
  );
};

export default UpdateProductsResults;