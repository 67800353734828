import React from 'react';
import { Row, Col, Card, Button, Spinner, Form } from 'react-bootstrap';

import MetafieldModel from '../../../../classes/Marketplace/Metafield';
import { TextField, Select } from '../../../Shared/EFBootstrap';

import { MetafieldsRouteProps } from './Metafields';

export interface ProductMetafieldProps extends MetafieldsRouteProps {
  metafield: MetafieldModel;
  index: number;
  saveMetafield: (metafield: MetafieldModel) => void;
  handleMetafieldChange: (metafield: MetafieldModel, fieldName: string, newValue: string | number | boolean) => void;
  deleteMetafieldModal: (metafield: MetafieldModel, show: boolean) => void;
}

export interface ProductMetafieldState {
  isOpen: boolean;
  isEditing: boolean;
  isSaving: boolean;
}


export default class Metafield extends React.Component<
  ProductMetafieldProps, ProductMetafieldState
> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isEditing: false, 
      isSaving: false
    };
  }

  saveMetafield = async () => {
    const { metafield, saveMetafield } = this.props;
    await this.setState({ isSaving: true });
    await saveMetafield(metafield);
    await this.setState({ isSaving: false, isEditing: false });
  }

  render() {
    const { isOpen, isEditing, isSaving } = this.state;
    const { metafield, handleMetafieldChange, deleteMetafieldModal } = this.props;

    return (
      <Card className='mt-3'>
        <Card.Header onClick={() => this.setState({ isOpen: !isOpen })}>
          <Row>
            <Col>
              {isEditing ?
              <input
                type="text"
                disabled={!isEditing}
                placeholder="Metafield Name"
                value={metafield.metafieldName}
                style={{ background: "white", border: "none", fontSize: "medium", paddingLeft: "10px" }}
                onChange={event => handleMetafieldChange(metafield, 'metafieldName', event.target.value)}
              /> :
              <h5>
                {metafield.metafieldName}
              </h5>
              }
            </Col>
            {(metafield.isNewField || !metafield.isNonEditable) &&
            <React.Fragment>
            <Col md={2}>
              <Button
                className="float-right"
                size="sm"
                variant={isEditing ? 'success' : 'warning'}
                onClick={() =>
                  isEditing ? this.saveMetafield() : this.setState({ isEditing: !isEditing })
                }
              >
                {isEditing ? (
                  isSaving ? (
                    <React.Fragment>
                      <Spinner size='sm' animation='border' />
                      Saving...
                    </React.Fragment>
                  ) : (
                    'Save'
                  )
                ) : (
                  'Edit'
                )}
              </Button>
            </Col>
            <Col md={1}>
              <Button
                className="float-right"
                size="sm"
                variant="danger"
                onClick={() => deleteMetafieldModal(metafield, true)}
              >
                Delete
              </Button>
            </Col>
            </React.Fragment>}
          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Select
                label='Scope'
                readOnly={!isEditing}
                options={[
                  { label: 'Product', value: 'product' },
                  { label: 'Variant', value: 'variant' }
                ]}
                value={metafield.ownerResource}
                onChange={newValue =>
                  handleMetafieldChange(metafield, 'ownerResource', newValue)
                }
              />
            </Col>
            <Col>
              <TextField
                label='Namespace'
                readOnly={!isEditing}
                value={metafield.namespace}
                onChange={newValue =>
                  handleMetafieldChange(metafield, 'namespace', newValue)
                }
              />
            </Col>
            <Col>
              <TextField
                label='Key'
                readOnly={!isEditing}
                value={metafield.key}
                onChange={newValue =>
                  handleMetafieldChange(metafield, 'key', newValue)
                }
              />
            </Col>
            <Col>
              <Select
                label='Value Type'
                readOnly={!isEditing}
                options={[
                  { label: 'integer', value: 'integer' },
                  { label: 'string', value: 'string' }
                ]}
                value={metafield.valueType}
                onChange={newValue =>
                  handleMetafieldChange(metafield, 'valueType', newValue)
                }
              />
            </Col>
            <Col>
              <TextField
                label='Default Value'
                readOnly={!isEditing}
                type={metafield.valueType === 'integer' ? 'number' : 'text'}
                value={metafield.value}
                onChange={newValue =>
                  handleMetafieldChange(metafield, 'value', newValue)
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                label='Helper Text'
                readOnly={!isEditing}
                value={metafield.helperText}
                onChange={newValue =>
                  handleMetafieldChange(metafield, 'helperText', newValue)
                }
              />
            </Col>
          </Row>
          { metafield.isNewField &&
          <Row>
            <Col>
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Set as non editable metafield"
                  disabled={!isEditing}
                  value={metafield.isNonEditable === true ? 1 : 0}
                  onChange={() => handleMetafieldChange(metafield, 'isNonEditable', metafield.isNonEditable === true ? false : true)}
                />
              </Form.Group>
            </Col>
          </Row>
          }
        </Card.Body>
      </Card>
    );
  }
}
