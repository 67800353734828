import React from 'react';
import { MdImage } from 'react-icons/md';

import * as Settings from '../../../../Settings';
import { StringIsNullOrUndefined, ListIsNullOrEmpty } from '../../../../utils/ParameterChecker';

import Product from '../../../../classes/Marketplace/Product';
import Variant from '../../../../classes/Marketplace/Variant';
import MarketplaceProductImage from '../../../../classes/Marketplace/Image';

interface VariantImageThumbnailProps {
  marketplaceId: string;
  variant: Variant;
  product: Product;
  productId: string;
}

const VariantImageThumbnail = (props: VariantImageThumbnailProps) => {
  const { marketplaceId, variant, product, productId } = props;

  const image = GetVariantImage(product, variant);

  return (
    <div className="variant-header-image">
      {productId !== "new" && image !== undefined ? (
        <img
          className={!StringIsNullOrUndefined(variant.marketplaceProductImageId) ? "" : "default-image-border"}
          src={`${Settings.eformedApiBaseUri}marketplace/${marketplaceId}/products/${product.id}/images/${image.id}/image`}
          width='50px'
          height='50px'
          alt={product.title + ' - ' + variant.title}
        />
      ) : (
        <span style={{ padding: '0px 13px' }}>
          <MdImage size={28} />
        </span>
      )}
    </div>
  );
}

export const GetVariantImage = (product: Product, variant: Variant) : MarketplaceProductImage => {
  let image: MarketplaceProductImage = undefined;
  if (ListIsNullOrEmpty(product.images)) {
    return image;
  }
  
  if (!StringIsNullOrUndefined(variant.marketplaceProductImageId)) {
    image = product.images.filter(
      image => image.id === variant.marketplaceProductImageId,
    )[0];
  } else {
    image = product.images.filter(
      image => image.position === 1,
    )[0];
  }

  return image;
}

export default VariantImageThumbnail;
