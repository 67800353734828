import React from 'react';
import { Button } from 'react-bootstrap';

import MarketplaceVariant from '../../../../../classes/Marketplace/Variant';
import MarketplaceChannel from '../../../../../classes/Marketplace/Channel';
import { CHANNEL_TYPE } from '../../../../../utils/Constants';

import { WalmartChannelAreaProps } from './WalmartChannelArea';
import { changePublishableStateOnVariantConnection } from './ChannelVariantArea';
import productFunctions from '../Functions';

const WalmartPublishButton = (props: WalmartChannelAreaProps) => {
  const { isEditing, product, channel, addAlert, handleChannelChange, handleVariantChange } = props;
  const connections = product.marketplaceChannelMarketplaceProducts.filter(
    connection => connection.marketplaceChannelId === channel.id,
  );
  const published =
    connections !== undefined &&
    connections !== null &&
    connections.length === 1 &&
    connections[0].active === true;

  /**const validateProductOptions = (): boolean => {
    let isFieldsValid = false;
    const optionValues = ["color", "finish", "babyothersize", "shoesize", "size", "pattern", "countperpack", "count", "scent", "flavor", "sportsteam", "diapersize", "character"];
    for(let x of ["1", "2", "3"]){
      if (
        product[`option${x}`] !== undefined &&
        product[`option${x}`] !== null &&
        product[`option${x}`] !== ""
      ) {
        let optionVal: string = product[`option${x}`];
        isFieldsValid = optionValues.includes(optionVal.toLowerCase());
        if (isFieldsValid) {
          break;
        }
      }
    }

    if (!isFieldsValid) {
      addAlert(
        "info",
        `To publish product to Walmart Channel, product options should include either one of these values. (color, finish, babyOtherSize, shoeSize, size, pattern, countPerPack, count, scent, flavor, sportsTeam, diaperSize, character)`
      );
    }

    return isFieldsValid;
  }*/

  const validateWalmartCategoryFields = (): boolean => {
    let isFieldsValid = true;

    if (connections.length > 0) {
      let connection = connections[0];
      isFieldsValid = connection.categoryId !== undefined && connection.categoryId !== null && !isNaN(connection.categoryId);
      isFieldsValid = connection.parentCategoryId !== undefined && connection.parentCategoryId !== null && !isNaN(connection.parentCategoryId) && isFieldsValid;
    } else {
      isFieldsValid = false;
    }

    if (!isFieldsValid) {
      addAlert(
        "info",
        `To publish product to Walmart Channel, please choose values for Parent Category and Sub Category`
      );
    }

    return isFieldsValid;
  }

  const validateProductVariants = () => {
    product.variants !== undefined && product.variants !== null && product.variants.length > 0 &&
    product.variants.forEach((variant, index) => {
      if (validateVariantFieldsForWalmartPublish(variant, channel, addAlert, false)) {

        // toggle isPublishable to on
        variant.marketplaceChannelMarketplaceVariants.forEach(mcmv => {
          if (channel.id === mcmv.marketplaceChannelId) {
            changePublishableStateOnVariantConnection(variant, mcmv, true, index, handleVariantChange);
          }
        });
      }
    });
  }

  const validateProductFieldsAndPublish = () => {
    let isFieldsValid = true;
    if (published === false) {
      isFieldsValid = validateWalmartCategoryFields();

      if (isFieldsValid) {
        validateProductVariants();
      }

      if (isFieldsValid) {
        handleChannelChange(channel, !published);
      }
    } else {
      handleChannelChange(channel, !published);
    }
  }

  return (
    <Button
      disabled={!isEditing}
      variant={published === false ? 'outline-success' : 'outline-danger'}
      onClick={() => validateProductFieldsAndPublish()}
    >
      {published === true ? 'Unpublish' : 'Publish'}
    </Button>
  );
};

export const validateVariantFieldsForWalmartPublish = (variant: MarketplaceVariant, channel: MarketplaceChannel, addAlert: any, showAlarts: boolean = true) : boolean => {
  let isFieldsValid = true;
  isFieldsValid = validateValue(variant.title, variant.sku, 'SKU', addAlert, showAlarts) && isFieldsValid;
  isFieldsValid = validateValue(variant.title, variant.barcode, 'Barcode', addAlert, showAlarts) && isFieldsValid;
  if (channel.channelType !== CHANNEL_TYPE.WALMART_MKP) {
    isFieldsValid = validateValue(variant.title, +variant.dimH, 'Height', addAlert, showAlarts) && isFieldsValid;
    isFieldsValid = validateValue(variant.title, +variant.dimL, 'Length', addAlert, showAlarts) && isFieldsValid;
    isFieldsValid = validateValue(variant.title, +variant.dimW, 'Width', addAlert, showAlarts) && isFieldsValid;
  }
  isFieldsValid = validateValue(variant.title, +variant.weight, 'Weight', addAlert, showAlarts) && isFieldsValid;
  isFieldsValid = validateValue(variant.title, variant.weightUnit, 'Weight Unit', addAlert, showAlarts) && isFieldsValid;

  let isManufacturerColorAvail = variant.manufacturerColor !== undefined && variant.manufacturerColor !== null && variant.manufacturerColor !== "";
  if (!isManufacturerColorAvail && showAlarts) {
    addAlert(
      "info",
      `To publish the product, first select Color Category for the variant, ${variant.title}.`
    );

    isFieldsValid = false;
  }

  let marketplaceChannelMarketplaceVariants = variant.marketplaceChannelMarketplaceVariants
    .filter(v => v.marketplaceChannelId === channel.id); 
  
  marketplaceChannelMarketplaceVariants !== undefined &&
    marketplaceChannelMarketplaceVariants !== null &&
    marketplaceChannelMarketplaceVariants.length > 0 &&
    marketplaceChannelMarketplaceVariants.forEach(mcmv => {
      isFieldsValid = validateValue(variant.title, +mcmv.cost, 'Cost', addAlert, showAlarts, channel.name) && isFieldsValid;
      if (channel.isWholeChannel !== true) {
        isFieldsValid = validateValue(variant.title, +mcmv.price, 'Price', addAlert, showAlarts, channel.name) && isFieldsValid;
      }
    });

  isFieldsValid = validateVariantArchive(variant, addAlert, showAlarts) && isFieldsValid;
  isFieldsValid = validateVariantAssociation(variant, addAlert, showAlarts) && isFieldsValid;
  isFieldsValid = validateVariantPricing(variant, channel, addAlert, showAlarts) && isFieldsValid;

  return isFieldsValid;
}

export const validateVariantArchive = (variant: MarketplaceVariant, addAlert: any, showAlarts: boolean = true) : boolean => {
  if (variant.archived === true) {
    showAlarts && addAlert(
      "info",
      `Un-archive the variant before publishing.`
    );

    return false
  }

  return true;
}

export const validateVariantAssociation = (variant: MarketplaceVariant, addAlert: any, showAlarts: boolean = true) : boolean => {
  if (variant.sellerVariants === undefined || variant.sellerVariants === null || variant.sellerVariants.length === 0) {
    showAlarts && addAlert(
      "info",
      `To publish the product, first associate a seller variant for the variant, ${variant.title}.`
    );

    return false
  }

  return true;
}

export const validateVariantPricing = (variant: MarketplaceVariant, channel: MarketplaceChannel, addAlert: any, showAlarts: boolean = true) : boolean => {
  const marketplaceChannelMarketplaceVariant = variant.marketplaceChannelMarketplaceVariants.find(mcmv => mcmv.marketplaceChannelId === channel.id);
  let sellers = variant.sellerVariants.map(sv => sv.seller);

  let finalPrice = productFunctions.getVariantChannelFinalPrice(marketplaceChannelMarketplaceVariant, channel, sellers);

  if (!(marketplaceChannelMarketplaceVariant.manualPrice > 0) && finalPrice < marketplaceChannelMarketplaceVariant.cost) {
    showAlarts && addAlert(
      "info",
      (
        channel.isWholeChannel === true ? 
          `Final price is lower than the Cost X ${channel.costMultiplier} of the variant, ${variant.title}. Correct it for the Channel, ${channel.name}.` :
          `Final price is lower than the Cost of the variant, ${variant.title}. Correct it for the Channel, ${channel.name}.`
      )
    );

    return false;
  }

  return true;
}

export const validateValue = (variantTitle: string, value: string | number, fieldName: string, addAlert: any, showAlarts: boolean = true, channelName: string = "") : boolean => {
  let formattedChannelStr = channelName !== undefined && channelName !== null && channelName !== "" ? ` in ${channelName} channel` : "";
  if (typeof value === 'string') {
    if (value === undefined || value === null || value.trim() === "") {
      showAlarts && addAlert(
        "info",
        `To publish the product, first fill a proper value for ${fieldName} of the variant, ${variantTitle}${formattedChannelStr}.`
      );
      return false;
    }
  } else if (typeof value === 'number') {
    if (value === undefined || value === null || value <= 0) {
      showAlarts && addAlert(
        "info",
        `To publish the product, first fill a non zero value for ${fieldName} of the variant, ${variantTitle}${formattedChannelStr}.`
      );
      return false;
    }
  }
  
  return true;
}

export default WalmartPublishButton;
