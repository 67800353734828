import * as React from "react";
import { Row, Col, Spinner, Button } from "react-bootstrap";
import { Select } from "../../Shared/EFBootstrap";
import { RouteComponentProps } from "react-router";

import PurchaseOrderMenuItem from "./ViewPurchaseOrders/PurchaseOrderMenuItem";

import * as Settings from "../../../Settings";
import BaseProps from "../../../interfaces/BaseProps";
import LabelValuePair from "../../../classes/Core/LabelValuePair";
import PurchaseOrder from "../../../classes/Logistics/PurchaseOrder";
import PurchaseOrderItem from "../../../classes/Logistics/PurchaseOrderItem";
import Company from "../../../classes/Logistics/PurchaseOrderCompany";
import Vendor from "../../../classes/Logistics/PurchaseOrderVendor";

const eformedApiBaseUri = Settings.eformedApiBaseUri;

interface ViewPurchaseOrdersProps extends BaseProps, RouteComponentProps {}

interface ViewPurchaseOrdersState {
  isLoading: boolean;
  vendor: string;
  vendors: Array<LabelValuePair>;
  purchaseOrders: Array<PurchaseOrderGroup>;
  lastPageGotten: number;
}

interface PurchaseOrderGroup extends PurchaseOrder {
  company: Company;
  lineItems: Array<PurchaseOrderItem>;
  vendor: Vendor;
}

export default class ViewPurchaseOrders extends React.Component<
  ViewPurchaseOrdersProps,
  ViewPurchaseOrdersState
> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      vendor: "All",
      vendors: [],
      purchaseOrders: [],
      lastPageGotten: 1
    };
  }

  componentDidMount() {
    this.getVendors();
    this.getPurchaseOrders(this.state.vendor, 1);
  }

  getVendors = () => {
    fetch(
      eformedApiBaseUri +
        "po/vendors.json?key=" +
        this.props.apiKey +
        "&shop=babywise-life.myshopify.com"
    )
      .then(response => {
        return response.json();
      })
      .then(data => {
        var vendors = [{ label: "All", value: "All" }];
        data.vendors.forEach(vend => {
          var vendor = { label: vend.name, value: vend.name };
          if (!vendors.includes(vendor)) {
            vendors.push(vendor);
          }
        });
        this.setState({ vendors: vendors });
      });
  };

  getPurchaseOrders = (vendor, page) => {
    this.setState({ isLoading: true });
    var url =
      eformedApiBaseUri +
      "po/purchaseorders.json?key=" +
      this.props.apiKey +
      "&shop=babywise-life.myshopify.com";
    if (vendor !== "All") {
      url = url + "&vendor=" + vendor;
    }
    if (page !== undefined && page !== null) {
      url = url + "&page=" + page;
    }
    fetch(url)
      .then(response => {
        return response.json();
      })
      .then(data => {
        var tempPos = this.state.purchaseOrders;
        tempPos.push(...data.purchaseOrders);
        this.setState({
          purchaseOrders: tempPos,
          isLoading: false,
          lastPageGotten: page || 1
        });
      });
  };

  render() {
    const { purchaseOrders } = this.state;

    if (
      !this.state.isLoading &&
      (purchaseOrders === undefined ||
        purchaseOrders === null ||
        purchaseOrders.length === 0)
    ) {
      return (
        <React.Fragment>
          <h1>View Purchase Orders</h1>

          <Row>
            <Col>
              <Select
                label="Vendor"
                value={this.state.vendor}
                onChange={newValue => {
                  this.setState({ vendor: newValue, purchaseOrders: [] });
                  this.getPurchaseOrders(newValue, 1);
                }}
                options={this.state.vendors}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col>
              <p className="text-warning">No purchase orders found...</p>
            </Col>
          </Row>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h1>View Purchase Orders</h1>
          <Row>
            <Col>
              <Select
                label="Vendor"
                value={this.state.vendor}
                onChange={newValue => {
                  this.setState({ vendor: newValue, purchaseOrders: [] });
                  this.getPurchaseOrders(newValue, 1);
                }}
                options={this.state.vendors}
              />
            </Col>
          </Row>
          {purchaseOrders.map((purchaseOrder, index) => (
            <PurchaseOrderMenuItem
              key={index}
              apiKey={this.props.apiKey}
              history={this.props.history}
              addAlert={this.props.addAlert}
              getPurchaseOrders={this.getPurchaseOrders}
              id={purchaseOrder.id}
              company={purchaseOrder.company.name}
              vendor={purchaseOrder.vendor.name}
              closed={purchaseOrder.closed}
              lineItems={purchaseOrder.lineItems}
              createdAt={purchaseOrder.createdAt}
            />
          ))}
          <Row className="justify-content-center mt-3 mb-3">
            <Col xs="auto">
              <Button
                variant="outline-info"
                onClick={() =>
                  this.getPurchaseOrders(
                    this.state.vendor,
                    this.state.lastPageGotten + 1
                  )
                }
              >
                {this.state.isLoading ? (
                  <React.Fragment>
                    <Spinner
                      as="span"
                      size="sm"
                      animation="border"
                      role="status"
                    />
                    <span>Loading...</span>
                  </React.Fragment>
                ) : (
                  "More..."
                )}
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
}
