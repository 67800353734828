const frontEnd = {
  name: 'Front End - Shopify',
  variant: 'success',
  text: 'light',
  description:
    "This stack contains apps provide either direct or indirect support to the external customer's experience.",
  apps: [
    {
      name: 'Advanced Collection Sorting',
      link: 'https://github.com/eformedpartners/advancedcollectionsorting',
      description:
        'This collects information about products and collections, then sorts those collections in advanced and custom ways. This is a newer app, and has been frequently worked on and added to.',
      services: [
        {
          name: 'AdvancedCollectionSorting.Storage',
          type: 'Storage',
          variant: 'info',
          description:
            'This holds all of the rules, pulled down Shopify data, and pulled other data, as well as the traffic data, for all of the Shopify shops for use by the app.',
          location: 'Azure SQL - AdvancedCollectionSorting',
          language: 'SQL',
          framework: 'Azure SQL',
        },
        {
          name: 'AdvancedCollectionSorting.API',
          type: 'Front-end',
          variant: 'success',
          description:
            'This provides both the Admin UI and the API for the Shopify app, and allows changing of settings from within Shopify.',
          location: 'Azure Serverless - advancedcollectionsorting',
          language: 'C# & React',
          framework: 'Azure Functions',
        },
        {
          name: 'AdvancedCollectionSorting.Core',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This provides shared functionality amongst the other services.',
          location: 'Shared',
          language: 'C#',
          framework: 'Class Libraries',
        },
        {
          name: 'AdvancedCollectionSorting.DataCollection',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This collects and processes all of the data in preparation for use.',
          location:
            'Azure Serverless - advancedcollectionsortingdatacollection',
          language: 'C#',
          framework: 'Azure Functions',
        },
        {
          name: 'AdvancedCollectionSorting.Shopify',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This is the service that actually sorts the collections and puts the changes live to Shopify. As a note, this can be very CPU intensive on large collections.',
          location: 'Azure Web App for Containers - acsshopifycontainer',
          language: 'C#',
          framework: 'Azure Functions',
        },
      ],
    },
    {
      name: 'Advanced Search',
      link: 'https://github.com/eformedpartners/advancedsearch',
      description:
        'This app is all about providing a search engine inside of Shopify Stores that provides more advanced capabilities than the out of the box search. This is a newer app.',
      services: [
        {
          name: 'AdvancedSearch.Storage',
          type: 'Storage',
          variant: 'info',
          description:
            'This holds the data in a staging area, ready for indexing into the search engine, and allows for rapid writing.',
          location: 'Azure Storage Blobs - advancedsearchpersistent',
          language: 'NoSQL',
          framework: 'Azure Storage',
        },
        {
          name: 'AdvancedSearch.Search',
          type: 'Storage',
          variant: 'info',
          description:
            'This is the actual search engine, that indexes from .Storage once per hour.',
          location: 'Azure Search - efadvancedsearch',
          language: 'Azure Search',
          framework: 'Azure Search',
        },
        {
          name: 'AdvancedSearch.API',
          type: 'Front-end',
          variant: 'success',
          description:
            'This provides both the Admin UI and the API for the Shopify app, and allows changing of settings from within Shopify.',
          location: 'Azure Web App for Containers - efadvancedsearch',
          language: 'C# & React',
          framework: 'ASP.NET Core',
        },
        {
          name: 'AdvancedSearch.AppProxy',
          type: 'Front-end',
          variant: 'success',
          description:
            'This is the actual front end the customer uses when searching on the sites. It is written in React and served through ASP.NET Core and some Razor Pages (needed for Shopify App Proxy requirements).',
          location: 'Azure Web App for Containers - efadvancedsearchproxy',
          language: 'C# & React',
          framework: 'ASP.NET Core',
        },
        {
          name: 'AdvancedSearch.Core',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This provides shared functionality amongst the other services.',
          location: 'Shared',
          language: 'C#',
          framework: 'Class Libraries',
        },
        {
          name: 'AdvancedSearch.Indexing',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This is a queue-driven service that rapidly fires new information into .Storage',
          location: 'Azure Serverless - advancedsearchindexing',
          language: 'C#',
          framework: 'Azure Functions',
        },
        {
          name: 'AdvancedSearch.Shopify',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This is the service that gathers changes from shopify and queues things for .Indexing',
          location: 'Azure Serverless - advancedsearchshopify',
          language: 'C#',
          framework: 'Azure Functions',
        },
      ],
    },
    {
      name: 'Affiliate Integration',
      link: 'https://github.com/eformedpartners/shopifyaffiliateintegration',
      description:
        'This app integrates with Affiliate partners and creates products that redirect to the external partners. This app is of medium age and has been infrequently updated.',
      services: [
        {
          name: 'ShopifyAffiliateIntegration.Storage',
          type: 'Storage',
          variant: 'info',
          description:
            'This holds all of the settings to integrate with affiliates, as well as all of the product data.',
          location: 'Azure SQL - AffiliateIntegration',
          language: 'SQL',
          framework: 'Azure SQL',
        },
        {
          name: 'ShopifyAffiliateIntegration.API',
          type: 'Front-end',
          variant: 'success',
          description:
            'This provides both the Admin UI and the API for the Shopify app, and allows changing of settings from within Shopify.',
          location: 'Azure Serverless - shopifyaffiliateintegration',
          language: 'C# & React',
          framework: 'Azure Functions',
        },
        {
          name: 'ShopifyAffiliateIntegration.Common',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This provides shared functionality amongst the other services.',
          location: 'Shared',
          language: 'C#',
          framework: 'Class Libraries',
        },
        {
          name: 'ShopifyAffiliateIntegration.RakutenIntegration',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This is the service that connects with the Rakuten FTP and updates the Storage',
          location: 'Azure Serverless - affiliateintegrationrakuten',
          language: 'C#',
          framework: 'Azure Functions',
        },
        {
          name: 'ShopifyAffiliateIntegration.Shopify',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This is the service that actually takes the data and puts the changes live to Shopify.',
          location: 'Azure Serverless - affiliateintegrationshopify',
          language: 'C#',
          framework: 'Azure Functions',
        },
      ],
    },
    {
      name: 'Automatic Collections',
      link: 'https://github.com/eformedpartners/automaticcollections',
      description:
        'This app automatically creates collections for every vendor present in a store. This app is older, but has had some updates.',
      services: [
        {
          name: 'AutomaticCollections.Storage',
          type: 'Storage',
          variant: 'info',
          description: 'This holds the settings for the app.',
          location: 'Azure Storage Tables - automaticcollpersistent',
          language: 'NoSQL',
          framework: 'Azure Storage',
        },
        {
          name: 'AutomaticCollections.API',
          type: 'Front-end',
          variant: 'success',
          description:
            'This provides both the Admin UI and the API for the Shopify app, and allows changing of settings from within Shopify.',
          location: 'Azure Serverless - automaticcollections',
          language: 'C# & React',
          framework: 'Azure Functions',
        },
        {
          name: 'AutomaticCollections.Core',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This provides shared functionality amongst the other services.',
          location: 'Shared',
          language: 'C#',
          framework: 'Class Libraries',
        },
        {
          name: 'AutomaticCollections.Shopify',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This is the service that actually puts the collections live to Shopify.',
          location:
            'Azure Web App for Containers - automaticcollectionsshopify',
          language: 'C#',
          framework: 'Azure Functions',
        },
      ],
    },
    {
      name: 'AutoTagger',
      link: 'https://github.com/eformedpartners/autotagger',
      description:
        'This app automatically automatically tags products based on a series of rules. This app is older, but has had some updates.',
      services: [
        {
          name: 'AutoTagger.Storage',
          type: 'Storage',
          variant: 'info',
          description:
            'This holds the settings for the app and all of the rules.',
          location: 'Azure SQL - AutoTagger',
          language: 'SQL',
          framework: 'Azure SQL',
        },
        {
          name: 'AutoTagger.API',
          type: 'Front-end',
          variant: 'success',
          description:
            'This provides both the Admin UI and the API for the Shopify app, and allows changing of settings from within Shopify.',
          location: 'Azure Serverless - autotaggerapp',
          language: 'C# & React',
          framework: 'Azure Functions',
        },
        {
          name: 'AutoTagger.Common',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This provides shared functionality amongst the other services.',
          location: 'Shared',
          language: 'C#',
          framework: 'Class Libraries',
        },
        {
          name: 'AutoTagger.Shopify',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This is the service that actually tags products on Shopify.',
          location: 'Azure Serverless - autotaggershopify',
          language: 'C#',
          framework: 'Azure Functions',
        },
      ],
    },
    {
      name: 'Bulk Metafields',
      link: 'https://github.com/eformedpartners/bulkmetafields',
      description:
        'This app allows users to create metafields in bulk inside Shopify. This app is older, tiny, and never updated.',
      services: [
        {
          name: 'BulkMetafields.Storage',
          type: 'Storage',
          variant: 'info',
          description: 'This holds the settings for the app.',
          location: 'Azure Storage Tables - bulkmetafieldspersistent',
          language: 'NoSQL',
          framework: 'Azure Storage',
        },
        {
          name: 'BulkMetafields.API',
          type: 'Front-end',
          variant: 'success',
          description:
            'This provides both the Admin UI and the API for the Shopify app, and allows changing of settings from within Shopify.',
          location: 'Azure Serverless - bulkmetafields',
          language: 'C# & React',
          framework: 'Azure Functions',
        },
      ],
    },
    {
      name: 'Collection Data Prefetcher',
      link: 'https://github.com/eformedpartners/CollectionDataPreFetcher',
      description:
        'This app retrieves collection information and places it into a metafield. This app is newer, tiny, and never updated.',
      services: [
        {
          name: 'CollectionDataPrefetcher.Storage',
          type: 'Storage',
          variant: 'info',
          description: 'This holds the settings for the app.',
          location: 'Azure Storage Tables - collectiondatapersistent',
          language: 'NoSQL',
          framework: 'Azure Storage',
        },
        {
          name: 'CollectionDataPrefetcher.Common',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This provides shared functionality amongst the other services.',
          location: 'Shared',
          language: 'C#',
          framework: 'Class Libraries',
        },
        {
          name: 'CollectionDataPrefetcher.Shopify',
          type: 'Back-end',
          variant: 'warning',
          description: 'This does the actual fetching and metafield updating.',
          location: 'Azure Serverless - collectiondataprefetcher',
          language: 'C#',
          framework: 'Azure Functions',
        },
      ],
    },
    {
      name: 'Collection Hierarchy Enforcer',
      link: 'https://github.com/eformedpartners/collectionhierarchyenforcer',
      description:
        'Shopify App that enforces a navigational hierarchy and alerts when anomalies occur. This app is very old and never updated.',
      services: [
        {
          name: 'CollectionHierarchyEnforcer.Storage',
          type: 'Storage',
          variant: 'info',
          description: 'This holds the settings for the app.',
          location: 'Azure SQL - CollectionHierarchyEnforcer',
          language: 'SQL',
          framework: 'Azure Storage',
        },
        {
          name: 'CollectionHierarchyEnforcer.Shopify',
          type: 'Back-end',
          variant: 'warning',
          description: 'This does the actual fetching and alerting.',
          location: 'Azure Serverless - collectionhierarchyenforcer',
          language: 'C#',
          framework: 'Azure Functions',
        },
      ],
    },
    {
      name: 'Out of Stock',
      link: 'https://github.com/eformedpartners/outofstockshopifyapp',
      description:
        'Shopify App that handles the tagging of products as in or out of stock and allows users to sign up for back in stock notifications. This app is older and rarely updated.',
      services: [
        {
          name: 'OutOfStock.Storage',
          type: 'Storage',
          variant: 'info',
          description: 'This holds the settings for the app.',
          location: 'Azure Storage Tables - outofstockpersistent',
          language: 'NoSQL',
          framework: 'Azure Storage',
        },
        {
          name: 'OutOfStock.API',
          type: 'Front-end',
          variant: 'success',
          description:
            'This provides both the Admin UI and the API for the Shopify app, and allows changing of settings from within Shopify.',
          location: 'Azure Serverless - outofstockshopifyapp',
          language: 'C# & React',
          framework: 'Azure Functions',
        },
        {
          name: 'OutOfStock.Core',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This provides shared functionality amongst the other services.',
          location: 'Shared',
          language: 'C#',
          framework: 'Class Libraries',
        },
        {
          name: 'OutOfStock.Shopify',
          type: 'Back-end',
          variant: 'warning',
          description: 'This does the actual fetching, tagging, and alerting.',
          location: 'Azure Serverless - outofstockshopify',
          language: 'C#',
          framework: 'Azure Functions',
        },
      ],
    },
    {
      name: 'Personalization',
      link: 'https://github.com/eformedpartners/personalization',
      description:
        'The intent of this app is to provide personalized recommendations on the front end. This app was once live, but has been taken down due to other priorities, and now just collects data for the time being. This app is older and rarely updated.',
      services: [
        {
          name: 'Personalization.Storage',
          type: 'Storage',
          variant: 'info',
          description: 'This holds the settings for the app.',
          location: 'Azure Storage Tables - personalizepersistent',
          language: 'NoSQL',
          framework: 'Azure Storage',
        },
        {
          name: 'Personalization.API',
          type: 'Back-end',
          variant: 'warning',
          description: 'This collects the data and places into Storage.',
          location: 'Azure Serverless - efpersonalization',
          language: 'C#',
          framework: 'Azure Functions',
        },
      ],
    },
    {
      name: 'Product Reviews Adapter',
      link: 'https://github.com/eformedpartners/productreviewsadapter',
      description:
        'Shopify App that allows for significant reviews customizability, like sending email triggers or couponing. This app was once live, but has been taken down due to the company using it being purchased, and is deprecated for the moment. This app is older and rarely updated.',
      services: [
        {
          name: 'ProductReviewsAdapter.API',
          type: 'Back-end',
          variant: 'warning',
          description: 'This collects the data and acts.',
          location: 'Azure Serverless - productreviewsadapter',
          language: 'C#',
          framework: 'Azure Functions',
        },
      ],
    },
    {
      name: 'Quality Control',
      link: 'https://github.com/eformedpartners/qualitycontrolshopify',
      description:
        'Shopify App that continually monitors product data and auto-corrects and alerts anomalies. This app is older and rarely updated.',
      services: [
        {
          name: 'QualityControl.Storage',
          type: 'Storage',
          variant: 'info',
          description: 'This holds the settings for the app.',
          location: 'Azure Storage Tables - qualitycontrolpersistent',
          language: 'NoSQL',
          framework: 'Azure Storage',
        },
        {
          name: 'QualityControl.API',
          type: 'Front-end',
          variant: 'success',
          description:
            'This provides both the Admin UI and the API for the Shopify app, and allows changing of settings from within Shopify.',
          location: 'Azure Serverless - qualitycontrolapp',
          language: 'C# & React',
          framework: 'Azure Functions',
        },
        {
          name: 'QualityControl.Core',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This provides shared functionality amongst the other services.',
          location: 'Shared',
          language: 'C#',
          framework: 'Class Libraries',
        },
        {
          name: 'QualityControl.Shopify',
          type: 'Back-end',
          variant: 'warning',
          description: 'This does the actual fetching, tagging, and alerting.',
          location: 'Azure Serverless - qualitycontrolshopify',
          language: 'C#',
          framework: 'Azure Functions',
        },
      ],
    },
    {
      name: 'Shopify Customer Adapter',
      link: 'https://github.com/eformedpartners/ShopifyCustomerAdapter',
      description:
        'Customer Information Backend Support for the FrontEnd. This helps some shortcomings of the way Shopify implements things like email list sign up. This app is newer, tiny, and never updated.',
      services: [
        {
          name: 'ShopifyCustomerAdapter.Shopify',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This runs scheduled jobs to do the appropriate actions in Shopify Stores.',
          location: 'Azure Serverless - shopifycustomeradapter',
          language: 'C# & React',
          framework: 'Azure Functions',
        },
      ],
    },
    {
      name: 'Shopify UX Checkup',
      link: 'https://github.com/eformedpartners/ShopifyUXCheckup',
      description:
        'Shopify App that continually monitors articles pages to ensure there are no bad customer experiences occurring, things like 404 links. This app is newer and never updated.',
      services: [
        {
          name: 'ShopifyUXCheckup.Storage',
          type: 'Storage',
          variant: 'info',
          description: 'This holds the settings for the app.',
          location: 'Azure Storage Tables - uxcheckuppersistent',
          language: 'NoSQL',
          framework: 'Azure Storage',
        },
        {
          name: 'ShopifyUXCheckup.WebApp',
          type: 'Front-end',
          variant: 'success',
          description:
            'This provides both the Admin UI and the API for the Shopify app, and allows changing of settings from within Shopify.',
          location: 'Azure Web App for Containers - uxcheckup',
          language: 'C# & React',
          framework: 'ASP.Net Core',
        },
        {
          name: 'ShopifyUXCheckup.Core',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This provides shared functionality amongst the other services.',
          location: 'Shared',
          language: 'C#',
          framework: 'Class Libraries',
        },
        {
          name: 'ShopifyUXCheckup.DataProcessing',
          type: 'Back-end',
          variant: 'warning',
          description: 'This does the actual web scraping and link checking.',
          location: 'Azure Serverless - uxcheckupdataprocessing',
          language: 'C#',
          framework: 'Azure Functions',
        },
      ],
    },
    {
      name: 'Standardized Colors',
      link: 'https://github.com/eformedpartners/standardizedcolorsapp',
      description:
        'Shopify App that tags products with the colors involved, and uses AI Vision to aid in the effort. This app is extremely old and rarely updated, and is the only app that is using .Net Framework instead of Core.',
      services: [
        {
          name: 'StandardizedColors.Storage',
          type: 'Storage',
          variant: 'info',
          description: 'This holds the settings for the app.',
          location: 'Azure Storage Tables - standardcolorspersistent',
          language: 'NoSQL',
          framework: 'Azure Storage',
        },
        {
          name: 'StandardizedColors.AI',
          type: 'AI',
          variant: 'primary',
          description: 'This is the computer vision engine.',
          location: 'Azure Cognitive Services - eformedcomputervision',
          language: 'None',
          framework: 'Azure Cognitive Services',
        },
        {
          name: 'StandardizedColors.API',
          type: 'Front-end',
          variant: 'success',
          description:
            'This provides both the Admin UI and the API for the Shopify app, and allows changing of settings from within Shopify. It also does the color and image checking and tagging of products',
          location: 'Azure Serverless - standardizedcolors',
          language: 'C# & React',
          framework: 'Azure Functions v1',
        },
      ],
    },
    {
      name: 'Wish Lists & Gift Registries',
      link: 'https://github.com/eformedpartners/listsregistries',
      description:
        'Shopify App for Wish List and Gift Registries. This is a medium age app, but has had many updates. That being said, it could probably use some more and is only live on one of the sites.',
      services: [
        {
          name: 'ListsRegistries.Storage',
          type: 'Storage',
          variant: 'info',
          description:
            'This holds all of the settings for the app and the lists themselves.',
          location: 'Azure SQL - WishListsGiftRegistries',
          language: 'SQL',
          framework: 'Azure SQL',
        },
        {
          name: 'ListsRegistries.API',
          type: 'Front-end',
          variant: 'success',
          description:
            'This provides both the Admin UI and the API for the Shopify app, and allows changing of settings from within Shopify.',
          location: 'Azure Web App for Containers - listsregistriesapi',
          language: 'C# & React',
          framework: 'ASP.NET Core',
        },
        {
          name: 'ListsRegistries.AppProxy',
          type: 'Front-end',
          variant: 'success',
          description:
            'This is the actual front end the customer uses when using lists on the sites. It is written in React and served through ASP.NET Core and some Razor Pages (needed for Shopify App Proxy requirements).',
          location: 'Azure Web App for Containers - listsregistries',
          language: 'C# & React',
          framework: 'ASP.NET Core',
        },
        {
          name: 'ListsRegistries.Core',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This provides shared functionality amongst the other services.',
          location: 'Shared',
          language: 'C#',
          framework: 'Class Libraries',
        },
        {
          name: 'ListsRegistries.DataProcessing',
          type: 'Back-end',
          variant: 'warning',
          description:
            'This does some light data processing, things like order tracking.',
          location: 'Azure Serverless - listdataproc',
          language: 'C#',
          framework: 'Azure Functions',
        },
      ],
    },
  ],
};

export default frontEnd;
