import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import * as Settings from '../../Settings';
import RouteVariableProps from '../../interfaces/RouteVariableProps';
import rest from '../../utils/Rest';
import errorHandler from '../../utils/ErrorHandler';

import { TextField } from "../Shared/EFBootstrap";

export interface MarketplaceVariantGoToProps {
  marketplaceId: string;
  apiKey: string;
}

const MarketplaceVariantGoTo = (props: MarketplaceVariantGoToProps) => {
  const [ marketplaceVariantId, setMarketplaceVariantId ] = useState("");
  const [ productId, setProductId ] = useState("");
  const { marketplaceId, apiKey } = props;

  useEffect(() => {
    if (marketplaceVariantId !== undefined && marketplaceVariantId !== "") getProductId();
  }, [marketplaceVariantId]);

  const getProductId = async () => {
    try {
      let data = await rest.get(
        `${
        Settings.eformedApiBaseUri
        }marketplace/${marketplaceId}/variants/${marketplaceVariantId}?key=${apiKey}`
      );

      setProductId(data);
    } catch (error) {
    }
  }

  return (
    <Row className="mt-2">
      <Col>
        <TextField
          label="Marketplace Variant Id"
          value={marketplaceVariantId}
          onChange={newValue => {
            setMarketplaceVariantId(newValue);
          }}
          placeholder={"00000000-0000-0000-0000-000000000000"}
          helpText="Use this to specifically navigate to a product using it's variant ID"
        />
      </Col>
      <Col lg="2" className="align-self-center text-right">
        <LinkContainer
          exact
          to={`/marketplace/${marketplaceId}/products/${productId}`}
        >
          <Button disabled={marketplaceVariantId === "" || productId === ""} variant="info">
            Go To
          </Button>
        </LinkContainer>
      </Col>
    </Row>
  );
};

export default MarketplaceVariantGoTo;
