import React from "react";
import { Row, Col, Button, Badge, Spinner } from "react-bootstrap";

import MarketplaceProductProps from "../../../../interfaces/MarketplaceProductProps";

interface ArchiveAreaProps extends MarketplaceProductProps {
  downloadProductInfo: () => void;
}

const ArchiveArea = (props: ArchiveAreaProps) => {
  const { product, isEditing, isDownloading, downloadProductInfo } = props;
  return (
    <Row className="mt-1">
      <Col className="align-self-center text-center">
        {product.ignored === true && (
          <Badge pill variant="warning">
            Ignored
          </Badge>
        )}
        {product.archived && (
          <Badge pill variant="danger">
            Archived
          </Badge>
        )}
      </Col>
      <Col xs="auto">
        <Button
          size="sm"
          disabled={isEditing}
          variant="outline-success"
          onClick={() => downloadProductInfo()}
        >
          {isDownloading ?
          <React.Fragment>
            <Spinner size='sm' animation='border' />
            Downloading...
          </React.Fragment>
          : "Download"
          }
        </Button>
      </Col>
      <Col xs="auto" className="align-self-center text-right">
        {product.archived && <IgnoreButton {...props} />}
        <ArchiveButton {...props} />
      </Col>
    </Row>
  );
};

const IgnoreButton = (props: MarketplaceProductProps) => {
  const { product, isEditing, handleProductChange } = props;
  return (
    <Button
      size="sm"
      disabled={!isEditing}
      variant={product.ignored === true ? "outline-success" : "outline-warning"}
      onClick={() =>
        handleProductChange("ignored", product.ignored === true ? false : true)
      }
    >
      {product.ignored === true ? "Unignore" : "Ignore"}
    </Button>
  );
};

const ArchiveButton = (props: MarketplaceProductProps) => {
  const { product, isEditing, handleProductChange } = props;

  const handleArchivingProduct = () => {        
    handleProductChange(
      "archived",
      product.archived === true ? false : true
    )

    if (!product.archived) {
      handleProductChange("ignored", false);
    }
  }

  return (
    <Button
      size="sm"
      disabled={!isEditing}
      variant={product.archived === true ? "outline-success" : "outline-danger"}
      onClick={() => handleArchivingProduct()}
    >
      {product.archived === true ? "Unarchive" : "Archive"}
    </Button>
  );
};

export default ArchiveArea;
