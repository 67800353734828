import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import { OrderLineItemStatus } from '../../../../classes/Marketplace/OrderLineItem';

const LineItemStatusDropdown = (props) => {
  const { lineItem, isProcessing, changeLineItemStatus } = props;
  const lineItemStatus = lineItem.status !== undefined && lineItem.status !== null && lineItem.status !== "" ? lineItem.status : "no status";
  return (
    <div>
      <DropdownButton
        disabled={isProcessing}
        //as={ButtonGroup}
        key={lineItem.id}
        id={`dropdown-button-drop-${lineItem.id}`}
        size="sm"
        variant="secondary"
        title={lineItemStatus}
      >
        <Dropdown.Item eventKey="1" onClick={() => changeLineItemStatus(OrderLineItemStatus.Created)}>{OrderLineItemStatus.Created}</Dropdown.Item>
        <Dropdown.Item eventKey="2" onClick={() => changeLineItemStatus(OrderLineItemStatus.Acknowledged)}>{OrderLineItemStatus.Acknowledged}</Dropdown.Item>
        <Dropdown.Item eventKey="3" onClick={() => changeLineItemStatus(OrderLineItemStatus.Shipped)}>{OrderLineItemStatus.Shipped}</Dropdown.Item>
        <Dropdown.Item eventKey="4" onClick={() => changeLineItemStatus(OrderLineItemStatus.Cancelled)}>{OrderLineItemStatus.Cancelled}</Dropdown.Item>
        <Dropdown.Item eventKey="4" onClick={() => changeLineItemStatus(OrderLineItemStatus.Refund)}>{OrderLineItemStatus.Refund}</Dropdown.Item>
      </DropdownButton>
  </div>
  );
}

export default LineItemStatusDropdown;