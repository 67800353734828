export const FIREBASE_CONFIG = {
  API_KEY: 'AIzaSyAPDI7ybuzzbkVc7XwN3715C-udg-kuIOc',
  AUTH_DOMAIN: 'eformed-api.firebaseapp.com',
  DATABASE_URL: 'https://eformed-api.firebaseio.com',
  PROJECT_ID: 'eformed-api',
  STORAGE_BUCKET: 'eformed-api.appspot.com',
  MESSAGING_SENDER_ID: '774689104376',
};

export const COST_MULTIPLIER = {
  DEFAULT_VALUE: 1.4,
  UPPER_BOUND: 2,
  LOWER_BOUND: 1
}

export const INVENTORY_POLICY = {
  CONTINUE: 'continue',
  DENY: 'deny',
  DISABLE: 'disable'
}

export const CHANNEL_TYPE = {
  SHOPIFY: 'Shopify',
  WALMART: 'Walmart',
  WALMART_MKP: 'WalmartMKP'
}

export const WALMART_FEED_TYPE = {
  INVENTORY: 'inventory',
  PARTNER: 'partner'
}

export const CHANNEL_PUBLISH_STATUS = {
  PUBLISHED: 'Published',
  UNPUBLISHED: 'Unpublished'
}

export const ALERT_HIDE_TIME = 8000; // 8 seconds