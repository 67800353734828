import React from 'react';
import { Nav, Navbar, NavDropdown, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import { firebaseApp } from '../../utils/Firebase';

import InnerNavMenu from './Nav/InnerNavMenu';
import NotificationPopover from './NotificationPopover';
import RoutingProps from '../../interfaces/RoutingProps';

const NavMenu = (props: RoutingProps) => {
  return (
    <Navbar id='top' bg='dark' variant='dark' collapseOnSelect expand='lg'>
      <Navbar.Toggle aria-controls='responsive-navbar-nav' />
      <Navbar.Collapse id='responsive-navbar-nav'>
        <Navbar.Brand href='/'>eFormed Portal</Navbar.Brand>
        <Nav className='mr-auto' activeKey={props.location.pathname}>
          <LinkContainer to='/home' exact>
            <Nav.Link>Home</Nav.Link>
          </LinkContainer>
          <InnerNavMenu {...props} />
        </Nav>
      </Navbar.Collapse>
      <Nav className="justify-content-center" style={{ flexDirection: 'row'}}>
        <NavItem>
          <Nav.Link>
            <NotificationPopover
              show={props.showNotificationPanel}
              showHideNotificationPanel={props.showHideNotificationPanel}
              alerts={props.alerts}
              removeAlert={props.removeAlert}
              removeAllAlerts={props.removeAllAlerts}
            />
          </Nav.Link>
        </NavItem>
        <NavDropdown
          className={props.username === undefined ? '' : 'active'}
          title={
            props.username === undefined
              ? 'Not Logged In'
              : props.app === undefined
              ? 'No App Selected'
              : props.app + ' App'
          }
          id='user-dropdown'
        >
          <NavDropdown.Header>Apps</NavDropdown.Header>
          {(props.user.admin === true || props.user.sellerAdmin === true) && (
            <LinkContainer
              to={props.user.admin === true ? '/admin/users' : '/admin/sellers'}
            >
              <NavDropdown.Item
                className={props.app === 'Admin' ? 'active' : ''}
                onClick={() => props.changeApp('Admin')}
              >
                Admin
              </NavDropdown.Item>
            </LinkContainer>
          )}
          {props.user.babywise === true && (
            <LinkContainer to='/familywise'>
              <NavDropdown.Item
                className={props.app === 'Familywise' ? 'active' : ''}
                onClick={() => props.changeApp('Familywise')}
              >
                Familywise
              </NavDropdown.Item>
            </LinkContainer>
          )}
          {props.user.logisticsCompanies !== undefined &&
            props.user.logisticsCompanies.length > 0 && (
              <LinkContainer to='/logistics/po'>
                <NavDropdown.Item
                  className={props.app === 'Logistics' ? 'active' : ''}
                  onClick={() => {
                    props.changeApp('Logistics');
                    props.setLogisticsCompany(props.user.logisticsCompanies[0]);
                  }}
                >
                  Logistics
                </NavDropdown.Item>
              </LinkContainer>
            )}
          {props.user.marketplaces !== undefined &&
            props.user.marketplaces.length > 0 && (
              <LinkContainer to='/marketplace'>
                <NavDropdown.Item
                  className={props.app === 'Marketplace' ? 'active' : ''}
                  onClick={() => {
                    props.changeApp('Marketplace');
                    props.setMarketplace(props.user.marketplaces[0]);
                  }}
                >
                  Marketplace
                </NavDropdown.Item>
              </LinkContainer>
            )}
          {props.user.sellers !== undefined && props.user.sellers.length > 0 && (
            <LinkContainer to={`/seller`}>
              <NavDropdown.Item
                className={props.app === 'Seller' ? 'active' : ''}
                onClick={() => {
                  props.changeApp('Seller');
                  props.setSeller(props.user.sellers[0]);
                }}
              >
                Seller
              </NavDropdown.Item>
            </LinkContainer>
          )}
          <NavDropdown.Divider />
          {props.username !== undefined && props.username !== null && (
            <NavDropdown.Item
              onClick={() => {
                firebaseApp.auth().signOut();
              }}
            >
              Sign Out
            </NavDropdown.Item>
          )}
        </NavDropdown>
      </Nav>
    </Navbar>
  );
};

export default NavMenu;
