import React from "react";
import { Row, Col, Collapse } from "react-bootstrap";

import setMarketplaceFromId from "../../../../utils/SetMarketplaceFromId";

import RouteVariableProps from "../../../../interfaces/RouteVariableProps";

import PriceSchedulerResults from "./PriceSchedulerResults";
import PriceSchedulerEntry from "./PriceSchedulerEntry";

interface MatchParams {
  marketplaceId: string;
}

export interface PriceSchedulerProps
  extends RouteVariableProps<MatchParams>,
    PriceSchedulerState {
  setResults: (any) => void;
}

export interface PriceSchedulerState {
  results: any;
}

export default class PriceScheduler extends React.Component<
  RouteVariableProps<MatchParams>
> {
  state = {
    results: undefined,
    hasResults: false
  };

  componentDidMount() {
    if (this.props.match.params.marketplaceId !== undefined) {
      this.initialSetMarketplace();
    }
  }

  initialSetMarketplace = async () => {
    let marketplaceId = this.props.match.params.marketplaceId;
    const { selectedMarketplace, setMarketplace, user } = this.props;

    if (selectedMarketplace === undefined) {
      await setMarketplaceFromId(
        marketplaceId,
        setMarketplace,
        user,
        this.props.history
      );
    }
  };

  setResults = results => {
    this.setState({ results: results, hasResults: true });
  };

  render() {
    const { hasResults } = this.state;
    return (
      <React.Fragment>
        <Row className="mt-5">
          <Col>
            <h1>Price Schedule Creator</h1>
          </Col>
        </Row>
        <Collapse in={hasResults}>
          <Row>
            <Col>
              <PriceSchedulerResults
                {...this.props}
                {...this.state}
                setResults={this.setResults}
              />
            </Col>
          </Row>
        </Collapse>
        <PriceSchedulerEntry
          {...this.props}
          {...this.state}
          setResults={this.setResults}
        />
      </React.Fragment>
    );
  }
}
