import * as React from "react";
import { Container, Row, Col, Spinner, Card } from "react-bootstrap";

import { RouteComponentProps } from "react-router-dom";

import PrintablePurchaseOrder from "./ViewPurchaseOrder/PrintablePurchaseOrder";

import * as Settings from "../../../Settings";
import BaseProps from "../../../interfaces/BaseProps";
import PurchaseOrder from "../../../classes/Logistics/PurchaseOrder";
import PurchaseOrderLineItem from "../../../classes/Logistics/PurchaseOrderItem";

const eformedApiBaseUri = Settings.eformedApiBaseUri;

interface MatchParams {
  poNumber: string;
}

interface ViewPurchaseOrderProps
  extends BaseProps,
    RouteComponentProps<MatchParams> {}

interface ViewPurchaseOrderState {
  isLoading: boolean;
  purchaseOrder: PurchaseOrder;
  lineItems: Array<PurchaseOrderLineItem>;
}

export default class ViewPurchaseOrder extends React.Component<
  ViewPurchaseOrderProps,
  ViewPurchaseOrderState
> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      purchaseOrder: new PurchaseOrder("babywise-life.myshopify.com"),
      lineItems: []
    };
  }

  componentDidMount() {
    this.getPurchaseOrder();
    alert(
      "View, print, when done, click the bill to company name to go back, or use your browsers back button."
    );
  }

  getPurchaseOrder = () => {
    const poNumber = this.props.match.params.poNumber;
    this.setState({ isLoading: true });

    if (poNumber !== undefined && poNumber !== null) {
      fetch(`
        ${eformedApiBaseUri}po/purchaseorders/${poNumber}?key=${
        this.props.apiKey
      }&shop=babywise-life.myshopify.com`)
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (
            data.purchaseOrder.shipByDate === undefined ||
            data.purchaseOrder.shipByDate === null
          ) {
            data.purchaseOrder.shipByDate = "ASAP";
          }

          if (
            data.purchaseOrder.purchaseOrderDiscount === undefined ||
            data.purchaseOrder.purchaseOrderDiscount === null
          ) {
            data.purchaseOrder.poLevelDiscount = 0;
          }

          this.setState({
            purchaseOrder: data.purchaseOrder,
            lineItems: data.lineItems,
            isLoading: false
          });
        });
    } else {
      alert("No PO Number!");
    }
  };

  render() {
    const { purchaseOrder } = this.state;

    if (this.state.isLoading) {
      return (
        <Container>
          <br />
          <br />
          <br />
          <br />
          <br />
          <Row className="justify-content-center">
            <Col>
              <Spinner animation="border" />
            </Col>
          </Row>
        </Container>
      );
    } else if (purchaseOrder === undefined || purchaseOrder === null) {
      return (
        <Container>
          <Card>Nothing to show here...</Card>
        </Container>
      );
    } else {
      return (
        <Container>
          <PrintablePurchaseOrder
            purchaseOrder={this.state.purchaseOrder}
            history={this.props.history}
          />
        </Container>
      );
    }
  }
}
