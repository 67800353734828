import React from 'react';
import { Row, Col, Button, Badge, Form } from 'react-bootstrap';
import { Select } from '../../Shared/EFBootstrap';

import { UserLineProps } from './UserLine';
import SellerPermission from '../../../classes/Seller/SellerPermission';

interface SellerSelectorProps extends UserLineProps {
  edit: boolean;
  handleChange: (settingName: string, newValue: any) => void;
}

const formatOptions = sellers => {
  return sellers.map((seller, index) => {
    return { label: seller.name, value: seller.id };
  });
};

export default class SellerSelector extends React.Component<
  SellerSelectorProps
> {
  state = {
    addSeller: '0',
  };

  handleAdd = (sellerId: string) => {
    const thisUser = this.props.user;
    const newUsers = this.props.users.map((user, iindex) => {
      if (thisUser.id !== user.id) {
        return user;
      } else {
        let tempSellers = user.sellers;

        let tempSeller = this.props.sellers.filter(
          seller => seller.id === sellerId,
        )[0];
        let tempPermission = new SellerPermission(
          tempSeller.id,
          tempSeller.name,
        );

        if (
          tempSellers.filter(tempSeller => tempSeller.id === tempPermission.id)
            .length === 0
        ) {
          tempSellers.push(tempPermission);
          user.sellers = tempSellers;
        }

        return user;
      }
    });

    this.props.setParentState({ users: newUsers });
  };

  addAll = () => {
    this.props.sellers
      .filter(s => s.id !== '0')
      .forEach(seller => {
        this.handleAdd(seller.id);
      });
  };

  handleRemove = (indexParam: number) => {
    const thisUser = this.props.user;
    const newUsers = this.props.users.map((user, iindex) => {
      if (thisUser.id !== user.id) {
        return user;
      } else {
        return {
          ...user,
          sellers: user.sellers.filter(
            (seller, iiindex) => indexParam !== iiindex,
          ),
        };
      }
    });

    this.props.setParentState({ users: newUsers });
  };

  setIsAllowAllSellers = (event) => {
    this.props.handleChange('isAllowAllSellers', event.target.checked);
  }

  render() {
    const options = formatOptions(this.props.sellers);
    if (this.props.edit === true) {
      return (
        <React.Fragment>
          <Row>
            <Col xs='auto'>Sellers</Col>
            <Col>
              {this.props.user.isAllowAllSellers !== true ?
              (this.props.user.sellers.length > 0 ? (
                this.props.user.sellers.map((seller, index) => (
                  <Badge
                    key={index}
                    pill
                    variant='danger'
                    onClick={() => this.handleRemove(index)}
                  >
                    {seller.name} x
                  </Badge>
                ))
              ) : (
                <Badge pill variant='primary'>
                  None
                </Badge>
              )) :
                <Badge pill variant='primary'>
                  Allow All sellers
                </Badge>
              }
            </Col>
          </Row>
          <Row className="my-2">
            <Col>
              <Form.Check 
                type="checkbox"
                label="Is Allow All Sellers"
                checked={this.props.user.isAllowAllSellers}
                onChange={this.setIsAllowAllSellers}
              />
            </Col>
          </Row>
          {this.props.user.isAllowAllSellers !== true &&
          <React.Fragment>
            <Row>
              <Col>
                <Select
                  label='Seller'
                  value={this.state.addSeller}
                  options={options}
                  onChange={newValue => {
                    this.setState({ addSeller: newValue });
                  }}
                />
              </Col>
              <Col className='align-self-center' xs='auto'>
                <Button
                  size='sm'
                  disabled={this.state.addSeller === '0'}
                  variant='outline-success'
                  onClick={() => this.handleAdd(this.state.addSeller)}
                >
                  Add
                </Button>
                <Button size='sm' variant='success' onClick={() => this.addAll()}>
                  Add All
                </Button>
              </Col>
            </Row>
          </React.Fragment>
          }
        </React.Fragment>
      );
    } else {
      return (
        <Row>
          <Col xs='auto'>Sellers</Col>
          <Col>
            {this.props.user.isAllowAllSellers !== true ? (this.props.user.sellers.length > 0 ? (
              this.props.user.sellers.map((seller, index) => (
                <Badge key={index} pill variant='primary'>
                  {seller.name}
                </Badge>
              ))
            ) : (
              <Badge pill variant='primary'>
                None
              </Badge>
            )) : 
              <Badge pill variant='primary'>
                Allow All sellers
              </Badge>
            }
          </Col>
        </Row>
      );
    }
  }
}
