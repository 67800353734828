import React from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';

import { ShopifyChannelAreaChildProps } from './ShopifyChannelArea';

const ChannelUpdateModal = (props: ShopifyChannelAreaChildProps) => {
  const { setChannelState, showModal, isUpdating, updateMessages } = props;

  return (
    <div role="dialog" aria-modal="true" className="fade modal show" 
      style={{display: showModal ? "block" : "none", backgroundColor: "#00000094"}}
      onClick={() => setChannelState({ showModal: false })}
    >
      <div role="document" className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-title h4">{isUpdating === true ? 'Updating...' : 'Updated'}</div>
            <button type="button" className="close" onClick={() => setChannelState({ showModal: false })}>
              <span aria-hidden="true" >×</span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal-body">
          {isUpdating === true ? (
            <Row className='text-center'>
              <Col>
                <Spinner animation='border' />
              </Col>
            </Row>
          ) : (
            <React.Fragment>
              <Row>
                <Col>
                  <h6>Log Messages:</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  {updateMessages.map((message, index) => (
                    <p key={index} className='text-dark'>
                      {message}
                    </p>
                  ))}
                </Col>
              </Row>
            </React.Fragment>
          )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChannelUpdateModal;
