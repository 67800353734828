import React from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';

import * as Settings from '../../../../Settings';
import RouteVariableProps from '../../../../interfaces/RouteVariableProps';
import rest from '../../../../utils/Rest';
import errorHandler from '../../../../utils/ErrorHandler';
import setMarketplaceFromId from '../../../../utils/SetMarketplaceFromId';
import LoadingPage from '../../../Shared/LoadingPage';

import Channel from '../../../../classes/Marketplace/Channel';
import MarketplaceChannel from './MarketplaceChannel';

interface MatchParams {
  marketplaceId: string;
}

export interface MarketplaceChannelRouteProps
  extends RouteVariableProps<MatchParams> {}

export default class MarketplaceChannels extends React.Component<
  MarketplaceChannelRouteProps
  > {
  state = {
    isLoading: false,
    MarketplaceChannels: new Array<Channel>(),
    isOpen: false
  };

  componentDidMount() {
    if (this.props.match.params.marketplaceId !== undefined) {
      this.initialSetMarketplace();
      this.getChannels();
    }
  }

  initialSetMarketplace = async () => {
    let marketplaceId = this.props.match.params.marketplaceId;
    const { selectedMarketplace, setMarketplace, user } = this.props;

    if (selectedMarketplace === undefined) {
      await setMarketplaceFromId(
        marketplaceId,
        setMarketplace,
        user,
        this.props.history,
      );
    }
  };

  getChannels = async () => {
    const { marketplaceId } = this.props.match.params;
    const { apiKey } = this.props;
    await this.setState({ isLoading: true });
    try {
      let data: Channel[] = await rest.get(
        `${
        Settings.eformedApiBaseUri
        }marketplace/${marketplaceId}/channels?key=${apiKey}`,
      );
      await this.setState({
        MarketplaceChannels: data.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          } else {
            return 0;
          }
        }),
        isLoading: false,
      });
    } catch (error) {
      errorHandler(
        error,
        'There was an error while retrieving channels. Please try again, if this is not the first time you have seen this error, please report it.',
        this.props.addAlert,
      );
      await this.setState({ isLoading: false });
    }
  };

  render() {
    const { isLoading, MarketplaceChannels } = this.state;

    if (isLoading) {
      return (
        <React.Fragment>
          <MarketplaceChannelsHeader />
          <LoadingPage />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <MarketplaceChannelsHeader />
          <Row className='mt-5'>
            <Col>
              <ListGroup>
                {MarketplaceChannels !== undefined &&
                  MarketplaceChannels.length > 0 &&
                  MarketplaceChannels.map((marketplaceChannel, index) => (
                    <MarketplaceChannel 
                      marketplaceChannel = {marketplaceChannel}
                      {...this.props}
                    />
                  ))}
              </ListGroup>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
}

const MarketplaceChannelsHeader = () => {
  return (
    <Row>
      <Col>
        <h1>Marketplace Channels</h1>
      </Col>
    </Row>
  );
};
