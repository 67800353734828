import React from 'react';
import { useDrop } from 'react-dnd';
import update from 'immutability-helper';

import MarketplaceProduct from '../../../../../classes/Marketplace/Product';

import ImageDraggable from './ImageDraggable';

export interface ImagesDraggableProps {
  marketplaceId: string;
  product: MarketplaceProduct;
  isEditing: boolean;
  isDeleting: boolean;
  setIsLightBoxOpen: (setIsLight: boolean) => void;
  setImagePrimary: (position: string) => void;
  deleteImageModal: (imageId: string, bool: boolean) => void;
  setImagesParentState: (stateObject: any) => void;
  setParentState: (stateObject: any) => void;
}

const ImagesDraggable = (props: ImagesDraggableProps) => {
  const { product, setParentState } = props;

  let cards = product.images;
  let tempProduct = product;

  const moveCard = (id: string, atIndex: number) => {
    const { card, index } = findCard(id);
    cards = update(cards, {
        $splice: [
          [index, 1],
          [atIndex, 0, card],
        ],
    });
    
    cards.forEach(card => {
      card.position = cards.indexOf(card) + 1;
    });
    
    tempProduct.images = cards;
    setParentState({ product: tempProduct });
  }

  const findCard = (id: string) => {
    const card = cards.filter((c) => `${c.id}` === id)[0]
    return {
      card,
      index: cards.indexOf(card),
    }
  }
  
  const [, drop] = useDrop({ accept: 'card' });

  return (
    <div ref={drop} className="row">
      {product.images.map((image, index) => (
        image.handle !== undefined &&
        <ImageDraggable
          key={image.id}
          {...props}
          index={index}
          id={image.id}
          image={image}
          moveCard={moveCard}
          findCard={findCard}
        />
      ))}
    </div>
  )
}


export default ImagesDraggable;