import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Select } from '../../Shared/EFBootstrap';

import { SellerLogsState } from '../SellerLogs';

interface SellerLogFiltersProps extends SellerLogsState {
  setSellerLogsState: (any) => void;
  resetPage: () => void;
}

const SellerLogFilters = (props: SellerLogFiltersProps) => {
  const { dropShipType, status, setSellerLogsState, resetPage } = props;

  return (
    <Row className='mt-5'>
      <Col>
        <Select
          label='Drop Ship Type'
          options={[
            { label: 'Any', value: 'any' },
            { label: 'Inventory', value: 'Inventory' },
            { label: 'Products', value: 'Products' },
          ]}
          value={dropShipType}
          onChange={newValue => setSellerLogsState({ dropShipType: newValue })}
        />
      </Col>
      <Col>
        <Select
          label='Status'
          options={[
            { label: 'Any', value: 'any' },
            { label: 'Not Skipped', value: 'NotSkipped' },
            { label: 'Success', value: 'Success' },
            { label: 'Failure', value: 'Failure' },
            { label: 'Skipped', value: 'Skipped' },
            { label: 'Timed Out', value: 'Timed Out' },
          ]}
          value={status}
          onChange={newValue => setSellerLogsState({ status: newValue })}
        />
      </Col>
      <Col className='mt-4'>
        <Button variant='info' onClick={() => resetPage()}>
          Apply / Reload Logs
        </Button>
      </Col>
    </Row>
  );
};

export default SellerLogFilters;
