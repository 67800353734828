import rest from '../../../../../utils/Rest';
import errorHandler from '../../../../../utils/ErrorHandler';
import * as Settings from '../../../../../Settings';

import MarketplaceChannel from '../../../../../classes/Marketplace/Channel';

const getMarketplaceChannels = async (props, setParentState) => {
  const { marketplaceId } = props.match.params;
  const { apiKey } = props;
  try {
    let data: MarketplaceChannel[] = await rest.get(
      `${
        Settings.eformedApiBaseUri
      }marketplace/${marketplaceId}/channels?key=${apiKey}`,
    );

    setParentState({
      channels: data.filter(channel => channel.active !== false),
    });
  } catch (error) {
    errorHandler(
      error,
      'There was an error while retrieving the marketplace channels. Please try again, if this is not the first time you have seen this error, please report the issue.',
      props.addAlert,
    );
  }
};

export default getMarketplaceChannels;
